import { useContext, useEffect, useState } from "react";
import { Button, Card, CardGroup, Carousel, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { AppContext } from "../state/State";
import { Link, useLocation } from "react-router-dom";
import { Definitions, fixMediaUrl, Language } from "../data/Definitions";
import { HomePage, NewsPost } from "../models/PiranhaModels";
import TreeMap from "../components/TreeMap";
import { InfoCircle } from "react-bootstrap-icons";

const Home: React.FC = (props) => {
    const loc = useLocation();
    const context = useContext(AppContext);
    const [selectedHighlight, setSelectedHighlight] = useState(0);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "" });
    }, [loc.pathname]);

    let [homePage, setHomePage] = useState<HomePage | null>(null);
    let [newsList, setNewsList] = useState<{ total: number, pageSize: number, page: Array<NewsPost> } | null>(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        async function loadHomePage() {
            const homePageId = Definitions.homePageSlug[i18n.resolvedLanguage as Language];
            let response = await fetch(`/api/pages/${homePageId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                setHomePage(await response.json() as HomePage);
            }

            const newsPageId = Definitions.newsPageSlug[i18n.resolvedLanguage as Language];
            response = await fetch(`/api/news/${newsPageId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                const news = await response.json();
                setNewsList({ total: news.total, pageSize: news.pageSize, page: news.page.$values });
            }
        }

        loadHomePage();
    }, [i18n.resolvedLanguage]);

    return (
        <>
            {(homePage?.regions?.MainCarousel?.$values?.length ?? 0) > 0 && <Carousel className="main-carousel">
                {homePage?.regions.MainCarousel.$values.map((x, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img src={fixMediaUrl(x.Image?.media?.publicUrl)} />
                            <Carousel.Caption>
                                <h1>{x.Title.value}</h1>
                                <div dangerouslySetInnerHTML={{ __html: x.Body.value }}></div>
                                {x.Link.value && <p>{x.Link.value.startsWith("http") ? <a className="carousel-learn-more" href={x.Link.value} target="_blank" rel="noreferrer noopener">{t("Learn more")}</a> : <Link className="carousel-learn-more" to={x.Link.value}>{t("Learn more")}</Link>}</p>}
                            </Carousel.Caption>
                        </Carousel.Item>);
                })}
            </Carousel>}

            <Container fluid>
                <hr className="featurette-divider" />

                {homePage?.regions?.ProjectDescription &&
                    <>
                        <div className="row justify-content-center py-5" style={{ backgroundColor: "#f3f3f3" }}>
                            <h2 className="text-center pb-3 pt-0">{t("About the project")}</h2>
                            <div className="col-md-6 d-flex align-items-center">
                                <div className="ms-md-5 me-md-5">
                                    <h3 className="py-2">{homePage.regions.ProjectDescription.Title.value}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: homePage.regions.ProjectDescription.Body.value }}></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img width="500" height="500" className="w-100 mh-100" src={fixMediaUrl(homePage.regions.ProjectDescription?.Image?.media?.publicUrl)} />
                            </div>
                        </div>
                    </>
                }

                <hr className="featurette-divider" />

                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <h2 className="text-center pb-3 pt-0">{t("Highlights")}</h2>
                        {(homePage?.regions?.ProjectHighlights?.$values?.length ?? 0) > 0 && <><Carousel className="carousel-highlights" indicators={false} interval={null} activeIndex={selectedHighlight} onSelect={(key, ev) => setSelectedHighlight(key)}>
                            {homePage?.regions.ProjectHighlights.$values.map((x, index) => {
                                return (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block"
                                            src={fixMediaUrl(x.Image?.media?.publicUrl)}
                                        />
                                        <Carousel.Caption>
                                            <h3>{x.Title.value}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: x.Body.value }}></p>
                                            {x.Link.value && <p>{x.Link.value.startsWith("http") ? <a className="carousel-learn-more" href={x.Link.value} target="_blank" rel="noreferrer noopener">{t("Learn more")}</a> : <Link className="carousel-learn-more" to={x.Link.value}>{t("Learn more")}</Link>}</p>}
                                        </Carousel.Caption>
                                    </Carousel.Item>);
                            })}
                        </Carousel>
                            <Row className="m-0" style={{ boxShadow: "2px 2px 5px #ddd" }}>
                                {homePage?.regions.ProjectHighlights.$values.map((x, index) => <Col key={index} onClick={() => setSelectedHighlight(index)} className={"text-center py-5 carousel-highlights-tab " + (index === selectedHighlight ? "selected" : "")}>
                                    <div className="mb-2">
                                        {x.Icon?.media?.publicUrl
                                            ?
                                            <img alt={`icon-${index}`} src={fixMediaUrl(x.Icon?.media?.publicUrl)} style={{ maxWidth: "25%", maxHeight: "100px" }} />
                                            :
                                            <InfoCircle size={"60px"} />
                                        }
                                    </div>
                                    <br />
                                    {x.Title.value}
                                </Col>)}
                            </Row>
                        </>}
                    </Col>
                </Row>

                <hr className="featurette-divider" />

                {(homePage?.regions?.IndicatorsData?.$values?.length ?? 0) > 0 && <div className="row g-3" style={{ color: "white", justifyContent: "space-evenly" }}>
                    {homePage?.regions.IndicatorsData.$values.map((x, i, r) => {
                        return (
                            <a href={x.IndicatorLink.value} target="_blank" style={{ textDecoration: "none", color: "inherit", backgroundColor: "#6cc6f0", maxWidth: "250px", height: "250px", borderRadius: "50%", display: "flex", flexDirection: "column" }} key={i} className="col-sm-12 align-items-center text-center justify-content-center">
                                <Row className="mb-2">
                                    <Col>
                                        {
                                            x.IndicatorIcon?.media?.publicUrl
                                                ?
                                                <img alt={`icon-${i}`} src={fixMediaUrl(x.IndicatorIcon?.media?.publicUrl)} style={{ filter: "grayscale(100) invert(100) brightness(100)", maxWidth: "120px", maxHeight: "120px" }} />
                                                :
                                                <InfoCircle size={60} />
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h1>{x.IndicatorValue.value}</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="px-4">
                                        <h5>{x.IndicatorName.value}</h5>
                                    </Col>
                                </Row>
                            </a>);
                    })}
                </div>}

                {newsList &&
                    <>
                        <hr className="featurette-divider" />
                        <h2 className="text-center pb-3 pt-0">{t("News")}</h2>
                        <Row xs={1} md={2} lg={4} className="g-3 news justify-content-center">
                            {newsList.page.map(x => {
                                return (
                                    <Col key={x.id}>
                                        <Card className="w-100 h-100">
                                            {(x.primaryImage && x.primaryImage.hasValue) && <Card.Img variant="top" src={fixMediaUrl(x.primaryImage?.media?.publicUrl)} />}
                                            <Card.Body>
                                                <Card.Title>{x.title}</Card.Title>
                                                <Card.Text>
                                                    {x.excerpt}
                                                </Card.Text>
                                                <div style={{ height: "40px" }}>
                                                    <Link className="btn btn-outline-secondary float-end" role="button" to={`/announcements/${x.slug}`}>{t("More")}</Link>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                }
                <hr className="featurette-divider" />
                <h2 className="text-center pb-3 pt-0">{t("Project data")}</h2>
                <Row>
                    <Col sm={12} md={{ span: 8, offset: 2 }}>
                        {/* <ReactApexChart options={treeMapOptions} series={getTreeMapSeries()} type="treemap" height={350} /> */}
                        <TreeMap data={homePage?.regions?.ProjectData} />
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default Home;
