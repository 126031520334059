import { Row, Col, Form, ButtonGroup, Button, Table, Alert, Modal, Image } from "react-bootstrap";
import { Image as EmptyImage, PlusCircle, PencilFill, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Formik, useFormikContext } from "formik";
import { DocumentTypes, WastewaterTreatementPlantFile } from "../../data/Definitions";
import { useRef, useState } from "react";

const WtpManageFiles: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [documentType, setDocumentType] = useState<DocumentTypes>(DocumentTypes.Additional);
    const [addFileModalShow, setAddFileModalShow] = useState(false);
    const addFileFormRef = useRef<any>(null);
    const [editFileModalShow, setEditFileModalShow] = useState(false);
    const editFileFormRef = useRef<any>(null);
    const [selectedFile, setSelectedFile] = useState<WastewaterTreatementPlantFile | null>(null);

    const handleAddFileSubmit = (val: any) => {
        if (!val.file.name || !val.file.size || !val.file.type || !val.title) return;
        let cur = (values as any).files;
        cur.push({
            title: val.title || null,
            path: val.file.name || null,
            fileType: val.file.type || null,
            size: val.file.size || null,
            public: val.public,
            documentType: documentType,
            added: true,
        });
        setFieldValue("files", cur);
        cur = (values as any).filesToUpload;
        if (!cur) cur = [];
        cur.push(val.file);
        setFieldValue("filesToUpload", cur);
        setAddFileModalShow(false);
        setSelectedFile(null);
    };

    const handleEditFileSubmit = (val: any) => {
        if (!selectedFile) return;
        if (!val.title) return;
        let cur = (values as any).files;
        let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantFile) => x === selectedFile);
        if (idxToEdit === -1) return;
        cur[idxToEdit] = Object.assign(selectedFile, {
            title: val.title,
            public: val.public,
        });
        setFieldValue("files", cur);
        setEditFileModalShow(false);
        setSelectedFile(null);
    };

    const removeFile = () => {
        if (!selectedFile) return;
        let cur = (values as any).files;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantFile) => x === selectedFile);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("files", cur);
        cur = (values as any).filesToUpload;
        if (cur) {
            idxToRemove = cur.findIndex((x: any) => x.name === selectedFile.path);
            if (idxToRemove > -1) {
                cur.splice(idxToRemove, 1);
                setFieldValue("filesToUpload", cur);
            }
        }
        setSelectedFile(null);
    }

    const getSelectedFileImageUrl = () => {
        if (!selectedFile) return;
        let cur = (values as any).filesToUpload;
        if (cur) {
            let idx = cur.findIndex((x: any) => x.name === selectedFile.path);
            if (idx > -1) {
                return URL.createObjectURL(cur[idx]);
            }
        }

        return selectedFile.path || undefined;
    }

    return (
        <>
            <Row>
                <Col>
                    <h4>{t("wtp.files.environmental_terms.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.EnvironmentalTerms); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.EnvironmentalTerms); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.EnvironmentalTerms}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.EnvironmentalTerms}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.EnvironmentalTerms).length === 0 && <Alert variant={'light'} className="m-0">
                <div className="text-danger">Απαιτείται η υποβολή της Απόφασης Έγκρισης Περιβαλλοντικών Όρων της Ε.Ε.Λ. σε ηλεκτρονική μορφή.</div>
            </Alert>}
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.EnvironmentalTerms).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.EnvironmentalTerms).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.files.sensitive_receiver_fek.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.SensitiveReceiverFEK); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.SensitiveReceiverFEK); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.SensitiveReceiverFEK}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.SensitiveReceiverFEK}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {((values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.SensitiveReceiverFEK).length === 0 && (values as any).dischargePoint?.waterBody?.isSensitive) && <Alert variant={'light'} className="m-0">
                <div className="text-danger">Για ευαίσθητους αποδέκτες απαιτείται η υποβολή του ΦΕΚ χαρακτηρισμού.</div>
            </Alert>}
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.SensitiveReceiverFEK).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.SensitiveReceiverFEK).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.files.risk_management_plans.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.RiskManagementPlans); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.RiskManagementPlans); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.RiskManagementPlans}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.RiskManagementPlans}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.RiskManagementPlans).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.RiskManagementPlans).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.files.reuse_no_compliance.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.ReuseNoCompliance); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.ReuseNoCompliance); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.RiskManagementPlans}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.ReuseNoCompliance}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.ReuseNoCompliance).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.ReuseNoCompliance).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.files.photos.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.Images); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.Images); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.Images}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.Images}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.Images).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.Images).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.files.additional_files.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.Additional); setAddFileModalShow(true); }}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => { setDocumentType(DocumentTypes.Additional); setEditFileModalShow(true); }} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.Additional}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeFile} disabled={!selectedFile || selectedFile.documentType !== DocumentTypes.Additional}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.public")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.Additional).length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).files.filter((x: WastewaterTreatementPlantFile) => x.documentType === DocumentTypes.Additional).map((x: WastewaterTreatementPlantFile, i: number) => <tr key={i} onClick={() => setSelectedFile(x)} className={selectedFile === x ? "selected" : ""}>
                                    <td>{x.added ? x.path : <a href={x.path || ""} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a>}</td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.public === true ? t("yes") : t("no")}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal
                show={addFileModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddFileModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t(`wtp.files.modal.add.${DocumentTypes[documentType].toLowerCase()}_header`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            file: {
                                name: "",
                                size: 0,
                                type: "",
                            },
                            title: "",
                            public: true,
                        }}
                        onSubmit={(v) => handleAddFileSubmit(v)}
                    >
                        {props => <Form noValidate ref={addFileFormRef} onSubmit={props.handleSubmit}>
                            {documentType === DocumentTypes.Images ? props.values.file.size ?
                                <div className="mb-2" style={{ margin: "auto", width: "300px", height: "300px", background: "rgba(0,0,0,0)" }}>
                                    <Image style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }} src={URL.createObjectURL(props.values.file as any)} />
                                </div> :
                                <div className="mb-2" style={{ margin: "auto", width: "300px", height: "300px", backgroundColor: "GrayText", textAlign: "center", color: "white", fontSize: "100px", lineHeight: "250px" }}>
                                    <EmptyImage />
                                </div> : <></>}
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 2 }}>{t("wtp.files.modal.file")}</Form.Label>
                                <Col md={{ span: 10 }}>
                                    <Form.Control
                                        type="file"
                                        id="file"
                                        name="file"
                                        accept={documentType === DocumentTypes.Images ? "image/*" : ""}
                                        required
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setFieldValue("file", event.target?.files ? event.target.files[0] : "")}
                                        isInvalid={props.touched.file && (!props.values.file.name || !props.values.file.size || !props.values.file.type)}
                                        isValid={props.touched.file && !!(props.values.file.name && props.values.file.size > 0 && props.values.file.type)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t("wtp.validation.required")}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 2 }}>{t("wtp.files.additional_files.title")}</Form.Label>
                                <Col md={{ span: 10 }}>
                                    <Form.Control
                                        placeholder={t("wtp.files.additional_files.title")}
                                        id="title"
                                        name="title"
                                        value={props.values.title}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.title && !props.values.title}
                                        isValid={props.touched.title && !!props.values.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t("wtp.validation.required")}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {documentType === DocumentTypes.Additional && <Form.Group as={Row} className="mb-2">
                                <Col sm={{ span: 10, offset: 2 }}>
                                    <Form.Check
                                        label={t("wtp.files.additional_files.public")}
                                        id="public"
                                        name="public"
                                        checked={props.values.public}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Form.Group>}
                        </Form>}
                    </Formik>
                    {/* <Alert variant={'light'} className="m-0">
                        <div className="text-danger">{t("wtp.files.modal.max_size")}</div>
                    </Alert> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addFileFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddFileModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editFileModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditFileModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.files.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            filename: selectedFile?.path?.split('/').pop() || "",
                            title: selectedFile?.title || "",
                            public: selectedFile?.public || true,
                        }}
                        onSubmit={(v) => handleEditFileSubmit(v)}
                    >
                        {props => <Form noValidate ref={editFileFormRef} onSubmit={props.handleSubmit}>
                            {documentType === DocumentTypes.Images ? getSelectedFileImageUrl() ?
                                <div className="mb-2" style={{ margin: "auto", width: "300px", height: "300px", background: "rgba(0,0,0,0)" }}>
                                    <Image style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }} src={getSelectedFileImageUrl()} />
                                </div> :
                                <div className="mb-2" style={{ margin: "auto", width: "300px", height: "300px", backgroundColor: "GrayText", textAlign: "center", color: "white", fontSize: "100px", lineHeight: "250px" }}>
                                    <EmptyImage />
                                </div> : <></>}
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.files.additional_files.filename")}</Form.Label>
                                <Col md={{ span: 9 }}>
                                    <Form.Control
                                        placeholder={t("wtp.files.additional_files.filename")}
                                        id="filename"
                                        name="filename"
                                        value={props.values.filename}
                                        onChange={props.handleChange}
                                        disabled
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.files.additional_files.title")}</Form.Label>
                                <Col md={{ span: 9 }}>
                                    <Form.Control
                                        placeholder={t("wtp.files.additional_files.title")}
                                        id="title"
                                        name="title"
                                        value={props.values.title}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.title && !props.values.title}
                                        isValid={props.touched.title && !!props.values.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {t("wtp.validation.required")}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {documentType === DocumentTypes.Additional && <Form.Group as={Row} className="mb-2">
                                <Col sm={{ span: 9, offset: 3 }}>
                                    <Form.Check
                                        label={t("wtp.files.additional_files.public")}
                                        id="public"
                                        name="public"
                                        checked={props.values.public}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Form.Group>}
                        </Form>}
                    </Formik>
                    {/* <Alert variant={'light'} className="m-0">
                        <div className="text-danger">{t("wtp.files.modal.max_size")}</div>
                    </Alert> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editFileFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditFileModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WtpManageFiles;
