import React from "react";
import { RLayer, RLayerProps, RContextType } from "rlayers";
import BingMaps from 'ol/source/BingMaps';
import TileLayer from "ol/layer/Tile";

/**
 * The properties interface definition
 */
interface BingLayerProps extends RLayerProps {
    accessToken: string;
}

/**
 * A component wrapper for ol/layer/MapboxVector
 */
export default class BingMap extends RLayer<BingLayerProps> {
    ol: TileLayer<BingMaps>;

    // Raster layers must extend RLayerRaster, non-tiled vector layers must extend RLayerVector
    // This allows you to have the same features as RLayers built-in components
    // Completely custom layers must extend RLayer
    constructor(
        props: Readonly<BingLayerProps>,
        context: React.Context<RContextType>
    ) {
        // You must call the parent constructor
        super(props, context);

        // You must create the this.ol object which must be compatible with the this.ol of the parent
        this.ol = new TileLayer({
            visible: false,
            preload: Infinity,
            source: new BingMaps({
                key: props.accessToken,
                imagerySet: "AerialWithLabelsOnDemand",
                maxZoom: 19
            }),
        });

        // You must enumerate all OpenLayers event sources here
        this.eventSources = [this.ol];

        // And call this function for the event handlers to work
        this.attachEventHandlers();
    }
}