import { Formik, useFormikContext } from "formik";
import React, { useState, useRef, FormEvent, useEffect, forwardRef, useContext } from "react";
import { Button, ButtonGroup, Col, Form, Row, Table, Modal } from "react-bootstrap";
import { PlusCircle, PencilFill, Trash, Plus, Pencil, PencilSquare, Lock, LockFill, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { IndustryBranch, Agglomeration, SewageNetworkType, WastewaterTreatementPlantIndustrialSectorsServed, Settlement, ElstatSettlement, SeptageServedSettlement, OriginType } from "../../data/Definitions";
import * as yup from 'yup';
import Select from 'react-select';
import { AppContext } from "../../state/State";
import { useNavigate } from "react-router-dom";
import { ConfirmationPopover } from "../../components/ConfirmationPopover";

const WtpManageWasteOrigin: React.FC<{ elstatSettlements: ElstatSettlement[], sewageNetworkTypes: SewageNetworkType[], industryBranches: IndustryBranch[], settlements: Settlement[], agglomerations: Agglomeration[] }> = ({ elstatSettlements, sewageNetworkTypes, industryBranches, settlements, agglomerations, }) => {
    const { t, i18n } = useTranslation();
    const [addIndustrialSectorModalShow, setAddIndustrialSectorModalShow] = useState(false);
    const addIndustrialSectorFormRef = useRef<any>(null);
    const [editIndustrialSectorModalShow, setEditIndustrialSectorModalShow] = useState(false);
    const editIndustrialSectorFormRef = useRef<any>(null);
    const [addSeptageSettlementModalShow, setAddSeptageSettlementModalShow] = useState(false);
    const addSeptageSettlementFormRef = useRef<any>(null);
    const [editSeptageSettlementModalShow, setEditSeptageSettlementModalShow] = useState(false);
    const editSeptageSettlementFormRef = useRef<any>(null);
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [selectedSeptageSettlement, setSelectedSeptageSettlement] = useState<SeptageServedSettlement | null>(null);
    const [selectedIndustrialSector, setSelectedIndustrialSector] = useState<WastewaterTreatementPlantIndustrialSectorsServed | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [addSewageSettlementModalShow, setAddSewageSettlementModalShow] = useState(false);
    const addSewageSettlementFormRef = useRef<any>(null);
    const [addSewageAgglomerationModalShow, setAddSewageAgglomerationModalShow] = useState(false);
    const addSewageAgglomerationFormRef = useRef<any>(null);

    const context = useContext(AppContext);

    const septageSettlementSchema = yup.object().shape({
        id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        population: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
    });

    const industrialBranchSchema = yup.object().shape({
        industryBranchId: yup.number().required(t("wtp.validation.required")).oneOf(industryBranches.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        loadPE: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        loadKG: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        originType: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
    });

    useEffect(() => {
        if (!addSeptageSettlementModalShow) {
            setSelectedSeptageSettlement(null)
        }
    }, [addSeptageSettlementModalShow])

    useEffect(() => {
        if (!addIndustrialSectorModalShow) {
            setSelectedIndustrialSector(null);
        }
    }, [addIndustrialSectorModalShow])

    useEffect(() => {
        if (!editSeptageSettlementModalShow) setSelectedSeptageSettlement(null);
    }, [editSeptageSettlementModalShow])

    useEffect(() => {
        if (!editIndustrialSectorModalShow) setSelectedIndustrialSector(null);
    }, [editIndustrialSectorModalShow])

    const handleAddSeptageSettlementSubmit = (val: {
        id: string,
        population: number,
    }) => {
        let cur = (values as any).septageServedSettlements;
        let newServedAgglomeration = {
            id: val.id,
            population: parseInt(val.population.toString()),
        };
        cur.push(newServedAgglomeration);
        setFieldValue("septageServedSettlements", cur);
        setAddSeptageSettlementModalShow(false);
        setSelectedSeptageSettlement(null);
    };

    const handleEditSeptageSettlementSubmit = (val: {
        population: number,
    }) => {
        if (!selectedSeptageSettlement) return;
        let cur = (values as any).septageServedSettlements;
        let idxToEdit = cur.findIndex((x: SeptageServedSettlement) => x === selectedSeptageSettlement);
        if (idxToEdit === -1) return;
        cur[idxToEdit] = Object.assign(selectedSeptageSettlement, {
            population: parseInt(val.population.toString()),
        });
        setFieldValue("septageServedSettlements", cur);
        setEditSeptageSettlementModalShow(false);
        setSelectedSeptageSettlement(null);
    };

    const handleAddIndustrialSectorSubmit = (val: {
        industryBranchId: number,
        loadPE: number,
        loadKG: number,
        originType: number,
    }) => {
        let cur = (values as any).industrialSectorsServed;
        cur.push({
            industryBranchId: parseInt(val.industryBranchId.toString()),
            loadPE: parseInt(val.loadPE.toString()),
            loadKG: parseInt(val.loadKG.toString()),
            originType: parseInt(val.originType.toString()),
        });
        setFieldValue("industrialSectorsServed", cur);
        setAddIndustrialSectorModalShow(false);
        setSelectedIndustrialSector(null);
    };

    const handleEditIndustrialSectorSubmit = (val: {
        loadPE: number,
        loadKG: number,
        originType: number,
    }) => {
        if (!selectedIndustrialSector) return;
        let cur = (values as any).industrialSectorsServed;
        let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantIndustrialSectorsServed) => x === selectedIndustrialSector);
        if (idxToEdit === -1) return;
        cur[idxToEdit] = Object.assign(selectedIndustrialSector, {
            loadPE: parseInt(val.loadPE.toString()),
            loadKG: parseInt(val.loadKG.toString()),
            originType: parseInt(val.originType.toString()),
        });
        setFieldValue("industrialSectorsServed", cur);
        setEditIndustrialSectorModalShow(false);
        setSelectedIndustrialSector(null);
    };

    const removeSeptageSettlement = () => {
        if (!selectedSeptageSettlement) return;
        let cur = (values as any).septageServedSettlements;
        let idxToRemove = cur.findIndex((x: SeptageServedSettlement) => x === selectedSeptageSettlement);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("septageServedSettlements", cur);
        setSelectedSeptageSettlement(null);
    }

    const removeIndustrialSector = () => {
        if (!selectedIndustrialSector) return;
        let cur = (values as any).industrialSectorsServed;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantIndustrialSectorsServed) => x === selectedIndustrialSector);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("industrialSectorsServed", cur);
        setSelectedIndustrialSector(null);
    }

    const handleAddSewageSettlementSubmit = (val: {
        id: string,
    }) => {
        let cur = (values as any).settlements;
        let toAdd = settlements.find(x => x.id === val.id);
        if (!toAdd) return;
        cur.push(toAdd);
        setFieldValue("settlements", cur);
        setAddSewageSettlementModalShow(false);
    };

    const handleAddSewageAgglomerationSubmit = (val: {
        id: string,
    }) => {
        let cur = (values as any).agglomerations;
        let toAdd = agglomerations.find(x => x.id === val.id);
        if (!toAdd) return;
        cur.push(toAdd);
        setFieldValue("agglomerations", cur);
        setAddSewageAgglomerationModalShow(false);
    };

    const removeSewageSettlement = (settlement: Settlement) => {
        if (!settlement) return;
        let cur = (values as any).settlements;
        cur = (cur as Settlement[]).filter(x => x !== settlement);
        setFieldValue("settlements", cur);
    }

    const removeSewageAgglomeration = (agglomeration: Agglomeration) => {
        if (!agglomeration) return;
        let cur = (values as any).agglomerations;
        cur = (cur as Agglomeration[]).filter(x => x !== agglomeration);
        setFieldValue("agglomerations", cur);
    }

    return (
        <>
            <Row>
                <Col>
                    <h4>{t("wtp.waste_origin.served_sewage_agglomeration.header")}</h4>
                </Col>
                {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddSewageAgglomerationModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                    </ButtonGroup>
                </Col>}
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table borderless size="sm">
                        <thead>
                            <tr style={{ borderBottom: "1px solid lightgrey" }}>
                                <th></th>
                                <th colSpan={2}>{t("wtp.waste_origin.served_sewage_agglomeration.name")}</th>
                                <th>{t("wtp.waste_origin.served_sewage_agglomeration.code")}</th>
                                <th className="text-center">{t("settlements.settlement_area")}</th>
                                <th className="text-center">{t("settlements.equivalent_population")}</th>
                                <th className="text-center">{t("settlements.population_density")}</th>
                                <th className="text-center">{t("settlements.sewage_network_service_percentage")}</th>
                                <th className="text-center">{t("settlements.sewage_network_connected_percentage")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).agglomerations.length === 0 ?
                                <tr>
                                    <td colSpan={9} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).agglomerations.map((x: Agglomeration, i: number) => <React.Fragment key={i}><tr key={i} style={{ backgroundColor: "#f4f4f4" }}>
                                    <td className="text-center align-middle">
                                        {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? <ConfirmationPopover
                                            id={`agglomeration-confirmation_popover-${i}`}
                                            header={t("wtp.reuse.confirmation.header")}
                                            body={t("wtp.reuse.confirmation.body")}
                                            okLabel={t("wtp.reuse.confirmation.ok")}
                                            onOk={() => { removeSewageAgglomeration(x) }}
                                            cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                            onCancel={() => { }}
                                        /> : <ButtonGroup>
                                            <Button variant="link" href={`/agglomerations/view/${x.id}`} target="_blank">
                                                <Eye />
                                            </Button>
                                        </ButtonGroup>}
                                    </td>
                                    <td colSpan={2} className="align-middle">{i18n.language === "en" ? x.nameEn : x.name}</td>
                                    <td className="align-middle">{x.id}</td>
                                    <td className="text-center align-middle">{x.area}</td>
                                    <td className="text-center align-middle">{x.equivalentPopulation}</td>
                                    <td className="text-center align-middle">{(() => { let res = (x.equivalentPopulation / x.area); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "-" })()}</td>
                                    <td className="text-center align-middle">{x.servedPopulationPercentage ? x.servedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                    <td className="text-center align-middle">{x.connectedPopulationPercentage ? x.connectedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                    <td className="text-center align-middle">{i18n.language === "en" ? sewageNetworkTypes.find(y => y.id === x.sewageNetworkTypeId)?.nameEn : sewageNetworkTypes.find(y => y.id === x.sewageNetworkTypeId)?.name}</td>
                                </tr>
                                    {x.settlements.map((y: Settlement, idx: number) => <tr key={`${i}-${idx}`}>
                                        <td className="text-center align-middle">
                                            {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? <></> : <ButtonGroup>
                                                <Button variant="link" style={{ visibility: "hidden" }} onClick={() => { }}>
                                                    <Pencil />
                                                </Button>
                                                <Button variant="link" href={`/settlements/edit/${y.id}`} target="_blank">
                                                    <Eye />
                                                </Button>
                                            </ButtonGroup>}
                                        </td>
                                        <td className="text-center align-middle" style={{ minWidth: "24px" }}></td>
                                        <td className="align-middle">{i18n.language === "en" ? y.nameEn : y.name}</td>
                                        <td className="align-middle">{y.id}</td>
                                        <td className="text-center align-middle">{y.area}</td>
                                        <td className="text-center align-middle">{y.equivalentPopulation}</td>
                                        <td className="text-center align-middle">{(() => { let res = (y.equivalentPopulation / y.area); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "-" })()}</td>
                                        <td className="text-center align-middle">{y.servedPopulationPercentage ? y.servedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                        <td className="text-center align-middle">{y.connectedPopulationPercentage ? y.connectedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                        <td className="text-center align-middle">{i18n.language === "en" ? sewageNetworkTypes.find(z => z.id === y.sewageNetworkTypeId)?.nameEn : sewageNetworkTypes.find(z => z.id === y.sewageNetworkTypeId)?.name}</td>
                                    </tr>)}
                                </React.Fragment>)
                            }
                        </tbody>
                        {(values as any).agglomerations.length > 0 && <tfoot style={{ borderTop: "1px solid lightgrey" }}>
                            <tr>
                                <th colSpan={4} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{(values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + a.area, 0)}</td>
                                <td className="text-center">{(values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + a.equivalentPopulation, 0)}</td>
                                <td className="text-center">{(() => { let res = ((values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + a.equivalentPopulation, 0) / (values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + a.area, 0)); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "-" })()}</td>
                                <td className="text-center">{(() => { let res = ((values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + (a.servedPopulationPercentage ?? 0), 0) / (values as any).agglomerations.length); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) + "%" : "-" })()}</td>
                                <td className="text-center">{(() => { let res = ((values as any).agglomerations.reduce((partialSum: number, a: Agglomeration) => partialSum + (a.connectedPopulationPercentage ?? 0), 0) / (values as any).agglomerations.length); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) + "%" : "-" })()}</td>
                                <td></td>
                            </tr>
                        </tfoot>}
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.waste_origin.served_sewage_settlement.header")}</h4>
                </Col>
                {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddSewageSettlementModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                    </ButtonGroup>
                </Col>}
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table borderless size="sm">
                        <thead>
                            <tr style={{ borderBottom: "1px solid lightgrey" }}>
                                <th></th>
                                <th>{t("wtp.waste_origin.served_sewage_settlement.name")}</th>
                                <th>{t("wtp.waste_origin.served_sewage_agglomeration.code")}</th>
                                <th className="text-center">{t("settlements.settlement_area")}</th>
                                <th className="text-center">{t("settlements.equivalent_population")}</th>
                                <th className="text-center">{t("settlements.population_density")}</th>
                                <th className="text-center">{t("settlements.sewage_network_service_percentage")}</th>
                                <th className="text-center">{t("settlements.sewage_network_connected_percentage")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).settlements.length === 0 ?
                                <tr>
                                    <td colSpan={9} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).settlements.map((x: Settlement, i: number) => <tr key={i}>
                                    <td className="text-center align-middle">
                                        {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? <ConfirmationPopover
                                            id={`agglomeration-confirmation_popover-${i}`}
                                            header={t("wtp.reuse.confirmation.header")}
                                            body={t("wtp.reuse.confirmation.body")}
                                            okLabel={t("wtp.reuse.confirmation.ok")}
                                            onOk={() => { removeSewageSettlement(x) }}
                                            cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                            onCancel={() => { }}
                                        /> :
                                            <Button variant="link" href={`/settlements/edit/${x.id}`} target="_blank"><Eye /></Button>}
                                    </td>
                                    <td className="align-middle">{i18n.language === "en" ? x.nameEn : x.name}</td>
                                    <td className="align-middle">{x.id}</td>
                                    <td className="text-center align-middle">{x.area}</td>
                                    <td className="text-center align-middle">{x.equivalentPopulation}</td>
                                    <td className="text-center align-middle">{(() => { let res = (x.equivalentPopulation / x.area); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "-" })()}</td>
                                    <td className="text-center align-middle">{x.servedPopulationPercentage ? x.servedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                    <td className="text-center align-middle">{x.connectedPopulationPercentage ? x.connectedPopulationPercentage.toFixed(2) + "%" : "-"}</td>
                                    <td className="text-center align-middle">{i18n.language === "en" ? sewageNetworkTypes.find(y => y.id === x.sewageNetworkTypeId)?.nameEn : sewageNetworkTypes.find(y => y.id === x.sewageNetworkTypeId)?.name}</td>
                                </tr>)
                            }
                        </tbody>
                        {(values as any).settlements.length > 0 && <tfoot style={{ borderTop: "1px solid lightgrey" }}>
                            <tr>
                                <th colSpan={3} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{(values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.area, 0)}</td>
                                <td className="text-center">{(values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.equivalentPopulation, 0)}</td>
                                <td className="text-center">{(() => { let res = ((values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.equivalentPopulation, 0) / (values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.area, 0)); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "-" })()}</td>
                                <td className="text-center">{(() => { let res = ((values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + (a.servedPopulationPercentage ?? 0), 0) / (values as any).settlements.length); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) + "%" : "-" })()}</td>
                                <td className="text-center">{(() => { let res = ((values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + (a.connectedPopulationPercentage ?? 0), 0) / (values as any).settlements.length); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) + "%" : "-" })()}</td>
                                <td></td>
                            </tr>
                        </tfoot>}
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.waste_origin.served_septage_agglomeration.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddSeptageSettlementModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setEditSeptageSettlementModalShow(true)} disabled={!selectedSeptageSettlement}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeSeptageSettlement} disabled={!selectedSeptageSettlement}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Form.Group as={Row} className="my-3">
                <Form.Label column sm="4" className="text-end">
                    {t("wtp.waste_origin.served_septage_agglomeration.daily_tanks_summer")}
                </Form.Label>
                <Col sm="2">
                    <Form.Control
                        id="septageTanksPerDaySummer"
                        name="septageTanksPerDaySummer"
                        onBlur={handleChange}
                        defaultValue={(values as any).septageTanksPerDaySummer || ""}
                        isInvalid={(touched as any).septageTanksPerDaySummer && !!(errors as any).septageTanksPerDaySummer}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).septageTanksPerDaySummer}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="my-3">
                <Form.Label column sm="4" className="text-end">
                    {t("wtp.waste_origin.served_septage_agglomeration.daily_tanks_winter")}
                </Form.Label>
                <Col sm="2">
                    <Form.Control
                        id="septageTanksPerDayWinter"
                        name="septageTanksPerDayWinter"
                        onBlur={handleChange}
                        defaultValue={(values as any).septageTanksPerDayWinter || ""}
                        isInvalid={(touched as any).septageTanksPerDayWinter && !!(errors as any).septageTanksPerDayWinter}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).septageTanksPerDayWinter}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("wtp.waste_origin.served_septage_agglomeration.agglomeration")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_septage_agglomeration.population")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).septageServedSettlements.length === 0 ?
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).septageServedSettlements.map((x: SeptageServedSettlement, i: number) => <tr key={i} onClick={() => setSelectedSeptageSettlement(x)} className={selectedSeptageSettlement === x ? "selected" : ""}>
                                    <td className="text-center"><Button variant="link" className="py-0" onClick={() => { setSelectedSeptageSettlement(x); setEditSeptageSettlementModalShow(true); }}><Pencil /></Button></td>
                                    <td>{(() => { let settlement = elstatSettlements.find(y => y.codeOik === x.id); return i18n.language === "en" ? `${settlement?.codeOik} - ${settlement?.namefOik}, ${settlement?.namefOta}, ${settlement?.namefGdiam}` : `${settlement?.codeOik} - ${settlement?.nameOik}, ${settlement?.nameOta}, ${settlement?.nameGdiam}`; })()}</td>
                                    <td className="text-center">{x.population}</td>
                                </tr>)
                            }
                        </tbody>
                        {(values as any).septageServedSettlements.length > 0 && <tfoot>
                            <tr>
                                <th colSpan={2} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{(values as any).septageServedSettlements.reduce((partialSum: number, a: SeptageServedSettlement) => partialSum + a.population, 0)}</td>
                            </tr>
                        </tfoot>}
                    </Table>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col>
                    <h4>{t("wtp.waste_origin.served_industry_branches.header")}</h4>
                </Col>
                <Col>
                    <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                        <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddIndustrialSectorModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setEditIndustrialSectorModalShow(true)} disabled={!selectedIndustrialSector}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                        <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeIndustrialSector} disabled={!selectedIndustrialSector}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t("wtp.waste_origin.served_industry_branches.annex")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_industry_branches.loadPE")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_industry_branches.loadKG")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_industry_branches.origin_type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values as any).industrialSectorsServed.length === 0 ?
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                (values as any).industrialSectorsServed.map((x: WastewaterTreatementPlantIndustrialSectorsServed, i: number) => <tr key={i} onClick={() => setSelectedIndustrialSector(x)} className={selectedIndustrialSector === x ? "selected" : ""}>
                                    <td className="text-center"><Button variant="link" className="py-0" onClick={() => { setSelectedIndustrialSector(x); setEditIndustrialSectorModalShow(true); }}><Pencil /></Button></td>
                                    <td>{i18n.language === "en" ? industryBranches.find(y => y.id === x.industryBranchId)?.nameEn : industryBranches.find(y => y.id === x.industryBranchId)?.name}</td>
                                    <td className="text-center">{x.loadPE}</td>
                                    <td className="text-center">{x.loadKG}</td>
                                    <td className="text-center">{t(OriginType[parseInt(x.originType.toString())])}</td>
                                </tr>)
                            }
                        </tbody>
                        {(values as any).industrialSectorsServed.length > 0 && <tfoot>
                            <tr>
                                <th colSpan={2} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{(values as any).industrialSectorsServed.reduce((partialSum: number, a: WastewaterTreatementPlantIndustrialSectorsServed) => partialSum + a.loadPE, 0)}</td>
                                <td className="text-center">{(values as any).industrialSectorsServed.reduce((partialSum: number, a: WastewaterTreatementPlantIndustrialSectorsServed) => partialSum + a.loadKG, 0)}</td>
                            </tr>
                        </tfoot>}
                    </Table>
                </Col>
            </Row>
            <Modal
                show={addSeptageSettlementModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddSeptageSettlementModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_septage_agglomeration.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            id: "",
                            population: 0,
                        }}
                        validationSchema={septageSettlementSchema}
                        onSubmit={(v) => handleAddSeptageSettlementSubmit(v)}
                    >
                        {props => <Form noValidate ref={addSeptageSettlementFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_septage_agglomeration.settlement") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(elstatSettlements.map(x => { return { value: x.codeOik, label: i18n.language === "en" ? `${x.codeOik} - ${x.namefOik}, ${x.namefOta}, ${x.namefGdiam}` : `${x.codeOik} - ${x.nameOik}, ${x.nameOta}, ${x.nameGdiam}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }]))}
                                        placeholder={t("settlements.settlement_code")}
                                        id="id"
                                        name="id"
                                        onChange={v => { props.setFieldValue("id", v?.value); let pop = elstatSettlements.find(x => x.codeOik === v?.value)?.population; if (pop) props.setFieldValue("population", pop); }}
                                        value={(elstatSettlements.map(x => { return { value: x.codeOik, label: i18n.language === "en" ? `${x.codeOik} - ${x.namefOik}, ${x.namefOta}, ${x.namefGdiam}` : `${x.codeOik} - ${x.nameOik}, ${x.nameOta}, ${x.nameGdiam}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }])).find(x => x.value === props.values.id)}
                                        className={'react-select ' + (!props.values.id ? 'is-invalid' : 'is-valid')}
                                        classNamePrefix={'react-select'}
                                        inputValue={inputValue}
                                        menuIsOpen={inputValue.length >= 2}
                                        onInputChange={(value) => setInputValue(value)}
                                        filterOption={(opt, inp) => {
                                            let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            return option.indexOf(input) !== -1;
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.id}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_sewage_agglomeration.population") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_sewage_agglomeration.population")}
                                        id="population"
                                        name="population"
                                        value={props.values.population}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.population && !!props.errors.population}
                                        isValid={props.touched.population && !props.errors.population}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.population}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addSeptageSettlementFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddSeptageSettlementModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editSeptageSettlementModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditSeptageSettlementModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_septage_agglomeration.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            id: selectedSeptageSettlement?.id || "",
                            population: selectedSeptageSettlement?.population || 0,
                        }}
                        validationSchema={septageSettlementSchema}
                        onSubmit={(v) => handleEditSeptageSettlementSubmit(v)}
                    >
                        {props => <Form noValidate ref={editSeptageSettlementFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_septage_agglomeration.settlement") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_sewage_agglomeration.agglomeration")}
                                        id="id"
                                        name="id"
                                        value={(() => { let settlement = elstatSettlements.find(y => y.codeOik === props.values.id); return i18n.language === "en" ? `${settlement?.codeOik} - ${settlement?.namefOik}, ${settlement?.namefOta}, ${settlement?.namefGdiam}` : `${settlement?.codeOik} - ${settlement?.nameOik}, ${settlement?.nameOta}, ${settlement?.nameGdiam}`; })()}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.id}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_sewage_agglomeration.population") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_sewage_agglomeration.population")}
                                        id="population"
                                        name="population"
                                        value={props.values.population}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.population && !!props.errors.population}
                                        isValid={props.touched.population && !props.errors.population}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.population}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editSeptageSettlementFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditSeptageSettlementModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addIndustrialSectorModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddIndustrialSectorModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_industry_branches.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            industryBranchId: 0,
                            loadPE: 0,
                            loadKG: 0,
                            originType: -1,
                        }}
                        validationSchema={industrialBranchSchema}
                        onSubmit={(v) => handleAddIndustrialSectorSubmit(v)}
                    >
                        {props => <Form noValidate ref={addIndustrialSectorFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.annex") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        name="industryBranchId"
                                        id="industryBranchId"
                                        value={props.values.industryBranchId}
                                        onChange={v => props.setFieldValue("industryBranchId", v.target.value)}
                                        isInvalid={props.touched.industryBranchId && !!props.errors.industryBranchId}
                                        isValid={props.touched.industryBranchId && !props.errors.industryBranchId}
                                    >
                                        <option key={"none"} value={0}>{t("wtp.waste_origin.served_industry_branches.modal.select_industry_branch")}</option>
                                        {industryBranches.filter(x => !(values as any).industrialSectorsServed.some((y: WastewaterTreatementPlantIndustrialSectorsServed) => y.industryBranchId === x.id)).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.industryBranchId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.origin_type") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        name="originType"
                                        id="originType"
                                        value={props.values.originType}
                                        onChange={v => props.setFieldValue("originType", v.target.value)}
                                        isInvalid={props.touched.originType && !!props.errors.originType}
                                        isValid={props.touched.originType && !props.errors.originType}
                                    >
                                        <option key={"none"} value={-1}>{t("wtp.waste_origin.served_industry_branches.modal.select_origin_type")}</option>
                                        {Object.values(OriginType).filter(x => !isNaN(parseInt(x.toString()))).map((x, i) => <option key={i} value={parseInt(x.toString())}>{t(OriginType[parseInt(x.toString())])}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.industryBranchId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.loadPE") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_industry_branches.loadPE")}
                                        id="loadPE"
                                        name="loadPE"
                                        value={props.values.loadPE}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.loadPE && !!props.errors.loadPE}
                                        isValid={props.touched.loadPE && !props.errors.loadPE}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.loadPE}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.loadKG") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_industry_branches.loadKG")}
                                        id="loadKG"
                                        name="loadKG"
                                        value={props.values.loadKG}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.loadKG && !!props.errors.loadKG}
                                        isValid={props.touched.loadKG && !props.errors.loadKG}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.loadKG}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addIndustrialSectorFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddIndustrialSectorModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editIndustrialSectorModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditIndustrialSectorModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_industry_branches.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            industryBranchId: selectedIndustrialSector?.industryBranchId || 0,
                            loadPE: selectedIndustrialSector?.loadPE || 0,
                            loadKG: selectedIndustrialSector?.loadKG || 0,
                            originType: selectedIndustrialSector?.originType != null ? selectedIndustrialSector?.originType : -1,
                        }}
                        validationSchema={industrialBranchSchema}
                        onSubmit={(v) => handleEditIndustrialSectorSubmit(v)}
                    >
                        {props => <Form noValidate ref={editIndustrialSectorFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.annex") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        name="industryBranchId"
                                        id="industryBranchId"
                                        value={props.values.industryBranchId}
                                        onChange={v => props.setFieldValue("industryBranchId", v.target.value)}
                                        isInvalid={props.touched.industryBranchId && !!props.errors.industryBranchId}
                                        isValid={props.touched.industryBranchId && !props.errors.industryBranchId}
                                        disabled
                                    >
                                        <option key={"none"} value={0}>{t("wtp.waste_origin.served_industry_branches.modal.select_industry_branch")}</option>
                                        {industryBranches.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.industryBranchId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.origin_type") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        name="originType"
                                        id="originType"
                                        value={props.values.originType}
                                        onChange={v => props.setFieldValue("originType", v.target.value)}
                                        isInvalid={props.touched.originType && !!props.errors.originType}
                                        isValid={props.touched.originType && !props.errors.originType}
                                    >
                                        <option key={"none"} value={-1}>{t("wtp.waste_origin.served_industry_branches.modal.select_origin_type")}</option>
                                        {Object.values(OriginType).filter(x => !isNaN(parseInt(x.toString()))).map((x, i) => <option key={i} value={parseInt(x.toString())}>{t(OriginType[parseInt(x.toString())])}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.industryBranchId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.loadPE") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_industry_branches.loadPE")}
                                        id="loadPE"
                                        name="loadPE"
                                        value={props.values.loadPE}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.loadPE && !!props.errors.loadPE}
                                        isValid={props.touched.loadPE && !props.errors.loadPE}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.loadPE}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_industry_branches.loadKG") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.waste_origin.served_industry_branches.loadKG")}
                                        id="loadKG"
                                        name="loadKG"
                                        value={props.values.loadKG}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.loadKG && !!props.errors.loadKG}
                                        isValid={props.touched.loadKG && !props.errors.loadKG}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.loadKG}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editIndustrialSectorFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditIndustrialSectorModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addSewageSettlementModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddSewageSettlementModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_sewage_settlement.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            id: "",
                        }}
                        validationSchema={yup.object().shape({
                            id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                        })}
                        onSubmit={(v) => handleAddSewageSettlementSubmit(v)}
                    >
                        {props => <Form noValidate ref={addSewageSettlementFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_sewage_settlement.settlement") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(settlements.map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }]))}
                                        placeholder={t("settlements.settlement_code")}
                                        id="id"
                                        name="id"
                                        onChange={v => { props.setFieldValue("id", v?.value); }}
                                        value={(settlements.map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }])).find(x => x.value === props.values.id)}
                                        className={'react-select ' + (!props.values.id ? 'is-invalid' : 'is-valid')}
                                        classNamePrefix={'react-select'}
                                        inputValue={inputValue}
                                        menuIsOpen={inputValue.length >= 2}
                                        onInputChange={(value) => setInputValue(value)}
                                        filterOption={(opt, inp) => {
                                            let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            return option.indexOf(input) !== -1;
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.id}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addSewageSettlementFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddSewageSettlementModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={addSewageAgglomerationModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddSewageAgglomerationModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.waste_origin.served_sewage_agglomeration.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            id: "",
                        }}
                        validationSchema={yup.object().shape({
                            id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
                        })}
                        onSubmit={(v) => handleAddSewageAgglomerationSubmit(v)}
                    >
                        {props => <Form noValidate ref={addSewageAgglomerationFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_sewage_agglomeration.agglomeration") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(agglomerations.map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_agglomeration"), }]))}
                                        placeholder={t("settlements.settlement_code")}
                                        id="id"
                                        name="id"
                                        onChange={v => { props.setFieldValue("id", v?.value); }}
                                        value={(agglomerations.map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_agglomeration"), }])).find(x => x.value === props.values.id)}
                                        className={'react-select ' + (!props.values.id ? 'is-invalid' : 'is-valid')}
                                        classNamePrefix={'react-select'}
                                        inputValue={inputValue}
                                        menuIsOpen={inputValue.length >= 2}
                                        onInputChange={(value) => setInputValue(value)}
                                        filterOption={(opt, inp) => {
                                            let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            return option.indexOf(input) !== -1;
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.id}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addSewageAgglomerationFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddSewageAgglomerationModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WtpManageWasteOrigin;
