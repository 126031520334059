import { Col, Row } from "react-bootstrap";

export const blockBuilder = (block: any, idx?: number) => {
    if (!block) return (<></>);
    switch (block.type) {
        case "Piranha.Extend.Blocks.ColumnBlock":
            if (!block.items) return (<></>);
            return (<Row key={idx} className='align-items-center py-2'>{block.items.map((x: any, i: number) => <Col key={i}>{blockBuilder(x)}</Col>)}</Row>);
        case "Piranha.Extend.Blocks.SeparatorBlock":
            return (<hr className='py-2' />);
        case "Piranha.Extend.Blocks.HtmlBlock":
            return (<Row key={idx} className='py-2'><Col dangerouslySetInnerHTML={{ __html: block.body?.value }} /></Row>);
        case "Piranha.Extend.Blocks.TextBlock":
            return (<Row key={idx} className='py-2'><Col>{block.body?.value}</Col></Row>);
        case "Piranha.Extend.Blocks.ImageBlock":
            if (!block.body?.media?.publicUrl) return (<></>);
            return (<Row key={idx}><Col><img src={block.body?.media?.publicUrl.replace("~", "")} alt={block.body?.media?.altText} style={{ maxWidth: "100%" }} /></Col></Row>);
        case "Piranha.Extend.Blocks.QuoteBlock":
            return (<Row key={idx} className='py-2'>
                <Col>
                    <blockquote className="blockquote">
                        <p>
                            {' '}
                            <span dangerouslySetInnerHTML={{ __html: block.body?.value }} />
                            {' '}
                        </p>
                        <footer className="blockquote-footer">
                            {block.author?.value}
                        </footer>
                    </blockquote>
                </Col>
            </Row>);
        default:
            return (<></>);
    }
}