import { useFormikContext } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const WtpManageComments: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();

    return (
        <>
            <h4>{t("wtp.general.general.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.comments.comments")}</Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id="generalRemarks"
                        name="generalRemarks"
                        onBlur={handleChange}
                        defaultValue={(values as any).generalRemarks ?? ""}
                        isInvalid={(touched as any).generalRemarks && !!(errors as any).generalRemarks}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).generalRemarks}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group >
            <hr></hr>
            <h4>{t("wtp.treatment.remarks.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.treatment.remarks.operational_problems")}</Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id="operationalProblems"
                        name="operationalProblems"
                        onBlur={handleChange}
                        defaultValue={(values as any).operationalProblems || ""}
                        isInvalid={(touched as any).operationalProblems && !!(errors as any).operationalProblems}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).operationalProblems}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3, offset: 3 }}>
                    <Form.Check
                        type="checkbox"
                        label={t("wtp.treatment.remarks.idle")}
                        id="isIdle"
                        name="isIdle"
                        checked={(values as any).isIdle}
                        onChange={e => setFieldValue('isIdle', e.target.checked)}
                        isInvalid={(touched as any).isIdle && !!(errors as any).isIdle}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).isIdle}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.treatment.remarks.idle_reason")}</Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id="idleReason"
                        name="idleReason"
                        onBlur={handleChange}
                        defaultValue={(values as any).idleReason || ""}
                        disabled={!(values as any).isIdle}
                        isInvalid={(touched as any).idleReason && !!(errors as any).idleReason}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).idleReason}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.treatment.remarks.problems_solution")}</Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id="problemsSolution"
                        name="problemsSolution"
                        onBlur={handleChange}
                        defaultValue={(values as any).problemsSolution || ""}
                        isInvalid={(touched as any).problemsSolution && !!(errors as any).problemsSolution}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).problemsSolution}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.treatment.remarks.costForecast")}</Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id="costForecast"
                        name="costForecast"
                        onBlur={handleChange}
                        defaultValue={(values as any).costForecast || ""}
                        isInvalid={(touched as any).costForecast && !!(errors as any).costForecast}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).costForecast}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        </>
    )
}

export default WtpManageComments;
