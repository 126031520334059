import { fromLonLat, transformExtent } from "ol/proj";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Table, Alert, Image, Modal } from "react-bootstrap";
import { Image as EmptyImage, Layers, Pencil } from 'react-bootstrap-icons';
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle } from "ol/geom";
import WKT from 'ol/format/WKT';
import { PlusCircle, PencilFill, Trash } from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";
import { RiverBasinDistrict, AuthorityType, WastewaterTreatementPlantExtension, AdministrativeRegion, PriorityCategory, IedCategory, Authority, RiverBasin, IndustryState, IndustryBranch } from "../../data/Definitions";
import { Formik, useFormikContext } from "formik";
import { useState, useRef, useEffect, useCallback } from "react";
import * as yup from 'yup';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../components/BingMap";
import React from "react";
import { wasteTreatmentOptions } from "../../data/Data";
import Select from 'react-select';

let unique_id = 0;

const WtpManageReuseGeneral: React.FC<{ isShowing: boolean, riverBasinDistricts: RiverBasinDistrict[], administrativeRegions: AdministrativeRegion[], authorityTypes: AuthorityType[], priorityCategories: PriorityCategory[], iedCategories: IedCategory[], authorities: Authority[], riverBasins: RiverBasin[], industryBranches: IndustryBranch[] }> = ({ isShowing, riverBasinDistricts, administrativeRegions, authorityTypes, priorityCategories, iedCategories, authorities, riverBasins, industryBranches }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [addExpansionsModalShow, setAddExpansionsModalShow] = useState(false);
    const [editExpansionsModalShow, setEditExpansionsModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const editFormRef = useRef<any>(null);
    const photoInputRef = useRef<any>(null);
    const [selectedExpansion, setSelectedExpansion] = useState<WastewaterTreatementPlantExtension | null>(null);
    const [drawing, setDrawing] = useState(false);
    const [geometry, setGeometry] = useState<Geometry | undefined>();
    const [showBing, setShowBing] = useState(false);
    const layersButton = <button>&#9776;</button>;
    const mapRef = React.createRef() as React.RefObject<RMap>;

    const expansionSchema = yup.object().shape({
        expansionWorkTitle: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        expansionCompletionYear: yup.number().required(t("wtp.validation.required")).min(1900, (v) => `${t("wtp.validation.greater_than")} ${v.min}`).max(new Date(Date.now()).getFullYear(), (v) => `${t("wtp.validation.less_than")} ${v.max}`).typeError(t("wtp.validation.type_error")),
        expansionFundingProgram: yup.string().typeError(t("wtp.validation.type_error")),
    });

    useEffect(() => {
        if (!(values as any).shape) return;
        if (geometry) return;
        let wkt = new WKT();
        let geom = wkt.readGeometry((values as any).shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        setGeometry(geom);
    }, [(values as any).shape]);

    useEffect(() => {
        if ((values as any).shape) return;
        if (!(values as any).longitude || !(values as any).latitude) return;
        let lon = parseFloat((values as any).longitude);
        if (isNaN(lon)) return;
        let lat = parseFloat((values as any).latitude);
        if (isNaN(lat)) return;
        let center = fromLonLat([lon, lat], 'EPSG:3857');
        let geom = new Circle(center, 100);
        mapRef.current?.ol.getView().setCenter(center);
        mapRef.current?.ol.getView().setZoom(16);
        setGeometry(geom);
    }, [(values as any).longitude, (values as any).latitude, (values as any).shape]);

    useEffect(() => {
        if (!editExpansionsModalShow) setSelectedExpansion(null);
    }, [editExpansionsModalShow])

    const handleAddSubmit = (val: {
        expansionWorkTitle: string,
        expansionCompletionYear: string,
        expansionFundingProgram: string,
    }) => {
        let cur = (values as any).extensions;
        cur.push({
            title: val.expansionWorkTitle,
            fundingProgram: val.expansionFundingProgram,
            completionYear: parseInt(val.expansionCompletionYear.toString()),
        });
        setFieldValue("extensions", cur);
        setAddExpansionsModalShow(false);
        setSelectedExpansion(null);
    };

    const handleEditSubmit = (val: {
        expansionWorkTitle: string,
        expansionCompletionYear: string,
        expansionFundingProgram: string,
    }) => {
        if (!selectedExpansion) return;
        let cur = (values as any).extensions;
        let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantExtension) => x === selectedExpansion);
        if (idxToEdit === -1) return;
        cur[idxToEdit] = Object.assign(selectedExpansion, {
            title: val.expansionWorkTitle,
            fundingProgram: val.expansionFundingProgram,
            completionYear: parseInt(val.expansionCompletionYear.toString()),
        });
        setFieldValue("extensions", cur);
        setEditExpansionsModalShow(false);
        setSelectedExpansion(null);
    };

    const removeExpansion = () => {
        if (!selectedExpansion) return;
        let cur = (values as any).extensions;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantExtension) => x === selectedExpansion);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("extensions", cur);
        setSelectedExpansion(null);
    }

    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        // setFieldValue("photoUrl", URL.createObjectURL(fileObj));
        setFieldValue("photo", fileObj);
    };

    const autoDetectGeo = async () => {
        if (!(values as any).longitude) return;
        if (!(values as any).latitude) return;
        const resRB = await fetch(`/api/spatial/riverbasin?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });
        const resAR = await fetch(`/api/spatial/administrativeRegion?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });
        const resAU = await fetch(`/api/spatial/municipal?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });

        if (resRB.status === 200) {
            const rb: RiverBasin = await resRB.json();
            setFieldValue("riverBasinDistrictId", rb.riverBasinDistrictId);
            setFieldValue("riverBasinId", rb.id);
        } else {
            setFieldValue("riverBasinDistrictId", 0);
            setFieldValue("riverBasinId", 0);
        }

        if (resAR.status === 200) {
            const ar: AdministrativeRegion = await resAR.json();
            setFieldValue("administrativeRegionId", ar.id ?? 0);
        } else {
            setFieldValue("administrativeRegionId", 0);
        }

        if (resAU.status === 200) {
            const au: Authority = await resAU.json();
            setFieldValue("municipalId", au.id ?? 0);
        } else {
            setFieldValue("municipalId", 0);
        }
    }

    return (
        <>
            <Row className="mb-2">
                {/* <Col md={{ span: 6 }} className="text-center">

                    {((values as any).photoUrl || (values as any).photo) ?
                        <div style={{ margin: "auto", width: "500px", height: "300px", background: "rgba(0,0,0,0)" }}>
                            <Image style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }} src={(values as any).photo ? URL.createObjectURL((values as any).photo) : (values as any).photoUrl} />
                        </div> :
                        <div style={{ margin: "auto", width: "500px", height: "300px", backgroundColor: "GrayText", textAlign: "center", color: "white", fontSize: "100px", lineHeight: "250px" }}>
                            <EmptyImage />
                        </div>}
                    <input
                        style={{ display: 'none' }}
                        ref={photoInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoChange}
                    />
                    <Button variant="outline-primary mt-2" onClick={() => photoInputRef.current?.click()}>{t("wtp.general.add_image")}</Button>
                </Col> */}
                <Col md={{ span: 6 }} className="pe-0">
                    <div style={{ width: "100%", height: "300px" }}>
                        {isShowing && <RMap
                            className='w-100 h-100'
                            initial={((values as any).longitude && (values as any).latitude) ? { center: fromLonLat([(values as any).longitude, (values as any).latitude]), zoom: 16 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                            ref={mapRef}
                        >
                            <RControl.RCustom className="m-2">
                                <Button variant="secondary" onClick={() => { !drawing && setGeometry(undefined); setDrawing(!drawing); }}>
                                    <PencilFill />
                                </Button>
                            </RControl.RCustom>
                            <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                <ROSM properties={{ label: "Map" }} />
                                <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                            </RControl.RLayers>
                            <RLayerVector
                                zIndex={11}
                                onChange={(e: BaseEvent) => {
                                    let source = e.target as VectorSource<Geometry>;
                                    if (!source?.forEachFeatureAtCoordinateDirect) {
                                        return;
                                    }

                                    let feats = source.getFeatures();
                                    if (feats.length > 1) {
                                        let geom = feats[0].getGeometry();
                                        setGeometry(geom);
                                        if (geom) {
                                            let wkt = new WKT();
                                            let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                            setFieldValue("shape", wktGeom);
                                            let ext = geom.getExtent();
                                            var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                            var center = extent.getCenter(lonLatExtent);
                                            if (center) {
                                                setFieldValue("longitude", center[0]);
                                                setFieldValue("latitude", center[1]);
                                            }
                                        }
                                        source.clear();
                                        setDrawing(false);
                                    }
                                }}
                            >
                                <RFeature
                                    key={`Feature${++unique_id}`}
                                    geometry={geometry}
                                >
                                    <RStyle.RStyle>
                                        <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                        <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                    </RStyle.RStyle>
                                </RFeature>
                                {drawing && <RInteraction.RDraw
                                    type={"Polygon"}
                                />}
                            </RLayerVector>
                        </RMap>}
                    </div>
                </Col>
                <Col md={{ span: 6 }}>
                    <h5 className="text-center">{t("wtp.general.coordinates")}</h5>
                    <Form.Group as={Row} className="mb-3">
                        <Col md={{ offset: 3, span: 6 }}>
                            <FloatingLabel label={t("wtp.general.longitude")}>
                                <Form.Control
                                    id="longitude"
                                    placeholder="Longitude"
                                    name="longitude"
                                    onBlur={handleChange}
                                    defaultValue={(values as any).longitude ?? ""}
                                    isInvalid={(touched as any).longitude && !!(errors as any).longitude}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).longitude}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Form.Group>
                    <Col mb={{ span: 6 }}>
                        <Form.Group as={Row} className="mb-3">
                            <Col md={{ offset: 3, span: 6 }}>
                                <FloatingLabel label={t("wtp.general.latitude")}>
                                    <Form.Control
                                        id="latitude"
                                        placeholder="Latitude"
                                        name="latitude"
                                        onBlur={handleChange}
                                        defaultValue={(values as any).latitude ?? ""}
                                        isInvalid={(touched as any).latitude && !!(errors as any).latitude}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(errors as any).latitude}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Form.Group>
                    </Col>
                </Col>
            </Row>
            <h4>{t("wtp.general.general.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.river_basin_district")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="riverBasinDistrictId"
                        name="riverBasinDistrictId"
                        onChange={(e) => { let currentCatchments = riverBasins.filter(x => x.riverBasinDistrictId?.toString() === e.target.value); setFieldValue("riverBasinId", currentCatchments.length === 1 ? currentCatchments.pop()?.id : 0); handleChange(e); }}
                        onBlur={handleBlur}
                        value={(values as any).riverBasinDistrictId ?? 0}
                        isInvalid={(touched as any).riverBasinDistrictId && !!(errors as any).riverBasinDistrictId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_river_basin_district")}</option>
                        {riverBasinDistricts.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).riverBasinDistrictId}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Button variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={autoDetectGeo}>{t("wtp.button.auto_assign")}</Button>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.catchment")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="riverBasinId"
                        name="riverBasinId"
                        onChange={handleChange}
                        value={(values as any).riverBasinId || "0"}
                        isInvalid={(touched as any).riverBasinId && !!(errors as any).riverBasinId}
                        disabled={(values as any).riverBasinDistrictId <= 0}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_catchment")}</option>
                        {riverBasins.filter(x => x.riverBasinDistrictId.toString() === (values as any).riverBasinDistrictId?.toString()).map((x, i) => <option value={x.id}>{`${i18n.language === "en" ? x.nameEn : x.name} ( ${x.code} )`}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).riverBasinId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.administrative_region")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="administrativeRegionId"
                        name="administrativeRegionId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).administrativeRegionId ?? 0}
                        isInvalid={(touched as any).administrativeRegionId && !!(errors as any).administrativeRegionId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_administrative_region")}</option>
                        {administrativeRegions.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).administrativeRegionId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("settlements.municipal")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="municipalId"
                        name="municipalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).municipalId ?? 0}
                        isInvalid={(touched as any).municipalId && !!(errors as any).municipalId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_municipal")}</option>
                        {authorities.filter(x => x.authorityTypeId === 1).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).municipalId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.reuse_activity_branch")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.general.reuse_activity_branch")}
                        id="reuse.activityBranchText"
                        name="reuse.activityBranchText"
                        onBlur={handleChange}
                        defaultValue={(values as any).reuse?.activityBranchText ?? ""}
                        isInvalid={(touched as any).reuse?.activityBranchText && !!(errors as any).reuse?.activityBranchText}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).reuse?.activityBranchText}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.reuse_activity_branch_name")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.general.reuse_activity_branch_name")}
                        id="reuse.activityBranch"
                        name="reuse.activityBranch"
                        onBlur={handleChange}
                        defaultValue={(values as any).reuse?.activityBranch ?? ""}
                        isInvalid={(touched as any).reuse?.activityBranch && !!(errors as any).reuse?.activityBranch}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).reuse?.activityBranch}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.reuse_state")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="reuse.state"
                        name="reuse.state"
                        onChange={handleChange}
                        value={!isNaN(parseInt((values as any).reuse?.state)) ? parseInt((values as any).reuse?.state) : -1}
                        isInvalid={(touched as any).reuse?.state && !!(errors as any).reuse?.state}
                    >
                        <option key={"none"} value={-1}>{t("wtp.general.select_reuse_state")}</option>
                        {Object.values(IndustryState).filter(x => !isNaN(parseInt(x.toString()))).map((x, i) => <option key={i} value={parseInt(x.toString())}>{t(IndustryState[parseInt(x.toString())])}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).reuse?.state}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group> */}
            {/* <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.authority")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="authorityTypeId"
                        name="authorityTypeId"
                        onChange={(e) => { setFieldValue("authorityId", 0); handleChange(e); }}
                        onBlur={handleBlur}
                        value={(values as any).authorityTypeId ?? 0}
                    >
                        {authorityTypes.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                </Col>
                <Col md={{ span: 3 }} className="p-0">
                    <Form.Select
                        id="authorityId"
                        name="authorityId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).authorityId ?? 0}
                        isInvalid={(touched as any).authorityId && !!(errors as any).authorityId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_authority")}</option>
                        {authorities.filter(x => x.authorityTypeId.toString() === (values as any).authorityTypeId.toString()).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).authorityId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group> */}
            <Row className="g-2">
                <Col className="text-start" md={{ offset: 3 }}>
                    {(touched as any).environmentalTermsFilePath && (errors as any).environmentalTermsFilePath && (
                        <Alert variant={'light'}>
                            <div className="text-danger">Απαιτείται η υποβολή της Απόφασης Έγκρισης Περιβαλλοντικών Όρων της Ε.Ε.Λ. σε ηλεκτρονική μορφή (βλ. καρτέλα 'Αρχεία').</div>
                        </Alert>
                    )}
                </Col>
            </Row>
        </>
    )
}

export default WtpManageReuseGeneral;
