import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const WtpManageIncomingLoad: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors } = useFormikContext();

    return (
        <>
            <h4>{t("wtp.incoming_load.capacity.header")}</h4>
            <Form.Group as={Row}>
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.capacity.wtp_capacity")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="capacity"
                        name="capacity"
                        onBlur={handleChange}
                        defaultValue={(values as any).capacity || ""}
                        isInvalid={(touched as any).capacity && !!(errors as any).capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).capacity}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <hr></hr>
            <h4>{t("wtp.incoming_load.inflow.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3 }}></Col>
                <Col md={{ span: 3 }} className="text-center">
                    <strong>{t("wtp.incoming_load.inflow.mean")}</strong>
                </Col>
                <Col md={{ span: 3 }} className="text-center">
                    <strong>{t("wtp.incoming_load.inflow.max")}</strong>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.inflow.total")}
                </Form.Label>
                <Col md={{ span: 3 }} className="text-center">
                    {+(parseFloat((values as any).inflowSewerageMean || 0) + parseFloat((values as any).inflowSeptageMean || 0)).toFixed(2) || '-'}
                </Col>
                <Col md={{ span: 3 }} className="text-center">
                    {+(parseFloat((values as any).inflowSewerageMax || 0) + parseFloat((values as any).inflowSeptageMax || 0)).toFixed(2) || '-'}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.inflow.sewerage")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="inflowSewerageMean"
                        name="inflowSewerageMean"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).inflowSewerageMean || ""}
                        isInvalid={(touched as any).inflowSewerageMean && !!(errors as any).inflowSewerageMean}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).inflowSewerageMean}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="inflowSewerageMax"
                        name="inflowSewerageMax"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).inflowSewerageMax || ""}
                        isInvalid={(touched as any).inflowSewerageMax && !!(errors as any).inflowSewerageMax}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).inflowSewerageMax}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {!(values as any).industry && <Form.Group as={Row}>
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.inflow.septage")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="inflowSeptageMean"
                        name="inflowSeptageMean"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).inflowSeptageMean || ""}
                        isInvalid={(touched as any).inflowSeptageMean && !!(errors as any).inflowSeptageMean}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).inflowSeptageMean}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="inflowSeptageMax"
                        name="inflowSeptageMax"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).inflowSeptageMax || ""}
                        isInvalid={(touched as any).inflowSeptageMax && !!(errors as any).inflowSeptageMax}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).inflowSeptageMax}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>}
            <hr></hr>
            <h4>{t("wtp.incoming_load.incoming.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3 }}></Col>
                <Col md={{ span: 3 }} className="text-center">
                    <strong>{t("wtp.incoming_load.incoming.mean")}</strong>
                </Col>
                <Col md={{ span: 3 }} className="text-center">
                    <strong>{t("wtp.incoming_load.incoming.max")}</strong>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.incoming.total")}
                </Form.Label>
                <Col md={{ span: 3 }} className="text-center">
                    {+(parseFloat((values as any).incomingSewerageMean ?? 0) + parseFloat((values as any).incomingSeptageMean ?? 0)).toFixed(2) || '-'}
                </Col>
                <Col md={{ span: 3 }} className="text-center">
                    {+(parseFloat((values as any).incomingSewerageMax ?? 0) + parseFloat((values as any).incomingSeptageMax ?? 0)).toFixed(2) || '-'}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.incoming.sewerage")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="incomingSewerageMean"
                        name="incomingSewerageMean"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).incomingSewerageMean || ""}
                        isInvalid={(touched as any).incomingSewerageMean && !!(errors as any).incomingSewerageMean}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).incomingSewerageMean}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="incomingSewerageMax"
                        name="incomingSewerageMax"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).incomingSewerageMax || ""}
                        isInvalid={(touched as any).incomingSewerageMax && !!(errors as any).incomingSewerageMax}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).incomingSewerageMax}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {!(values as any).industry && <Form.Group as={Row}>
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.incoming_load.incoming.septage")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="incomingSeptageMean"
                        name="incomingSeptageMean"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).incomingSeptageMean || ""}
                        isInvalid={(touched as any).incomingSeptageMean && !!(errors as any).incomingSeptageMean}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).incomingSeptageMean}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="incomingSeptageMax"
                        name="incomingSeptageMax"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).incomingSeptageMax || ""}
                        isInvalid={(touched as any).incomingSeptageMax && !!(errors as any).incomingSeptageMax}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).incomingSeptageMax}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>}
        </>
    )
}

export default WtpManageIncomingLoad;
