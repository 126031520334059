import { ReactElement } from "react"
import { Button, OverlayTrigger, Popover } from "react-bootstrap"
import { Trash } from "react-bootstrap-icons"

export const ConfirmationPopover: React.FC<ConfirmationPropTypes> = (props) => {

    return <OverlayTrigger
        trigger="focus"
        overlay={
            <Popover id={props.id}>
                <Popover.Header as="h5" className="text-center">{props.header}</Popover.Header>
                <Popover.Body className="text-center">
                    <p>{props.body}</p>
                    <Button size="sm" variant="outline-secondary" className="me-2" onClick={() => props.onCancel()}>{props.cancelLabel ?? "Cancel"}</Button>
                    <Button size="sm" variant="danger" className="ms-2" onClick={() => props.onOk()}>{props.okLabel ?? "Ok"}</Button>
                </Popover.Body>
            </Popover>
        }
    >
        <Button
            size="sm"
            variant="outline-danger"
            style={{ border: "none" }}
            className="d-flex align-items-center justify-content-center m-0 flex-grow-0"
            disabled={!!props.disabled}
        >
            {props.icon || <Trash />}
        </Button>
    </OverlayTrigger>
}

interface ConfirmationPropTypes {
    header: string,
    body: string
    okLabel?: string,
    cancelLabel?: string,
    onOk: Function,
    onCancel: Function,
    id: string,
    disabled?: boolean,
    icon?: ReactElement
}