import { fromLonLat, transformExtent } from "ol/proj";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Table, Alert, Image, Modal } from "react-bootstrap";
import { Image as EmptyImage, Layers, Pencil } from 'react-bootstrap-icons';
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle } from "ol/geom";
import WKT from 'ol/format/WKT';
import { PlusCircle, PencilFill, Trash } from 'react-bootstrap-icons';
import { useTranslation } from "react-i18next";
import { RiverBasinDistrict, AuthorityType, WastewaterTreatementPlantExtension, AdministrativeRegion, PriorityCategory, IedCategory, Authority, RiverBasin } from "../../data/Definitions";
import { Formik, useFormikContext } from "formik";
import { useState, useRef, useEffect, useCallback } from "react";
import * as yup from 'yup';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../components/BingMap";
import React from "react";

let unique_id = 0;

const WtpManageGeneral: React.FC<{ isShowing: boolean, riverBasinDistricts: RiverBasinDistrict[], administrativeRegions: AdministrativeRegion[], authorityTypes: AuthorityType[], priorityCategories: PriorityCategory[], iedCategories: IedCategory[], authorities: Authority[], riverBasins: RiverBasin[] }> = ({ isShowing, riverBasinDistricts, administrativeRegions, authorityTypes, priorityCategories, iedCategories, authorities, riverBasins }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [addExpansionsModalShow, setAddExpansionsModalShow] = useState(false);
    const [editExpansionsModalShow, setEditExpansionsModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const editFormRef = useRef<any>(null);
    const photoInputRef = useRef<any>(null);
    const [selectedExpansion, setSelectedExpansion] = useState<WastewaterTreatementPlantExtension | null>(null);
    const [drawing, setDrawing] = useState(false);
    const [geometry, setGeometry] = useState<Geometry | undefined>();
    const [showBing, setShowBing] = useState(false);
    const layersButton = <button>&#9776;</button>;
    const mapRef = React.createRef() as React.RefObject<RMap>;

    const expansionSchema = yup.object().shape({
        expansionWorkTitle: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        expansionCompletionYear: yup.number().required(t("wtp.validation.required")).min(1900, (v) => `${t("wtp.validation.greater_than")} ${v.min}`).max(new Date(Date.now()).getFullYear(), (v) => `${t("wtp.validation.less_than")} ${v.max}`).typeError(t("wtp.validation.type_error")),
        expansionFundingProgram: yup.string().typeError(t("wtp.validation.type_error")),
        expansionBudget: yup.string().typeError(t("wtp.validation.type_error")),
    });

    useEffect(() => {
        if (!(values as any).shape) return;
        if (geometry) return;
        let wkt = new WKT();
        let geom = wkt.readGeometry((values as any).shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        setGeometry(geom);
    }, [(values as any).shape]);

    useEffect(() => {
        if ((values as any).shape) return;
        if (!(values as any).longitude || !(values as any).latitude) return;
        let lon = parseFloat((values as any).longitude);
        if (isNaN(lon)) return;
        let lat = parseFloat((values as any).latitude);
        if (isNaN(lat)) return;
        let center = fromLonLat([lon, lat], 'EPSG:3857');
        let geom = new Circle(center, 100);
        mapRef.current?.ol.getView().setCenter(center);
        mapRef.current?.ol.getView().setZoom(16);
        setGeometry(geom);
    }, [(values as any).longitude, (values as any).latitude, (values as any).shape]);

    useEffect(() => {
        if (!editExpansionsModalShow) setSelectedExpansion(null);
    }, [editExpansionsModalShow])

    const handleAddSubmit = (val: {
        expansionWorkTitle: string,
        expansionCompletionYear: string,
        expansionFundingProgram: string,
        expansionBudget: string,
    }) => {
        let cur = (values as any).extensions;
        cur.push({
            title: val.expansionWorkTitle,
            fundingProgram: val.expansionFundingProgram,
            budget: val.expansionBudget,
            completionYear: parseInt(val.expansionCompletionYear.toString()),
        });
        setFieldValue("extensions", cur);
        setAddExpansionsModalShow(false);
        setSelectedExpansion(null);
    };

    const handleEditSubmit = (val: {
        expansionWorkTitle: string,
        expansionCompletionYear: string,
        expansionFundingProgram: string,
        expansionBudget: string,
    }) => {
        if (!selectedExpansion) return;
        let cur = (values as any).extensions;
        let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantExtension) => x === selectedExpansion);
        if (idxToEdit === -1) return;
        cur[idxToEdit] = Object.assign(selectedExpansion, {
            title: val.expansionWorkTitle,
            fundingProgram: val.expansionFundingProgram,
            budget: val.expansionBudget,
            completionYear: parseInt(val.expansionCompletionYear.toString()),
        });
        setFieldValue("extensions", cur);
        setEditExpansionsModalShow(false);
        setSelectedExpansion(null);
    };

    const removeExpansion = () => {
        if (!selectedExpansion) return;
        let cur = (values as any).extensions;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantExtension) => x === selectedExpansion);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("extensions", cur);
        setSelectedExpansion(null);
    }

    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        // setFieldValue("photoUrl", URL.createObjectURL(fileObj));
        setFieldValue("photo", fileObj);
    };

    const autoDetectGeo = async () => {
        if (!(values as any).longitude) return;
        if (!(values as any).latitude) return;
        const resRB = await fetch(`/api/spatial/riverbasin?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });
        const resAR = await fetch(`/api/spatial/administrativeRegion?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });
        const resAU = await fetch(`/api/spatial/municipal?lng=${(values as any).longitude}&lat=${(values as any).latitude}`, { cache: "no-store" });

        if (resRB.status === 200) {
            const rb: RiverBasin = await resRB.json();
            setFieldValue("riverBasinDistrictId", rb.riverBasinDistrictId);
            setFieldValue("riverBasinId", rb.id);
        } else {
            setFieldValue("riverBasinDistrictId", 0);
            setFieldValue("riverBasinId", 0);
        }

        if (resAR.status === 200) {
            const ar: AdministrativeRegion = await resAR.json();
            setFieldValue("administrativeRegionId", ar.id ?? 0);
        } else {
            setFieldValue("administrativeRegionId", 0);
        }

        if (resAU.status === 200) {
            const au: Authority = await resAU.json();
            setFieldValue("municipalId", au.id ?? 0);
        } else {
            setFieldValue("municipalId", 0);
        }
    }

    return (
        <>
            <Row className="mb-2">
                {/* <Col md={{ span: 6 }} className="text-center">

                    {((values as any).photoUrl || (values as any).photo) ?
                        <div style={{ margin: "auto", width: "500px", height: "300px", background: "rgba(0,0,0,0)" }}>
                            <Image style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }} src={(values as any).photo ? URL.createObjectURL((values as any).photo) : (values as any).photoUrl} />
                        </div> :
                        <div style={{ margin: "auto", width: "500px", height: "300px", backgroundColor: "GrayText", textAlign: "center", color: "white", fontSize: "100px", lineHeight: "250px" }}>
                            <EmptyImage />
                        </div>}
                    <input
                        style={{ display: 'none' }}
                        ref={photoInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoChange}
                    />
                    <Button variant="outline-primary mt-2" onClick={() => photoInputRef.current?.click()}>{t("wtp.general.add_image")}</Button>
                </Col> */}
                <Col md={{ span: 6 }} className="pe-0">
                    <div style={{ width: "100%", height: "300px" }}>
                        {isShowing && <RMap
                            className='w-100 h-100'
                            initial={((values as any).longitude && (values as any).latitude) ? { center: fromLonLat([(values as any).longitude, (values as any).latitude]), zoom: 16 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                            ref={mapRef}
                        >
                            <RControl.RCustom className="m-2">
                                <Button variant="secondary" onClick={() => { !drawing && setGeometry(undefined); setDrawing(!drawing); }}>
                                    <PencilFill />
                                </Button>
                            </RControl.RCustom>
                            <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                <ROSM properties={{ label: "Map" }} />
                                <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                            </RControl.RLayers>
                            <RLayerVector
                                zIndex={11}
                                onChange={(e: BaseEvent) => {
                                    let source = e.target as VectorSource<Geometry>;
                                    if (!source?.forEachFeatureAtCoordinateDirect) {
                                        return;
                                    }

                                    let feats = source.getFeatures();
                                    if (feats.length > 1) {
                                        let geom = feats[0].getGeometry();
                                        setGeometry(geom);
                                        if (geom) {
                                            let wkt = new WKT();
                                            let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                            setFieldValue("shape", wktGeom);
                                            let ext = geom.getExtent();
                                            var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                            var center = extent.getCenter(lonLatExtent);
                                            if (center) {
                                                setFieldValue("longitude", center[0]);
                                                setFieldValue("latitude", center[1]);
                                            }
                                        }
                                        source.clear();
                                        setDrawing(false);
                                    }
                                }}
                            >
                                <RFeature
                                    key={`Feature${++unique_id}`}
                                    geometry={geometry}
                                >
                                    <RStyle.RStyle>
                                        <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                        <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                    </RStyle.RStyle>
                                </RFeature>
                                {drawing && <RInteraction.RDraw
                                    type={"Polygon"}
                                    finishCondition={e => true}
                                />}
                            </RLayerVector>
                        </RMap>}
                    </div>
                </Col>
                <Col md={{ span: 6 }}>
                    <h5 className="text-center">{t("wtp.general.coordinates")}</h5>
                    <Form.Group as={Row} className="mb-3">
                        <Col md={{ offset: 3, span: 6 }}>
                            <FloatingLabel label={t("wtp.general.longitude")}>
                                <Form.Control
                                    id="longitude"
                                    placeholder="Longitude"
                                    name="longitude"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values as any).longitude ?? ""}
                                    isInvalid={(touched as any).longitude && !!(errors as any).longitude}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).longitude}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Form.Group>
                    <Col mb={{ span: 6 }}>
                        <Form.Group as={Row} className="mb-3">
                            <Col md={{ offset: 3, span: 6 }}>
                                <FloatingLabel label={t("wtp.general.latitude")}>
                                    <Form.Control
                                        id="latitude"
                                        placeholder="Latitude"
                                        name="latitude"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={(values as any).latitude ?? ""}
                                        isInvalid={(touched as any).latitude && !!(errors as any).latitude}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(errors as any).latitude}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Form.Group>
                    </Col>
                </Col>
            </Row>
            <h4>{t("wtp.general.general.header")}</h4>
            {/* {!(values as any).industry && <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.priority_category")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="priorityCategoryId"
                        name="priorityCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).priorityCategoryId}
                        isInvalid={(touched as any).priorityCategoryId && !!(errors as any).priorityCategoryId}
                    >
                        {priorityCategories.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).priorityCategoryId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>} */}
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.ied_category")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="iedCategoryId"
                        name="iedCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).iedCategoryId}
                        isInvalid={(touched as any).iedCategoryId && !!(errors as any).iedCategoryId}
                    >
                        {iedCategories.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).iedCategoryId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.eprtr")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="eprtr"
                        name="eprtr"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).eprtr}
                        isInvalid={(touched as any).eprtr && !!(errors as any).eprtr}
                    >
                        <option key={0} value={0}>{t("no")}</option>
                        <option key={1} value={1}>{t("yes")}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).eprtr}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.eprtr_code")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.general.general.eprtr_code")}
                        id="eprtrCode"
                        name="eprtrCode"
                        onBlur={handleChange}
                        defaultValue={(values as any).eprtrCode || ""}
                        isInvalid={(touched as any).eprtrCode && !!(errors as any).eprtrCode}
                        disabled={(values as any).eprtr === 0 || (values as any).eprtr === "0"}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).eprtrCode}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.river_basin_district")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="riverBasinDistrictId"
                        name="riverBasinDistrictId"
                        onChange={(e) => { let currentCatchments = riverBasins.filter(x => x.riverBasinDistrictId?.toString() === e.target.value); setFieldValue("riverBasinId", currentCatchments.length === 1 ? currentCatchments.pop()?.id : 0); handleChange(e); }}
                        onBlur={handleBlur}
                        value={(values as any).riverBasinDistrictId ?? 0}
                        isInvalid={(touched as any).riverBasinDistrictId && !!(errors as any).riverBasinDistrictId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_river_basin_district")}</option>
                        {riverBasinDistricts.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).riverBasinDistrictId}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Button variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={autoDetectGeo}>{t("wtp.button.auto_assign")}</Button>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.catchment")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="riverBasinId"
                        name="riverBasinId"
                        onChange={handleChange}
                        value={(values as any).riverBasinId || "0"}
                        isInvalid={(touched as any).riverBasinId && !!(errors as any).riverBasinId}
                        disabled={(values as any).riverBasinDistrictId <= 0}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_catchment")}</option>
                        {riverBasins.filter(x => x.riverBasinDistrictId.toString() === (values as any).riverBasinDistrictId?.toString()).map((x, i) => <option key={x.id} value={x.id}>{`${i18n.language === "en" ? x.nameEn : x.name} ( ${x.code} )`}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).riverBasinId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.general.general.administrative_region")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="administrativeRegionId"
                        name="administrativeRegionId"
                        onChange={ev => { setFieldValue("municipalId", 0); handleChange(ev); }}
                        onBlur={handleBlur}
                        value={(values as any).administrativeRegionId ?? 0}
                        isInvalid={(touched as any).administrativeRegionId && !!(errors as any).administrativeRegionId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_administrative_region")}</option>
                        {administrativeRegions.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).administrativeRegionId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("settlements.municipal")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="municipalId"
                        name="municipalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).municipalId ?? 0}
                        isInvalid={(touched as any).municipalId && !!(errors as any).municipalId}
                    >
                        <option key={"none"} value={0}>{t("wtp.general.general.select_municipal")}</option>
                        {((values as any).administrativeRegionId && (values as any).administrativeRegionId != "0")
                            ? authorities.filter(x => x.authorityTypeId === 1).filter(x => x.administrativeRegionId?.toString() === (values as any).administrativeRegionId.toString()).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                            : authorities.filter(x => x.authorityTypeId === 1).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                        }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).municipalId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {!(values as any).industry && <>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.authority")}</Form.Label>
                    <Col md={{ span: 3 }} className="pe-0">
                        <Form.Select
                            id="authorityTypeId"
                            name="authorityTypeId"
                            onChange={(e) => { setFieldValue("authorityId", 0); handleChange(e); }}
                            onBlur={handleBlur}
                            value={(values as any).authorityTypeId}
                            isInvalid={(touched as any).authorityTypeId && !!(errors as any).authorityTypeId}
                        >
                            <option key={"none"} value={0}>{t("wtp.general.general.select_authority_type")}</option>
                            {authorityTypes.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).authorityTypeId}
                        </Form.Control.Feedback>
                    </Col>
                    <Col md={{ span: 3 }} className="p-0">
                        {(values as any).authorityTypeId.toString() === "1" ? <>
                            <Form.Select
                                id="authorityId"
                                name="authorityId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={(values as any).authorityId}
                                isInvalid={(touched as any).authorityId && !!(errors as any).authorityId}
                            >
                                <option key={"none"} value={0}>{t("wtp.general.general.select_authority")}</option>
                                {authorities.filter(x => (x.authorityTypeId ?? 0).toString() === ((values as any).authorityTypeId ?? 0).toString()).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).authorityId}
                            </Form.Control.Feedback>
                        </>
                            : (values as any).authorityTypeId.toString() === "2" ? <>
                                <Form.Select
                                    id="authorityId"
                                    name="authorityId"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={(values as any).authorityId}
                                    isInvalid={(touched as any).authorityId && !!(errors as any).authorityId}
                                >
                                    <option key={"none"} value={0}>{t("wtp.general.general.select_authority")}</option>
                                    {authorities.filter(x => (x.authorityTypeId ?? 0).toString() === ((values as any).authorityTypeId ?? 0).toString()).map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {(errors as any).authorityId}
                                </Form.Control.Feedback>
                            </>
                                : <></>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.funding")}</Form.Label>
                    <Col md={{ span: 3 }} className="pe-0">
                        <Form.Control
                            placeholder={t("wtp.general.general.funding")}
                            id="fundingProgram"
                            name="fundingProgram"
                            onBlur={handleChange}
                            defaultValue={(values as any).fundingProgram || ""}
                            isInvalid={(touched as any).fundingProgram && !!(errors as any).fundingProgram}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).fundingProgram}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.construction_completed_on")}</Form.Label>
                    <Col md={{ span: 3 }} className="pe-0">
                        <Form.Control
                            type="date"
                            id="constructionEndedOn"
                            name="constructionEndedOn"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={(values as any).constructionEndedOn ? new Date((values as any).constructionEndedOn.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : ""}
                            isInvalid={(touched as any).constructionEndedOn && !!(errors as any).constructionEndedOn}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).constructionEndedOn}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </>}
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.operation_started_on")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        type="date"
                        id="operationStartedOn"
                        name="operationStartedOn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).operationStartedOn ? new Date((values as any).operationStartedOn.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : ""}
                        isInvalid={(touched as any).operationStartedOn && !!(errors as any).operationStartedOn}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).operationStartedOn}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.general.environmental_terms_expire_on")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        type="date"
                        id="environmentalTermsExpireOn"
                        name="environmentalTermsExpireOn"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).environmentalTermsExpireOn ? new Date((values as any).environmentalTermsExpireOn.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : ""}
                        isInvalid={(touched as any).environmentalTermsExpireOn && !!(errors as any).environmentalTermsExpireOn}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).environmentalTermsExpireOn}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Row className="g-2">
                <Col className="text-start" md={{ offset: 3 }}>
                    {(touched as any).environmentalTermsFilePath && (errors as any).environmentalTermsFilePath && (
                        <Alert variant={'light'}>
                            <div className="text-danger">Απαιτείται η υποβολή της Απόφασης Έγκρισης Περιβαλλοντικών Όρων της Ε.Ε.Λ. σε ηλεκτρονική μορφή (βλ. καρτέλα 'Αρχεία').</div>
                        </Alert>
                    )}
                </Col>
            </Row>
            <hr></hr>
            {!(values as any).industry && <>
                <Row>
                    <Col>
                        <h4>{t("wtp.general.upgrades.header")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddExpansionsModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                            <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setEditExpansionsModalShow(true)} disabled={!selectedExpansion}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                            <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeExpansion} disabled={!selectedExpansion}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="text-center">{t("wtp.general.upgrades.completion_year")}</th>
                                    <th>{t("wtp.general.upgrades.work_title")}</th>
                                    <th>{t("wtp.general.upgrades.funding_program")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(values as any).extensions.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className="text-center">
                                            {t("wtp.table.no_data")}
                                        </td>
                                    </tr> :
                                    (values as any).extensions.map((x: WastewaterTreatementPlantExtension, i: number) => <tr key={i} onClick={() => setSelectedExpansion(x)} className={selectedExpansion === x ? "selected" : ""}>
                                        <td className="text-center"><Button variant="link" className="py-0" onClick={() => { setSelectedExpansion(x); setEditExpansionsModalShow(true); }}><Pencil /></Button></td>
                                        <td className="text-center">{x.completionYear}</td>
                                        <td>{x.title}</td>
                                        <td>{x.fundingProgram}</td>
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <hr></hr>
            </>}
            <h4>{t("wtp.general.lab.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.general.lab.chief")}</Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="staffChiefChemLab"
                        name="staffChiefChemLab"
                        onBlur={handleChange}
                        defaultValue={(values as any).staffChiefChemLab ?? ""}
                        isInvalid={(touched as any).staffChiefChemLab && !!(errors as any).staffChiefChemLab}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).staffChiefChemLab}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3, offset: 3 }}>
                    <Form.Check
                        type="checkbox"
                        label={t("wtp.general.lab.complex_inflow_sampler")}
                        id="complexSamplerInflow"
                        name="complexSamplerInflow"
                        onChange={e => setFieldValue('complexSamplerInflow', e.target.checked)}
                        checked={(values as any).complexSamplerInflow}
                        isInvalid={(touched as any).complexSamplerInflow && !!(errors as any).complexSamplerInflow}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).complexSamplerInflow}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3, offset: 3 }}>
                    <Form.Check
                        type="checkbox"
                        label={t("wtp.general.lab.complex_outflow_sampler")}
                        id="complexSamplerOutflow"
                        name="complexSamplerOutflow"
                        onChange={e => setFieldValue('complexSamplerOutflow', e.target.checked)}
                        checked={(values as any).complexSamplerOutflow}
                        isInvalid={(touched as any).complexSamplerOutflow && !!(errors as any).complexSamplerOutflow}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).complexSamplerOutflow}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Modal
                show={addExpansionsModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddExpansionsModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.general.upgrades.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            expansionWorkTitle: "",
                            expansionCompletionYear: "",
                            expansionBudget: "",
                            expansionFundingProgram: "",
                        }}
                        validationSchema={expansionSchema}
                        onSubmit={(v) => handleAddSubmit(v)}
                    >
                        {props => <Form noValidate ref={addFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.completion_year") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.completion_year")}
                                        id="expansionCompletionYear"
                                        name="expansionCompletionYear"
                                        value={props.values.expansionCompletionYear}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionCompletionYear && !!props.errors.expansionCompletionYear}
                                        isValid={props.touched.expansionCompletionYear && !props.errors.expansionCompletionYear}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionCompletionYear}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.work_title") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.work_title")}
                                        id="expansionWorkTitle"
                                        name="expansionWorkTitle"
                                        value={props.values.expansionWorkTitle}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionWorkTitle && !!props.errors.expansionWorkTitle}
                                        isValid={props.touched.expansionWorkTitle && !props.errors.expansionWorkTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionWorkTitle}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.budget")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.budget")}
                                        id="expansionBudget"
                                        name="expansionBudget"
                                        value={props.values.expansionBudget}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionBudget && !!props.errors.expansionBudget}
                                        isValid={props.touched.expansionBudget && !props.errors.expansionBudget}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionBudget}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.funding_program")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.funding_program")}
                                        id="expansionFundingProgram"
                                        name="expansionFundingProgram"
                                        value={props.values.expansionFundingProgram}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddExpansionsModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editExpansionsModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditExpansionsModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.general.upgrades.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            expansionWorkTitle: selectedExpansion?.title || "",
                            expansionCompletionYear: selectedExpansion?.completionYear?.toString() || "",
                            expansionBudget: selectedExpansion?.budget || "",
                            expansionFundingProgram: selectedExpansion?.fundingProgram || "",
                        }}
                        validationSchema={expansionSchema}
                        onSubmit={(v) => handleEditSubmit(v)}
                    >
                        {props => <Form noValidate ref={editFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.completion_year") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.completion_year")}
                                        id="expansionCompletionYear"
                                        name="expansionCompletionYear"
                                        value={props.values.expansionCompletionYear}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionCompletionYear && !!props.errors.expansionCompletionYear}
                                        isValid={props.touched.expansionCompletionYear && !props.errors.expansionCompletionYear}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionCompletionYear}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.work_title") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.work_title")}
                                        id="expansionWorkTitle"
                                        name="expansionWorkTitle"
                                        value={props.values.expansionWorkTitle}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionWorkTitle && !!props.errors.expansionWorkTitle}
                                        isValid={props.touched.expansionWorkTitle && !props.errors.expansionWorkTitle}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionWorkTitle}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.budget")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.budget")}
                                        id="expansionBudget"
                                        name="expansionBudget"
                                        value={props.values.expansionBudget}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.expansionBudget && !!props.errors.expansionBudget}
                                        isValid={props.touched.expansionBudget && !props.errors.expansionBudget}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.expansionBudget}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.general.upgrades.funding_program")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("wtp.general.upgrades.funding_program")}
                                        id="expansionFundingProgram"
                                        name="expansionFundingProgram"
                                        value={props.values.expansionFundingProgram}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditExpansionsModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WtpManageGeneral;
