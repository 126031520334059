import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    "Home": "Home",
                    "wtp": "Wastewater Treatment Plants",
                    "login": "Login for authorized users",
                    "Login": "Login for authorized users",
                    "logout": "Logout",
                    "agglomerations": "Agglomerations",
                    "queries": "Query tools",
                    "announcements": "Announcements",
                    "contact": "Contact",
                    "appTitle": "Wastewater Treatment Plants Monitoring Database",
                    "logInError": "Error while signing you in! Check your credentials and try again.",
                    "manager": "Manager",
                    "Restore snapshot": "Restore snapshot",
                    "snapshots.current_snapshot": "Current snapshot",
                    "Snapshots": "Snapshots",
                    "snapshots.table.no_data": "No snapshots found",
                    "snapshots.snapshotFile": "File",
                    "snapshots.restore.header": "Restore snapshot",
                    "snapshots.restore.ok": "OK",
                    "snapshots.restore.cancel": "Cancel",
                    "snapshots.restore.body": "Are you sure you want to restore this snapshot?",
                    "yes": "Yes",
                    "no": "No",
                    "nav_menu.settlements": "Settlements",
                    "nav_menu.snapshots": "Snapshots",
                    "nav_menu.agglomerations": "Agglomerations",
                    "nav_menu.settings": "Settings",
                    "project_data.total_agglomerations": "Total No of agglomerations",
                    "project_data.compliant": "Compliant",
                    "project_data.non_compliant": "Non compliant",
                    "project_data.insufficient_data": "Incomplete connections or measurements",
                    "project_data.operational_problems": "Operational problems ",
                    "project_data.planned_projects": "Integrated or planned projects ",
                    "project_data.mature_projects": "Mature projects with infrastructure needs ",
                    "project_data.immature_projects": "Immature projects",
                    "admin.data": "Data Editor",
                    "admin.deya": "D.E.Y.A.",
                    "admin.deya.deya_name": "D.E.Y.A. name (greek)",
                    "admin.deya.deya_name_english": "D.E.Y.A. name",
                    "admin.parameter": "Parameter",
                    "admin.parameters": "Parameters",
                    "admin.parameters.parameter_name": "Parameter name",
                    "admin.parameters.parameter_unit": "Parameter unit",
                    "admin.parameters.parameter_description": "Description",
                    "admin.sensitiveReceiver": "Sensitive Receiver",
                    "admin.sensitiveReceivers": "Sensitive Receivers",
                    "admin.sensitiveReceivers.sensitive_receiver_name": "Sensitive receiver name (greek)",
                    "admin.sensitiveReceivers.sensitive_receiver_name_english": "Sensitive receiver name",
                    "admin.sensitiveReceivers.sensitive_receiver_code": "Sensitive receiver code",
                    "admin.sensitiveReceivers.sensitive_receiver_type": "Sensitive receiver type",
                    "admin.sensitiveReceivers.sensitive_receiver_water_bodies": "Water bodies",
                    "admin.sensitiveReceivers.select_sensitive_receiver_type": "Please select sensitive receiver type",
                    "admin.sensitiveReceivers.select_sensitive_receiver_water_bodies": "Please select water bodies",
                    "W.T.P. Edit": "W.T.P. Edit",
                    "My W.T.P.": "My W.T.P.",
                    "search_for": "Search for...",
                    "rows_per_page": "Rows per page",
                    "rows_per_page_of": "of",
                    "wtp.button.save": "Save",
                    "wtp.button.saving": "Saving...",
                    "wtp.button.cancel": "Cancel",
                    "wtp.button.close": "Close",
                    "wtp.button.update": "Update",
                    "wtp.button.modify": "MODIFY",
                    "wtp.button.add_upper": "ADD",
                    "wtp.button.add": "Add",
                    "wtp.button.edit": "EDIT",
                    "wtp.button.edit_capitalized": "Edit",
                    "wtp.button.reset": "Reset",
                    "wtp.button.back": "Back to the list",
                    "wtp.button.delete": "DELETE",
                    "wtp.button.insert": "INSERT",
                    "wtp.button.confirm": "Confirm",
                    "wtp.button.next": "Next",
                    "wtp.button.previous": "Previous",
                    "wtp.button.submit_file": "Submit file",
                    "wtp.button.delete_file": "Delete",
                    "wtp.button.auto_assign": "Auto assign",
                    "wtp.button.edit_wtp_data": "Edit W.T.P. Data",
                    "wtp.button.add_multiple": "Import from file",
                    "wtp.button.recalculate_aepo": "Recalculate AEPO",
                    "wtp.button.recalculate_legislation": "Recalculate Directive 91/271/ΕΟΚ",
                    "wtp.button.recalculate_reuse_kya": "Recalculate KYA 145116",
                    "wtp.button.recalculate_reuse_eu": "Recalculate Regulation (EU) 2020/741",
                    "wtp.table.no_data": "No data to display",
                    "wtp.table.total": "Total",
                    "wtp.select.no_options": "No available options",
                    "wtp.save.error": "Error occured while saving!",
                    "wtp.save.warning": "Could not save! Same entry found.",
                    "wtp.save.success": "Changes saved successfully!",
                    "wtp.validation.required": "This field is required",
                    "wtp.validation.non_positive": "This field must be greater than 0",
                    "wtp.validation.non_integer": "This field must contain integer",
                    "wtp.validation.type_error": "This field must contain a number",
                    "wtp.validation.no_select": "Please select a value from the list",
                    "wtp.validation.greater_than": "This field must contain a number greater than or equal to ",
                    "wtp.validation.less_than": "This field must contain a number less than or equal to ",
                    "wtp.validation.date_invalid": "Input date is invalid",
                    "wtp.pagination.page": "Page",
                    "wtp.pagination.of": "of",
                    "wtp.pagination.entries": "entries",
                    "wtp.pagination.entries_per_page": "Entries per page",
                    "wtp.main.header": "Wastewater Treatmenent Plant",
                    "wtp.main.last_updated": "Last Updated",
                    "wtp.main.general": "GENERAL",
                    "wtp.main.waste_orgin": "WASTE ORIGIN",
                    "wtp.main.industry_characteristics": "INDUSTRY CHARACTERISTICS",
                    "wtp.main.incoming_load": "INCOMING LOAD",
                    "wtp.main.processing": "TREATMENT",
                    "wtp.main.energy_greenhouse_emissions": "ENERGY - GREENHOUSE EMISSIONS",
                    "wtp.main.disposal": "DISPOSAL",
                    "wtp.main.reuse": "REUSE",
                    "wtp.main.measurements": "MEASUREMENTS",
                    "wtp.main.files": "FILES",
                    "wtp.main.comments": "COMMENTS",
                    "wtp.main.limits": "LIMITS",
                    "wtp.main.measurements_wtp": "WTP MEASUREMENTS",
                    "wtp.main.measurements_reuse": "REUSE MEASUREMENTS",
                    "wtp.general.add_image": "Add image",
                    "wtp.general.coordinates": "Coordinates of W.T.P.",
                    "wtp.general.coordinates.longitude": "Longitude of W.T.P.",
                    "wtp.general.coordinates.latitude": "Latitude of W.T.P.",
                    "wtp.general.longitude": "Longitude",
                    "wtp.general.latitude": "Latitude",
                    "wtp.general.general.header": "General",
                    "wtp.general.general.priority_category": "Priority Category",
                    "wtp.general.general.ied_category": "IED Category",
                    "wtp.general.general.eprtr_code": "Ε-PRTR Code",
                    "wtp.general.general.river_basin_district": "Water District",
                    "wtp.general.general.select_river_basin_district": "Select water district",
                    "wtp.general.general.select_catchment": "Select catchment",
                    "wtp.general.general.administrative_region": "Administrative Region",
                    "wtp.general.general.authority_type": "Authority type",
                    "wtp.general.general.authority": "Authority",
                    "wtp.general.general.select_authority_type": "Select authority type",
                    "wtp.general.general.select_authority": "Select authority",
                    "wtp.general.general.funding": "Funding Program",
                    "wtp.general.general.construction_completed_on": "Construction Completed On",
                    "wtp.general.general.operation_started_on": "Operation Started On",
                    "wtp.general.general.environmental_terms_expire_on": "Environmental Terms Expire on",
                    "wtp.general.general.catchment": "Catchment",
                    "wtp.general.general.select_administrative_region": "Select administrative region",
                    "wtp.general.general.select_municipal": "Select municipal",
                    "wtp.general.upgrades.header": "Expansions / Upgrades of W.T.P.",
                    "wtp.general.upgrades.add.header": "Add expansion / upgrade of W.T.P.",
                    "wtp.general.upgrades.edit.header": "Edit expansion / upgrade of W.T.P.",
                    "wtp.general.upgrades.completion_year": "Year of completion",
                    "wtp.general.upgrades.work_title": "Work title",
                    "wtp.general.upgrades.budget": "Budget",
                    "wtp.general.upgrades.funding_program": "Funding program",
                    "wtp.general.lab.header": "Laboratory",
                    "wtp.general.lab.chief": "Chemical analysis staff chief",
                    "wtp.general.lab.complex_inflow_sampler": "Using complex inflow sampler",
                    "wtp.general.lab.complex_outflow_sampler": "Using complex outflow sampler",
                    "wtp.general.reuse_state": "Reuse WTP state",
                    "wtp.general.select_reuse_state": "Select state",
                    "wtp.general.reuse_activity_branch": "Activity branch",
                    "wtp.general.select_reuse_activity_branch": "Select activity branch",
                    "wtp.general.reuse_activity_branch_name": "Activity name",
                    "wtp.general.treatment_method": "Waste treatment method",
                    "wtp.waste_origin.served_sewage_agglomeration.header": "Served Agglomerations (via sewerage network)",
                    "wtp.waste_origin.served_sewage_settlement.header": "Served Settlements (via sewerage network)",
                    "wtp.waste_origin.served_sewage_agglomeration.agglomeration": "Agglomeration",
                    "wtp.waste_origin.served_sewage_agglomeration.code": "Code",
                    "wtp.waste_origin.served_sewage_agglomeration.population": "Peak population (PE)",
                    "wtp.waste_origin.served_sewage_agglomeration.rate": "Rate % S.N.",
                    "wtp.waste_origin.served_sewage_agglomeration.network_type": "Network type",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.add.header": "Add served agglomeration (via sewerage network)",
                    "wtp.waste_origin.served_sewage_settlement.modal.add.header": "Add served settlement (via sewerage network)",
                    "wtp.waste_origin.served_sewage_settlement.settlement": "Settlement",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.new_agglomeration": "New agglomeration",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.agglomeration_name": "Agglomeration name (in greek)",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.agglomeration_name_english": "Agglomeration name",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_agglomeration": "Select agglomeration",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement": "Select settlement",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_network_type": "Select network type",
                    "wtp.waste_origin.served_septage_agglomeration.header": "Served Settlements (via septage)",
                    "wtp.waste_origin.served_septage_agglomeration.settlement": "Settlement",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks": "Total incoming tanks per day",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks_summer": "Total incoming tanks per day (summer period)",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks_winter": "Total incoming tanks per day (winter period)",
                    "wtp.waste_origin.served_septage_agglomeration.agglomeration": "Settlement",
                    "wtp.waste_origin.served_septage_agglomeration.population": "Peak population (PE)",
                    "wtp.waste_origin.served_septage_agglomeration.modal.add.header": "Add served settlement (via septage)",
                    "wtp.waste_origin.served_septage_agglomeration.modal.edit.header": "Edit served settlement (via septage)",
                    "wtp.waste_origin.served_industry_branches.header": "Served Industry Branches (see Annex III, K.Y.A. 5673/400/1997)",
                    "wtp.waste_origin.served_industry_branches.annex": "Annex",
                    "wtp.waste_origin.served_industry_branches.loadPE": "WTP incoming load (PE)",
                    "wtp.waste_origin.served_industry_branches.loadKG": "WTP incoming load (kg/d)",
                    "wtp.waste_origin.served_industry_branches.modal.add.header": "Add new served industry branch",
                    "wtp.waste_origin.served_industry_branches.modal.edit.header": "Edit served industry branch",
                    "wtp.waste_origin.served_industry_branches.modal.select_industry_branch": "Select industry branch",
                    "wtp.waste_origin.served_industry_branches.origin_type": "WTP entry method",
                    "wtp.waste_origin.served_industry_branches.modal.select_origin_type": "Select WTP entry method",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.sewage_network": "Through an urban sewage network",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.independent_pipeline": "Through an independent pipeline",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.tanker_truck": "Through a tanker truck",
                    "wtp.industry_characteristics.header": "Industry Characteristics",
                    "wtp.industry_characteristics.industry_name": "Industry Name",
                    "wtp.industry_characteristics.industry_state": "Industry State",
                    "wtp.industry_characteristics.state.active": "Active",
                    "wtp.industry_characteristics.state.inactive": "Inactive",
                    "wtp.industry_characteristics.state.temporary_inactive": "Temporary inactive",
                    "wtp.industry_characteristics.industry_code": "Industry Code",
                    "wtp.industry_characteristics.operational_period_start": "Operational Period Start",
                    "wtp.industry_characteristics.operational_period_end": "Operational Period End",
                    "wtp.industry_characteristics.stakod": "STAKOD code",
                    "wtp.industry_characteristics.select_stakod": "Select STAKOD code",
                    "wtp.industry_characteristics.raw_material_type": "Raw Material Description",
                    "wtp.industry_characteristics.raw_material": "Raw Material Consumption (tn/year)",
                    "wtp.industry_characteristics.water": "Water Consumption (m³/year)",
                    "wtp.industry_characteristics.energy": "Energy Consumption (kWh/year)",
                    "wtp.industry_characteristics.production_type": "Production Type",
                    "wtp.industry_characteristics.production": "Production (tn/year)",
                    "wtp.industry_characteristics.industry_branch": "Industry Branche",
                    "wtp.industry_characteristics.load_entering": "WTP Entering Load (PE)",
                    "wtp.industry_characteristics.select_industry_state": "Select industry state",
                    "wtp.incoming_load.capacity.header": "Capacity",
                    "wtp.incoming_load.capacity.wtp_capacity": "WTP load capacity (PE)",
                    "wtp.incoming_load.inflow.header": "Inflow",
                    "wtp.incoming_load.inflow.mean": "Mean (Annual Average)",
                    "wtp.incoming_load.inflow.max": "Max",
                    "wtp.incoming_load.inflow.total": "WTP total incoming supply (m³/day)",
                    "wtp.incoming_load.inflow.sewerage": "Incoming sewage supply (m³/day)",
                    "wtp.incoming_load.inflow.septage": "Incoming septage supply (m³/day)",
                    "wtp.incoming_load.incoming.header": "Incoming Loads",
                    "wtp.incoming_load.incoming.mean": "Mean (Annual Average)",
                    "wtp.incoming_load.incoming.max": "Max",
                    "wtp.incoming_load.incoming.total": "WTP total incoming load (Kg BOD5/day)",
                    "wtp.incoming_load.incoming.sewerage": "Incoming sewage load (Kg BOD5/day)",
                    "wtp.incoming_load.incoming.septage": "Incoming septage load (Kg BOD5/day)",
                    "wtp.treatment.method": "Treatment method",
                    "wtp.treatment.other_treatment": "Τreatment description",
                    "wtp.treatment.waste.header": "Waste Treatment",
                    "wtp.treatment.waste.disinfection_chlorination": "Chlorination",
                    "wtp.treatment.waste.disinfection_uv": "UV",
                    "wtp.treatment.waste.disinfection_ozone": "Ozonation",
                    "wtp.treatment.dry_waste.header": "Sludge Treatment",
                    "wtp.treatment.remarks.header": "Remarks",
                    "wtp.treatment.remarks.operational_problems": "Brief description of operating problems",
                    "wtp.treatment.remarks.idle": "Idle",
                    "wtp.treatment.remarks.idle_reason": "Reasons for inactivity",
                    "wtp.treatment.remarks.problems_solution": "Troubleshooting suggestions",
                    "wtp.treatment.remarks.costForecast": "Cost estimate to make fully operational",
                    "wtp.energy_greenhouse_emissions.energy.header": "Energy efficiency & energy from biogas combustion",
                    "wtp.energy_greenhouse_emissions.energy_consumption": "Energy consumption kWh/year",
                    "wtp.energy_greenhouse_emissions.energy_consumption_per_cubic_meter": "Energy consumption kWh/m³ wastewater",
                    "wtp.energy_greenhouse_emissions.energy_from_renewable": "Total energy produced from RES kWh/year",
                    "wtp.energy_greenhouse_emissions.energy_from_biogas": "Total energy produced from biogas combustion kWh/year",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions.header": "Greenhouse gases (CO₂, N₂O, CH₄)",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions": "Greenhouse gases (CO₂, N₂O, CH₄) (equivalent tonnes CO₂/year)",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.estimation": "Estimation",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.measurement": "Measurement",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.calculation": "Calculation",
                    "wtp.receiver.receiver.header": "Receiver",
                    "wtp.receiver.receiver.code": "Code",
                    "wtp.receiver.receiver.name": "Name",
                    "wtp.receiver.receiver.nameEn": "Name (English)",
                    "wtp.receiver.receiver.category": "Category",
                    "wtp.receiver.receiver.type": "Type",
                    "wtp.receiver.receiver.longitude": "Longtitude",
                    "wtp.receiver.receiver.latitude": "Latitude",
                    "wtp.receiver.receiver.remarks": "Remarks",
                    "wtp.receiver.receiver.subheader": "Discharge point",
                    "wtp.receiver.water_body.subheader": "Water body",
                    "wtp.receiver.reuse.header": "Reuse",
                    "wtp.receiver.reuse.inflow": "Inflow",
                    "wtp.receiver.reuse.unit": "m³/year",
                    "wtp.receiver.reuse.total": "Total",
                    "wtp.receiver.reuse.irrigation": "Irrigation",
                    "wtp.receiver.reuse.industry": "Industry",
                    "wtp.receiver.reuse.other": "Other method",
                    "wtp.receiver.reuse.remarks": "Remarks",
                    "wtp.receiver.sludge_dry.header": "Produced sludge",
                    "wtp.receiver.sludge_dry.dry_solid": "Dry Solid",
                    "wtp.receiver.sludge_dry.unit": "Tonnes DS/year",
                    "wtp.receiver.sludge_dry.landfillD1": "Lanfill (D1)",
                    "wtp.receiver.sludge_dry.landfillD5": "Lanfill (D5)",
                    "wtp.receiver.sludge_dry.temp_storage": "Temporal storage (D15 or R13)",
                    "wtp.receiver.sludge_dry.agriculture": "Agriculture (R10)",
                    "wtp.receiver.sludge_dry.energy": "Energy (R1)",
                    "wtp.receiver.sludge_dry.compost": "Compost (R3)",
                    "wtp.receiver.sludge_dry.other": "Other method",
                    "wtp.receiver.sludge_dry.other_description": "Description",
                    "wtp.receiver.sludge_dry.solids_ratio": "Solids percentage of produced sludge",
                    "wtp.receiver.sludge_dry.remarks": "Remarks",
                    "wtp.reuse.enable.header": "Enable Reuse",
                    "wtp.reuse.supplies.header": "Supplies",
                    "wtp.reuse.supplies.map": "Map",
                    "wtp.reuse.supplies.supply": "Supply (m³/year)",
                    "wtp.reuse.supplies.area": "Area (acre)",
                    "wtp.reuse.supplies.irrigation": "Irrigation",
                    "wtp.reuse.supplies.unlimited_irrigation": "Unlimited irrigation",
                    "wtp.reuse.supplies.limited_irrigation": "Limited irrigation",
                    "wtp.reuse.supplies.underground_aquifers_enrichment": "Underground aquifers enrichment",
                    "wtp.reuse.supplies.urban_green": "Urban green",
                    "wtp.reuse.supplies.urban_usage": "Urban usage",
                    "wtp.reuse.supplies.industrial_usage": "Industrial usage",
                    "wtp.reuse.supplies.other_method": "Other method",
                    "wtp.reuse.supplies.ground_water_body": "Ground water body",
                    "wtp.reuse.supplies.cultivation_category": "Cultivation category",
                    "wtp.reuse.supplies.cultivation_description": "Cultivation description",
                    "wtp.reuse.supplies.irrigation_method": "Irrigation method",
                    "wtp.reuse.supplies.irrigation_type": "Irrigation type",
                    "wtp.reuse.supplies.aquifers_enrichment_type": "Underground aquifers enrichment type",
                    "wtp.reuse.supplies.water_quality_category": "Water quality category",
                    "wtp.reuse.supplies.explanation": "Explanation",
                    "wtp.reuse.supplies.description": "Description",
                    "wtp.reuse.supplies.usage": "Usage",
                    "wtp.reuse.supplies.usage_water_cooling": "Disposable cooling water",
                    "wtp.reuse.supplies.usage_non_water_cooling": "Except for disposable cooling water",
                    "wtp.reuse.supplies.total": "Total",
                    "wtp.reuse.supplies.modal.add.header": "Add new supply",
                    "wtp.reuse.supplies.modal.edit.header": "Edit supply",
                    "wtp.reuse.supplies.modal.select_cultivation_category": "Select cultivation category",
                    "wtp.reuse.supplies.modal.select_irrigation_method": "Select irrigation method",
                    "wtp.reuse.supplies.modal.select_irrigation_type": "Select irrigation type",
                    "wtp.reuse.supplies.modal.select_aquifers_enrichment_type": "Select underground aquifers enrichment type",
                    "wtp.reuse.supplies.modal.select_water_quality_category": "Select water quality category",
                    "wtp.reuse.supplies.modal.select_water_body": "Select underground body",
                    "wtp.reuse.supplies.modal.same_entry": "Entry with same values detected! Please check parameters and try again.",
                    "wtp.reuse.cultivation_category.a": "A",
                    "wtp.reuse.cultivation_category.b": "B",
                    "wtp.reuse.cultivation_category.c": "C",
                    "wtp.reuse.cultivation_category.d": "D",
                    "wtp.reuse.cultivation_category.a.description": "All crops of edible plants eaten raw whose edible part comes into direct contact with reclaimed water and root crops eaten raw",
                    "wtp.reuse.cultivation_category.b.description": "Crops of edible plants eaten raw when the edible part is produced above ground and not in direct contact with reclaimed water, crops of edible plants that are processed and crops of non-edible plants, including crops used to feed dairy animals or animals that produce meat",
                    "wtp.reuse.cultivation_category.c.description": "Edible crops that are eaten raw when the edible part is produced above ground and not in direct contact with reclaimed water, edible crops that are processed and non-edible crops",
                    "wtp.reuse.cultivation_category.d.description": "Industrial and energy crops and seed crops",
                    "wtp.reuse.irrigation_method.1.description": "Drip irrigation",
                    "wtp.reuse.irrigation_method.2.description": "Irrigation method that avoids direct contact with the edible part of the crop",
                    "wtp.reuse.irrigation_method.3.description": "Artificial rain",
                    "wtp.reuse.irrigation_method.4.description": "Other method",
                    "wtp.reuse.water_quality_category.a": "A",
                    "wtp.reuse.water_quality_category.b": "B",
                    "wtp.reuse.water_quality_category.c": "C",
                    "wtp.reuse.water_quality_category.d": "D",
                    "wtp.reuse.irrigation_type.0.description": "Unlimited",
                    "wtp.reuse.irrigation_type.1.description": "Limited",
                    "wtp.reuse.aquifers_enrichment_type.0.description": "Through filtration",
                    "wtp.reuse.aquifers_enrichment_type.1.description": "Through drilling",
                    "wtp.reuse.additional.header": "Additional Information",
                    "wtp.reuse.additional.limited_capacity_islandic_area": "Islandic area with limited capacity",
                    "wtp.reuse.additional.land_irrigation": "Pasture or fodder is irrigated",
                    "wtp.reuse.additional.aerosolization_hazard": "There is a risk of aerosolization",
                    "wtp.reuse.additional.reuse_comments": "Comment on reclaimed water production",
                    "wtp.reuse.additional.hazards_comments": "Comment listing additional risks arising from the Risk Management Plan",
                    "wtp.reuse.confirmation.header": "Delete confirmation",
                    "wtp.reuse.confirmation.body": "Are you sure you want to delete this entry?",
                    "wtp.reuse.confirmation.ok": "Delete",
                    "wtp.reuse.confirmation.cancel": "Cancel",
                    "wtp.reuse.additional_elements.header": "Additional Elements",
                    "wtp.reuse.additional_elements.continuous_turbidity_check": "Is there a continuous turbidity measurement instrument at the output of the treatment?",
                    "wtp.measurements.display_options.header": "Display Options",
                    "wtp.measurements.display_options.sampler_position": "Sampler Position",
                    "wtp.measurements.display_options.inflow": "Inflow",
                    "wtp.measurements.display_options.outflow": "Outflow",
                    "wtp.measurements.display_options.display": "Display",
                    "wtp.measurements.display_options.all": "All",
                    "wtp.measurements.display_options.range": "Range",
                    "wtp.measurements.display_options.yearly": "Yearly",
                    "wtp.measurements.display_options.year": "Year",
                    "wtp.measurements.display_options.from": "From",
                    "wtp.measurements.display_options.to": "To",
                    "wtp.measurements.sampling_results.header": "Sampling Results",
                    "wtp.measurements.sampling_results.check": "Check W.T.P. Outflow Requirements",
                    "wtp.measurements.sampling_results.check_reuse": "Check W.T.P. Reuse Requirements",
                    "wtp.measurements.sampling_results.date": "Date",
                    "wtp.measurements.sampling_results.flow": "Flow (m³/day)",
                    "wtp.measurements.sampling_remarks.header": "Samples Remarks",
                    "wtp.measurements.sampling_results.select_sampler_location": "Select sampler location",
                    "wtp.measurements.sampling_results.select_parameter": "Select parameter",
                    "wtp.measurements.sampling_results.modal.add.header": "Add new sampling results",
                    "wtp.measurements.sampling_results.modal.edit.header": "Edit sampling results",
                    "wtp.measurements.sampling_results.modal.add_file.header": "Import sampling results from file",
                    "wtp.measurements.sampling_results.modal.add_file.file": "File",
                    "wtp.measurements.sampling_results.select_sheet": "Select sheet",
                    "wtp.measurements.sampling_results.select_column": "Select column",
                    "wtp.measurements.sampling_results.modal.same_parameter": "Parameters with duplicate values detected! Please check parameters and try again.",
                    "wtp.measurements.sampling_results.modal.invalid_date": "Invalid date detected! Please check correct date column is selected and dates in file are in format DD/MM/YYYY.",
                    "wtp.measurements.sampling_results.modal.check_results.header": "Check imported data",
                    "wtp.measurements.sampling_results.modal.check_results.description": "The following table displays the top 5 measurements of the imported data. Please check that everything is correct before import confirmation.",
                    "wtp.measurements.limits.date_issued": "Issuing Date",
                    "wtp.measurements.limits.date_expired": "Expiration Date",
                    "wtp.measurements.limits.comments": "Comments",
                    "wtp.measurements.limits.modal.add.header": "Add environmental permit",
                    "wtp.measurements.limits.modal.edit.header": "Edit environmental permit",
                    "wtp.measurements.limits.header": "Environmental permits",
                    "wtp.measurements.limits.parameter": "Parameter",
                    "wtp.measurements.limits.parameter_value": "Parameter value",
                    "wtp.measurements.limits.parameter_count": "Min samples count",
                    "wtp.measurements.limits.parameter_compliance": "Compliance",
                    "wtp.measurements.add_parameter": "Add parameter",
                    "wtp.measurements.sampling_positions": "Edit Sampling Positions",
                    "wtp.measurements.compliance_aepo": "Compliance by AEPO",
                    "wtp.measurements.compliance_legislation": "Compliance by Directive 91/271/EOK",
                    "wtp.measurements.compliance_reuse_kya": "Compliance by KYA 145116",
                    "wtp.measurements.compliance_reuse_eu": "Compliance by Regulation (EU) 2020/741",
                    "wtp.measurements.compliance_reuse.total_water_quality": "Final water quality characterization",
                    "wtp.measurements.compliance_reuse.water_quality_check": "Fitness-for-use check based on measurements",
                    "wtp.measurements.no_environmental_permits_found": "No environmental permits are defined",
                    "wtp.files.environmental_terms.header": "Environmental Terms",
                    "wtp.files.sensitive_receiver_fek.header": "Sensitive Receiver F.E.K.",
                    "wtp.files.risk_management_plans.header": "Risk Management Plans",
                    "wtp.files.reuse_no_compliance.header": "Reuse Limits Non-compliance Archives",
                    "wtp.files.photos.header": "WTP photos",
                    "wtp.files.additional_files.header": "Additional Files",
                    "wtp.files.additional_files.filename": "Filename",
                    "wtp.files.additional_files.title": "Title",
                    "wtp.files.additional_files.file_type": "File type",
                    "wtp.files.additional_files.public": "Public",
                    "wtp.files.additional_files.file_size": "File size (in bytes)",
                    "wtp.files.modal.add.environmentalterms_header": "Add Environmental Terms file",
                    "wtp.files.modal.add.sensitivereceiverfek_header": "Add Sensitive Receiver F.E.K. file",
                    "wtp.files.modal.add.riskmanagementplans_header": "Add Risk Management Plans file",
                    "wtp.files.modal.add.reusenocompliance_header": "Add Reuse Limits Non-compliance Archive file",
                    "wtp.files.modal.add.additional_header": "Add additional file",
                    "wtp.files.modal.add.images_header": "Add WTP photo",
                    "wtp.files.modal.edit.header": "Edit file",
                    "wtp.files.modal.max_size": "Max file size: 20MB",
                    "wtp.files.modal.file": "File",
                    "wtp.comments.comments": "Comments",
                    "wtp.waste_origin.served_sewage_agglomeration.name": "Name",
                    "wtp.waste_origin.served_sewage_settlement.name": "Name",
                    "settlements.agglomeration": "Agglomeration",
                    "settlements.header": "Settlement Data",
                    "settlements.settlement_code": "Elstat code",
                    "settlements.settlement_name": "Settlement name",
                    "settlements.municipal": "Municipal",
                    "settlements.administrative_region": "Administrative Region",
                    "settlements.river_basin": "River Basin",
                    "settlements.water_district": "Water District",
                    "settlements.settlement_limits": "Settlement Limits",
                    "settlements.settlement_area": "Settlement Area (ha)",
                    "settlements.population": "Population",
                    "settlements.equivalent_population": "Equivalent Population",
                    "settlements.served_population": "Served Population",
                    "settlements.connected_population": "Connected Population",
                    "settlements.population_density": "Population Density (ER/acre)",
                    "settlements.sewage_network_service_percentage": "Served SN Percentage",
                    "settlements.sewage_network_connected_percentage": "Connected SN Percentage",
                    "settlements.area_from_shape": "Area from shape (acre)",
                    "settlements.area_from_shape_ha": "Area from shape (ha)",
                    "settlements.area": "Area (acre)",
                    "settlements.length": "Length (km)",
                    "settlements.pumping_station_count": "No of Pumping Stations",
                    "settlements.sewage_network_type": "Sewage Network Type",
                    "settlements.constructed_sewage_network": "Constructed Sewage Network",
                    "settlements.connected_sewage_network": "Connected Sewage Network",
                    "settlements.sewage_network_data": "Sewage Network Data",
                    "settlements.map.constructed_sewage_network": "Constructed SN",
                    "settlements.map.connected_sewage_network": "Connected SN",
                    "settlements.duplicate_id": "Settlement with the same code found!",
                    "agglomerations.header": "Agglomeration Data",
                    "agglomerations.constructed_sewage_network_area": "Constructed SN Area (acre)",
                    "agglomerations.constructed_sewage_network_length": "Constructed SN Length (km)",
                    "agglomerations.connected_sewage_network_area": "Connected SN Area (acre)",
                    "agglomerations.connected_sewage_network_length": "Connected SN Length (km)",
                    "agglomerations.from_settlements_sum": "From settlements sum",
                    "agglomerations.characterization_year": "Year",
                    "agglomerations.characterization": "Agglomeration Characterization",
                    "agglomerations.agglomeration_name": "Agglomeration Name (Greek)",
                    "agglomerations.agglomeration_name_english": "Agglomeration Name (English)",
                    "agglomerations.agglomeration_code": "Agglomeration Code",
                    "agglomerations.agglomeration_coordinates": "Agglomeration Coordinates",
                    "agglomerations.add_settlement": "Add Settlement",
                    "agglomerations.select_priority": "Select priority category",
                    "agglomerations.duplicate_id": "Agglomeration with the same code found!",
                    "wastewatertreatmentplants.wastewatertreatmentplant_code": "W.T.P. Code",
                    "wastewatertreatmentplants.wastewatertreatmentplant_name": "W.T.P. Name (Greek)",
                    "wastewatertreatmentplants.wastewatertreatmentplant_name_english": "W.T.P. Name (English)",
                    "wastewatertreatmentplants.add_wtp": "Add W.T.P.",
                    "wastewatertreatmentplants.operator": "W.T.P. Operator",
                    "wastewatertreatmentplants.operator.name": "Operator's Full Name",
                    "wastewatertreatmentplants.operator.email": "Operator's Email",
                    "wastewatertreatmentplants.operator.last_login_on": "Operator's Last Login",
                    "wastewatertreatmentplants.select_operator": "Select W.T.P. Operator",
                    "wastewatertreatmentplants.type": "W.T.P. Type",
                    "wastewatertreatmentplants.select_type": "Select W.T.P. Type",
                    "wastewatertreatmentplants.edit_wtp": "Edit W.T.P.",
                    "wastewatertreatmentplants.general": "General",
                    "wastewatertreatmentplants.sampler_positions": "Sampler Positions",
                    "wastewatertreatmentplants.sampler_position_name": "Name (Greek)",
                    "wastewatertreatmentplants.sampler_position_name_english": "Name (English)",
                    "wastewatertreatmentplants.no_sampler_positions": "No sampler positions have been defined! At least one sampler position has to be defined.",
                    "query.compliance": "Compliance (91/271/ΕΟΚ)",
                    "query.sensitive": "Sensitive",
                    "query.normal": "Normal",
                    "query.treatment_method.waste": "Waste treatment method",
                    "query.treatment_method.sludge": "Sludge treatment method",
                    "query.reuse_method": "Reuse method",
                    "query.reuse_methods": "Reuse methods",
                    "query.equivalent_population.min": "Min equivalent population",
                    "query.equivalent_population.max": "Max equivalent population",
                    "query.sewage_network_percentage": "Sewage network percentage",
                    "query.sewage_network_percentage.max": "Max sewage network percentage",
                    "query.sewage_network_percentage.min": "Min sewage network percentage",
                    "water_body.types.0": "Lake",
                    "water_body.types.1": "River",
                    "water_body.types.2": "Sea",
                    "water_body.types.3": "Ground water",
                    "water_body.types.4": "Transitional",
                    "query.wtp.code": "W.T.P. Code",
                    "query.wtp.name": "W.T.P. Name",
                    "query.wtp.year": "Year",
                    "query.wtp.priority": "Priority",
                    "query.wtp.compliance": "W.T.P. Compliance",
                    "query.wtp.waste_treatment": "Waste treatment method",
                    "query.wtp.sludge_treatment": "Sludge treatment method",
                    "query.wtp.capacity": "Capacity (p.e.)",
                    "query.wtp.reuse": "Reuse",
                    "query.wtp.administrative_region": "Administrative Region",
                    "query.wtp.municipal": "Municipal",
                    "query.wtp.water_district": "Water District",
                    "query.wtp.river_basin": "River Basin",
                    "query.wtp.receiver_code": "Receiver Code",
                    "query.wtp.receiver_name": "Receiver Name",
                    "query.wtp.receiver_type": "Receiver Type",
                    "query.wtp.receiver_water_type": "Water Body Category",
                    "query.wtp.population": "Equivalent Population",
                    "query.wtp.served_population_percentage": "Served Population Percentage",
                    "query.wtp.contact_name": "Contact Person",
                    "query.wtp.contact_authority": "Authority",
                    "query.wtp.contact_position": "Position",
                    "query.wtp.contact_address": "Address",
                    "query.wtp.contact_phone": "Phone",
                    "query.wtp.contact_mobile": "Mobile",
                    "query.wtp.contact_email": "Email",
                    "query.wtp.measurements_info": "The database of the Special Secretariat of Waters gives you the possibility to see in detail the measurements at the inflow and outflow of the biological cleanings, as submitted by the Operation Manager of the W.T.P.",
                    "query.wtp.measurements_button": "Show samples results",
                    "query.wtp.reuse_measurements_button": "Show reuse samples results",
                    "query.wtp.header": "Wastewater Treatment Plant Presentation",
                    "query.wtp.map_wtp": "W.T.P. Location",
                    "query.wtp.map_receiver": "Receiver Location",
                    "query.wtp.extensions_header": "Extensions & Upgrades",
                    "query.wtp.agglomerations_header": "Served Agglomerations",
                    "query.wtp.agglomerations_info": "Agglomerations served by the WWTP via sewage network.",
                    "query.wtp.settlements_info": "Settlements served by the WWTP via sewage network.",
                    "query.wtp.septage_info": "Septage from the following agglomerations are carried to the WWTP by trucks.",
                    "query.wtp.industries_header": "Connected Industries",
                    "query.wtp.load_header": "Incoming Loads",
                    "query.wtp.capacity_header": "Capacity of the constructed plant (EP)",
                    "query.wtp.inflow_header": "Inflow",
                    "query.wtp.wastewater_treatment_header": "Wastewater Treatment",
                    "query.wtp.sludge_treatment_header": "Sludge Treatment",
                    "query.wtp.disposal_header": "Receiver",
                    "query.wtp.reuse_header": "Treated Outflow Reuse",
                    "query.wtp.sludge_disposal_header": "Sludge Disposal",
                    "query.wtp.compliance_header": "Compliance",
                    "query.wtp.compliance_info": "Are the required effluent limits met by the facility? Is the Wastewater Treatment Plant working properly? Directive 91/271/EEC and KYA 5673/400/1997 establish the criteria and compliance requirements.",
                    "query.wtp.compliance_reuse_header": "Reuse Compliance",
                    "query.wtp.compliance_reuse_info": "Are the required effluent limits from the facility met during reuse? Is the Wastewater Treatment Plant working properly? KYA 145116 and Regulation (EU) 2020/741 establish the criteria and compliance requirements during reuse.",
                    "query.wtp.compliance.info.criteria": "Which are the compliance criteria?",
                    "query.wtp.compliance.info.symbols": "What do the symbols mean?",
                    "query.wtp.compliance.info.symbols.info": "The following symbols signs that for the specific parameter and base year, the WWTP",
                    "query.wtp.compliance.info.symbols.pass": "Passes the compliance criteria.",
                    "query.wtp.compliance.info.symbols.fail": "Fails to satisfy the compliance criteria.",
                    "query.wtp.compliance.info.symbols.not_required": "According to the Directive does not fall into the consideration.",
                    "query.wtp.compliance.info.symbols.no_data": "Not sufficient data for evaluation.",
                    "query.wtp.compliance_reuse.info.criteria": "What are the compliance criteria for reclaimed water reuse for agricultural irrigation?",
                    "query.wtp.compliance_reuse.info.symbols": "What do the symbols mean?",
                    "query.wtp.compliance_reuse.info.symbols.info": "The following symbols signs that for the specific parameter, in total and base year, the WWTP",
                    "query.wtp.compliance_reuse.info.symbols.data": "Produces, according to Regulation (EU) 2020/741, reclaimed water of quality A, B, C or D.",
                    "query.wtp.compliance_reuse.info.symbols.no_data": "Not sufficient data for evaluation.",
                    "query.wtp.results_header": "Sampling Results",
                    "query.wtp.reuse_results_header": "Reuse Sampling Results",
                    "query.wtp.info_header": "More Information - Files",
                    "query.wtp.contact_header": "Contact",
                    "query.wtp.search": "W.T.P. Search",
                    "query.agglomeration.served_population": "Served population",
                    "query.agglomeration.connected_population": "Connected population",
                    "query.agglomeration.constructed_network": "Constructed S.N.",
                    "query.agglomeration.connected_network": "Connected S.N.",
                    "query.agglomeration.code": "Agglomeration Code",
                    "query.agglomeration.name": "Agglomeration Name",
                    "query.agglomeration.search": "Agglomeration Search",
                    "query.agglomeration.coordinates": "Agglomeration Coordinates",
                    "query.agglomeration.coordinates.longitude": "Agglomeration Longitude",
                    "query.agglomeration.coordinates.latitude": "Agglomeration Latitude",
                    "query.reuse.supplies.irrigation": "Irrigation",
                    "query.reuse.supplies.filtration_aquifers_enrichment": "Underground aquifers enrichment via filtration",
                    "query.reuse.supplies.drilling_aquifers_enrichment": "Underground aquifers enrichment via drilling",
                    "query.reuse.supplies.urban_green": "Urban green",
                    "query.reuse.supplies.urban_usage": "Urban usage",
                    "query.reuse.supplies.industrial_usage": "Industrial usage",
                    "query.reuse.supplies.other_method": "Other method",
                }
            },
            el: {
                translation: {
                    "Learn more": "Περισσότερα",
                    "Project data": "Δεδομένα",
                    "News": "Νέα",
                    "Results": "Αποτελέσματα",
                    "Water district": "Υδ.Διαμέρισμα",
                    "Receiving area type": "Χαρακτηρισμός αποδέκτη",
                    "Treatment scheme": "Επεξεργασία",
                    "Article 17 compliance": "Κατάσταση ως προς Οδ.91/271/ΕΟΚ",
                    "Min capacity (p.e)": "Ελάχιστη Δυναμικότητα ΜΙΠ",
                    "Max capacity (p.e)": "Μέγιστη Δυναμικότητα ΜΙΠ",
                    "Disinfection": "Απολύμανση Λυμάτων",
                    "Urban": "Αστική",
                    "Industrial": "Βιομηχανική Οδηγίας",
                    "Reuse": "Άλλη εγκατάσταση / επαναχρησιμοποίηση",
                    "Sludge management": "Διαχειριση Παραγόμενης Ιλύος",
                    "Choose...": "Επιλογή ...",
                    "Search": "Αναζήτηση",
                    "Clear": "Καθαρισμός",
                    "NUTS": "Ευρω.Κωδικός NUTS",
                    "Year": "Έτος",
                    "SSW ID": "Όνομα χρήστη",
                    "Password": "Κωδικός πρόσβασης",
                    "Contact": "Επικοινωνία",
                    "Announcements": "Ανακοινώσεις",
                    "Login": "Είσοδος σε εξουσιοδοτημένους χρήστες",
                    "Log in": "Σύνδεση",
                    "Logout": "Αποσύνδεση",
                    "Query Tools": "Ερωτήματα",
                    "Wastewater Treatment Plants": "Εγκαταστάσεις Επεξεργασίας Λυμάτων",
                    "Home": "Αρχική",
                    "wtp": "Εγκαταστάσεις Επεξεργασίας Λυμάτων",
                    "login": "Είσοδος σε εξουσιοδοτημένους χρήστες",
                    "logout": "Αποσύνδεση",
                    "agglomerations": "Οικισμοί Οδηγίας",
                    "queries": "Ερωτήματα",
                    "announcements": "Ανακοινώσεις",
                    "contact": "Επικοινωνία",
                    "Remember me": "Απομνημόνευση",
                    "Sign in": "Είσοδος",
                    "Sign out": "Έξοδος",
                    "appTitle": "Εγκαταστάσεις Επεξεργασίας Λυμάτων Βάση Δεδομένων Παρακολούθησης Λειτουργίας",
                    "More": "Περισσότερα",
                    "About the project": "Σχετικά με το έργο",
                    "Highlights": "Κυριότερα σημεία",
                    "Next": "Επόμενο",
                    "Previous": "Προηγούμενο",
                    "First": "Αρχή",
                    "Last": "Τέλος",
                    "Help": "Βοήθεια",
                    "No recent announcements": "Δεν βρέθηκαν ανακοινώσεις",
                    "Contact the system administrator if your don't have an account.": "Επικοινωνήστε με τον διαχειριστή του συστήματος αν επιθυμείτε να δημιουργήσετε λογαριασμό.",
                    "You are currently signed in as": "Είστε συνδεδεμένος-η ως",
                    "logInError": "Παρουσιάστηκε σφάλμα κατά τη σύνδεσή σας! Παρακαλούμε ελέγξτε τα στοιχεία σας και προσπαθήστε ξανά.",
                    "manager": "Διαχείριση",
                    "W.T.P. Edit": "Επεξεργασία Ε.Ε.Λ.",
                    "My W.T.P.": "H Ε.Ε.Λ. μου",
                    "W.T.P.": "Ε.Ε.Λ.",
                    "Settlements": "Οικισμοί ΕΛΣΤΑΤ",
                    "Agglomerations": "Οικισμοί Οδηγίας",
                    "Add Settlement": "Προσθήκη Οικισμού ΕΛΣΤΑΤ",
                    "Edit Settlement": "Επεξεργασία Οικισμού ΕΛΣΤΑΤ",
                    "Add Agglomeration": "Προσθήκη Οικισμού Οδηγίας",
                    "Edit Agglomeration": "Επεξεργασία Οικισμού Οδηγίας",
                    "View Agglomeration": "Προβολή Οικισμού Οδηγίας",
                    "yes": "Ναι",
                    "no": "Όχι",
                    "search_for": "Αναζήτηση...",
                    "rows_per_page": "Εγγραφές ανά σελίδα",
                    "rows_per_page_of": "από",
                    "Select All": "Επιλογή Όλων",
                    "Select None": "Καμμία Επιλογή",
                    "nav_menu.settlements": "Οικισμοί ΕΛΣΤΑΤ",
                    "Snapshots": "Στιγμιότυπα",
                    "snapshots.current_snapshot": "Τρέχον στιγμιότυπο",
                    "snapshots.table.no_data": "Δεν βρέθηκαν στιγμιότυπα",
                    "Restore snapshot": "Επαναφορά στιγμιότυπου",
                    "nav_menu.snapshots": "Στιγμιότυπα",
                    "nav_menu.agglomerations": "Οικισμοί Οδηγίας",
                    "nav_menu.settings": "Επιλογές",
                    "project_data.total_agglomerations": "Σύνολο οικισμών",
                    "project_data.compliant": "Σε συμμόρφωση",
                    "project_data.non_compliant": "Σε μη συμμόρφωση",
                    "project_data.insufficient_data": "Ελλιπείς συνδέσεις ή μετρήσεις εκροής",
                    "project_data.operational_problems": "Λειτουργικά προβλήματα",
                    "project_data.planned_projects": "Ενταγμένα ή προγραμματισμένα έργα",
                    "project_data.mature_projects": "Ώριμα έργα με ανάγκες σε υποδομές",
                    "project_data.immature_projects": "Ανώριμα έργα",
                    "admin.data": "Επεξεργασία Δεδομένων",
                    "admin.deya": "Δ.Ε.Υ.Α.",
                    "admin.deya.deya_name": "Όνομα Δ.Ε.Υ.Α.",
                    "admin.deya.deya_name_english": "Όνομα Δ.Ε.Υ.Α. (αγγλικά)",
                    "admin.parameter": "Παραμέτρου",
                    "admin.parameters": "Παράμετροι",
                    "admin.parameters.parameter_name": "Όνομα παραμέτρου",
                    "admin.parameters.parameter_unit": "Μονάδα μέτρησης",
                    "admin.parameters.parameter_description": "Περιγραφή",
                    "admin.sensitiveReceiver": "Ευαίσθητου Αποδέκτη",
                    "admin.sensitiveReceivers": "Ευαίσθητοι Αποδέκτες",
                    "admin.sensitiveReceivers.sensitive_receiver_name": "Όνομα ευαίσθητου αποδέκτη",
                    "admin.sensitiveReceivers.sensitive_receiver_name_english": "Όνομα ευαίσθητου αποδέκτη (αγγλικά)",
                    "admin.sensitiveReceivers.sensitive_receiver_code": "Κωδικός ευαίσθητου αποδέκτη",
                    "admin.sensitiveReceivers.sensitive_receiver_type": "Τύπος ευαίσθητου αποδέκτη",
                    "admin.sensitiveReceivers.sensitive_receiver_water_bodies": "Υδάτινα σώματα",
                    "admin.sensitiveReceivers.select_sensitive_receiver_type": "Επιλέξτε τύπο ευαίσθητου αποδέκτη",
                    "admin.sensitiveReceivers.select_sensitive_receiver_water_bodies": "Επιλέξτε υδάτινα σώματα",
                    "wtp.button.save": "Αποθήκευση",
                    "wtp.button.saving": "Αποθήκευση...",
                    "wtp.button.cancel": "Ακύρωση",
                    "wtp.button.close": "Κλείσιμο",
                    "wtp.button.reset": "Επαναφορά",
                    "wtp.button.back": "Επιστροφή στη λίστα",
                    "wtp.button.update": "Ενημέρωση",
                    "wtp.button.modify": "ΤΡΟΠΟΠΟΙΗΣΗ",
                    "wtp.button.add_upper": "ΠΡΟΣΘΗΚΗ",
                    "wtp.button.add_multiple": "Εισαγωγή από αρχείο",
                    "wtp.button.edit": "ΕΠΕΞΕΡΓΑΣΙΑ",
                    "wtp.button.delete": "ΔΙΑΓΡΑΦΗ",
                    "wtp.button.add": "Προσθήκη",
                    "wtp.button.edit_capitalized": "Επεξεργασία",
                    "wtp.button.insert": "ΕΙΣΑΓΩΓΗ",
                    "wtp.button.confirm": "Επιβεβαίωση",
                    "wtp.button.next": "Επόμενο",
                    "wtp.button.previous": "Επιστροφή",
                    "wtp.button.submit_file": "Υποβολή αρχείου",
                    "wtp.button.delete_file": "Διαγραφή",
                    "wtp.button.auto_assign": "Αυτόματη απόδοση",
                    "wtp.button.edit_wtp_data": "Επεξεργασία Στοιχείων Ε.Ε.Λ.",
                    "wtp.button.recalculate_aepo": "Επανέλεγχος ΑΕΠΟ",
                    "wtp.button.recalculate_legislation": "Επανέλεγχος Οδηγίας 91/271/ΕΟΚ",
                    "wtp.button.recalculate_reuse_kya": "Επανέλεγχος ΚΥΑ 145116",
                    "wtp.button.recalculate_reuse_eu": "Επανέλεγχος Κανονισμού (ΕΕ) 2020/741",
                    "wtp.table.no_data": "Δεν υπάρχουν δεδομένα για προβολή",
                    "wtp.table.total": "Σύνολο",
                    "snapshots.snapshotFile": "Αρχείο",
                    "snapshots.restore.header": "Επαναφορά στιγμιότυπου",
                    "snapshots.restore.ok": "OK",
                    "snapshots.restore.cancel": "Άκυρο",
                    "snapshots.restore.body": "Είστε βέβαιος/η πως θέλετε να επαναφέρετε αυτό το στιγμιότυπο;",
                    "wtp.select.no_options": "Δεν υπάρχουν διαθέσιμες επιλογές",
                    "wtp.save.error": "Παρουσιάστηκε σφάλμα κατά την αποθήκευση των αλλαγών!",
                    "wtp.save.warning": "Οι αλλαγές δεν αποθηκέυτηκαν επιτυχώς! Βρέθηκε εγγραφή με τον ίδιο κωδικό.",
                    "wtp.save.success": "Οι αλλαγές αποθηκέυτηκαν επιτυχώς!",
                    "wtp.validation.required": "Αυτό το πεδίο είναι απαραίτητο",
                    "wtp.validation.non_positive": "Αυτό το πεδίο πρέπει να είναι μεγαλύτερο του 0",
                    "wtp.validation.non_integer": "Αυτό το πεδίο πρέπει να περιέχει ακέραιο αριθμό",
                    "wtp.validation.type_error": "Αυτό το πεδίο πρέπει να περιέχει αριθμό",
                    "wtp.validation.no_select": "Παρακαλούμε επιλέξτε από τη λίστα",
                    "wtp.validation.greater_than": "Αυτό το πεδίο πρέπει να περιέχει αριθμό μεγαλύτερο ή ίσο του ",
                    "wtp.validation.less_than": "Αυτό το πεδίο πρέπει να περιέχει αριθμό μικρότερο ή ίσο του ",
                    "wtp.validation.date_invalid": "Η ημερομηνία που έχει εισαχθεί δεν είναι αποδεκτή",
                    "wtp.pagination.page": "Σελίδα",
                    "wtp.pagination.of": "από",
                    "wtp.pagination.entries": "εγγραφές",
                    "wtp.pagination.entries_per_page": "Εγγραφές ανά σελίδα",
                    "wtp.main.header": "Εγκατάσταση Επεξεργασίας Λυμάτων",
                    "wtp.main.last_updated": "Τελευταία Ενημέρωση",
                    "wtp.main.general": "ΓΕΝΙΚΑ",
                    "wtp.main.waste_orgin": "ΠΡΟΕΛΕΥΣΗ ΛΥΜΑΤΩΝ",
                    "wtp.main.industry_characteristics": "ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ ΒΙΟΜΗΧΑΝΙΑΣ",
                    "wtp.main.incoming_load": "ΕΙΣΕΡΧΟΜΕΝΑ ΦΟΡΤΙΑ",
                    "wtp.main.processing": "ΕΠΕΞΕΡΓΑΣΙΑ",
                    "wtp.main.energy_greenhouse_emissions": "ΕΝΕΡΓΕΙΑ - ΑΕΡΙΑ ΘΕΡΜΟΚΗΠΙΟΥ",
                    "wtp.main.disposal": "ΔΙΑΘΕΣΗ",
                    "wtp.main.reuse": "ΕΠΑΝΑΧΡΗΣΙΜΟΠΟΙΗΣΗ ΑΝΑΚΤΗΜΕΝΟΥ ΝΕΡΟΥ",
                    "wtp.main.measurements": "ΜΕΤΡΗΣΕΙΣ",
                    "wtp.main.files": "ΑΡΧΕΙΑ",
                    "wtp.main.comments": "ΣΧΟΛΙΑ",
                    "wtp.main.limits": "ΟΡΙΑ",
                    "wtp.main.measurements_wtp": "ΜΕΤΡΗΣΕΙΣ ΕΕΛ",
                    "wtp.main.measurements_reuse": "ΜΕΤΡΗΣΕΙΣ ΕΠΑΝΑΧΡΗΣΙΜΟΠΟΙΗΣΗΣ",
                    "wtp.general.add_image": "Εισαγωγή εικόνας",
                    "wtp.general.coordinates": "Συντεταγμένες Ε.Ε.Λ.",
                    "wtp.general.coordinates.longitude": "Γεωγραφικό Μήκος Ε.Ε.Λ.",
                    "wtp.general.coordinates.latitude": "Γεωγραφικό Πλάτος Ε.Ε.Λ.",
                    "wtp.general.longitude": "Γεωγραφικό Μήκος",
                    "wtp.general.latitude": "Γεωγραφικό Πλάτος",
                    "wtp.general.general.header": "Γενικά",
                    "wtp.general.general.priority_category": "Κατηγορία Προτεραιότητας",
                    "wtp.general.general.ied_category": "Υπαγωγή στις διατάξεις της Οδηγίας 2010/75/ΕΕ (IED)",
                    "wtp.general.general.eprtr": "Υπαγωγή στις διατάξεις του Κανονισμού ΕΚ 166/06 (Ε-PRTR)",
                    "wtp.general.general.eprtr_code": "Κωδικός Ε-PRTR",
                    "wtp.general.general.river_basin_district": "Υδατικό Διαμέρισμα",
                    "wtp.general.general.select_river_basin_district": "Επιλέξτε υδατικό διαμέρισμα",
                    "wtp.general.general.select_catchment": "Επιλέξτε λεκανη απορροής ποταμού",
                    "wtp.general.general.authority": "Φορέας",
                    "wtp.general.general.select_authority": "Επιλέξτε φορέα",
                    "wtp.general.general.authority_type": "Είδος φορέα",
                    "wtp.general.general.select_authority_type": "Επιλέξτε είδος φορέα",
                    "wtp.general.general.administrative_region": "Περιφέρεια",
                    "wtp.general.general.select_administrative_region": "Επιλέξτε περιφέρεια",
                    "wtp.general.general.select_municipal": "Επιλέξτε δήμο",
                    "wtp.general.general.funding": "Πρόγραμμα Χρηματοδότησης Κατασκευής - Π/Υ",
                    "wtp.general.general.construction_completed_on": "Ημερομηνία Ολοκλήρωσης Κατασκευής",
                    "wtp.general.general.operation_started_on": "Ημερομηνία Εναρξης Λειτουργίας",
                    "wtp.general.general.environmental_terms_expire_on": "Ημερομηνία Λήξης Περιβαλλοντικών Όρων",
                    "wtp.general.general.catchment": "Λεκάνη Απορροής Ποταμού",
                    "wtp.general.upgrades.header": "Επεκτάσεις / Αναβαθμίσεις της Ε.Ε.Λ.",
                    "wtp.general.upgrades.add.header": "Προσθήκη επέκτασης / αναβάθμισης της Ε.Ε.Λ.",
                    "wtp.general.upgrades.edit.header": "Επεξεργασία επέκτασης / αναβάθμισης της Ε.Ε.Λ.",
                    "wtp.general.upgrades.completion_year": "Έτος ολοκλήρωσης",
                    "wtp.general.upgrades.work_title": "Τίτλος έργου",
                    "wtp.general.upgrades.budget": "Προϋπολογισμός του έργου",
                    "wtp.general.upgrades.funding_program": "Πρόγραμμα χρηματοδότησης κατασκευής - Π/Υ",
                    "wtp.general.lab.header": "Εργαστήριο",
                    "wtp.general.lab.chief": "Υπεύθυνος χημικών αναλύσεων",
                    "wtp.general.lab.complex_inflow_sampler": "Χρήση σύνθετου δειγματολήπτη στην είσοδο",
                    "wtp.general.lab.complex_outflow_sampler": "Χρήση σύνθετου δειγματολήπτη στην έξοδο",
                    "wtp.general.reuse_state": "Κατάσταση ΕΕΛ ανακτημένου νερού",
                    "wtp.general.select_reuse_state": "Επιλέξτε κατάσταση ΕΕΛ",
                    "wtp.general.reuse_activity_branch": "Κλάδος δραστηριότητας",
                    "wtp.general.select_reuse_activity_branch": "Επιλέξτε κλάδο δραστηριότητας",
                    "wtp.general.reuse_activity_branch_name": "Όνομα δραστηριότητας",
                    "wtp.general.treatment_method": "Επεξεργασία λυμάτων",
                    "wtp.waste_origin.served_sewage_agglomeration.header": "Εξυπηρετούμενοι Οικισμοί Οδηγίας (μέσω δικτύου αποχέτευσης)",
                    "wtp.waste_origin.served_sewage_settlement.header": "Εξυπηρετούμενοι Οικισμοί ΕΛΣΤΑΤ (μέσω δικτύου αποχέτευσης)",
                    "wtp.waste_origin.served_sewage_agglomeration.agglomeration": "Οικισμός Οδηγίας",
                    "wtp.waste_origin.served_sewage_agglomeration.code": "Κωδικός",
                    "wtp.waste_origin.served_sewage_agglomeration.population": "Πληθυσμός Αιχμής (Μ.Ι.Π.)",
                    "wtp.waste_origin.served_sewage_agglomeration.rate": "Ποσοστό % Δ.Α.",
                    "wtp.waste_origin.served_sewage_agglomeration.network_type": "Τύπος δικτύου",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.add.header": "Προσθήκη εξυπηρετούμενου οικισμού οδηγίας (μέσω δικτύου αποχέτευσης)",
                    "wtp.waste_origin.served_sewage_settlement.modal.add.header": "Προσθήκη εξυπηρετούμενου οικισμού ΕΛΣΤΑΤ (μέσω δικτύου αποχέτευσης)",
                    "wtp.waste_origin.served_sewage_settlement.settlement": "Οικισμός ΕΛΣΤΑΤ",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.new_agglomeration": "Νέος οικισμός ΕΛΣΤΑΤ",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.agglomeration_name": "Όνομα οικισμού ΕΛΣΤΑΤ",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.agglomeration_name_english": "Όνομα οικισμού ΕΛΣΤΑΤ (στα αγγλικά)",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_agglomeration": "Επιλέξτε οικισμό οδηγίας",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement": "Επιλέξτε οικισμό ΕΛΣΤΑΤ",
                    "wtp.waste_origin.served_sewage_agglomeration.modal.select_network_type": "Επιλέξτε τύπο δικτύου",
                    "wtp.waste_origin.served_septage_agglomeration.header": "Εξυπηρετούμενοι Οικισμοί (με βοθρολύματα)",
                    "wtp.waste_origin.served_septage_agglomeration.settlement": "Οικισμός",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks": "Συνολικά εισερχόμενα βυτία ανά ημέρα",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks_summer": "Συνολικά εισερχόμενα βυτία ανά ημέρα (θερινή περίοδος)",
                    "wtp.waste_origin.served_septage_agglomeration.daily_tanks_winter": "Συνολικά εισερχόμενα βυτία ανά ημέρα (χειμερινή περίοδος)",
                    "wtp.waste_origin.served_septage_agglomeration.agglomeration": "Οικισμός",
                    "wtp.waste_origin.served_septage_agglomeration.population": "Πληθυσμός Αιχμής (Μ.Ι.Π.)",
                    "wtp.waste_origin.served_septage_agglomeration.modal.add.header": "Προσθήκη εξυπηρετούμενου οικισμού (με βοθρολύματα)",
                    "wtp.waste_origin.served_septage_agglomeration.modal.edit.header": "Επεξεργασία εξυπηρετούμενου οικισμού (με βοθρολύματα)",
                    "wtp.waste_origin.served_industry_branches.header": "Εξυπηρετούμενοι Βιομηχανικοί Τομείς (βλ. Παράρτημα ΙΙΙ, Κ.Υ.Α. 5673/400/1997)",
                    "wtp.waste_origin.served_industry_branches.annex": "Παράρτημα",
                    "wtp.waste_origin.served_industry_branches.loadPE": "Οργανικό Φορτίο που εισέρχεται στην ΕΕΛ (Μ.Ι.Π.)",
                    "wtp.waste_origin.served_industry_branches.loadKG": "Οργανικό Φορτίο που εισέρχεται στην ΕΕΛ (kg/d)",
                    "wtp.waste_origin.served_industry_branches.origin_type": "Τρόπος εισόδου στην ΕΕΛ",
                    "wtp.waste_origin.served_industry_branches.modal.header": "Εξυπηρετούμενοι Βιομηχανικοί Τομείς",
                    "wtp.waste_origin.served_industry_branches.modal.add.header": "Προσθήκη νέου εξυπηρετούμενου βιομηχανικού τομέα",
                    "wtp.waste_origin.served_industry_branches.modal.edit.header": "Επεξεργασία εξυπηρετούμενου βιομηχανικού τομέα",
                    "wtp.waste_origin.served_industry_branches.modal.select_industry_branch": "Επιλέξτε βιομηχανικό τομέα",
                    "wtp.waste_origin.served_industry_branches.modal.select_origin_type": "Επιλέξτε τρόπο εισόδου στην ΕΕΛ",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.sewage_network": "Μέσω δικτύου αποχέτευσης αστικών λυμάτων",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.independent_pipeline": "Μέσω ανεξάρτητου αγωγού",
                    "wtp.waste_origin.served_industry_branches.modal.origin_type.tanker_truck": "Μέσω βυτίου",
                    "wtp.industry_characteristics.header": "Χαρακτηριστικά Βιομηχανίας",
                    "wtp.industry_characteristics.industry_name": "Όνομα Βιομηχανίας",
                    "wtp.industry_characteristics.industry_state": "Κατάσταση Βιομηχανίας",
                    "wtp.industry_characteristics.state.active": "Ενεργή",
                    "wtp.industry_characteristics.state.inactive": "Ανενεργή",
                    "wtp.industry_characteristics.state.temporary_inactive": "Προσωρινά ανενεργή",
                    "wtp.industry_characteristics.industry_code": "Κωδικός Βιομηχανίας",
                    "wtp.industry_characteristics.operational_period_start": "Ημερομηνία Έναρξης Λειτουργίας Βιομηχανίας",
                    "wtp.industry_characteristics.operational_period_end": "Ημερομηνία Λήξης Λειτουργίας Βιομηχανίας",
                    "wtp.industry_characteristics.stakod": "Κωδικός ΣΤΑΚΟΔ",
                    "wtp.industry_characteristics.select_stakod": "Επιλέξτε κωδικό ΣΤΑΚΟΔ",
                    "wtp.industry_characteristics.raw_material_type": "Περιγραφή Πρώτης Ύλης ",
                    "wtp.industry_characteristics.raw_material": "Κατανάλωση Πρώτων Υλών (tn/έτος)",
                    "wtp.industry_characteristics.water": "Κατανάλωση Νερού (m³/έτος)",
                    "wtp.industry_characteristics.energy": "Κατανάλωση Ενέργειας (kWh/έτος)",
                    "wtp.industry_characteristics.production_type": "Είδος Παραγόμενου Προϊόντος",
                    "wtp.industry_characteristics.production": "Παραγόμενο Προϊόν (tn/έτος)",
                    "wtp.industry_characteristics.industry_branch": "Βιομηχανικός Τομέας",
                    "wtp.industry_characteristics.load_entering": "Φορτίο Εισόδου στην ΕΕΛ (ΜΙΠ)",
                    "wtp.industry_characteristics.select_industry_state": "Επιλέξτε κατάσταση βιομηχανίας",
                    "wtp.incoming_load.capacity.header": "Δυναμικότητα",
                    "wtp.incoming_load.capacity.wtp_capacity": "Δυναμικότητα οργανικού φορτίου ΕΕΛ (Μ.Ι.Π.)",
                    "wtp.incoming_load.inflow.header": "Παροχή",
                    "wtp.incoming_load.inflow.mean": "Μέσο (Ετήσιος Μέσος Όρος)",
                    "wtp.incoming_load.inflow.max": "Μέγιστο",
                    "wtp.incoming_load.inflow.total": "Συνολική εισερχόμενη παροχή στην Ε.Ε.Λ. (m³/ημ)",
                    "wtp.incoming_load.inflow.sewerage": "Εισερχόμενη παροχή λυμάτων (m³/ημ)",
                    "wtp.incoming_load.inflow.septage": "Εισερχόμενη παροχή βοθρολυμάτων (m³/ημ)",
                    "wtp.incoming_load.incoming.header": "Εισερχόμενα Φορτία",
                    "wtp.incoming_load.incoming.mean": "Μέσο (Ετήσιος Μέσος Όρος)",
                    "wtp.incoming_load.incoming.max": "Μέγιστο",
                    "wtp.incoming_load.incoming.total": "Συνολικό εισερχόμενο φορτίο στην Ε.Ε.Λ. (Kg BOD5/ημ)",
                    "wtp.incoming_load.incoming.sewerage": "Εισερχόμενο φορτίο από λύματα (Kg BOD5/ημ)",
                    "wtp.incoming_load.incoming.septage": "Εισερχόμενο φορτίο από βοθρολύματα (Kg BOD5/ημ)",
                    "wtp.treatment.method": "Μέθοδος επεξεργασίας",
                    "wtp.treatment.other_treatment": "Περιγραφή επεξεργασίας",
                    "wtp.treatment.waste.header": "Επεξεργασία λυμάτων",
                    "wtp.treatment.waste.disinfection_chlorination": "Χλωρίωση",
                    "wtp.treatment.waste.disinfection_uv": "UV",
                    "wtp.treatment.waste.disinfection_ozone": "Όζον",
                    "wtp.treatment.dry_waste.header": "Επεξεργασία ιλύος",
                    "wtp.treatment.remarks.header": "Παρατηρήσεις",
                    "wtp.treatment.remarks.operational_problems": "Σύντομη περιγραφή προβλημάτων λειτουργίας",
                    "wtp.treatment.remarks.idle": "Αδρανεί",
                    "wtp.treatment.remarks.idle_reason": "Λόγοι αδράνειας",
                    "wtp.treatment.remarks.problems_solution": "Προτάσεις αντιμετώπισης - επίλυσης προβλημάτων",
                    "wtp.treatment.remarks.costForecast": "Εκτίμηση κόστους για να γίνει πλήρως λειτουργική",
                    "wtp.energy_greenhouse_emissions.energy.header": "Ενεργειακή αποδοτικότητα & ενέργεια από καύση βιοαερίου",
                    "wtp.energy_greenhouse_emissions.energy_consumption": "Κατανάλωση ενέργειας kWh/έτος",
                    "wtp.energy_greenhouse_emissions.energy_consumption_per_cubic_meter": "Κατανάλωση ενέργειας kWh/m³ λυμάτων",
                    "wtp.energy_greenhouse_emissions.energy_from_renewable": "Συνολική παραγόμενη ενέργεια από ΑΠΕ kWh/έτος",
                    "wtp.energy_greenhouse_emissions.energy_from_biogas": "Συνολική παραγόμενη ενέργεια από καύση βιοαερίου kWh/έτος",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions.header": "Αέρια Θερμοκηπίου (CO₂, N₂O, CH₄)",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions": "Αέρια θερμοκηπίου (CO₂, N₂O, CH₄) (ισοδύναμοι τόνοι CO₂/έτος)",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.estimation": "Εκτίμηση",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.measurement": "Μέτρηση",
                    "wtp.energy_greenhouse_emissions.greenhouse_emissions_type.calculation": "Υπολογισμός",
                    "wtp.receiver.receiver.header": "Αποδέκτης",
                    "wtp.receiver.receiver.code": "Κωδικός",
                    "wtp.receiver.receiver.name": "Ονομασία",
                    "wtp.receiver.receiver.nameEn": "Ονομασία (Αγγλικά)",
                    "wtp.receiver.receiver.category": "Κατηγορία",
                    "wtp.receiver.receiver.type": "Χαρακτηρισμός",
                    "wtp.receiver.receiver.longitude": "Γεωγρ. Μήκος",
                    "wtp.receiver.receiver.latitude": "Γεωγρ. Πλάτος",
                    "wtp.receiver.receiver.remarks": "Παρατηρήσεις",
                    "wtp.receiver.receiver.subheader": "Σημείο διάθεσης",
                    "wtp.receiver.water_body.subheader": "Υδάτινο σώμα",
                    "wtp.receiver.reuse.header": "Επαναχρησιμοποίηση",
                    "wtp.receiver.reuse.inflow": "Παροχή",
                    "wtp.receiver.reuse.unit": "m³/έτος",
                    "wtp.receiver.reuse.total": "Σύνολο",
                    "wtp.receiver.reuse.irrigation": "Άρδευση",
                    "wtp.receiver.reuse.industry": "Βιομηχανία",
                    "wtp.receiver.reuse.other": "Άλλη μέθοδος",
                    "wtp.receiver.reuse.remarks": "Παρατηρήσεις",
                    "wtp.receiver.sludge_dry.header": "Παραγόμενη ιλύς",
                    "wtp.receiver.sludge_dry.dry_solid": "Ποσότητα Ξηρών Στερεών",
                    "wtp.receiver.sludge_dry.unit": "Τόνοι DS/έτος",
                    "wtp.receiver.sludge_dry.total": "Σύνολο",
                    "wtp.receiver.sludge_dry.landfillD1": "Διάθεση σε ΧΥΤΑ (D1)",
                    "wtp.receiver.sludge_dry.landfillD5": "Μονοταφή σε ΧΥΤΑ (D5)",
                    "wtp.receiver.sludge_dry.temp_storage": "Προσωρινή αποθήκευση (D15 ή R13)",
                    "wtp.receiver.sludge_dry.agriculture": "Αξιοποίηση στη γεωργία (R10)",
                    "wtp.receiver.sludge_dry.energy": "Ενεργειακή αξιοποίηση (R1)",
                    "wtp.receiver.sludge_dry.compost": "Κομποστοποίηση (R3)",
                    "wtp.receiver.sludge_dry.other": "Άλλη μέθοδος",
                    "wtp.receiver.sludge_dry.other_description": "Περιγραφή",
                    "wtp.receiver.sludge_dry.solids_ratio": "Ποσοστό στερεών παραγόμενης ιλύος",
                    "wtp.receiver.sludge_dry.remarks": "Παρατηρήσεις",
                    "wtp.reuse.enable.header": "Ενεργοποίηση Επαναχρησιμοποίησης",
                    "wtp.reuse.supplies.header": "Παροχές",
                    "wtp.reuse.supplies.map": "Χάρτης",
                    "wtp.reuse.supplies.supply": "Παροχή (m³/έτος)",
                    "wtp.reuse.supplies.area": "Έκταση (στρ)",
                    "wtp.reuse.supplies.irrigation": "Άρδευση καλλιεργιών",
                    "wtp.reuse.supplies.unlimited_irrigation": "Απεριόριστη άρδευση",
                    "wtp.reuse.supplies.limited_irrigation": "Περιορισμένη άρδευση",
                    "wtp.reuse.supplies.underground_aquifers_enrichment": "Εμπλουτισμός υπόγειων υδροφορέων",
                    "wtp.reuse.supplies.urban_green": "Περιαστικό πράσινο",
                    "wtp.reuse.supplies.urban_usage": "Αστική χρήση",
                    "wtp.reuse.supplies.industrial_usage": "Βιομηχανική χρήση",
                    "wtp.reuse.supplies.other_method": "Άλλη μέθοδος",
                    "wtp.reuse.supplies.ground_water_body": "Υπόγειο Σώμα",
                    "wtp.reuse.supplies.cultivation_category": "Κατηγορία Καλλιέργειας",
                    "wtp.reuse.supplies.cultivation_description": "Περιγραφή Καλλιέργειας",
                    "wtp.reuse.supplies.irrigation_method": "Μέθοδος Άρδευσης",
                    "wtp.reuse.supplies.irrigation_type": "Τύπος Άρδευσης",
                    "wtp.reuse.supplies.aquifers_enrichment_type": "Τύπος εμπλουτισμού υπόγειων υδροφορέων",
                    "wtp.reuse.supplies.water_quality_category": "Κατηγορία Ποιότητας Ανακτημένου Νερού",
                    "wtp.reuse.supplies.explanation": "Επεξήγηση",
                    "wtp.reuse.supplies.description": "Περιγραφή",
                    "wtp.reuse.supplies.usage": "Χρήση",
                    "wtp.reuse.supplies.usage_water_cooling": "Νερό ψύξης μιας χρήσης",
                    "wtp.reuse.supplies.usage_non_water_cooling": "Εκτός νερού ψύξης μιας χρήσης",
                    "wtp.reuse.supplies.total": "Σύνολο",
                    "wtp.reuse.supplies.modal.add.header": "Προσθήκη νέας παροχής",
                    "wtp.reuse.supplies.modal.edit.header": "Επεξεργασία παροχής",
                    "wtp.reuse.supplies.modal.select_cultivation_category": "Επιλέξτε κατηγορία καλλιέργειας",
                    "wtp.reuse.supplies.modal.select_irrigation_method": "Επιλέξτε μέθοδο άρδευσης",
                    "wtp.reuse.supplies.modal.select_irrigation_type": "Επιλέξτε τύπο άρδευσης",
                    "wtp.reuse.supplies.modal.select_aquifers_enrichment_type": "Επιλέξτε τύπο εμπλουτισμού υπόγειων υδροφορέων",
                    "wtp.reuse.supplies.modal.select_water_quality_category": "Επιλέξτε κατηγορία ποιότητας ανακτημένου νερού",
                    "wtp.reuse.supplies.modal.select_water_body": "Επιλέξτε υπόγειο σώμα",
                    "wtp.reuse.supplies.modal.same_entry": "Βρέθηκε εγγραφή με τα ίδια στοιχεία! Παρακαλούμε ελέγξτε τα στοιχεία και δοκιμάστε ξανά.",
                    "wtp.reuse.cultivation_category.a": "Α",
                    "wtp.reuse.cultivation_category.b": "Β",
                    "wtp.reuse.cultivation_category.c": "Γ",
                    "wtp.reuse.cultivation_category.d": "Δ",
                    "wtp.reuse.cultivation_category.a.description": "Όλες οι καλλιέργειες εδώδιμων φυτών που καταναλώνονται ωμά των οποίων το βρώσιμο τμήμα έρχεται σε άμεση επαφή με ανακτημένο νερό και τα ριζώδη φυτά που καταναλώνονται ωμά",
                    "wtp.reuse.cultivation_category.b.description": "Καλλιέργειες εδώδιμων φυτών που καταναλώνονται ωμά όταν το βρώσιμο μέρος παράγεται πάνω από το έδαφος και δεν βρίσκεται σε άμεση επαφή με ανακτημένο νερό, καλλιέργειες εδώδιμων φυτών που μεταποιούνται και καλλιέργειες μη εδώδιμων φυτών, συμπεριλαμβανομένων των καλλιεργειών που χρησιμοποιούνται για τη διατροφή γαλακτοπαραγωγικών ζώων ή ζώων που παράγουν κρέας",
                    "wtp.reuse.cultivation_category.c.description": "Καλλιέργειες εδώδιμων φυτών που καταναλώνονται ωμά όταν το βρώσιμο μέρος παράγεται πάνω από το έδαφος και δεν βρίσκεται σε άμεση επαφή με ανακτημένο νερό, καλλιέργειες εδώδιμων φυτών που μεταποιούνται και καλλιέργειες μη εδώδιμων",
                    "wtp.reuse.cultivation_category.d.description": "Βιομηχανικές και ενεργειακές καλλιέργειες και καλλιέργειες σπόρων",
                    "wtp.reuse.irrigation_method.1.description": "Στάγδην άρδευση",
                    "wtp.reuse.irrigation_method.2.description": "Mέθοδος άρδευσης που αποφεύγει την άμεση επαφή με το βρώσιμο μέρος της καλλιέργειας",
                    "wtp.reuse.irrigation_method.3.description": "Tεχνητή βροχή",
                    "wtp.reuse.irrigation_method.4.description": "Άλλη μέθοδος",
                    "wtp.reuse.water_quality_category.a": "Α",
                    "wtp.reuse.water_quality_category.b": "Β",
                    "wtp.reuse.water_quality_category.c": "Γ",
                    "wtp.reuse.water_quality_category.d": "Δ",
                    "wtp.reuse.irrigation_type.0.description": "Απεριόριστη",
                    "wtp.reuse.irrigation_type.1.description": "Περιορισμένη",
                    "wtp.reuse.aquifers_enrichment_type.0.description": "Μέσω διήθησης",
                    "wtp.reuse.aquifers_enrichment_type.1.description": "Μέσω γεώτρησης",
                    "wtp.reuse.additional.header": "Πρόσθετα Στοιχεία",
                    "wtp.reuse.additional.limited_capacity_islandic_area": "Nησιωτική περιοχή με περιορισμένη δυνατότητα",
                    "wtp.reuse.additional.land_irrigation": "Πραγματοποιείται άρδευση βοσκοτόπων ή χορτονομής",
                    "wtp.reuse.additional.aerosolization_hazard": "Υπάρχει κίνδυνος αερόλυσης",
                    "wtp.reuse.additional.reuse_comments": "Σχόλιο για την παραγωγή ανακτημένου νερού",
                    "wtp.reuse.additional.hazards_comments": "Σχόλιο με την καταγραφή πρόσθετων κινδύνων που προκύπτουν από το Σχέδιο Διαχείρισης Κινδύνου",
                    "wtp.reuse.confirmation.header": "Επιβεβαίωση διαγραφής",
                    "wtp.reuse.confirmation.body": "Είστε βέβαιος/η ότι θέλετε να διαγράψετε αυτή την εγγραφή;",
                    "wtp.reuse.confirmation.ok": "Διαγραφή",
                    "wtp.reuse.confirmation.cancel": "Ακύρωση",
                    "wtp.reuse.additional_elements.header": "Πρόσθετα Στοιχεία",
                    "wtp.reuse.additional_elements.continuous_turbidity_check": "Υπάρχει όργανο συνεχούς μέτρησης της θολότητας στην έξοδο της επεξεργασίας;",
                    "wtp.measurements.display_options.header": "Επιλογές Προβολής",
                    "wtp.measurements.display_options.sampler_position": "Θέση Δειγματολήπτη",
                    "wtp.measurements.display_options.inflow": "Είσοδος",
                    "wtp.measurements.display_options.outflow": "Έξοδος",
                    "wtp.measurements.display_options.display": "Προβολή",
                    "wtp.measurements.display_options.all": "Όλα",
                    "wtp.measurements.display_options.range": "Σε χρονική περίοδο",
                    "wtp.measurements.display_options.year": "Έτος",
                    "wtp.measurements.display_options.yearly": "Έτος",
                    "wtp.measurements.display_options.from": "Από",
                    "wtp.measurements.display_options.to": "Έως",
                    "wtp.measurements.sampling_results.header": "Αποτελέσματα Δειγματοληψιών",
                    "wtp.measurements.sampling_results.check": "Έλεγχος Απαιτήσεων Εκροής Ε.Ε.Λ.",
                    "wtp.measurements.sampling_results.check_reuse": "Έλεγχος Απαιτήσεων Επαναχρησιμοποίησης Ε.Ε.Λ.",
                    "wtp.measurements.sampling_results.date": "Ημερομηνία",
                    "wtp.measurements.sampling_results.flow": "Παροχή (m³/ημ)",
                    "wtp.measurements.sampling_remarks.header": "Σχολιασμός Μετρήσεων",
                    "wtp.measurements.sampling_results.select_sampler_location": "Επιλέξτε θέση δειγματολήπτη",
                    "wtp.measurements.sampling_results.select_parameter": "Επιλέξτε παράμετρο",
                    "wtp.measurements.sampling_results.modal.add.header": "Προσθήκη νέων αποτελεσμάτων δειγματοληψίας",
                    "wtp.measurements.sampling_results.modal.edit.header": "Επεξεργασία αποτελεσμάτων δειγματοληψίας",
                    "wtp.measurements.sampling_results.modal.add_file.header": "Εισαγωγή αποτελεσμάτων δειγματοληψίας από αρχείο",
                    "wtp.measurements.sampling_results.modal.add_file.file": "Αρχείο",
                    "wtp.measurements.sampling_results.select_sheet": "Επιλέξτε φύλλο",
                    "wtp.measurements.sampling_results.select_column": "Επιλέξτε στήλη",
                    "wtp.measurements.sampling_results.modal.same_parameter": "Ανιχνεύτηκαν παράμετροι με πολλαπλές τιμές! Παρακαλούμε ελέγξτε τις παραμέτρους και δοκιμάστε ξανά.",
                    "wtp.measurements.sampling_results.modal.invalid_date": "Ανιχνεύτηκε μη αποδεκτή ημερομηνία! Παρακαλούμε ελέγξτε ότι έχετε επιλέξει τη σωστή στήλη με τις ημερομηνίες και ότι οι ημερομηνίες στο αρχείο είναι στη μορφή ΗΗ/ΜΜ/ΕΕΕΕ.",
                    "wtp.measurements.sampling_results.modal.check_results.header": "Ελεγχος δεδομένων προς εισαγωγή",
                    "wtp.measurements.sampling_results.modal.check_results.description": "Ο πίνακας που ακολουθεί εμφανίζει τις 5 πρώτες μετρήσεις από τα δεδομένα προς εισαγωγή. Παρακαλούμε ελέγξτε την ακρίβεια των δεδομένων πριν προχωρήσετε στην εισαγωγή.",
                    "wtp.measurements.limits.date_issued": "Ημερομηνία Έναρξης",
                    "wtp.measurements.limits.date_expired": "Ημερομηνία Λήξης",
                    "wtp.measurements.limits.comments": "Σχόλια",
                    "wtp.measurements.limits.modal.add.header": "Προσθήκη περιβαλλοντικών ορίων",
                    "wtp.measurements.limits.modal.edit.header": "Επεξεργασία περιβαλλοντικών ορίων",
                    "wtp.measurements.limits.header": "Περιβαλλοντικά όρια",
                    "wtp.measurements.limits.parameter": "Παράμετρος",
                    "wtp.measurements.limits.parameter_value": "Τιμή παραμέτρου",
                    "wtp.measurements.limits.parameter_count": "Ελάχιστος αριθμός δειγμάτων",
                    "wtp.measurements.limits.parameter_compliance": "Ποσοστό συμμόρφωσης",
                    "wtp.measurements.add_parameter": "Προσθήκη παραμέτρου",
                    "wtp.measurements.sampling_positions": "Επεξεργασία Θέσεων Δειγματολήπτη",
                    "wtp.measurements.compliance_aepo": "Συμμόρφωση βάσει της ΑΕΠΟ",
                    "wtp.measurements.compliance_legislation": "Συμμόρφωση βάσει της Οδηγίας 91/271/ΕΟΚ",
                    "wtp.measurements.compliance_reuse_kya": "Συμμόρφωση βάσει της ΚΥΑ 145116",
                    "wtp.measurements.compliance_reuse_eu": "Συμμόρφωση βάσει του Κανονισμού (ΕΕ) 2020/741",
                    "wtp.measurements.compliance_reuse.total_water_quality": "Τελικός χαρακτηρισμός ποιότητας ανακτημένου νερού",
                    "wtp.measurements.compliance_reuse.water_quality_check": "Έλεγχος καταλληλότητας χρήσης βάσει μετρήσεων",
                    "wtp.measurements.no_environmental_permits_found": "Δεν έχουν οριστεί περιβαλλοντικοί όροι",
                    "wtp.files.environmental_terms.header": "Περιβαλλοντικοί Όροι",
                    "wtp.files.sensitive_receiver_fek.header": "Φ.Ε.Κ. Χαρακτηρισμού Ευαίσθητου Αποδέκτη",
                    "wtp.files.risk_management_plans.header": "Σχέδια Διαχείρισης Κινδύνου",
                    "wtp.files.reuse_no_compliance.header": "Αρχεία Μη Συμμορφώσεων Ορίων Επαναχρησιμοποίησης",
                    "wtp.files.photos.header": "Φωτογραφίες Ε.Ε.Λ.",
                    "wtp.files.additional_files.header": "Πρόσθετα Αρχεία",
                    "wtp.files.additional_files.filename": "Όνομα αρχείου",
                    "wtp.files.additional_files.title": "Τίτλος",
                    "wtp.files.additional_files.file_type": "Τύπος αρχείου",
                    "wtp.files.additional_files.public": "Δημόσια προβολή",
                    "wtp.files.additional_files.file_size": "Μέγεθος αρχείου (σε bytes)",
                    "wtp.files.modal.add.environmentalterms_header": "Προσθήκη αρχείου Περιβαλλοντικών Όρων",
                    "wtp.files.modal.add.sensitivereceiverfek_header": "Προσθήκη αρχείου Φ.Ε.Κ. Χαρακτηρισμού Ευαίσθητου Αποδέκτη",
                    "wtp.files.modal.add.riskmanagementplans_header": "Προσθήκη αρχείου Σχεδίων Διαχείρισης Κινδύνου",
                    "wtp.files.modal.add.reusenocompliance_header": "Προσθήκη αρχείου Μη Συμμορφώσεων Ορίων Επαναχρησιμοποίησης",
                    "wtp.files.modal.add.additional_header": "Προσθήκη πρόσθετου αρχείου",
                    "wtp.files.modal.add.images_header": "Προσθήκη φωτογραφίας Ε.Ε.Λ.",
                    "wtp.files.modal.edit.header": "Επεξεργασία αρχείου",
                    "wtp.files.modal.max_size": "Μέγιστο μέγεθος αρχείου: 20MB",
                    "wtp.files.modal.file": "Αρχείο",
                    "wtp.comments.comments": "Σχόλια",
                    "wtp.waste_origin.served_sewage_agglomeration.name": "Όνομα",
                    "wtp.waste_origin.served_sewage_settlement.name": "Όνομα",
                    "settlements.agglomeration": "Οικισμός Οδηγίας",
                    "settlements.header": "Στοιχεία Οικισμού ΕΛΣΤΑΤ",
                    "settlements.settlement_code": "Κωδικός ΕΛΣΤΑΤ",
                    "settlements.settlement_name": "Όνομα οικισμού ΕΛΣΤΑΤ",
                    "settlements.municipal": "Δήμος",
                    "settlements.administrative_region": "Περιφέρεια",
                    "settlements.river_basin": "Λεκάνη Απορροής Ποταμού",
                    "settlements.water_district": "Υδατικό Διαμέρισμα",
                    "settlements.settlement_limits": "Όριο οικισμού ΕΛΣΤΑΤ",
                    "settlements.settlement_area": "Έκταση οικισμού ΕΛΣΤΑΤ (εκτάρια)",
                    "settlements.population": "Μόνιμος Πληθυσμός",
                    "settlements.equivalent_population": "Ισοδύναμος Πληθυσμός",
                    "settlements.served_population": "Εν δυνάμει Εξυπηρετούμενος Πληθυσμός",
                    "settlements.connected_population": "Συνδεδεμένος Πληθυσμός",
                    "settlements.population_density": "Πληθυσμιακή πυκνότητα (ΙΚ/στρ)",
                    "settlements.sewage_network_service_percentage": "Ποσοστό δικτύου αποχέτευσης",
                    "settlements.sewage_network_connected_percentage": "Ποσοστό εξυπηρετούμενου (συνδεδεμένου) πληθυσμού από δίκτυο αποχέτευσης",
                    "settlements.area_from_shape": "Έκταση από σχήμα (στρ)",
                    "settlements.area_from_shape_ha": "Έκταση από σχήμα (εκτάρια)",
                    "settlements.area": "Έκταση (στρ)",
                    "settlements.length": "Μήκος (χλμ)",
                    "settlements.pumping_station_count": "Αριθμός Αντλιοστασίων",
                    "settlements.sewage_network_type": "Τύπος Δικτύου Αποχέτευσης",
                    "settlements.map.settlement_limits": "Όρια οικισμού ΕΛΣΤΑΤ",
                    "settlements.constructed_sewage_network": "Κατασκευασμένο Δίκτυο Αποχέτευσης",
                    "settlements.connected_sewage_network": "Συνδεδεμένο Δίκτυο Αποχέτευσης",
                    "settlements.sewage_network_data": "Στοιχεία Δικτύου Αποχέτευσης",
                    "settlements.map.constructed_sewage_network": "Κατασκευασμένο ΔΑ",
                    "settlements.map.connected_sewage_network": "Συνδεδεμένο ΔΑ",
                    "settlements.duplicate_id": "Βρέθηκε οικισμός ΕΛΣΤΑΤ με τον ίδιο κωδικό!",
                    "agglomerations.header": "Στοιχεία Οικισμού Οδηγίας",
                    "agglomerations.constructed_sewage_network_area": "Έκταση Κατασκευασμένου ΔΑ (στρ)",
                    "agglomerations.constructed_sewage_network_length": "Μήκος Κατασκευασμένου ΔΑ (χλμ)",
                    "agglomerations.connected_sewage_network_area": "Έκταση Συνδεδεμένου ΔΑ (στρ)",
                    "agglomerations.connected_sewage_network_length": "Μήκος Συνδεδεμένου ΔΑ (χλμ)",
                    "agglomerations.from_settlements_sum": "Από άθροισμα οικισμών",
                    "agglomerations.characterization_year": "Έτος Xαρακτηρισμού",
                    "agglomerations.characterization": "Xαρακτηρισμός Οικισμού Οδηγίας",
                    "agglomerations.agglomeration_name": "Όνομα Οικισμού Οδηγίας",
                    "agglomerations.agglomeration_name_english": "Όνομα Οικισμού Οδηγίας (Αγγλικά)",
                    "agglomerations.agglomeration_code": "Κωδικός Οικισμού Οδηγίας",
                    "agglomerations.agglomeration_coordinates": "Συντεταγμένες Οικισμού Οδηγίας",
                    "agglomerations.add_settlement": "Προσθήκη Οικισμού ΕΛΣΤΑΤ",
                    "agglomerations.select_priority": "Επιλέξτε κατηγορία προτεραιότητας",
                    "agglomerations.duplicate_id": "Βρέθηκε οικισμός οδηγίας με τον ίδιο κωδικό!",
                    "wastewatertreatmentplants.wastewatertreatmentplant_code": "Κωδικός Ε.Ε.Λ.",
                    "wastewatertreatmentplants.wastewatertreatmentplant_name": "Όνομα Ε.Ε.Λ.",
                    "wastewatertreatmentplants.wastewatertreatmentplant_name_english": "Όνομα Ε.Ε.Λ. (Αγγλικά)",
                    "wastewatertreatmentplants.add_wtp": "Προσθήκη Ε.Ε.Λ.",
                    "wastewatertreatmentplants.operator": "Διαχειριστής Ε.Ε.Λ. (Operator)",
                    "wastewatertreatmentplants.operator.name": "Ονοματεπώνυμο Διαχειριστή",
                    "wastewatertreatmentplants.operator.email": "Email Διαχειριστή",
                    "wastewatertreatmentplants.operator.last_login_on": "Τελευταία Σύνδεση Διαχειριστή",
                    "wastewatertreatmentplants.select_operator": "Επιλέξτε διαχειριστή Ε.Ε.Λ. (Operator)",
                    "wastewatertreatmentplants.type": "Τύπος Ε.Ε.Λ.",
                    "wastewatertreatmentplants.select_type": "Επιλέξτε τύπο Ε.Ε.Λ.",
                    "wastewatertreatmentplants.edit_wtp": "Επεξεργασία Ε.Ε.Λ.",
                    "wastewatertreatmentplants.general": "Γενικά",
                    "wastewatertreatmentplants.sampler_positions": "Θέσεις Δειγματολήπτη",
                    "wastewatertreatmentplants.sampler_position_name": "Όνομα",
                    "wastewatertreatmentplants.sampler_position_name_english": "Όνομα (Αγγλικά)",
                    "wastewatertreatmentplants.no_sampler_positions": "Δεν έχουν οριστεί θέσεις δειγματολήπτη! Θα πρέπει να δηλωθεί τουλάχιστον μία θέση δειγματολήπτη.",
                    "query.compliance": "Κατάσταση συμμορφωσης (91/271/ΕΟΚ)",
                    "query.sensitive": "Ευαίσθητος",
                    "query.normal": "Κανονικός",
                    "query.treatment_method.waste": "Μέθοδος επεξεργασίας λυμάτων",
                    "query.treatment_method.sludge": "Μέθοδος επεξεργασίας ιλύος",
                    "query.reuse_method": "Μέθοδος επαναχρησιμοποίησης",
                    "query.reuse_methods": "Μέθοδοι Επαναχρησιμοποίησης",
                    "query.equivalent_population.min": "Ελάχιστος ισοδύναμος πληθυσμός",
                    "query.equivalent_population.max": "Μέγιστος ισοδύναμος πληθυσμός",
                    "query.sewage_network_percentage": "Ποσοστό δικτύου αποχέτευσης",
                    "query.sewage_network_percentage.max": "Μέγιστο ποσοστό δικτύου αποχέτευσης",
                    "query.sewage_network_percentage.min": "Ελάχιστο ποσοστό δικτύου αποχέτευσης",
                    "water_body.types.0": "Λίμνη",
                    "water_body.types.1": "Ποτάμι",
                    "water_body.types.2": "Θάλασσα",
                    "water_body.types.3": "Υπόγεια ύδατα",
                    "water_body.types.4": "Μεταβατικό",
                    "query.wtp.code": "Κωδικός Ε.Ε.Λ.",
                    "query.wtp.name": "Όνομα Ε.Ε.Λ.",
                    "query.wtp.year": "Έτος  έναρξης λειτουργίας",
                    "query.wtp.priority": "Προτεραιότητα",
                    "query.wtp.compliance": "Σε Συμμόρφωση",
                    "query.wtp.waste_treatment": "Μέθοδος Επεξεργασίας Λυμάτων",
                    "query.wtp.sludge_treatment": "Μέθοδος Επεξεργασίας Ιλύος",
                    "query.wtp.capacity": "Δυναμικότητα ΜΙΠ",
                    "query.wtp.reuse": "Επαναχρησιμοποίηση",
                    "query.wtp.administrative_region": "Περιφέρεια",
                    "query.wtp.municipal": "Δήμος",
                    "query.wtp.water_district": "Υδατικό Διαμέρισμα",
                    "query.wtp.river_basin": "Λεκάνη Απορροής Ποταμού",
                    "query.wtp.receiver_code": "Κωδικός Σημείου Διάθεσης",
                    "query.wtp.receiver_name": "Ονομασία Σημείου Διάθεσης",
                    "query.wtp.receiver_type": "Χαρακτηρισμός Αποδέκτη",
                    "query.wtp.receiver_water_type": "Κατηγορία Αποδέκτη",
                    "query.wtp.population": "Ισοδύναμος Πληθυσμός",
                    "query.wtp.served_population_percentage": "Ποσοστό δικτύου αποχέτευσης",
                    "query.wtp.contact_name": "Υπεύθυνος Λειτουργίας",
                    "query.wtp.contact_authority": "Φορέας",
                    "query.wtp.contact_position": "Θέση",
                    "query.wtp.contact_address": "Διεύθυνση",
                    "query.wtp.contact_phone": "Τηλέφωνο",
                    "query.wtp.contact_mobile": "Κινητό",
                    "query.wtp.contact_email": "Email",
                    "query.wtp.measurements_info": "Η βάση δεδομένων σας δίνει τη δυνατότητα να δείτε αναλυτικά τις μετρήσεις στην είσοδο και την έξοδο των εγκαταστάσεων επεξεργασίας λυμάτων, όπως έχουν υποβληθεί από τον Υπεύθυνο Λειτουργίας της κάθε εγκατάστασης.",
                    "query.wtp.measurements_button": "Δείτε τα αναλυτικά αποτελέσματα των δειγματοληψιών",
                    "query.wtp.reuse_measurements_button": "Δείτε τα αναλυτικά αποτελέσματα των δειγματοληψιών επαναχρησιμοποίησης",
                    "query.wtp.header": "Παρουσίαση Εγκατάστασησ Επεξεργασίας Λυμάτων",
                    "query.wtp.map_wtp": "Θέση Εγκατάστασης Επεξεργασίας Λυμάτων",
                    "query.wtp.map_receiver": "Κατάληξη του χερσαίου τμήματος του αγωγού διάθεσης των επεξεργασμένων λυμάτων",
                    "query.wtp.extensions_header": "Επεκτάσεις & Αναβαθμίσεις",
                    "query.wtp.agglomerations_header": "Εξυπηρετούμενοι Οικισμοί",
                    "query.wtp.agglomerations_info": "Οικισμοί οδηγίας που εξυπηρετούνται από την Εγκατάσταση Επεξεργασίας Λυμάτων μέσω δικτύου αποχέτευσης.",
                    "query.wtp.settlements_info": "Οικισμοί που εξυπηρετούνται από την Εγκατάσταση Επεξεργασίας Λυμάτων μέσω δικτύου αποχέτευσης.",
                    "query.wtp.septage_info": "Τα βοθρολύματα από τους παρακάτω οικισμούς μεταφέρονται στην Εγκατάσταση Επεξεργασίας Λυμάτων με βυτιοφόρα.",
                    "query.wtp.industries_header": "Συνδεδεμένες Βιομηχανίες",
                    "query.wtp.load_header": "Εισερχόμενα φορτία",
                    "query.wtp.capacity_header": "Δυναμικότητα κατασκευασμένης εγκατάστασης (Μ.Ι.Π.)",
                    "query.wtp.inflow_header": "Παροχή",
                    "query.wtp.wastewater_treatment_header": "Γραμμή Επεξεργασίας Λυμάτων",
                    "query.wtp.sludge_treatment_header": "Γραμμή Επεξεργασίας Ιλύος",
                    "query.wtp.disposal_header": "Διάθεση Επεξεργασμένης Εκροής - Αποδέκτης",
                    "query.wtp.reuse_header": "Επαναχρησιμοποίηση Επεξεργασμένων Λυμάτων",
                    "query.wtp.sludge_disposal_header": "Διάθεση Παραγόμενης Ιλύος",
                    "query.wtp.compliance_header": "Έλεγχος Λειτουργίας - Απαιτήσεις Συμμόρφωσης",
                    "query.wtp.compliance_info": "Ικανοποιούνται τα απαιτούμενα όρια εκροής από την εγκατάσταση; Λειτουργεί σωστά η Εγκατάσταση Επεξεργασίας Λυμάτων; Η Οδηγία 91/271/ΕΟΚ και η ΚΥΑ 5673/400/1997 θεσπίζουν τα κριτήρια και τις απαιτήσεις συμμόρφωσης.",
                    "query.wtp.compliance_reuse_header": "Έλεγχος Λειτουργίας - Απαιτήσεις Συμμόρφωσης (Επαναχρησιμοποίηση)",
                    "query.wtp.compliance_reuse_info": "Ικανοποιούνται τα απαιτούμενα όρια εκροής από την εγκατάσταση κατά την επαναχρησιμοποίηση; Λειτουργεί σωστά η Εγκατάσταση Επεξεργασίας Λυμάτων; Η ΚΥΑ 145116 και ο Κανονισμός (ΕΕ) 2020/741 θεσπίζουν τα κριτήρια και τις απαιτήσεις συμμόρφωσης κατά την επαναχρησιμοποίηση.",
                    "query.wtp.compliance.info.criteria": "Ποιές είναι οι απαιτήσεις συμμόρφωσης;",
                    "query.wtp.compliance.info.symbols": "Τι σημαίνουν τα σύμβολα;",
                    "query.wtp.compliance.info.symbols.info": "Τα παρακάτω σύμβολα υποδηλώνουν ότι για τη συγκεκριμένη παράμετρο και το έτος βάσης, η ΕΕΛ",
                    "query.wtp.compliance.info.symbols.pass": "Ικανοποιεί τα κριτήρια συμμόρφωσης.",
                    "query.wtp.compliance.info.symbols.fail": "Δεν ικανοποιεί τα κριτήρια συμμόρφωσης.",
                    "query.wtp.compliance.info.symbols.not_required": "Σύμφωνα με την Οδηγία δεν λαμβάνεται υπόψη.",
                    "query.wtp.compliance.info.symbols.no_data": "Δεν υπάρχουν επαρκή στοιχεία για αξιολόγηση.",
                    "query.wtp.compliance_reuse.info.criteria": "Ποιες είναι οι απαιτήσεις συμμόρφωσης για την επαναχρησιμοποίηση ανακτημένου νερού για γεωργική άρδευση;",
                    "query.wtp.compliance_reuse.info.symbols": "Τι σημαίνουν τα σύμβολα;",
                    "query.wtp.compliance_reuse.info.symbols.info": "Τα παρακάτω σύμβολα υποδηλώνουν ότι για τη συγκεκριμένη παράμετρο, συνολικά και το έτος βάσης, η ΕΕΛ",
                    "query.wtp.compliance_reuse.info.symbols.data": "Παράγει, σύμφωνα με τον Κανονισμό (ΕΕ) 2020/741, ανακτημένο νερό κατηγορίας ποιότητας Α, Β, Γ ή Δ.",
                    "query.wtp.compliance_reuse.info.symbols.no_data": "Δεν υπάρχουν επαρκή στοιχεία για αξιολόγηση.",
                    "query.wtp.results_header": "Μετρήσεις - Αναλυτικά Αποτελέσματα Δειγματοληψιών",
                    "query.wtp.reuse_results_header": "Μετρήσεις - Αναλυτικά Αποτελέσματα Δειγματοληψιών Επαναχρησιμοποίησης",
                    "query.wtp.info_header": "Περισσότερες Πληροφορίες - Αρχεία",
                    "query.wtp.contact_header": "Επικοινωνία",
                    "query.wtp.search": "Αναζήτηση Ε.Ε.Λ.",
                    "query.agglomeration.served_population": "Εξυπηρετούμενος πληθυσμός",
                    "query.agglomeration.connected_population": "Συνδεδεμένος πληθυσμός",
                    "query.agglomeration.constructed_network": "Κατασκευασμένο Δ.Α.",
                    "query.agglomeration.connected_network": "Συνδεδεμένο Δ.Α.",
                    "query.agglomeration.code": "Κωδικός Οικισμού Οδηγίας",
                    "query.agglomeration.name": "Όνομα Οικισμού Οδηγίας",
                    "query.agglomeration.search": "Αναζήτηση Οικισμού Οδηγίας",
                    "query.agglomeration.coordinates": "Συντεταγμένες Οικισμού Οδηγίας",
                    "query.agglomeration.coordinates.longitude": "Γεωγραφικό Μήκος Οικισμού",
                    "query.agglomeration.coordinates.latitude": "Γεωγραφικό Πλάτος Οικισμού",
                    "query.reuse.supplies.irrigation": "Άρδευση καλλιεργιών",
                    "query.reuse.supplies.filtration_aquifers_enrichment": "Εμπλουτισμός υπόγειων υδροφορέων μέσω διήθησης",
                    "query.reuse.supplies.drilling_aquifers_enrichment": "Εμπλουτισμός υπόγειων υδροφορέων μέσω γεώτρησης",
                    "query.reuse.supplies.urban_green": "Περιαστικό πράσινο",
                    "query.reuse.supplies.urban_usage": "Αστική χρήση",
                    "query.reuse.supplies.industrial_usage": "Βιομηχανική χρήση",
                    "query.reuse.supplies.other_method": "Άλλη μέθοδος",
                    "Forgot your password? Click here to change password.": "Ξεχάσατε τον κωδικό σας; Κάντε κλικ εδώ για να αλλάξετε τον κωδικό πρόσβασης.",
                    "Forgot password": "Ξέχασα τον κωδικό",
                    "Send request": "Αποστολή αιτήματος",
                    "The link has been sent, please check your email to reset your password.": "Ο σύνδεσμος έχει σταλεί, ελέγξτε το email σας για να επαναφέρετε τον κωδικό πρόσβασής σας.",
                    "Reset password": "Επαναφορά κωδικού πρόσβασης",
                    "Change password": "Αλλαγή κωδικού πρόσβασης",
                    "Confirm password": "Επιβεβαίωση κωδικού",
                    "Password changed successfully! Please use your new password to login.": "Ο κωδικός άλλαξε επιτυχώς! Χρησιμοποιήστε τον νέο σας κωδικό πρόσβασης για να συνδεθείτε.",
                    "Error while resetting password.": "Σφάλμα κατά την επαναφορά του κωδικού πρόσβασης.",
                    "Passwords must have at least one non alphanumeric character.": "Οι κωδικοί πρόσβασης πρέπει να έχουν τουλάχιστον έναν μη αλφαριθμητικό χαρακτήρα.",
                    "Passwords must have at least one digit ('0'-'9').": "Οι κωδικοί πρόσβασης πρέπει να έχουν τουλάχιστον ένα ψηφίο ('0'-'9').",
                    "Passwords must have at least one uppercase ('A'-'Z').": "Οι κωδικοί πρόσβασης πρέπει να έχουν τουλάχιστον ένα κεφαλαίο λατινικό ('A'-'Z').",
                    "Passwords must have at least one lowercase ('a'-'z').": "Οι κωδικοί πρόσβασης πρέπει να έχουν τουλάχιστον ένα πεζό λατινικό ('a'-'z').",
                    "Invalid token.": "Μη έγκυρο διακριτικό (token).",
                    "The password and confirmation password do not match.": "Ο κωδικός πρόσβασης και ο κωδικός επιβεβαίωσης δεν ταιριάζουν.",
                    "Passwords must be at least 6 characters.": "Οι κωδικοί πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες.",
                }
            }
        }
    });

export default i18n;