import { Alert, Button, Form, Modal, Table, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { SamplingPointType } from '../data/Definitions';
import { Formik } from 'formik';
import * as yup from 'yup';
import useFetch from 'use-http';

const SamplerPositionsModal: React.FC<{ wastewaterTreatmentPlantId: number | string, samplingPointTypes: SamplingPointType[], show: boolean, setShow: Function, setSamplingPointTypes: Function }> = ({ wastewaterTreatmentPlantId, samplingPointTypes, show: samplingPointTypesModalShow, setShow: setSamplingPointTypesModalShow, setSamplingPointTypes }) => {
    const { t, i18n } = useTranslation();
    const samplingPointTypesFormRef = useRef<any>(null);
    const [selectedSamplerPosition, setSelectedSamplerPosition] = useState<SamplingPointType>();
    const { get, put, del, response, loading, error, cache } = useFetch('/api');

    const save = async (values: {
        samplingPointTypes: SamplingPointType[],
    }) => {
        if (!values) return;
        let data = new FormData();
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (!value) return;
            if (key.startsWith('samplerPositionName')) return;
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    value.forEach((obj: Object, index: number) => {
                        Object.entries(obj).forEach(innerEntry => {
                            let [innerKey, innerValue] = innerEntry;
                            if (innerValue === null) return;
                            data.append(`${key}[][${index}][${innerKey}]`, innerValue);
                        });
                    });
                } else {
                    data.append(key, JSON.stringify(value));
                }
            } else {
                data.append(key, value);
            }
        });

        const res = await fetch(`/api/wastewatertreatmentplant/${wastewaterTreatmentPlantId}/samplingPointTypes`, {
            method: 'PUT',
            body: data,
        });
        if (res.status === 200) {
            cache.delete(`url:/api/wastewatertreatmentplant||method:GET||body:`);
            // cache.delete(`url:/api/wastewatertreatmentplant/${wastewaterTreatmentPlantId}||method:GET||body:`);
            cache.delete(`url:/api/wastewatertreatmentplant/samplingpointtypes/${wastewaterTreatmentPlantId}||method:GET||body:`);
            const res2 = await fetch(`/api/wastewatertreatmentplant/samplingPointTypes/${wastewaterTreatmentPlantId}`);
            const spts = await res2.json();
            setSamplingPointTypes(spts);
            setSamplingPointTypesModalShow(false);
        }
    }


    return (<Modal
        show={samplingPointTypesModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setSamplingPointTypesModalShow(false)}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {t("wtp.measurements.sampling_positions")}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Formik
                initialValues={{
                    samplingPointTypes: samplingPointTypes ?? [],
                }}
                validationSchema={yup.object().shape({
                    samplingPointTypes: yup.array().min(1),
                })}
                enableReinitialize
                onSubmit={save}
            >
                {props => <Form noValidate ref={samplingPointTypesFormRef} onSubmit={props.handleSubmit}>
                    {props.values.samplingPointTypes.length === 0 && <Alert key={"error"} variant={"danger"}>
                        {t("wastewatertreatmentplants.no_sampler_positions")}
                    </Alert>}
                    <Table>
                        <thead>
                            <tr>
                                <th>{t("wastewatertreatmentplants.sampler_position_name")}</th>
                                <th>{t("wastewatertreatmentplants.sampler_position_name_english")}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.values.samplingPointTypes.map((x, i) => <tr key={i}>
                                <td>
                                    <Form.Control
                                        id={`samplerPositionName${i}`}
                                        name={`samplerPositionName${i}`}
                                        type="text"
                                        placeholder={t("wastewatertreatmentplants.sampler_position_name")}
                                        onChange={props.handleChange}
                                        value={(props.values as any)[`samplerPositionName${i}`] ?? x.name ?? ""}
                                        isInvalid={(props.errors as any)[`samplerPositionName${i}`]}
                                        disabled={x !== selectedSamplerPosition}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        id={`samplerPositionNameEn${i}`}
                                        name={`samplerPositionNameEn${i}`}
                                        type="text"
                                        placeholder={t("wastewatertreatmentplants.sampler_position_name_english")}
                                        onChange={props.handleChange}
                                        value={(props.values as any)[`samplerPositionNameEn${i}`] ?? x.nameEn ?? ""}
                                        isInvalid={(props.errors as any)[`samplerPositionNameEn${i}`]}
                                        disabled={x !== selectedSamplerPosition}
                                    />
                                </td>
                                <td className='align-middle text-end'>
                                    <ButtonGroup>
                                        {x !== selectedSamplerPosition ? (x.nameEn && ["Inlet", "Outlet"].includes(x.nameEn)) ? [] : [
                                            <Button
                                                key="edit"
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => setSelectedSamplerPosition(x)}
                                            >
                                                {t("wtp.button.edit_capitalized")}
                                            </Button>,
                                            <Button
                                                key="delete"
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => props.setFieldValue("samplingPointTypes", props.values.samplingPointTypes.filter(y => y !== x))}>
                                                {t("wtp.button.delete_file")}
                                            </Button>,
                                        ] : [
                                            <Button
                                                key="save"
                                                variant="outline-success"
                                                size="sm"
                                                onClick={() => {
                                                    if (!(props.values as any)[`samplerPositionName${i}`] || !(props.values as any)[`samplerPositionNameEn${i}`]) {
                                                        if (!(props.values as any)[`samplerPositionName${i}`]) props.setFieldError(`samplerPositionName${i}`, "error");
                                                        if (!(props.values as any)[`samplerPositionNameEn${i}`]) props.setFieldError(`samplerPositionNameEn${i}`, "error");
                                                        return;
                                                    }
                                                    x.nameEn = (props.values as any)[`samplerPositionNameEn${i}`];
                                                    x.name = (props.values as any)[`samplerPositionName${i}`];
                                                    setSelectedSamplerPosition(undefined);
                                                }}
                                            >
                                                {t("wtp.button.save")}
                                            </Button>,
                                            <Button
                                                key="cancel"
                                                variant="outline-secondary"
                                                size="sm"
                                                onClick={() => {
                                                    props.setFieldValue(`samplerPositionName${i}`, x.name);
                                                    props.setFieldValue(`samplerPositionNameEn${i}`, x.nameEn);
                                                    setSelectedSamplerPosition(undefined);
                                                }}
                                            >
                                                {t("wtp.button.cancel")}
                                            </Button>,
                                        ]
                                        }
                                    </ButtonGroup>
                                </td>
                            </tr>)}
                            <tr>
                                <td>
                                    <Form.Control
                                        id="samplerPositionName"
                                        name="samplerPositionName"
                                        type="text"
                                        placeholder={t("wastewatertreatmentplants.sampler_position_name")}
                                        onChange={props.handleChange}
                                        value={(props.values as any).samplerPositionName ?? ""}
                                        isInvalid={(props.errors as any).samplerPositionName}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        id="samplerPositionNameEn"
                                        name="samplerPositionNameEn"
                                        type="text"
                                        placeholder={t("wastewatertreatmentplants.sampler_position_name_english")}
                                        onChange={props.handleChange}
                                        value={(props.values as any).samplerPositionNameEn ?? ""}
                                        isInvalid={(props.errors as any).samplerPositionNameEn}
                                    />
                                </td>
                                <td className='align-middle text-end'>
                                    <ButtonGroup>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => {
                                                if (!(props.values as any).samplerPositionName || !(props.values as any).samplerPositionNameEn) {
                                                    if (!(props.values as any).samplerPositionName) props.setFieldError("samplerPositionName", "error");
                                                    if (!(props.values as any).samplerPositionNameEn) props.setFieldError("samplerPositionNameEn", "error");
                                                    return;
                                                }
                                                props.setFieldValue(
                                                    "samplingPointTypes",
                                                    props.values.samplingPointTypes.concat([
                                                        {
                                                            id: 0,
                                                            name: (props.values as any).samplerPositionName,
                                                            nameEn: (props.values as any).samplerPositionNameEn,
                                                        }
                                                    ])
                                                );
                                                props.setFieldValue("samplerPositionName", "");
                                                props.setFieldValue("samplerPositionNameEn", "");
                                            }}
                                        >
                                            {t("wtp.button.add")}
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Form>}
            </Formik>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="success" onClick={() => samplingPointTypesFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.save")}</Button>
            <Button variant="secondary" onClick={() => setSamplingPointTypesModalShow(false)}>{t("wtp.button.cancel")}</Button>
        </Modal.Footer>
    </Modal>
    );
}

export default SamplerPositionsModal;