import { Formik, useFormikContext } from "formik";
import { useState, useRef, FormEvent, useEffect, forwardRef } from "react";
import { Button, ButtonGroup, Col, Form, Row, Table, Modal } from "react-bootstrap";
import { PlusCircle, PencilFill, Trash, Plus, Pencil, PencilSquare } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { IndustryBranch, IndustryState, Stakod, WastewaterTreatementPlantIndustrialSectorsServed } from "../../data/Definitions";
import * as yup from 'yup';

const WtpManageIndustryCharacteristics: React.FC<{ industryBranches: IndustryBranch[], stakods: Stakod[] }> = ({ industryBranches, stakods }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();

    return (
        <>
            <h4>{t("wtp.industry_characteristics.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.industry_name")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.industry_name")}
                        id="industry.name"
                        name="industry.name"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.name || ""}
                        isInvalid={(touched as any).industry?.name && !!(errors as any).industry?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.name}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.industry_characteristics.industry_state")}
                </Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        id="industry.state"
                        name="industry.state"
                        onChange={handleChange}
                        value={!isNaN(parseInt((values as any).industry?.state)) ? parseInt((values as any).industry?.state) : -1}
                        isInvalid={(touched as any).industry?.state && !!(errors as any).industry?.state}
                    >
                        <option key={"none"} value={-1}>{t("wtp.industry_characteristics.select_industry_state")}</option>
                        {Object.values(IndustryState).filter(x => !isNaN(parseInt(x.toString()))).map((x, i) => <option key={i} value={parseInt(x.toString())}>{t(IndustryState[parseInt(x.toString())])}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.state}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.industry_code")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.industry_code")}
                        id="industry.codePlant"
                        name="industry.codePlant"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.codePlant || 0}
                        isInvalid={(touched as any).industry?.codePlant && !!(errors as any).industry?.codePlant}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.codePlant}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group> */}
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.operational_period_start")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        type="date"
                        id="industry.operationalPeriodStart"
                        name="industry.operationalPeriodStart"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).industry?.operationalPeriodStart ? new Date((values as any).industry?.operationalPeriodStart.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : ""}
                        isInvalid={(touched as any).industry?.operationalPeriodStart && !!(errors as any).industry?.operationalPeriodStart}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.operationalPeriodStart}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.operational_period_end")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        type="date"
                        id="industry.operationalPeriodEnd"
                        name="industry.operationalPeriodEnd"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).industry?.operationalPeriodEnd ? new Date((values as any).industry?.operationalPeriodEnd.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : ""}
                        isInvalid={(touched as any).industry?.operationalPeriodEnd && !!(errors as any).industry?.operationalPeriodEnd}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.operationalPeriodEnd}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.industry_branch")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        name="industry.industryBranchId"
                        id="industry.industryBranchId"
                        onChange={(e) => { let currentStakods = stakods.filter(x => x.associatedIndustryBranchId?.toString() === e.target.value); setFieldValue("industry.stakodId", currentStakods.length === 1 ? currentStakods.pop()?.id : 0); handleChange(e); }}
                        value={(values as any).industry?.industryBranchId}
                        isInvalid={(touched as any).industry?.industryBranchId && !!(errors as any).industry?.industryBranchId}
                    >
                        <option key={"none"} value={0}>{t("wtp.waste_origin.served_industry_branches.modal.select_industry_branch")}</option>
                        {industryBranches.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.industryBranchId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.stakod")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Select
                        name="industry.stakodId"
                        id="industry.stakodId"
                        onChange={handleChange}
                        value={(values as any).industry?.stakodId || "0"}
                        isInvalid={(touched as any).industry?.stakodId && !!(errors as any).industry?.stakodId}
                        disabled={(values as any).industry?.industryBranchId <= 0}
                    >
                        <option key={"none"} value={"0"}>{t("wtp.industry_characteristics.select_stakod")}</option>
                        {stakods.filter(x => x.associatedIndustryBranchId?.toString() === (values as any).industry?.industryBranchId?.toString()).map((x, i) => <option key={i} value={x.id}>{`${x.id} - ${x.description}`}</option>)}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.stakodId}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.raw_material_type")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.raw_material_type")}
                        id="industry.rawmaterialType"
                        name="industry.rawmaterialType"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.rawmaterialType || ""}
                        isInvalid={(touched as any).industry?.rawmaterialType && !!(errors as any).industry?.rawmaterialType}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.rawmaterialType}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.raw_material")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.raw_material")}
                        id="industry.rawmaterial"
                        name="industry.rawmaterial"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.rawmaterial || 0}
                        isInvalid={(touched as any).industry?.rawmaterial && !!(errors as any).industry?.rawmaterial}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.rawmaterial}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.water")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.water")}
                        id="industry.water"
                        name="industry.water"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.water || 0}
                        isInvalid={(touched as any).industry?.water && !!(errors as any).industry?.water}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.water}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {/* <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.energy")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.energy")}
                        id="industry.energy"
                        name="industry.energy"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.energy || 0}
                        isInvalid={(touched as any).industry?.energy && !!(errors as any).industry?.energy}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.energy}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group> */}
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.production_type")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.production_type")}
                        id="industry.productionType"
                        name="industry.productionType"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.productionType || ""}
                        isInvalid={(touched as any).industry?.productionType && !!(errors as any).industry?.productionType}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.productionType}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.production")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.production")}
                        id="industry.production"
                        name="industry.production"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.production || 0}
                        isInvalid={(touched as any).industry?.production && !!(errors as any).industry?.production}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.production}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.industry_characteristics.load_entering")}</Form.Label>
                <Col md={{ span: 3 }} className="pe-0">
                    <Form.Control
                        placeholder={t("wtp.industry_characteristics.load_entering")}
                        id="industry.loadEntering"
                        name="industry.loadEntering"
                        onBlur={handleChange}
                        defaultValue={(values as any).industry?.loadEntering || 0}
                        isInvalid={(touched as any).industry?.loadEntering && !!(errors as any).industry?.loadEntering}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).industry?.loadEntering}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        </>
    )
}

export default WtpManageIndustryCharacteristics;
