import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { reference, flatten } from '../data/Definitions';

const FormErrorNotification: React.FC<{ setPage: Function, isReuse: boolean }> = ({ setPage, isReuse }) => {
    const { isValid, isValidating, isSubmitting, errors } = useFormikContext();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!isValidating && isSubmitting) {
            if (!isValid) {
                setShow(true);
            } else {
                setShow(false);
            }
        }
        if (Object.keys(errors).length === 0) {
            setShow(false);
        }
    }, [isSubmitting, isValid, isValidating, errors])

    return (<ToastContainer className="p-2 mb-5" position={'bottom-end'} containerPosition="fixed">
        <Toast
            className="d-inline-block mb-5"
            bg={"danger"}
            onClose={() => setShow(false)}
            show={show}
        >
            <Toast.Header>
                <strong className="me-auto">Εντοπίστηκαν σφάλματα στη φόρμα</strong>
            </Toast.Header>
            <Toast.Body className='text-white' style={{ maxHeight: "350px", overflowY: "auto" }}>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {Object.keys(flatten(errors)).map((key, index) => <p style={{ cursor: "pointer" }} onClick={() => setPage(isReuse ? (reference as any)[key].reusePage : (reference as any)[key].page)} key={index}><small><strong>{t((reference as any)[key].caption)}</strong></small> {(flatten(errors) as any)[key]}</p>)}
            </Toast.Body>
        </Toast>
    </ToastContainer>)
}

export default FormErrorNotification