import { Geometry, Point } from "ol/geom";
import { fromLonLat, transformExtent } from "ol/proj";
import { Row, Col, Form, Button } from "react-bootstrap";
import { RControl, RFeature, RInteraction, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Circle, Layers, PencilFill } from "react-bootstrap-icons";
import { useContext, useEffect, useState } from "react";
import BingMap from "../../components/BingMap";
import { AppContext } from "../../state/State";
import BaseEvent from "ol/events/Event";
import WKT from "ol/format/WKT";
import VectorSource from "ol/source/Vector";
import * as extent from "ol/extent";
import { WaterBody, WaterBodyType } from "../../data/Definitions";

let unique_id = 0;

const WtpManageDisposal: React.FC<{ isShowing: boolean }> = ({ isShowing }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [showBing, setShowBing] = useState(false);
    const [drawing, setDrawing] = useState(false);
    const [geometry, setGeometry] = useState<Geometry | undefined>();
    const context = useContext(AppContext);
    const [waterBodies, setWaterBodies] = useState<any[]>([]);

    useEffect(() => {
        if (!(values as any).dischargePoint?.location) return;
        let wkt = new WKT();
        let geomCollection = wkt.readGeometry((values as any).dischargePoint?.location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        let ext = geomCollection.getExtent();
        let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
        let lonLatCenter = extent.getCenter(lonLatExtent);
        if (lonLatCenter) {
            setFieldValue("dischargePoint.longitude", lonLatCenter[0]);
            setFieldValue("dischargePoint.latitude", lonLatCenter[1]);
            autoDetectWaterBody(lonLatCenter[0], lonLatCenter[1], false);
        }
    }, [])

    const autoDetectWaterBody = async (lng: number, lat: number, set: boolean = true) => {
        if (!lng) return;
        if (!lat) return;
        // if (!(values as any).dischargePoint?.location) {
        const location = new Point(fromLonLat([lng, lat]));
        const wkt = new WKT();
        const wktGeom = wkt.writeGeometry(location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        setFieldValue("dischargePoint.location", wktGeom);
        // }

        const res = await fetch(`/api/spatial/waterbody?lng=${lng}&lat=${lat}`, { cache: "no-store" });
        if (res.status === 200) {
            const wbs: WaterBody[] = await res.json();
            setWaterBodies(wbs);
            if (set) {
                setFieldValue("dischargePoint.waterBodyId", wbs[0].id);
                setFieldValue("dischargePoint.waterBody", wbs[0]);
            }
        }
    }

    const calculateSludgeDryTotal = (excludeEmpty: boolean = false) => {
        let total = 0.0;
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryLandfillD1Ratio))))
            total += parseFloat((values as any).sludgeDryLandfillD1 || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryLandfillD5Ratio))))
            total += parseFloat((values as any).sludgeDryLandfillD5 || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryTempStorageRatio))))
            total += parseFloat((values as any).sludgeDryTempStorage || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryAgricultureRatio))))
            total += parseFloat((values as any).sludgeDryAgriculture || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryEnergyRatio))))
            total += parseFloat((values as any).sludgeDryEnergy || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryCompostRatio))))
            total += parseFloat((values as any).sludgeDryCompost || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryOtherRatio))))
            total += parseFloat((values as any).sludgeDryOther || 0);
        return Math.round(total * 1000) / 1000 || '-';
    }

    const calculateSludgeDryPercentage = () => {
        let calculatedTotal = calculateSludgeDryTotal(true);
        if (typeof calculatedTotal === 'string') return '-';
        let total = 0.0;
        total += parseFloat((values as any).sludgeDryLandfillD1 || 0) * parseFloat((values as any).sludgeDryLandfillD1Ratio || 0);
        total += parseFloat((values as any).sludgeDryLandfillD5 || 0) * parseFloat((values as any).sludgeDryLandfillD5Ratio || 0);
        total += parseFloat((values as any).sludgeDryTempStorage || 0) * parseFloat((values as any).sludgeDryTempStorageRatio || 0);
        total += parseFloat((values as any).sludgeDryAgriculture || 0) * parseFloat((values as any).sludgeDryAgricultureRatio || 0);
        total += parseFloat((values as any).sludgeDryEnergy || 0) * parseFloat((values as any).sludgeDryEnergyRatio || 0);
        total += parseFloat((values as any).sludgeDryCompost || 0) * parseFloat((values as any).sludgeDryCompostRatio || 0);
        total += parseFloat((values as any).sludgeDryOther || 0) * parseFloat((values as any).sludgeDryOtherRatio || 0);
        let percentage = total / calculatedTotal;
        return percentage ? (percentage).toFixed(2) : '-';
    }

    return (
        <>
            <Row>
                <h4>{t("wtp.receiver.receiver.header")}</h4>
                <Col md={{ span: 6 }}>
                    <Row className="mb-2 mt-2">
                        <Col md={{ span: 6 }} className="pe-0">
                            <div className="text-center"><strong>{t("wtp.receiver.receiver.subheader")}</strong></div>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.code")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="dischargePoint.code"
                                placeholder={t("wtp.receiver.receiver.code")}
                                name="dischargePoint.code"
                                onBlur={handleChange}
                                defaultValue={(values as any).dischargePoint?.code || ""}
                                disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.name")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="dischargePoint.name"
                                placeholder={t("wtp.receiver.receiver.name")}
                                name="dischargePoint.name"
                                onBlur={handleChange}
                                defaultValue={((i18n.language === "en" && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1) ? (values as any).dischargePoint?.nameEn : (values as any).dischargePoint?.name) || ""}
                                disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                            />
                        </Col>
                    </Form.Group>
                    {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.nameEn")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="dischargePoint.nameEn"
                                placeholder={t("wtp.receiver.receiver.nameEn")}
                                name="dischargePoint.nameEn"
                                onBlur={handleChange}
                                defaultValue={(values as any).dischargePoint?.nameEn || ""}
                            />
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.longitude")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="dischargePoint.longitude"
                                placeholder={t("wtp.receiver.receiver.longitude")}
                                name="dischargePoint.longitude"
                                onBlur={e => { handleChange(e); autoDetectWaterBody(parseFloat(e.target.value), (values as any).dischargePoint.latitude); }}
                                defaultValue={(values as any).dischargePoint?.longitude || ""}
                                disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.latitude")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="dischargePoint.latitude"
                                placeholder={t("wtp.receiver.receiver.latitude")}
                                name="dischargePoint.latitude"
                                onBlur={e => { handleChange(e); autoDetectWaterBody((values as any).dischargePoint.longitude, parseFloat(e.target.value)); }}
                                defaultValue={(values as any).dischargePoint?.latitude || ""}
                                disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                            />
                        </Col>
                    </Form.Group>
                    <Row className="mb-2 mt-4">
                        <Col md={{ span: 6 }} className="pe-0">
                            <div className="text-center"><strong>{t("wtp.receiver.water_body.subheader")}</strong></div>
                        </Col>
                    </Row>
                    {waterBodies.length > 0 && <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.water_body.subheader")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Select
                                onChange={(e) => {
                                    setFieldValue("dischargePoint.waterBodyId", waterBodies[parseInt(e.target.value)].id);
                                    setFieldValue("dischargePoint.waterBody", waterBodies[parseInt(e.target.value)]);
                                }}
                                value={waterBodies.findIndex(x => (values as any).dischargePoint?.waterBody?.code === x.code)}
                            >
                                {waterBodies.map((x, i) => <option key={i} value={i}>{i18n.language === "en" ? `${x.code} - ${x.nameEn} (${t("water_body.types." + x.type)})` : `${x.code} - ${x.name} (${t("water_body.types." + x.type)})`}</option>)}
                            </Form.Select>
                        </Col>
                    </Form.Group>}
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.code")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                id="waterBodyCode"
                                placeholder={t("wtp.receiver.receiver.code")}
                                name="waterBodyCode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={(values as any).dischargePoint?.waterBody?.code || ""}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.name")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                id="waterBodyName"
                                placeholder={t("wtp.receiver.receiver.name")}
                                name="waterBodyName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={(i18n.language === "en" ? (values as any).dischargePoint?.waterBody?.nameEn : (values as any).dischargePoint?.waterBody?.name) || ""}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.category")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                id="receiverCategoryId"
                                name="receiverCategoryId"
                                value={((values as any).dischargePoint?.waterBody?.type !== null || (values as any).dischargePoint?.waterBody?.type !== undefined) ? t("water_body.types." + (values as any).dischargePoint?.waterBody?.type.toString()) : undefined}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.type")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                id="receiverTypeId"
                                name="receiverTypeId"
                                value={(values as any).dischargePoint?.waterBody?.isSensitive ? i18n.language === "en" ? "Sensitive" : "Ευαίσθητος" : i18n.language === "en" ? "Normal" : "Κανονικός"}
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2 mt-4">
                        <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.receiver.receiver.remarks")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                id="receiverRemarks"
                                name="receiverRemarks"
                                onBlur={handleChange}
                                defaultValue={(values as any).receiverRemarks || ""}
                                isInvalid={(touched as any).receiverRemarks && !!(errors as any).receiverRemarks}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).receiverRemarks}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Col>
                <Col md={{ span: 6 }}>
                    <div style={{ width: "100%", height: "380px" }}>
                        {isShowing && <RMap className='w-100 h-100' initial={((values as any).dischargePoint?.longitude && (values as any).dischargePoint?.latitude) ? { center: fromLonLat([(values as any).dischargePoint?.longitude, (values as any).dischargePoint?.latitude]), zoom: 15 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}>
                            {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <RControl.RCustom className="m-2">
                                <Button variant="secondary" onClick={() => { !drawing && setGeometry(undefined); setDrawing(!drawing); }}>
                                    <PencilFill />
                                </Button>
                            </RControl.RCustom>}
                            <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                <ROSM properties={{ label: "Map" }} />
                                <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                            </RControl.RLayers>
                            <RLayerVector
                                zIndex={11}
                                onAddFeature={(e: BaseEvent) => {
                                    let source = e.target as VectorSource<Geometry>;
                                    if (!source?.forEachFeatureAtCoordinateDirect) {
                                        return;
                                    }

                                    if (!drawing) return;

                                    let feats = source.getFeatures();
                                    if (feats.length > 0) {
                                        let geom = feats.filter(x => !x.getProperties().type)[0]?.getGeometry();
                                        if (geom) {
                                            let wkt = new WKT();
                                            let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                            setFieldValue("dischargePoint.location", wktGeom);
                                            let ext = geom.getExtent();
                                            var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                            var center = extent.getCenter(lonLatExtent);
                                            if (center) {
                                                setFieldValue("dischargePoint.longitude", center[0]);
                                                setFieldValue("dischargePoint.latitude", center[1]);
                                                autoDetectWaterBody(center[0], center[1]);
                                            }
                                            source.clear();
                                            setDrawing(false);
                                        }
                                    }
                                }}
                            >
                                <RFeature
                                    key={`Feature${++unique_id}`}
                                    geometry={new Point(fromLonLat([(values as any).dischargePoint?.longitude, (values as any).dischargePoint?.latitude]))}
                                    properties={{ type: "location" }}
                                >
                                    <RStyle.RStyle>
                                        <RStyle.RCircle radius={10}>
                                            <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                            <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                        </RStyle.RCircle>
                                    </RStyle.RStyle>
                                </RFeature>
                                {drawing && <RInteraction.RDraw
                                    type={"Point"}
                                />}
                            </RLayerVector>
                        </RMap>}
                    </div>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={{ span: 6 }}>
                    <h4>{t("wtp.receiver.sludge_dry.header")}</h4>
                    <Row className="mb-2">
                        <Col md={{ span: 3, offset: 6 }} className="text-center align-self-end">
                            <strong>{t("wtp.receiver.sludge_dry.dry_solid")}</strong>
                        </Col>
                        <Col md={{ span: 3 }} className="text-center align-self-end">
                            <strong>{t("wtp.receiver.sludge_dry.solids_ratio")}</strong>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={{ span: 3, offset: 6 }} className="text-center">
                            ({t("wtp.receiver.sludge_dry.unit")})
                        </Col>
                        <Col md={{ span: 3 }} className="text-center">
                            (%)
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}><strong>{t("wtp.receiver.sludge_dry.total")}</strong></Form.Label>
                        <Form.Label column className="text-center" md={{ span: 3 }}><strong>{calculateSludgeDryTotal()}</strong></Form.Label>
                        <Form.Label column className="text-center" md={{ span: 3 }}><strong>{calculateSludgeDryPercentage()}</strong></Form.Label>
                    </Row>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.landfillD1")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryLandfillD1"
                                name="sludgeDryLandfillD1"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryLandfillD1 || ""}
                                isInvalid={!!(errors as any).sludgeDryLandfillD1}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryLandfillD1}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryLandfillD1Ratio"
                                name="sludgeDryLandfillD1Ratio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryLandfillD1Ratio || ""}
                                isInvalid={!!(errors as any).sludgeDryLandfillD1Ratio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryLandfillD1Ratio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.landfillD5")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryLandfillD5"
                                name="sludgeDryLandfillD5"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryLandfillD5 || ""}
                                isInvalid={!!(errors as any).sludgeDryLandfillD5}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryLandfillD5}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryLandfillD5Ratio"
                                name="sludgeDryLandfillD5Ratio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryLandfillD5Ratio || ""}
                                isInvalid={!!(errors as any).sludgeDryLandfillD5Ratio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryLandfillD5Ratio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.temp_storage")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryTempStorage"
                                name="sludgeDryTempStorage"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryTempStorage || ""}
                                isInvalid={!!(errors as any).sludgeDryTempStorage}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryTempStorage}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryTempStorageRatio"
                                name="sludgeDryTempStorageRatio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryTempStorageRatio || ""}
                                isInvalid={!!(errors as any).sludgeDryTempStorageRatio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryTempStorageRatio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.agriculture")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryAgriculture"
                                name="sludgeDryAgriculture"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryAgriculture || ""}
                                isInvalid={!!(errors as any).sludgeDryAgriculture}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryAgriculture}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryAgricultureRatio"
                                name="sludgeDryAgricultureRatio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryAgricultureRatio || ""}
                                isInvalid={!!(errors as any).sludgeDryAgricultureRatio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryAgricultureRatio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.energy")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryEnergy"
                                name="sludgeDryEnergy"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryEnergy || ""}
                                isInvalid={!!(errors as any).sludgeDryEnergy}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryEnergy}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryEnergyRatio"
                                name="sludgeDryEnergyRatio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryEnergyRatio || ""}
                                isInvalid={!!(errors as any).sludgeDryEnergyRatio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryEnergyRatio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.compost")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryCompost"
                                name="sludgeDryCompost"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryCompost || ""}
                                isInvalid={!!(errors as any).sludgeDryCompost}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryCompost}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryCompostRatio"
                                name="sludgeDryCompostRatio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryCompostRatio || ""}
                                isInvalid={!!(errors as any).sludgeDryCompostRatio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryCompostRatio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.other")}</Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Col md={{ span: 3, offset: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryOtherDescription"
                                name="sludgeDryOtherDescription"
                                placeholder={t("wtp.receiver.sludge_dry.other_description")}
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryOtherDescription || ""}
                                isInvalid={!!(errors as any).sludgeDryOtherDescription}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryOtherDescription}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryOther"
                                name="sludgeDryOther"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryOther || ""}
                                isInvalid={!!(errors as any).sludgeDryOther}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryOther}
                            </Form.Control.Feedback>
                        </Col>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Control
                                id="sludgeDryOtherRatio"
                                name="sludgeDryOtherRatio"
                                className="text-center"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryOtherRatio || ""}
                                isInvalid={!!(errors as any).sludgeDryOtherRatio}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryOtherRatio}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Form.Label column className="text-end" md={{ span: 6 }}>{t("wtp.receiver.sludge_dry.remarks")}</Form.Label>
                        <Col md={{ span: 6 }} className="pe-0">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                id="sludgeDryRemarks"
                                name="sludgeDryRemarks"
                                onBlur={handleChange}
                                defaultValue={(values as any).sludgeDryRemarks || ""}
                                isInvalid={(touched as any).sludgeDryRemarks && !!(errors as any).sludgeDryRemarks}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).sludgeDryRemarks}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default WtpManageDisposal;
