import { fromLonLat, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerVector, RMap, ROSM, RPopup, RStyle } from "rlayers";
import 'ol/ol.css';
import { LineString, Point } from "ol/geom";
import { createRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../state/State';
import { useLocation } from "react-router-dom";
import useFetch from "use-http";
import { CheckResult, ComplianceStatus, ExtensionsView, FileView, ParameterCheckResult, ResultSampleView, ReuseParameterCheckResult, ReuseSupplyView, SamplingParameter, SeptageServedSettlementsView, ServedIndustrialSectorsView, SewageServedSettlementsView, WastewaterTreatmentPlantInfoView, WastewaterTreatmentPlantQueryView, WastewaterTreatmentPlantsResultView, WastewaterTreatmentPlantsSamplingPointTypeView } from "../data/Definitions";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, Image, ListGroup, Modal, Offcanvas, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { reuseOptions, sludgeTreatmentOptions, wasteTreatmentOptions } from "../data/Data";
import { ExclamationCircle, FiletypeCsv, QuestionCircleFill, Search } from "react-bootstrap-icons";
import BingMap from "../components/BingMap";
import WKT from "ol/format/WKT";
import * as extent from "ol/extent";
import wtpMarker from '../assets/images/mrk_eel.png';
import receiverMarker from '../assets/images/mrk_apd.png';
import { shiftKeyOnly } from "ol/events/condition";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import DataTable from "react-data-table-component";
import React from "react";

const colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922", "#743411"];

const ResultsModal: React.FC<{ show: boolean, onHide: () => void, wtp: WastewaterTreatmentPlantQueryView | undefined, reuse: boolean }> = (props) => {
    const { t, i18n } = useTranslation();
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const [results, setResults] = useState<WastewaterTreatmentPlantsResultView[]>([]);
    const [samplingPointTypes, setSamplingPointTypes] = useState<WastewaterTreatmentPlantsSamplingPointTypeView[]>([]);
    const [samplingParameters, setSamplingParameters] = useState<SamplingParameter[]>([]);
    const [fullscreen, setFullscreen] = useState(false);
    const [yearFilter, setYearFilter] = useState(0);
    const [inputData, setInputData] = useState<WastewaterTreatmentPlantsResultView[][]>([]);
    const [inputDataReversed, setInputDataReversed] = useState<WastewaterTreatmentPlantsResultView[][]>([]);
    const [visibleParametersInChart, setVisibleParametersInChart] = useState<string[]>([]);

    useEffect(() => {
        if (props.show) {
            getData();
        } else {
            setResults([]);
            setSamplingPointTypes([]);
            setSamplingParameters([]);
            setYearFilter(0);
            setInputData([]);
            setInputDataReversed([]);
            setVisibleParametersInChart([]);
        }
    }, [props.show])

    useEffect(() => {
        handleMeasurementsData(results, samplingPointTypes, samplingParameters);
    }, [yearFilter])

    const handleMeasurementsData = (inResults: WastewaterTreatmentPlantsResultView[], inSamplingPointTypes: WastewaterTreatmentPlantsSamplingPointTypeView[], inSamplingParameters: SamplingParameter[]) => {
        if (!inResults) return;
        if (!inSamplingPointTypes) return;
        if (!inSamplingParameters) return;
        let data = inResults.sort((a: WastewaterTreatmentPlantsResultView, b: WastewaterTreatmentPlantsResultView) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });

        const latestYear = data[0]?.samplingDate ? new Date(data[0].samplingDate).getFullYear() : yearFilter;
        data = data.filter((x: WastewaterTreatmentPlantsResultView) => new Date(x.samplingDate!).getFullYear() === (yearFilter || latestYear));

        let inputSamples: any = {};
        let inputSamplesReversed: any = {};
        if (inSamplingPointTypes) {
            for (var samplingPointType of inSamplingPointTypes) {
                if (samplingPointType.id) {
                    let tempData = data.filter(x => x.samplingPointTypeId === samplingPointType.id);
                    inputSamples[samplingPointType.id] = tempData;
                    inputSamplesReversed[samplingPointType.id] = [...tempData].reverse();
                }
            }
        }

        setInputData(Object.assign({}, inputSamples));
        setInputDataReversed(Object.assign({}, inputSamplesReversed));
        setVisibleParametersInChart(inSamplingParameters.slice(0, 7).map(x => x.name));
    }

    const changeParameterVisibility = (val: string) => {
        if (!val) return;
        let idxToRemove = visibleParametersInChart.indexOf(val);
        let arr = [...visibleParametersInChart];
        if (idxToRemove === -1) {
            arr.push(val);
        } else {
            arr.splice(idxToRemove, 1);
        }
        setVisibleParametersInChart(arr);
    }

    const getData = async () => {
        if (!props.wtp) return;

        let sps = await get('/query/samplingparameters');
        if (response.ok) setSamplingParameters(sps);

        let spts = await get(`/query/samplingpointtypes/${props.wtp.code}`);
        if (response.ok) {
            setSamplingPointTypes(spts);
        }

        let res = await get(props.reuse ? `/query/reuse_results/${props.wtp.code}` : `/query/results/${props.wtp.code}`);
        if (response.ok) setResults(res);

        handleMeasurementsData(res, spts, sps);
    }

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
            },
        },
    };

    const getSamplingParametersToShow = useCallback((data: WastewaterTreatmentPlantsResultView[]) => {
        if (!data) return [];
        const params = data.flatMap(x => x.samples).flatMap(x => x?.samplingParameterId).filter((item, i, ar) => ar.indexOf(item) === i);
        return samplingParameters.filter(x => params.includes(x.id));
    }, [samplingParameters]);

    const columns = useCallback((samplingPointType?: number) => {
        if (!samplingPointType) return [];
        return [
            {
                key: "samplingDate",
                name: t("wtp.measurements.sampling_results.date"),
                selector: (row: WastewaterTreatmentPlantsResultView) => row.samplingDate ? new Date(row.samplingDate).toLocaleDateString(i18n.language, { year: "numeric", month: "2-digit", day: "2-digit" }) : "N/A",
                center: true,
            },
            ...getSamplingParametersToShow(inputDataReversed[samplingPointType]).map(x => {
                return {
                    key: x.id.toString(),
                    name: `${x.name} (${x.unit})`,
                    selector: (row: WastewaterTreatmentPlantsResultView) => row.samples?.find(y => x.id === y.samplingParameterId)?.value?.toLocaleString(i18n.language) || "-",
                    center: true,
                };
            })
        ];
    }, [getSamplingParametersToShow, i18n.language, inputDataReversed, t])

    const chart = useCallback((samplingPointType?: number) => {
        if (!samplingPointType) return <></>;
        if (inputDataReversed[samplingPointType].length === 0) return <></>;
        return <ResponsiveContainer minHeight={500}>
            <LineChart data={inputDataReversed[samplingPointType]}>
                <CartesianGrid strokeDasharray="4 3" />
                <Legend verticalAlign="top" onClick={data => changeParameterVisibility(data.value)} />
                <Tooltip labelStyle={{ fontWeight: "bold" }} labelFormatter={x => new Date(x).toLocaleDateString(i18n.language, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })} formatter={x => x.toLocaleString(i18n.language)} />
                <XAxis dataKey={"samplingDate"} tickFormatter={(x: string, i: number) => new Date(x).toLocaleDateString(i18n.language, { year: "numeric", month: "2-digit", day: "2-digit" })} />
                <YAxis />
                {getSamplingParametersToShow(inputDataReversed[samplingPointType]).map(x => <Line key={x.name} type="monotone" name={x.name} dataKey={y => y.samples.find((z: ResultSampleView) => z.samplingParameterId === x.id)?.value} stroke={colors[x.id]} unit={x.unit || undefined} connectNulls hide={visibleParametersInChart.indexOf(x.name) === -1} isAnimationActive={false} />)}
            </LineChart>
        </ResponsiveContainer>
    }, [inputDataReversed, samplingParameters, visibleParametersInChart, i18n.language]);

    const convertArrayOfObjectsToCSV = (array: WastewaterTreatmentPlantsResultView[], cols: any) => {
        let result: any;

        const columnDelimiter = ';';
        const lineDelimiter = '\n';
        let header: string[] = cols.map((x: any) => x.name);
        header[0] = "Sampling date";

        // result = 'sep=' + columnDelimiter + lineDelimiter;
        result = '\ufeff';
        result += header.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            let keys = cols.map((x: any) => x.key);
            keys.forEach((key: string) => {
                if (ctr > 0) result += columnDelimiter;

                if (ctr === 0) {
                    result += item.samplingDate ? new Date(item.samplingDate).toLocaleDateString("el-GR") : "";
                } else {
                    result += item.samples?.find(x => x.samplingParameterId?.toString() === key)?.value?.toString().replace(".", ",") ?? "";
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    const downloadCSV = (array: WastewaterTreatmentPlantsResultView[], cols: any) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array, cols);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    if (!props.wtp) return <></>;

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: "small" }}
            {...fullscreen && { fullscreen }}
            onExited={() => {
                setFullscreen(false);
                setResults([]);
            }}
        >
            <Modal.Header closeButton className="w-100">
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="text-primary">{t(props.reuse ? "query.wtp.reuse_results_header" : "query.wtp.results_header")} {i18n.language === "en" ? props.wtp.nameEn : props.wtp.name} - {props.wtp.code}</h5>
                </Modal.Title>
                <Button size="sm" className={fullscreen ? "btn-exit-fullscreen" : "btn-fullscreen"} onClick={(e) => { (e.target as any).blur(); setFullscreen(!fullscreen); }} />
            </Modal.Header>
            <Modal.Body style={fullscreen ? { maxHeight: "calc(100vh - 64px", overflowY: "auto" } : { maxHeight: "calc(100vh - 300px", overflowY: "auto" }}>
                {loading ? <div style={{ width: "100%", textAlign: "center" }}><Spinner animation={"border"} /></div> : results.length === 0 ? <div className="text-center">
                    {t("wtp.table.no_data")}
                </div> : <>
                    <Form.Group as={Row} className="mb-2 me-1">
                        <Form.Label column className="text-center" md={{ span: 1 }}>{t("wtp.measurements.display_options.year")}</Form.Label>
                        <Col md={{ span: 2 }} className="pe-0">
                            <Form.Select aria-label="Επιλογή" value={yearFilter} onChange={v => setYearFilter(parseInt(v.target.value))}>
                                {(Array.from(new Set(results.map((x: WastewaterTreatmentPlantsResultView) => new Date(x.samplingDate!).getFullYear()))) as number[]).sort().reverse().map((x) => <option key={x} value={x}>{x}</option>)}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    <Tabs
                        defaultActiveKey={samplingPointTypes[0]?.id ?? undefined}
                        className="mb-3"
                    >
                        {samplingPointTypes.map(x => <Tab key={x.id} eventKey={x.id} title={i18n.language === "en" ? x.nameEn : x.name}>
                            {chart(x.id)}
                            <DataTable
                                striped={true}
                                columns={columns(x.id)}
                                data={inputData[x.id ?? 0]}
                                pagination
                                noDataComponent={<div className="text-center">
                                    {t("wtp.table.no_data")}
                                </div>}
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                actions={[
                                    inputData[x.id ?? 0].length > 0 && <Button variant="outline-secondary" size="sm" onClick={() => downloadCSV(inputData[x.id ?? 0], columns(x.id))}><FiletypeCsv />&nbsp;Export to CSV</Button>,
                                ]}
                            />
                        </Tab>)}
                    </Tabs>
                </>}
            </Modal.Body>
        </Modal>
    );
}

const WastewaterTreatmentPlantsModal: React.FC<{ show: boolean, onHide: () => void, onShowResults: () => void, onShowReuseResults: () => void, wtp: WastewaterTreatmentPlantQueryView | undefined }> = (props) => {
    const { t, i18n } = useTranslation();
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const [info, setInfo] = useState<WastewaterTreatmentPlantInfoView>();
    const [complianceLegislationResults, setComplianceLegislationResults] = useState<CheckResult[]>([]);
    const [complianceAepoResults, setComplianceAepoResults] = useState<CheckResult[]>([]);
    const [complianceReuseKYAResults, setComplianceReuseKYAResults] = useState<CheckResult[]>([]);
    const [complianceReuseEUResults, setComplianceReuseEUResults] = useState<CheckResult[]>([]);
    const [samplingParameters, setSamplingParameters] = useState<SamplingParameter[]>([]);
    const [fullscreen, setFullscreen] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showReuseHelp, setShowReuseHelp] = useState(false);
    const map = useRef<RMap>(null);

    useEffect(() => {
        if (props.show) {
            getInfo();
        } else {
            setInfo(undefined);
            setComplianceLegislationResults([]);
            setComplianceAepoResults([]);
            setComplianceReuseKYAResults([]);
            setComplianceReuseEUResults([]);
        }
    }, [props.show])

    useEffect(() => {
        map.current?.ol.updateSize();
    }, [fullscreen])

    const getInfo = async () => {
        if (!props.wtp) return;

        let sps = await get('/query/samplingparameters');
        if (response.ok) setSamplingParameters(sps);

        let clrs = await get(`/compliance/${props.wtp.code}/legislation`);
        if (response.ok) {
            setComplianceLegislationResults(clrs);
        }

        // let cars = await get(`/compliance/${props.wtp.code}/aepo`);
        // if (response.ok) {
        //     setComplianceAepoResults(cars);
        // }

        let crkyars = await get(`/compliance/${props.wtp.code}/reuse_kya`);
        if (response.ok) {
            setComplianceReuseKYAResults(crkyars);
        }

        let creurs = await get(`/compliance/${props.wtp.code}/reuse_eu`);
        if (response.ok) {
            setComplianceReuseEUResults(creurs);
        }

        let wtp = await get(`/query/wastewatertreatmentplants/${props.wtp.code}`);
        if (response.ok) {
            setInfo(wtp);
        }
    }

    const getMapInitialPosition = () => {
        if (info?.longitude && info?.latitude) {
            return { center: fromLonLat([info.longitude, info.latitude]), zoom: 15 }
        }
        if (info?.shape) {
            let wkt = new WKT();
            let geom = wkt.readGeometry(info.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
            let ext = geom.getExtent();
            var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
            var center = extent.getCenter(lonLatExtent);
            if (center) {
                return { center: fromLonLat([center[0], center[1]]), zoom: 15 };
            }
        }
        return { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 };
    }

    const getComplianceStatus = (parameterId: number, yearResults: ParameterCheckResult[]) => {
        var r = yearResults.find(x => x.parameterId === parameterId);
        if (!r) return "❔";
        switch (r.complianceStatus) {
            case ComplianceStatus.Pass: return "✔️";
            case ComplianceStatus.Fail: return "❌";
            case ComplianceStatus.NotRequired: return "󠁼➖";
            default: return "❔";
        }
    }

    const getWaterQuality = (parameterId: number, yearResults: ReuseParameterCheckResult[]) => {
        var r = yearResults.find(x => x.parameterId === parameterId);
        if (!r) return "❔";
        switch (r.waterQuality) {
            case 0: return i18n.language === "en" ? "A" : "Α";
            case 1: return i18n.language === "en" ? "B" : "Β";
            case 2: return i18n.language === "en" ? "C" : "Γ";
            case 3: return i18n.language === "en" ? "D" : "Δ";
            case null: return "󠁼➖";
            default: return "❔";
        }
    }

    const getTotalWaterQuality = (yearResults: ReuseParameterCheckResult[]) => {
        var r = yearResults.map(x => x.waterQuality);
        var f = r.filter(x => x !== null && x !== undefined) as number[];
        var waterQuality = r.some(x => x === null || x === undefined) ? null : Math.max(...f);
        switch (waterQuality) {
            case 0: return i18n.language === "en" ? "A" : "Α";
            case 1: return i18n.language === "en" ? "B" : "Β";
            case 2: return i18n.language === "en" ? "C" : "Γ";
            case 3: return i18n.language === "en" ? "D" : "Δ";
            case null: return "󠁼➖";
            default: return "❔";
        }
    }

    const calculateSludgeDryTotal = (values: any, excludeEmpty: boolean = false) => {
        if (!values) return '-';
        let total = 0.0;
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryLandfillD1Ratio))))
            total += parseFloat((values as any).sludgeDryLandfillD1 || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryLandfillD5Ratio))))
            total += parseFloat((values as any).sludgeDryLandfillD5 || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryTempStorageRatio))))
            total += parseFloat((values as any).sludgeDryTempStorage || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryAgricultureRatio))))
            total += parseFloat((values as any).sludgeDryAgriculture || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryEnergyRatio))))
            total += parseFloat((values as any).sludgeDryEnergy || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryCompostRatio))))
            total += parseFloat((values as any).sludgeDryCompost || 0);
        if (!(excludeEmpty && isNaN(parseFloat((values as any).sludgeDryOtherRatio))))
            total += parseFloat((values as any).sludgeDryOther || 0);
        return Math.round(total * 1000) / 1000 || '-';
    }

    const calculateSludgeDryPercentage = (values: any) => {
        if (!values) return '-';
        let calculatedTotal = calculateSludgeDryTotal(values, true);
        if (typeof calculatedTotal === 'string') return '-';
        let total = 0.0;
        total += parseFloat((values as any).sludgeDryLandfillD1 || 0) * parseFloat((values as any).sludgeDryLandfillD1Ratio || 0);
        total += parseFloat((values as any).sludgeDryLandfillD5 || 0) * parseFloat((values as any).sludgeDryLandfillD5Ratio || 0);
        total += parseFloat((values as any).sludgeDryTempStorage || 0) * parseFloat((values as any).sludgeDryTempStorageRatio || 0);
        total += parseFloat((values as any).sludgeDryAgriculture || 0) * parseFloat((values as any).sludgeDryAgricultureRatio || 0);
        total += parseFloat((values as any).sludgeDryEnergy || 0) * parseFloat((values as any).sludgeDryEnergyRatio || 0);
        total += parseFloat((values as any).sludgeDryCompost || 0) * parseFloat((values as any).sludgeDryCompostRatio || 0);
        total += parseFloat((values as any).sludgeDryOther || 0) * parseFloat((values as any).sludgeDryOtherRatio || 0);
        let percentage = total / calculatedTotal;
        return percentage ? percentage.toLocaleString(i18n.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-';
    }

    const arrayReorder = (arr: any[], old_index: number, new_index: number) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    if (!props.wtp) return <></>;

    return (<>
        <Modal
            show={props?.show}
            onHide={props?.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: "small" }}
            {...fullscreen && { fullscreen }}
            onExited={() => {
                setFullscreen(false);
                setInfo(undefined);
            }}
        >
            <Modal.Header closeButton className="w-100">
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="text-primary">{i18n.language === "en" ? props.wtp.nameEn : props.wtp.name} - {props.wtp.code}</h5>
                </Modal.Title>
                <Button size="sm" className={fullscreen ? "btn-exit-fullscreen" : "btn-fullscreen"} onClick={(e) => { (e.target as any).blur(); setFullscreen(!fullscreen); }} />
            </Modal.Header>
            <Modal.Body style={fullscreen ? { maxHeight: "calc(100vh - 64px", overflowY: "auto" } : { maxHeight: "calc(100vh - 300px", overflowY: "auto" }}>
                {loading ? <div style={{ width: "100%", textAlign: "center" }}><Spinner animation={"border"} /></div> : <>
                    {(props.show && info) && <RMap
                        ref={map}
                        className='w-100'
                        height={200}
                        initial={getMapInitialPosition()}
                    >
                        <RControl.RLayers>
                            <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                        </RControl.RLayers>
                        <RLayerVector
                            zIndex={11}
                        >
                            {info?.shape ? <RFeature
                                key={"WTPShape"}
                                geometry={(() => new WKT().readGeometry(info.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }))()}
                            >
                                <RStyle.RStyle>
                                    <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                    <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                </RStyle.RStyle>
                            </RFeature>
                                : (info?.longitude && info?.latitude) ?
                                    <RFeature
                                        key={"WTPLocation"}
                                        geometry={new Point(fromLonLat([info.longitude, info.latitude]))}
                                    >
                                        <RStyle.RStyle>
                                            <RStyle.RIcon src={wtpMarker} anchor={[0.5, 1]} />
                                        </RStyle.RStyle>
                                        <RPopup trigger={'hover'}>
                                            <div className='card'>
                                                <p className='card-body text-center mb-0'>
                                                    {t("query.wtp.map_wtp")}
                                                </p>
                                            </div>
                                        </RPopup>
                                    </RFeature>
                                    : <></>}
                            {props.wtp.receiverLocation && <RFeature
                                key={"ReceiverLocation"}
                                geometry={(() => new WKT().readGeometry(props.wtp.receiverLocation, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }))()}
                            >
                                <RStyle.RStyle>
                                    <RStyle.RIcon src={receiverMarker} anchor={[0.5, 1]} />
                                </RStyle.RStyle>
                                <RPopup trigger={'hover'}>
                                    <div className='card'>
                                        <p className='card-body text-center mb-0'>
                                            {t("query.wtp.map_receiver")}
                                        </p>
                                    </div>
                                </RPopup>
                            </RFeature>}
                            {(info?.longitude && info?.latitude && props.wtp.receiverLocation) && <RFeature
                                key={"ConnectionLine"}
                                geometry={(() => new LineString([fromLonLat([info.longitude, info.latitude]), fromLonLat(extent.getCenter(transformExtent(new WKT().readGeometry(props.wtp.receiverLocation, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }).getExtent(), 'EPSG:3857', 'EPSG:4326')))]))()}
                            >
                                <RStyle.RStyle>
                                    <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                    <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                </RStyle.RStyle>
                            </RFeature>}
                        </RLayerVector>
                    </RMap>}
                    <Row className="mt-4">
                        {info?.photos && info?.photos.map((x, i) => <Col key={i}>
                            <a href={x} target={"_blank"} rel="noreferrer noopener"><Image style={{ maxHeight: "100px" }} thumbnail src={x} /></a>
                        </Col>)}
                    </Row>
                    <h6 className="text-primary text-start mt-4">{t("wtp.general.general.header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                            {t("wtp.general.general.authority")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{i18n.language === "en" ? info?.authorityTypeEn : info?.authorityType}{(info?.authorityType && info?.authority) ? " - " : ""}{i18n.language === "en" ? info?.authorityEn : info?.authority}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.general.general.funding")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.fundingProgram ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.general.general.construction_completed_on")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.constructionEndedOn ? (new Date(info.constructionEndedOn)).toLocaleDateString(i18n.language) : "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.general.general.operation_started_on")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.operationStartedOn ? (new Date(info.operationStartedOn)).toLocaleDateString(i18n.language) : "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.general.general.environmental_terms_expire_on")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.environmentalTermsExpireOn ? (new Date(info.environmentalTermsExpireOn)).toLocaleDateString(i18n.language) : "-"}</strong>
                        </Col>
                    </Row>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.extensions_header")}</h6>
                    <hr />
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th className="text-center">{t("wtp.general.upgrades.completion_year")}</th>
                                <th>{t("wtp.general.upgrades.work_title")}</th>
                                <th>{t("wtp.general.upgrades.funding_program")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!info?.extensions || info?.extensions.length === 0 ?
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.extensions.map((x: ExtensionsView, i: number) => <tr key={i}>
                                    <td className="text-center">{x.year}</td>
                                    <td>{x.title}</td>
                                    <td>{x.fundingProgram}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.agglomerations_header")}</h6>
                    <hr />
                    <ul>
                        <li>
                            <div>{t("query.wtp.agglomerations_info")}</div>
                        </li>
                    </ul>
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th>{t("wtp.waste_origin.served_sewage_agglomeration.code")}</th>
                                <th>{t("wtp.waste_origin.served_sewage_agglomeration.agglomeration")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_septage_agglomeration.population")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</th>
                                <th className="text-center">{t("settlements.sewage_network_service_percentage")} (%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!info?.sewageServedAgglomerations || info?.sewageServedAgglomerations.length === 0) ?
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.sewageServedAgglomerations.map((x: SewageServedSettlementsView, i: number) => <tr key={i}>
                                    <td>{x.code}</td>
                                    <td>{i18n.language === "en" ? x.nameEn : x.name}</td>
                                    <td className="text-center">{x.population?.toLocaleString(i18n.language)}</td>
                                    <td className="text-center">{i18n.language === "en" ? x.networkTypeEn : x.networkType}</td>
                                    <td className="text-center">{x.constructedNetworkPercentage?.toLocaleString(i18n.language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? "-"}</td>
                                </tr>)
                            }
                        </tbody>
                        {(info?.sewageServedAgglomerations && info?.sewageServedAgglomerations.length > 0) && <tfoot>
                            <tr>
                                <th colSpan={2} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{info?.sewageServedAgglomerations.reduce((partialSum: number, a: SewageServedSettlementsView) => partialSum + (a.population ?? 0), 0)?.toLocaleString(i18n.language)}</td>
                                <td></td>
                            </tr>
                        </tfoot>}
                    </Table>
                    <ul>
                        <li>
                            <div>{t("query.wtp.settlements_info")}</div>
                        </li>
                    </ul>
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th>{t("wtp.waste_origin.served_septage_agglomeration.agglomeration")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_septage_agglomeration.population")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!info?.sewageServedSettlements || info?.sewageServedSettlements.length === 0) ?
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.sewageServedSettlements.map((x: SewageServedSettlementsView, i: number) => <tr key={i}>
                                    <td>{i18n.language === "en" ? `${x.code} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.code} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`}</td>
                                    <td className="text-center">{x.population}</td>
                                    <td className="text-center">{i18n.language === "en" ? x.networkTypeEn : x.networkType}</td>
                                </tr>)
                            }
                        </tbody>
                        {(info?.sewageServedSettlements && info?.sewageServedSettlements.length > 0) && <tfoot>
                            <tr>
                                <th colSpan={1} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{info?.sewageServedSettlements.reduce((partialSum: number, a: SewageServedSettlementsView) => partialSum + (a.population ?? 0), 0)}</td>
                                <td></td>
                            </tr>
                        </tfoot>}
                    </Table>
                    <ul>
                        <li>
                            <div>{t("query.wtp.septage_info")}</div>
                        </li>
                    </ul>
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th>{t("wtp.waste_origin.served_septage_agglomeration.agglomeration")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_septage_agglomeration.population")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!info?.septageServedSettlements || info?.septageServedSettlements.length === 0) ?
                                <tr>
                                    <td colSpan={2} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.septageServedSettlements.map((x: SeptageServedSettlementsView, i: number) => <tr key={i}>
                                    <td>{i18n.language === "en" ? `${x.code} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.code} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`}</td>
                                    <td className="text-center">{x.population}</td>
                                </tr>)
                            }
                        </tbody>
                        {(info?.septageServedSettlements && info?.septageServedSettlements.length > 0) && <tfoot>
                            <tr>
                                <th colSpan={1} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{info?.septageServedSettlements.reduce((partialSum: number, a: SeptageServedSettlementsView) => partialSum + (a.population ?? 0), 0)}</td>
                            </tr>
                        </tfoot>}
                    </Table>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.industries_header")}</h6>
                    <hr />
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th>{t("wtp.waste_origin.served_industry_branches.annex")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_industry_branches.loadPE")}</th>
                                <th className="text-center">{t("wtp.waste_origin.served_industry_branches.loadKG")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!info?.servedIndustrialSectors || info?.servedIndustrialSectors.length === 0) ?
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.servedIndustrialSectors.map((x: ServedIndustrialSectorsView, i: number) => <tr key={i}>
                                    <td>{i18n.language === "en" ? x.nameEn : x.name}</td>
                                    <td className="text-center">{x.loadPE ? x.loadPE.toLocaleString(i18n.language) : "-"}</td>
                                    <td className="text-center">{x.loadKG ? x.loadKG.toLocaleString(i18n.language) : "-"}</td>
                                </tr>)
                            }
                        </tbody>
                        {(info?.servedIndustrialSectors && info?.servedIndustrialSectors.length > 0) && <tfoot>
                            <tr>
                                <th colSpan={1} className="text-end">{t("wtp.table.total")}</th>
                                <td className="text-center">{info?.servedIndustrialSectors.reduce((partialSum: number, a: ServedIndustrialSectorsView) => partialSum + (a.loadPE ?? 0), 0)}</td>
                                <td className="text-center">{info?.servedIndustrialSectors.reduce((partialSum: number, a: ServedIndustrialSectorsView) => partialSum + (a.loadKG ?? 0), 0)}</td>
                            </tr>
                        </tfoot>}
                    </Table>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.load_header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.capacity_header")}:
                        </Col>
                        <Col className="text-center">
                            <strong>{props.wtp.capacity?.toLocaleString(i18n.language)}</strong>
                        </Col>
                        <Col className="text-center">
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className="text-end">
                        </Col>
                        <Col className="text-center">
                            <u><strong>{t("wtp.incoming_load.incoming.mean")}</strong></u>
                        </Col>
                        <Col className="text-center">
                            <u><strong>{t("wtp.incoming_load.incoming.max")}</strong></u>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.incoming.total")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingTotalMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingTotalMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.incoming.sewerage")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingSewerageMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingSewerageMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.incoming.septage")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingSeptageMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.incomingSeptageMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.inflow_header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                        </Col>
                        <Col className="text-center">
                            <u><strong>{t("wtp.incoming_load.inflow.mean")}</strong></u>
                        </Col>
                        <Col className="text-center">
                            <u><strong>{t("wtp.incoming_load.inflow.max")}</strong></u>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.inflow.total")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowTotalMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowTotalMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.inflow.sewerage")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowSewerageMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowSewerageMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.incoming_load.inflow.septage")}
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowSeptageMean?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                        <Col className="text-center">
                            <strong>{info?.inflowSeptageMax?.toLocaleString(i18n.language) ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="text-primary text-start mt-4">{t("query.wtp.wastewater_treatment_header")}</h6>
                            <hr />
                            {(info?.wasteTreatmentMethod && info?.wasteTreatmentMethod.length > 0) ?
                                <ul>
                                    {info?.wasteTreatmentMethod?.map((x, i) => {
                                        let m = wasteTreatmentOptions.find(y => y.code === x);
                                        return <li key={i}>
                                            {(i18n.language === "en" ? m?.nameEn : m?.name)?.split('(').shift()}
                                            {x.includes("other") && info?.wasteTreatmentMethodDescription && <ul><li key={"other"}>{info.wasteTreatmentMethodDescription}</li></ul>}
                                        </li>;
                                    })}
                                </ul> : <ul><li>{t("wtp.table.no_data")}</li></ul>
                            }
                        </Col>
                        <Col>
                            <h6 className="text-primary text-start mt-4">{t("query.wtp.sludge_treatment_header")}</h6>
                            <hr />
                            {(info?.sludgeTreatmentMethod && info?.sludgeTreatmentMethod.length > 0) ?
                                <ul>
                                    {info?.sludgeTreatmentMethod?.map((x, i) => {
                                        let m = sludgeTreatmentOptions.find(y => y.code === x);
                                        return <li key={i}>
                                            {(i18n.language === "en" ? m?.nameEn : m?.name)?.split('(').shift()}
                                            {x.includes("other") && info?.sludgeTreatmentMethodDescription && <ul><li key={"other"}>{info.sludgeTreatmentMethodDescription}</li></ul>}
                                        </li>;
                                    })}
                                </ul> : <ul><li>{t("wtp.table.no_data")}</li></ul>
                            }
                        </Col>
                    </Row>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.disposal_header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.receiver_code")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.wtp.receiverCode}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.receiver_name")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{i18n.language === "en" ? props.wtp.receiverNameEn : props.wtp.receiverName}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.receiver_water_type")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.wtp.receiverWaterType ? t(`water_body.types.${props.wtp.receiverWaterType.toString()}`) : "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.receiver_type")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.wtp.receiverSensitive ? t("query.sensitive") : t("query.normal")}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6 className="text-primary text-start mt-4">{t("query.wtp.reuse_header")}</h6>
                            <hr />
                            <Row>
                                <Col className="text-end">
                                </Col>
                                <Col className="text-center">
                                    <u><strong>{t("wtp.reuse.supplies.supply")}</strong></u>
                                </Col>
                                <Col className="text-center">
                                    <u><strong>{t("wtp.reuse.supplies.area")}</strong></u>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                </Col>
                                <Col className="text-center">
                                    ({i18n.language === "en" ? "m3/year" : "m3/έτος"})
                                </Col>
                                <Col className="text-center">
                                    (km²)
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.table.total")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{(() => { let sum = info?.reuseSupplies.reduce((partialSum: number, a: ReuseSupplyView) => partialSum + (a.supply ?? 0), 0); return !sum ? "-" : sum; })()}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{(() => { let sum = info?.reuseSupplies.reduce((partialSum: number, a: ReuseSupplyView) => partialSum + (a.area ?? 0), 0); return !sum ? "-" : sum; })()}</strong>
                                </Col>
                            </Row>
                            {reuseOptions.map(x => {
                                let reuseSupply = info?.reuseSupplies.find(s => s.typeId === x.value);
                                return <Row key={x.value}>
                                    <Col className="text-end">
                                        {t(x.label)}
                                    </Col>
                                    <Col className="text-center">
                                        <strong>{reuseSupply?.supply ?? "-"}</strong>
                                    </Col>
                                    <Col className="text-center">
                                        <strong>{reuseSupply?.area ?? "-"}</strong>
                                    </Col>
                                </Row>;
                            })}
                        </Col>
                        <Col>
                            <h6 className="text-primary text-start mt-4">{t("query.wtp.sludge_disposal_header")}</h6>
                            <hr />
                            <Row>
                                <Col className="text-end">
                                </Col>
                                <Col className="text-center">
                                    <u><strong>{t("wtp.receiver.sludge_dry.dry_solid")}</strong></u>
                                </Col>
                                <Col className="text-center">
                                    <u><strong>{t("wtp.receiver.sludge_dry.solids_ratio")}</strong></u>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                </Col>
                                <Col className="text-center">
                                    ({t("wtp.receiver.sludge_dry.unit")})
                                </Col>
                                <Col className="text-center">
                                    (%)
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.table.total")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{calculateSludgeDryTotal(info)}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{calculateSludgeDryPercentage(info)}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.landfillD1")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryLandfillD1 ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryLandfillD1Ratio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.landfillD5")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryLandfillD5 ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryLandfillD5Ratio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.temp_storage")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryTempStorage ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryTempStorageRatio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.agriculture")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryAgriculture ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryAgricultureRatio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.energy")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryEnergy ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryEnergyRatio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.compost")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryCompost ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryCompostRatio ?? "-"}</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    {t("wtp.receiver.sludge_dry.other")}
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryOther ?? "-"}</strong>
                                </Col>
                                <Col className="text-center">
                                    <strong>{info?.sludgeDryOtherRatio ?? "-"}</strong>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {!info?.isVirtual && <>
                        <h6 className="text-primary text-start mt-4">{t("query.wtp.compliance_header")}</h6>
                        <hr />
                        <p>{t("query.wtp.compliance_info")}</p>
                        <ul><li><strong>{t("wtp.measurements.compliance_legislation")}</strong></li></ul>
                        {complianceLegislationResults.length > 0 && complianceLegislationResults.flatMap(x => x.results).length > 0 ?
                            <Table striped bordered hover size="sm" className="mt-2">
                                <thead>
                                    <tr>
                                        <th className="text-center">{t("Year")}</th>
                                        {arrayReorder(complianceLegislationResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i), 3, 4).map((x, i) => <th key={i} className="text-center">{samplingParameters.find(p => p.id === x)?.name}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        complianceLegislationResults.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)).map((x: CheckResult, i: number) => <tr key={i}>
                                            <td className="text-center">{x.year}</td>
                                            {arrayReorder(complianceLegislationResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i), 3, 4).map((y, i) => <td className="text-center">{getComplianceStatus(y ?? 0, x.results ?? [])}</td>)}
                                        </tr>)
                                    }
                                </tbody>
                            </Table> : <p>{t("wtp.table.no_data")}</p>}
                        {/* <ul><li><strong>{t("wtp.measurements.compliance_aepo")}</strong></li></ul>
                        {complianceAepoResults.length > 0 && complianceAepoResults.flatMap(x => x.results).length > 0 ? <Table striped bordered hover size="sm" className="mt-2">
                            <thead>
                                <tr>
                                    <th className="text-center">{t("query.wtp.year")}</th>
                                    {complianceAepoResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((x, i) => <th key={i} className="text-center">{samplingParameters.find(p => p.id === x)?.name}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    complianceAepoResults.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)).map((x: CheckResult, i: number) => <tr key={i}>
                                        <td className="text-center">{x.year}</td>
                                        {complianceAepoResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((y, i) => <td className="text-center">{getComplianceStatus(y ?? 0, x.results ?? [])}</td>)}
                                    </tr>)
                                }
                            </tbody>
                        </Table> : <p>{t("wtp.table.no_data")}</p>} */}
                        <Alert key="legend" variant="primary">
                            <Row>
                                <Col md="auto" className="d-flex align-items-center">
                                    <QuestionCircleFill size={20} />
                                </Col>
                                <Col className="d-flex flex-column justify-content-center">
                                    <p className="mb-1">- <Alert.Link as="a" target="_blank" href="uploads/KYA_5673_400_1997_ANNEX.pdf">{t("query.wtp.compliance.info.criteria")}</Alert.Link></p>
                                    <p className="mb-0">- <Alert.Link onClick={() => { setShowReuseHelp(false); setShowHelp(true); }}>{t("query.wtp.compliance.info.symbols")}</Alert.Link></p>
                                </Col>
                            </Row>
                        </Alert>
                    </>}
                    {info?.isReuse && <>
                        <h6 className="text-primary text-start mt-4">{t("query.wtp.compliance_reuse_header")}</h6>
                        <hr />
                        <p>{t("query.wtp.compliance_reuse_info")}</p>
                        {/* <ul><li><strong>{t("wtp.measurements.compliance_reuse_kya")}</strong></li></ul>
                        {complianceReuseKYAResults.length > 0 && complianceReuseKYAResults.flatMap(x => x.results).length > 0 ?
                            <Table striped bordered hover size="sm" className="mt-2">
                                <thead>
                                    <tr>
                                        <th className="text-center">{t("Year")}</th>
                                        {complianceReuseKYAResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((x, i) => <th key={i} className="text-center">{samplingParameters.find(p => p.id === x)?.name}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        complianceReuseKYAResults.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)).map((x: CheckResult, i: number) => <tr key={i}>
                                            <td className="text-center">{x.year}</td>
                                            {complianceReuseKYAResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((y, i) => <td className="text-center">{getComplianceStatus(y ?? 0, x.results ?? [])}</td>)}
                                        </tr>)
                                    }
                                </tbody>
                            </Table> : <p>{t("wtp.table.no_data")}</p>} */}
                        <ul><li><strong>{t("wtp.measurements.compliance_reuse_eu")}</strong></li></ul>
                        {complianceReuseEUResults.length > 0 && complianceReuseEUResults.flatMap(x => x.results).length > 0 ? <Table striped bordered hover size="sm" className="mt-2">
                            <thead>
                                <tr>
                                    <th className="text-center">{t("Year")}</th>
                                    {complianceReuseEUResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((x, i) => <td key={i} className="text-center">{samplingParameters.find(p => p.id === x)?.name}</td>)}
                                    <th className="text-center">{t("wtp.measurements.compliance_reuse.total_water_quality")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    complianceReuseEUResults.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)).map((x: CheckResult, i: number) => <tr key={i}>
                                        <td className="text-center">{x.year}</td>
                                        {complianceReuseEUResults.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i).map((y, i) => <td className="text-center">{getWaterQuality(y ?? 0, x.results ?? [])}</td>)}
                                        <td className="text-center"><strong>{getTotalWaterQuality(x.results ?? [])}</strong></td>
                                    </tr>)
                                }
                            </tbody>
                        </Table> : <p>{t("wtp.table.no_data")}</p>}
                        <Alert key="reuse_legend" variant="primary">
                            <Row>
                                <Col md="auto" className="d-flex align-items-center">
                                    <QuestionCircleFill size={20} />
                                </Col>
                                <Col className="d-flex flex-column justify-content-center">
                                    <p className="mb-1">- <Alert.Link as="a" target="_blank" href="uploads/%ce%95%ce%a0%ce%95%ce%9e%ce%97%ce%93%ce%97%ce%a3%ce%95%ce%99%ce%a3_%ce%93%ce%99%ce%91_%ce%a4%ce%99%ce%a3_%ce%95%ce%9b%ce%91%ce%a7%ce%99%ce%a3%ce%a4%ce%95%ce%a3_%ce%91%ce%a0%ce%91%ce%99%ce%a4%ce%97%ce%a3%ce%95%ce%99%ce%a3_%ce%a7%ce%a1%ce%97%ce%a3%ce%97%ce%a3_%ce%91%ce%9d%ce%91%ce%9a%ce%a4%ce%97%ce%9c%ce%95%ce%9d%ce%9f%ce%a5_%ce%9d%ce%95%ce%a1%ce%9f%ce%a5.pdf">{t("query.wtp.compliance_reuse.info.criteria")}</Alert.Link></p>
                                    <p className="mb-0">- <Alert.Link onClick={() => { setShowHelp(false); setShowReuseHelp(true); }}>{t("query.wtp.compliance_reuse.info.symbols")}</Alert.Link></p>
                                </Col>
                            </Row>
                        </Alert>
                    </>}
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.results_header")}</h6>
                    <hr />
                    <ul>
                        <li>
                            {t("query.wtp.measurements_info")}
                        </li>
                    </ul>
                    {!info?.isVirtual && <div className="w-100 text-center mb-2">
                        <Button size="sm" variant="secondary" onClick={e => { (e.target as any).blur(); props.onShowResults() }}>{t("query.wtp.measurements_button")}</Button>
                    </div>}
                    {info?.isReuse && <div className="w-100 text-center">
                        <Button size="sm" variant="secondary" onClick={e => { (e.target as any).blur(); props.onShowReuseResults() }}>{t("query.wtp.reuse_measurements_button")}</Button>
                    </div>}
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.info_header")}</h6>
                    <hr />
                    <Table striped bordered hover size="sm" className="mt-2">
                        <thead>
                            <tr>
                                <th>{t("wtp.files.additional_files.filename")}</th>
                                <th>{t("wtp.files.additional_files.title")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_type")}</th>
                                <th className="text-center">{t("wtp.files.additional_files.file_size")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!info?.files || info?.files.length === 0 ?
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        {t("wtp.table.no_data")}
                                    </td>
                                </tr> :
                                info?.files.map((x: FileView, i: number) => <tr key={i}>
                                    <td><a href={x.path} target={"_blank"} rel="noreferrer noopener">{x.originalFileName}</a></td>
                                    <td>{x.title}</td>
                                    <td className="text-center">{x.fileType}</td>
                                    <td className="text-center">{x.size}</td>
                                </tr>)
                            }
                        </tbody>
                    </Table>
                    <h6 className="text-primary text-start mt-4">{t("query.wtp.contact_header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_name")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactName ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_authority")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactAuthority ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_position")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactPosition ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_address")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactAddress ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_phone")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactPhone ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_mobile")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactMobile ?? "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.contact_email")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{info?.contactEmail ?? "-"}</strong>
                        </Col>
                    </Row>
                </>}
            </Modal.Body>
        </Modal>
        <Modal
            show={showHelp}
            onHide={() => setShowHelp(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: "small" }}
            backdrop={false}
            contentClassName="shadow-lg"
        >
            <Modal.Header closeButton><h5>{t("Help")}</h5></Modal.Header>
            <Modal.Body>
                <p>{t("query.wtp.compliance.info.symbols.info")}:</p>
                <Row className="mb-3">
                    <Col md="auto">✔️</Col>
                    <Col>{t("query.wtp.compliance.info.symbols.pass")}</Col>
                </Row>
                <Row className="mb-3">
                    <Col md="auto">❌</Col>
                    <Col>{t("query.wtp.compliance.info.symbols.fail")}</Col>
                </Row>
                <Row className="mb-3">
                    <Col md="auto">󠁼➖</Col>
                    <Col>{t("query.wtp.compliance.info.symbols.not_required")}</Col>
                </Row>
                <Row className="mb-3">
                    <Col md="auto">&nbsp;❔</Col>
                    <Col>{t("query.wtp.compliance.info.symbols.no_data")}</Col>
                </Row>
            </Modal.Body>
        </Modal>
        <Modal
            show={showReuseHelp}
            onHide={() => setShowReuseHelp(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: "small" }}
            backdrop={false}
            contentClassName="shadow-lg"
        >
            <Modal.Header closeButton><h5>{t("Help")}</h5></Modal.Header>
            <Modal.Body>
                <p>{t("query.wtp.compliance_reuse.info.symbols.info")}:</p>
                <Row className="mb-3">
                    <Col md="3" style={{ textAlign: "center" }}><strong>{i18n.language === "en" ? 'A / B / C / D' : 'Α / Β / Γ / Δ'}</strong></Col>
                    <Col>{t("query.wtp.compliance_reuse.info.symbols.data")}</Col>
                </Row>
                <Row className="mb-3">
                    <Col md="3" style={{ textAlign: "center" }}>&nbsp;❔</Col>
                    <Col>{t("query.wtp.compliance_reuse.info.symbols.no_data")}</Col>
                </Row>
            </Modal.Body>
        </Modal>
    </>
    );
}

const WastewaterTreatmentPlants: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const popup = useRef<any>([]);
    const [currentPopup, setCurrentPopup] = useState<RPopup>();
    const vectorLayer = useRef<RLayerVector>(null);
    const [wastewaterTreatmentPlants, setWastewaterTreatmentPlants] = useState<WastewaterTreatmentPlantQueryView[]>([]);
    const [infoModalShow, setInfoModalShow] = useState(false);
    const [resultsModalShow, setResultsModalShow] = useState(false);
    const [reuseResultsModalShow, setReuseResultsModalShow] = useState(false);
    const [selectedWTP, setSelectedWTP] = useState<WastewaterTreatmentPlantQueryView>();
    const [searchModalShow, setSearchModalShow] = useState(false);
    const [filter, setFilter] = useState("");
    const mapRef = createRef() as React.RefObject<RMap>;
    const filterInputRef = createRef() as React.RefObject<HTMLInputElement>;

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Wastewater Treatment Plants" });
    }, [loc.pathname]);

    useEffect(() => {
        let code = loc.search.substring(1);
        if (!code) return;
        let idx = wastewaterTreatmentPlants.findIndex(x => x.code === code);
        if (idx < 0) return;
        handleWTPClick(idx);
    }, [loc.search, wastewaterTreatmentPlants])

    useEffect(() => {
        getWastewaterTreatmentPlants();
    }, [])

    const getWastewaterTreatmentPlants = async () => {
        let wtps = await get('/query/wastewatertreatmentplants');
        if (response.ok) {
            wtps.forEach((wtp: WastewaterTreatmentPlantQueryView) => {
                wtp.nameNormalized = wtp.name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                wtp.nameEnNormalized = wtp.nameEn?.toLowerCase().normalize('NFD');
            });
            setWastewaterTreatmentPlants(wtps);
        }
    }

    const handleWTPClick = (index: number) => {
        popup.current.filter((x: any) => x.visible).forEach((el: any) => {
            el.hide();
        });
        setSearchModalShow(false);
        let wastewaterTreatmentPlant = wastewaterTreatmentPlants[index];
        if (!wastewaterTreatmentPlant) return;
        if (!wastewaterTreatmentPlant.longitude || !wastewaterTreatmentPlant.latitude) return;
        mapRef.current?.ol.getView()
            .animate(
                {
                    zoom: 6,
                },
                {
                    center: fromLonLat([wastewaterTreatmentPlant.longitude, wastewaterTreatmentPlant.latitude]),
                    zoom: 14,
                },
                () => {
                    popup.current[index].show();
                }
            );
    }

    const debounce = (func: Function, timeout: number = 300) => {
        let timer: NodeJS.Timeout;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const processChange = debounce((v: string) => setFilter(v.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")));

    return (
        <>
            <RMap
                className='full-screen-map with-space'
                initial={{ center: fromLonLat([23.70066804453946, 37.983956404442104]), zoom: 6 }}
                ref={mapRef}
                noDefaultControls
                noDefaultInteractions
            >
                <RControl.RZoom />
                <RControl.RZoomSlider />
                <RInteraction.RPinchZoom />
                <RInteraction.RDragPan />
                <RInteraction.RMouseWheelZoom condition={shiftKeyOnly} />
                <RInteraction.RDoubleClickZoom />
                <ROSM />
                <RLayerVector zIndex={11} ref={vectorLayer}>
                    {wastewaterTreatmentPlants.filter(x => x.longitude && x.latitude).map((x, i) => <RFeature
                        key={`Feature_${x.code}`}
                        geometry={new Point(fromLonLat([x.longitude!, x.latitude!]))}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            popup.current.forEach((element: any) => {
                                element?.hide();
                            });
                            popup.current[i]?.show();
                        }}
                    >
                        <RStyle.RStyle>
                            <RStyle.RCircle radius={x.capacity ? x.capacity >= 500000 ? 10 : x.capacity >= 250000 ? 9 : x.capacity >= 100000 ? 8 : x.capacity >= 50000 ? 7 : 6 : 6}>
                                <RStyle.RFill color={x.compliance ? "rgba(71,86,127, 0.5)" : "rgba(200, 50, 50, 0.5)"}></RStyle.RFill>
                                <RStyle.RStroke width={2} color={x.compliance ? "rgba(71,86,127, 1)" : "rgba(200, 50, 50, 1)"}></RStyle.RStroke>
                            </RStyle.RCircle>
                        </RStyle.RStyle>
                        <RPopup ref={el => popup.current[i] = el} trigger={'click'} className='example-overlay' autoPan>
                            <div className='card' style={{ borderColor: x.compliance ? "rgba(71,86,127, 0.5)" : "rgba(200, 50, 50, 0.5)" }}>
                                <p className='card-header' style={{ backgroundColor: x.compliance ? "rgba(71,86,127, 0.25)" : "rgba(200, 50, 50, 0.25)" }}>
                                    <strong>{i18n.language === "en" ? x.nameEn : x.name} - {x.code}</strong>
                                    <button type="button" className="btn btn-outline-secondary btn-sm py-0 float-end" onClick={() => popup.current[i]?.hide()}>X</button>
                                </p>
                                <p className='card-body text-center'>
                                    {t("query.wtp.administrative_region")}: <strong>{i18n.language === "en" ? x.administrativeRegionEn : x.administrativeRegion}</strong><br />
                                    {t("query.wtp.municipal")}: <strong>{i18n.language === "en" ? x.municipalEn : x.municipal}</strong><br />
                                    {t("query.wtp.water_district")}: <strong>{i18n.language === "en" ? x.riverBasinDistrictEn : x.riverBasinDistrict}</strong><br />
                                    {t("query.wtp.river_basin")}: <strong>{i18n.language === "en" ? x.riverBasinEn : x.riverBasin}</strong><br />
                                    {t("query.wtp.capacity")}: <strong>{x.capacity?.toLocaleString(i18n.language)}</strong><br />
                                    {t("query.wtp.year")}: <strong>{x.year}</strong><br />
                                    <Button size="sm" variant="link" onClick={() => { setSelectedWTP(x); setInfoModalShow(true); }}>{t("More")}</Button>
                                </p>
                            </div>
                        </RPopup>
                    </RFeature>)}
                </RLayerVector>
            </RMap>
            <div style={{ marginTop: "-40px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "right", right: "3em", padding: "5px 10px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                <p className="mb-0" style={{ fontSize: "small" }}><ExclamationCircle style={{ marginRight: "5px", marginTop: "-4px" }} /><strong>Hold shift + mouse wheel scroll to zoom</strong></p>
            </div>
            <div style={{ marginTop: "-120px", position: "relative", width: "fit-content", zIndex: 12, float: "right", right: "3em", padding: "10px 20px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                <p className="mb-2" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(71, 86, 127, 0.5)", border: "2px solid rgba(71, 86, 127, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.compliant")}</strong></p>
                <p className="mb-0" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(200, 50, 50, 0.5)", border: "2px solid rgba(200, 50, 50, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.non_compliant")}</strong></p>
            </div>
            <div style={{ marginTop: "-46px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "left", left: "8px" }}>
                <Button variant="secondary" onClick={() => setSearchModalShow(true)}>
                    <Search />
                </Button>
            </div>
            <WastewaterTreatmentPlantsModal
                show={infoModalShow}
                onHide={() => setInfoModalShow(false)}
                wtp={selectedWTP}
                onShowResults={() => { setInfoModalShow(false); setResultsModalShow(true); }}
                onShowReuseResults={() => { setInfoModalShow(false); setReuseResultsModalShow(true); }}
            />
            <ResultsModal
                show={resultsModalShow}
                onHide={() => { setResultsModalShow(false); setInfoModalShow(true); }}
                wtp={selectedWTP}
                reuse={false}
            />
            <ResultsModal
                show={reuseResultsModalShow}
                onHide={() => { setReuseResultsModalShow(false); setInfoModalShow(true); }}
                wtp={selectedWTP}
                reuse={true}
            />
            <Offcanvas
                show={searchModalShow}
                onHide={() => setSearchModalShow(false)}
                onExited={() => setFilter("")}
                onShow={() => filterInputRef.current?.focus()}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("query.wtp.search")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form.Floating>
                        <Form.Control
                            id="filter"
                            type="text"
                            onChange={e => processChange(e.target.value)}
                            placeholder=""
                            ref={filterInputRef}
                        />
                        <label htmlFor="filter">{t("query.wtp.name")}</label>
                    </Form.Floating>
                    <ListGroup variant="flush" className="mt-2" style={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
                        {wastewaterTreatmentPlants.map((x, i) => <ListGroup.Item key={i} hidden={!(x.nameNormalized?.includes(filter) || x.nameEnNormalized?.includes(filter))} action onClick={() => handleWTPClick(i)}><Form.Text as="strong">{i18n.language === "en" ? x.nameEn : x.name}</Form.Text><br /><Form.Text>{i18n.language === "en" ? x.name : x.nameEn}</Form.Text></ListGroup.Item>)}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default WastewaterTreatmentPlants;
