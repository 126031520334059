import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const WtpManageEnergyGreenhouseEmissions: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors } = useFormikContext();

    return (
        <>
            <h4>{t("wtp.energy_greenhouse_emissions.energy.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3 }}></Col>
                <Col md={{ span: 3 }}></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.energy_greenhouse_emissions.energy_consumption")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="energyConsumption"
                        name="energyConsumption"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).energyConsumption || ""}
                        isInvalid={(touched as any).energyConsumption && !!(errors as any).energyConsumption}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).energyConsumption}
                    </Form.Control.Feedback>
                </Col>

            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.energy_greenhouse_emissions.energy_consumption_per_cubic_meter")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="energyConsumptionPerCubicMeter"
                        name="energyConsumptionPerCubicMeter"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).energyConsumptionPerCubicMeter || ""}
                        isInvalid={(touched as any).energyConsumptionPerCubicMeter && !!(errors as any).energyConsumptionPerCubicMeter}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).energyConsumptionPerCubicMeter}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.energy_greenhouse_emissions.energy_from_renewable")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="energyFromRenewables"
                        name="energyFromRenewables"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).energyFromRenewables || ""}
                        isInvalid={(touched as any).energyFromRenewables && !!(errors as any).energyFromRenewables}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).energyFromRenewables}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.energy_greenhouse_emissions.energy_from_biogas")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="energyFromBiogas"
                        name="energyFromBiogas"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).energyFromBiogas || ""}
                        isInvalid={(touched as any).energyFromBiogas && !!(errors as any).energyFromBiogas}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).energyFromBiogas}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <hr></hr>
            <h4>{t("wtp.energy_greenhouse_emissions.greenhouse_emissions.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Col md={{ span: 3 }}></Col>
                <Col md={{ span: 3 }}></Col>
                <Col md={{ span: 3 }}></Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.energy_greenhouse_emissions.greenhouse_emissions")}
                </Form.Label>
                <Col md={{ span: 3 }}>
                    <Form.Control
                        id="greenhouseEmissions"
                        name="greenhouseEmissions"
                        className="text-center"
                        onBlur={handleChange}
                        defaultValue={(values as any).greenhouseEmissions || ""}
                        isInvalid={(touched as any).greenhouseEmissions && !!(errors as any).greenhouseEmissions}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).greenhouseEmissions}
                    </Form.Control.Feedback>
                </Col>
                <Col md={{ span: 3 }}>
                    <Form.Select
                        id="greenhouseEmissionsType"
                        name="greenhouseEmissionsType"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).greenhouseEmissionsType}
                        isInvalid={(touched as any).greenhouseEmissionsType && !!(errors as any).greenhouseEmissionsType}
                    >
                        <option key={"0"} value={0}>{t("wtp.energy_greenhouse_emissions.greenhouse_emissions_type.estimation")}</option>
                        <option key={"1"} value={1}>{t("wtp.energy_greenhouse_emissions.greenhouse_emissions_type.measurement")}</option>
                        <option key={"2"} value={2}>{t("wtp.energy_greenhouse_emissions.greenhouse_emissions_type.calculation")}</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).greenhouseEmissionsType}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        </>
    )
}

export default WtpManageEnergyGreenhouseEmissions;
