import { useContext, useEffect, useState, createRef, useRef } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Form, Modal, Row, Spinner, Table, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

import { fromLonLat, getPointResolution, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle, GeometryCollection, Point } from "ol/geom";
import WKT from 'ol/format/WKT';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../../components/BingMap";

import { ArrowCounterclockwise, Layers, PencilFill, PlusCircle, Save, SkipBackward, SquareFill, Trash, XCircle } from 'react-bootstrap-icons';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import useFetch from 'use-http';
import { AdministrativeRegion, Agglomeration, Authority, ElstatSettlement, PriorityCategory, RiverBasin, Settlement, SewageNetworkType } from '../../../data/Definitions';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';

let unique_id = 0;

const AgglomerationsEdit: React.FC = (props) => {
    const [agglomerationsData, setAgglomerationData] = useState<Agglomeration>();
    const [settlements, setSettlements] = useState<Settlement[]>();
    const [sewageNetworkTypes, setSewageNetworkTypes] = useState<SewageNetworkType[]>([]);
    const [priorityCategories, setPriorityCategories] = useState<PriorityCategory[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [addModalShow, setAddModalShow] = useState(false);
    const addSettlementFormRef = useRef<any>(null);
    const formikRef = useRef<FormikProps<Agglomeration>>(null);

    const [saving, setSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [savingError, setSavingError] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [drawing, setDrawing] = useState(false);
    const [geometry, setGeometry] = useState<Geometry | undefined>();
    const [showBing, setShowBing] = useState(false);
    const layersButton = <button>&#9776;</button>;
    const mapRef = createRef() as React.RefObject<RMap>;

    const { get, put, response, loading, error, cache } = useFetch('/api');

    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Edit Agglomeration" });
    }, [loc.pathname]);

    useEffect(() => {
        getAgglomerationData(id);
    }, [])

    // useEffect(() => {
    //     addShapesToMap(agglomerationsData?.settlements ?? []);
    // }, [agglomerationsData])

    const getAgglomerationData = async (code: string | undefined) => {
        if (!code) return;
        setLoadingError(false);
        let snts = await get('/wastewatertreatmentplant/sewagenetworktypes');
        if (response.ok) setSewageNetworkTypes(snts);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let pcs = await get('/wastewatertreatmentplant/prioritycategories');
        if (response.ok) setPriorityCategories(pcs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ss = await get('/settlements');
        if (response.ok) setSettlements(ss);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ad = await get(`/agglomerations/${id}`);
        if (response.ok) {
            let wkt = new WKT();
            let geomCollection = !ad.location ? new GeometryCollection(ad.settlements.map((x: Settlement) => wkt.readGeometry(x.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }))) : wkt.readGeometry(ad.location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
            let ext = geomCollection.getExtent();
            let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
            let lonLatCenter = extent.getCenter(lonLatExtent);
            if (lonLatCenter) {
                ad.longitude = lonLatCenter[0];
                ad.latitude = lonLatCenter[1];
                const geoData = await autoDetectGeo(lonLatCenter[0], lonLatCenter[1]);
                ad.riverBasinDistrict = geoData?.riverBasinDistrict;
                ad.administrativeRegion = geoData?.administrativeRegion;
                ad.riverBasin = geoData?.riverBasin;
                ad.municipal = geoData?.municipal;
                ad.riverBasinDistrictEn = geoData?.riverBasinDistrictEn;
                ad.administrativeRegionEn = geoData?.administrativeRegionEn;
                ad.riverBasinEn = geoData?.riverBasinEn;
                ad.municipalEn = geoData?.municipalEn;
            } else {
                ad.riverBasinDistrict = "";
                ad.riverBasin = "";
                ad.riverBasinDistrictEn = "";
                ad.riverBasinEn = "";
            }

            ad.population = ad.population ? ad.population : (ad.settlements?.reduce((partialSum: number, a: Settlement) => partialSum + a.population, 0) ?? 0);
            ad.area = ad.area ? ad.area : (ad.settlements?.reduce((partialSum: number, a: Settlement) => partialSum + a.area, 0) ?? 0);

            setAgglomerationData(ad);
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const reload = async () => {
        cache.delete(`url:/api/agglomerations/${id}||method:GET||body:`);
        await getAgglomerationData(id);
    }

    const saveData = async (values: Agglomeration) => {
        setSaving(true);
        let data = new FormData();
        values.location = `POINT (${(values as any).longitude} ${(values as any).latitude})`;
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (value === null) return;
            if (typeof value === 'object') {
                if (Array.isArray(value)) {
                    value.forEach((obj: Object, index: number) => {
                        Object.entries(obj).forEach(innerEntry => {
                            let [innerKey, innerValue] = innerEntry;
                            if (innerValue === null) return;
                            data.append(`${key}[][${index}][${innerKey}]`, innerValue);
                        });
                    });
                } else {
                    data.append(key, JSON.stringify(value));
                }
            } else {
                data.append(key, value);
            }
        });

        var res = await fetch('/api/agglomerations', {
            method: 'PUT',
            body: data,
        });
        if (res.status === 200) {
            setSavingError(false);
            setShowToast(true);
            cache.delete(`url:/api/agglomerations||method:GET||body:`);
            cache.delete(`url:/api/agglomerations?noshapes||method:GET||body:`);
            cache.delete(`url:/api/settlements||method:GET||body:`);
            cache.delete(`url:/api/settlements?noshapes||method:GET||body:`);
            cache.delete(`url:/api/agglomerations/${id}||method:GET||body:`);
            // reload();
        } else {
            if (res.redirected) {
                // navigate("/login");
            } else {
                setSavingError(true);
                setShowToast(true);
            }
        }
        setSaving(false);
    }

    const onSettlementsChange = (settlementId: string, add: boolean) => {
        if (!formikRef.current) return;
        if (!settlementId) return;

        let sets = (formikRef.current.values.settlements as Settlement[]);
        if (add) {
            let settlement = settlements?.find(x => x.id === settlementId);
            if (!settlement) return;
            sets.push(settlement);
        } else {
            sets = sets.filter(x => x.id !== settlementId);
        }

        formikRef.current.setFieldValue("settlements", [...sets]);
        formikRef.current.setFieldValue("area", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.area, 0));
        formikRef.current.setFieldValue("population", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.population, 0));
        formikRef.current.setFieldValue("equivalentPopulation", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.equivalentPopulation, 0));
        formikRef.current.setFieldValue("servedPopulation", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.servedPopulation, 0));
        formikRef.current.setFieldValue("connectedPopulation", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedPopulation, 0));
        formikRef.current.setFieldValue("constructedSewageNetworkArea", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkArea, 0));
        formikRef.current.setFieldValue("constructedSewageNetworkLength", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkLength, 0));
        formikRef.current.setFieldValue("connectedSewageNetworkArea", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkArea, 0));
        formikRef.current.setFieldValue("connectedSewageNetworkLength", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkLength, 0));
        formikRef.current.setFieldValue("pumpingStationCount", sets.reduce((partialSum: number, a: Settlement) => partialSum + a.pumpingStationCount, 0));

        if (sets.length === 0) {
            formikRef.current.setFieldValue("longitude", "");
            formikRef.current.setFieldValue("latitude", "");
            formikRef.current.setFieldValue("location", null);
            mapRef.current?.ol.getView().animate({ zoom: 5 }, { center: fromLonLat([23.209779819621144, 38.566884483911025]) });
            return;
        }

        let wkt = new WKT();
        let geomCollection = new GeometryCollection([...sets].map((x: Settlement) => wkt.readGeometry(x.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })));
        let ext = geomCollection.getExtent();
        let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
        let lonLatCenter = extent.getCenter(lonLatExtent);
        if (lonLatCenter) {
            formikRef.current.setFieldValue("longitude", lonLatCenter[0]);
            formikRef.current.setFieldValue("latitude", lonLatCenter[1]);
            formikRef.current.setFieldValue("location", `POINT (${lonLatCenter[0]} ${lonLatCenter[1]})`);
            autoDetectGeo(lonLatCenter[0], lonLatCenter[1]);
            mapRef.current?.ol.getView().animate({ center: extent.getCenter(ext) }, { zoom: 13 });
        } else {
            formikRef.current.setFieldValue("longitude", 23.209779819621144);
            formikRef.current.setFieldValue("latitude", 38.566884483911025);
            formikRef.current.setFieldValue("location", `POINT (38.566884483911025 23.209779819621144)`);
            formikRef.current.setFieldValue("riverBasinDistrict", "");
            formikRef.current.setFieldValue("riverBasin", "");
            formikRef.current.setFieldValue("riverBasinDistrictEn", "");
            formikRef.current.setFieldValue("riverBasinEn", "");
            formikRef.current.setFieldValue("riverBasinDistrictId", null);
            formikRef.current.setFieldValue("riverBasinId", null);
            mapRef.current?.ol.getView().animate({ zoom: 5 }, { center: fromLonLat([23.209779819621144, 38.566884483911025]) });
        }
    };

    const createLocation = (lng: number, lat: number) => {
        if (!lng) return;
        if (!lat) return;
        formikRef.current?.setFieldValue("location", `POINT (${lng} ${lat})`);
    }

    const autoDetectGeo = async (lng: number, lat: number) => {
        if (!lng) return;
        if (!lat) return;
        const resRB = await fetch(`/api/spatial/riverbasin?lng=${lng}&lat=${lat}`, { cache: "no-store" });
        const resAR = await fetch(`/api/spatial/administrativeRegion?lng=${lng}&lat=${lat}`, { cache: "no-store" });
        const resAU = await fetch(`/api/spatial/municipal?lng=${lng}&lat=${lat}`, { cache: "no-store" });
        let dt = {
            municipal: "",
            administrativeRegion: "",
            riverBasinDistrict: "",
            riverBasin: "",
            municipalEn: "",
            administrativeRegionEn: "",
            riverBasinDistrictEn: "",
            riverBasinEn: "",
            riverBasinDistrictId: null as number | null,
            riverBasinId: null as number | null,
            administrativeRegionId: null as number | null,
            municipalId: null as number | null,
        };

        if (resRB.status === 200) {
            const rb: RiverBasin = await resRB.json();
            formikRef.current?.setFieldValue("riverBasinDistrictId", rb.riverBasinDistrictId ?? null);
            formikRef.current?.setFieldValue("riverBasinId", rb.id ?? null);
            formikRef.current?.setFieldValue("riverBasinDistrict", rb.riverBasinDistrict?.name ?? "");
            formikRef.current?.setFieldValue("riverBasin", rb.name ?? "");
            formikRef.current?.setFieldValue("riverBasinDistrictEn", rb.riverBasinDistrict?.nameEn ?? "");
            formikRef.current?.setFieldValue("riverBasinEn", rb.nameEn ?? "");
            dt.riverBasinDistrictId = rb.riverBasinDistrictId ?? null;
            dt.riverBasinId = rb.id ?? null;
            dt.riverBasin = rb.name ?? "";
            dt.riverBasinDistrict = rb.riverBasinDistrict?.name ?? "";
            dt.riverBasinEn = rb.nameEn ?? "";
            dt.riverBasinDistrictEn = rb.riverBasinDistrict?.nameEn ?? "";
        } else {
            formikRef.current?.setFieldValue("riverBasinDistrictId", null);
            formikRef.current?.setFieldValue("riverBasinId", null);
            formikRef.current?.setFieldValue("riverBasinDistrict", "");
            formikRef.current?.setFieldValue("riverBasin", "");
            formikRef.current?.setFieldValue("riverBasinDistrictEn", "");
            formikRef.current?.setFieldValue("riverBasinEn", "");
        }

        if (resAR.status === 200) {
            const ar: AdministrativeRegion = await resAR.json();
            formikRef.current?.setFieldValue("administrativeRegionId", ar.id ?? null);
            formikRef.current?.setFieldValue("administrativeRegion", ar.name ?? "");
            formikRef.current?.setFieldValue("administrativeRegionEn", ar.nameEn ?? "");
            dt.administrativeRegionId = ar.id ?? null;
            dt.administrativeRegion = ar.name ?? "";
            dt.administrativeRegionEn = ar.nameEn ?? "";
        } else {
            formikRef.current?.setFieldValue("administrativeRegionId", null);
            formikRef.current?.setFieldValue("administrativeRegion", "");
            formikRef.current?.setFieldValue("administrativeRegionEn", "");
        }

        if (resAU.status === 200) {
            const au: Authority = await resAU.json();
            formikRef.current?.setFieldValue("municipalId", au.id ?? null);
            formikRef.current?.setFieldValue("municipal", au.name ?? "");
            formikRef.current?.setFieldValue("municipalEn", au.nameEn ?? "");
            dt.municipalId = au.id ?? null;
            dt.municipal = au.name ?? "";
            dt.municipalEn = au.nameEn ?? "";
        } else {
            formikRef.current?.setFieldValue("municipalId", null);
            formikRef.current?.setFieldValue("municipal", "");
            formikRef.current?.setFieldValue("municipalEn", "");
        }

        return dt;
    }

    const validationSchema = yup.object().shape({
        id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        area: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        population: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        equivalentPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        connectedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        constructedSewageNetworkArea: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        constructedSewageNetworkLength: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkShape: yup.string().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkArea: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkLength: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        // sewageNetworkTypeId: yup.number().integer(t("wtp.validation.non_integer")).required(t("wtp.validation.required")).positive(t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        pumpingStationCount: yup.number().integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        year: yup.number().positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).nullable().typeError(t("wtp.validation.type_error")),
        // priorityCategoryId: yup.number().required(t("wtp.validation.required")).oneOf(priorityCategories.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        location: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    return (
        <Container fluid className='menu-padding'>
            <ToastContainer className="p-3" position={'top-end'} containerPosition="fixed">
                <Toast
                    className="d-inline-block m-1"
                    bg={savingError ? 'danger' : 'success'}
                    key={0}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body className={'text-white'}>
                        {savingError ? t("wtp.save.error") : t("wtp.save.success")}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {!loading && !error && agglomerationsData && <Formik
                enableReinitialize
                initialValues={agglomerationsData}
                validationSchema={validationSchema}
                onSubmit={(values) => saveData(values)}
                innerRef={formikRef}
                onReset={reload}
            >
                {props => (
                    <>
                        <form noValidate onSubmit={props.handleSubmit} onReset={props.handleReset}>
                            <Row>
                                <Col className='position-relative'>
                                    <div style={{ minHeight: "calc(100vh - 382px)" }}>
                                        <Row className="mb-2">
                                            <h4>{t("agglomerations.header")}</h4>
                                            <Col md={{ span: 7 }}>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_name")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="name"
                                                            name="name"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).name || ""}
                                                            isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).name}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_name_english")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="nameEn"
                                                            name="nameEn"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).nameEn || ""}
                                                            isInvalid={(props.touched as any).nameEn && !!(props.errors as any).nameEn}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).nameEn}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_code")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="id"
                                                            name="id"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).id || ""}
                                                            isInvalid={(props.touched as any).id && !!(props.errors as any).id}
                                                            disabled
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).id}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.characterization")}</Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="isSensitive"
                                                            name="isSensitive"
                                                            value={(props.values as any).isSensitive === true ? "1" : "0"}
                                                            onChange={v => props.setFieldValue("isSensitive", v.target.value === "1" ? true : false)}
                                                            isInvalid={props.touched.isSensitive && !!props.errors.isSensitive}
                                                        >
                                                            <option key={0} value={"0"}>{t("query.normal")}</option>
                                                            <option key={1} value={"1"}>{t("query.sensitive")}</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.isSensitive}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("Settlements")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <ButtonGroup className='float-end btn-group-sm mb-2' aria-label="Toolbar">
                                                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                                                        </ButtonGroup>
                                                        <Table striped size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th className="align-middle text-center">{t("settlements.settlement_name")}</th>
                                                                    <th className="align-middle text-center">{t("settlements.settlement_code")}</th>
                                                                    <th className="align-middle text-center">{t("settlements.population")}</th>
                                                                    <th className="align-middle text-center">{t("settlements.equivalent_population")}</th>
                                                                    <th className="align-middle text-center">{t("settlements.settlement_area")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(props.values as any).settlements?.length === 0 ?
                                                                    <tr>
                                                                        <td colSpan={6} className="text-center">
                                                                            {t("wtp.table.no_data")}
                                                                        </td>
                                                                    </tr> :
                                                                    (props.values as any).settlements.map((x: Settlement, i: number) => <tr key={i}>
                                                                        <td className="text-center"><Button variant="link" style={{ color: "red" }} className="py-0" onClick={() => { onSettlementsChange(x.id, false); }}><Trash /></Button></td>
                                                                        <td className="align-middle text-center">{i18n.language === "en" ? x.nameEn : x.name}</td>
                                                                        <td className="align-middle text-center">{x.id}</td>
                                                                        <td className="align-middle text-center">{x.population}</td>
                                                                        <td className="align-middle text-center">{x.equivalentPopulation}</td>
                                                                        <td className="align-middle text-center">{x.area}</td>
                                                                    </tr>)
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_coordinates")}</Form.Label>
                                                    <Col md={{ span: 3 }} className="pe-0">
                                                        <Form.Label>{t("wtp.general.longitude")}</Form.Label>
                                                        <Form.Control
                                                            id="longitude"
                                                            name="longitude"
                                                            onChange={props.handleChange}
                                                            onBlur={(e) => { props.handleBlur(e); autoDetectGeo((props.values as any).longitude, (props.values as any).latitude); createLocation((props.values as any).longitude, (props.values as any).latitude); }}
                                                            value={(props.values as any).longitude || ""}
                                                            isInvalid={props.touched.location && !!props.errors.location}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.location}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col md={{ span: 3, offset: 1 }} className="pe-0">
                                                        <Form.Label>{t("wtp.general.latitude")}</Form.Label>
                                                        <Form.Control
                                                            id="latitude"
                                                            name="latitude"
                                                            onChange={props.handleChange}
                                                            onBlur={(e) => { props.handleBlur(e); autoDetectGeo((props.values as any).longitude, (props.values as any).latitude); createLocation((props.values as any).longitude, (props.values as any).latitude); }}
                                                            value={(props.values as any).latitude || ""}
                                                            isInvalid={props.touched.location && !!props.errors.location}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.municipal")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.municipal")}
                                                            value={(i18n.language === "en" ? (props.values as any).municipalEn : (props.values as any).municipal) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.administrative_region")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.administrative_region")}
                                                            value={(i18n.language === "en" ? (props.values as any).administrativeRegionEn : (props.values as any).administrativeRegion) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.river_basin")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.river_basin")}
                                                            id="riverBasin"
                                                            name="riverBasin"
                                                            value={(i18n.language === "en" ? props.values.riverBasinEn : props.values.riverBasin) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.water_district")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.water_district")}
                                                            id="riverBasinDistrict"
                                                            name="riverBasinDistrict"
                                                            value={(i18n.language === "en" ? props.values.riverBasinDistrictEn : props.values.riverBasinDistrict) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="area"
                                                            name="area"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).area || ""}
                                                            isInvalid={(props.touched as any).area && !!(props.errors as any).area}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).area}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="population"
                                                            name="population"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).population || ""}
                                                            isInvalid={(props.touched as any).population && !!(props.errors as any).population}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).population}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.equivalent_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="equivalentPopulation"
                                                            name="equivalentPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).equivalentPopulation || ""}
                                                            isInvalid={(props.touched as any).equivalentPopulation && !!(props.errors as any).equivalentPopulation}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).equivalentPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.population_density")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => { let res = (parseFloat((props.values as any).equivalentPopulation) / (parseFloat((props.values as any).area) * 10)); return (res && !isNaN(res) && isFinite(res)) && isFinite(res) ? res.toFixed(2) : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.served_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="servedPopulation"
                                                            name="servedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulation || ""}
                                                            isInvalid={(props.touched as any).servedPopulation && !!(props.errors as any).servedPopulation}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).servedPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_service_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            id="servedPopulationPercentage"
                                                            name="servedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).servedPopulationPercentage && !!(props.errors as any).servedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).servedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).servedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.connected_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedPopulation"
                                                            name="connectedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulation || ""}
                                                            isInvalid={(props.touched as any).connectedPopulation && !!(props.errors as any).connectedPopulation}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_connected_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            id="connectedPopulationPercentage"
                                                            name="connectedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).connectedPopulationPercentage && !!(props.errors as any).connectedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).connectedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).connectedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.constructed_sewage_network_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkArea"
                                                            name="constructedSewageNetworkArea"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            isInvalid={(props.touched as any).constructedSewageNetworkArea && !!(props.errors as any).constructedSewageNetworkArea}
                                                            value={(props.values as any).constructedSewageNetworkArea || ""}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).constructedSewageNetworkArea}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkArea, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.constructed_sewage_network_length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkLength"
                                                            name="constructedSewageNetworkLength"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            isInvalid={(props.touched as any).constructedSewageNetworkLength && !!(props.errors as any).constructedSewageNetworkLength}
                                                            value={(props.values as any).constructedSewageNetworkLength || ""}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).constructedSewageNetworkLength}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkLength, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.connected_sewage_network_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkArea"
                                                            name="connectedSewageNetworkArea"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            isInvalid={(props.touched as any).connectedSewageNetworkArea && !!(props.errors as any).connectedSewageNetworkArea}
                                                            value={(props.values as any).connectedSewageNetworkArea || ""}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkArea, 0)}
                                                            disabled
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedSewageNetworkArea}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.connected_sewage_network_length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkLength"
                                                            name="connectedSewageNetworkLength"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            isInvalid={(props.touched as any).connectedSewageNetworkLength && !!(props.errors as any).connectedSewageNetworkLength}
                                                            value={(props.values as any).connectedSewageNetworkLength || ""}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedSewageNetworkLength}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkLength, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.pumping_station_count")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="pumpingStationCount"
                                                            name="pumpingStationCount"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).pumpingStationCount || ""}
                                                            isInvalid={(props.touched as any).pumpingStationCount && !!(props.errors as any).pumpingStationCount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).pumpingStationCount}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.pumpingStationCount, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="sewageNetworkTypeId"
                                                            name="sewageNetworkTypeId"
                                                            value={(props.values as any).sewageNetworkTypeId ?? 0}
                                                            onChange={v => props.setFieldValue("sewageNetworkTypeId", v.target.value)}
                                                            isInvalid={props.touched.sewageNetworkTypeId && !!props.errors.sewageNetworkTypeId}
                                                        >
                                                            <option key={"none"} value={0}>{t("wtp.waste_origin.served_sewage_agglomeration.modal.select_network_type")}</option>
                                                            {sewageNetworkTypes.map(x => <option key={x.id} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.sewageNetworkTypeId}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                {/* <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>
                                                        {t("wtp.general.general.priority_category")}
                                                    </Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="priorityCategoryId"
                                                            name="priorityCategoryId"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).priorityCategoryId}
                                                            isInvalid={(props.touched as any).priorityCategoryId && !!(props.errors as any).priorityCategoryId}
                                                        >
                                                            <option key={0} value={0}>{t("agglomerations.select_priority")}</option>
                                                            {priorityCategories.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).priorityCategoryId}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group> */}
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.characterization_year")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="year"
                                                            name="year"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).year || ""}
                                                            isInvalid={(props.touched as any).year && !!(props.errors as any).year}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).year}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.files.additional_files.public")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Select
                                                            id="isPublic"
                                                            name="isPublic"
                                                            value={(props.values as any).isPublic ?? "False"}
                                                            onChange={v => props.setFieldValue("isPublic", v.target.value)}
                                                            isInvalid={props.touched.isPublic && !!props.errors.isPublic}
                                                        >
                                                            <option key={"no"} value={"False"}>{t("no")}</option>
                                                            <option key={"yes"} value={"True"}>{t("yes")}</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.isPublic}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col md={{ span: 5 }}>
                                                <div style={{ width: "100%", height: "500px" }}>
                                                    <RMap
                                                        className='w-100 h-100'
                                                        initial={((props.values as any).longitude && (props.values as any).latitude) ? { center: fromLonLat([(props.values as any).longitude, (props.values as any).latitude]), zoom: 13 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                                                        ref={mapRef}
                                                    >
                                                        <RControl.RCustom className="m-2">
                                                            <Button variant="secondary" onClick={() => { !drawing && setGeometry(undefined); setDrawing(!drawing); }}>
                                                                <PencilFill />
                                                            </Button>
                                                        </RControl.RCustom>
                                                        <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                                            <ROSM properties={{ label: "Map" }} />
                                                            <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                                                        </RControl.RLayers>
                                                        <RLayerVector
                                                            zIndex={11}
                                                            onAddFeature={(e: BaseEvent) => {
                                                                let source = e.target as VectorSource<Geometry>;
                                                                if (!source?.forEachFeatureAtCoordinateDirect) {
                                                                    return;
                                                                }

                                                                if (!drawing) return;

                                                                let feats = source.getFeatures();
                                                                if (feats.length > 0) {
                                                                    let geom = feats.filter(x => !x.getProperties().type)[0]?.getGeometry();
                                                                    if (geom) {
                                                                        let wkt = new WKT();
                                                                        let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                                                        props.setFieldValue("location", wktGeom);
                                                                        let ext = geom.getExtent();
                                                                        var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                                                        var center = extent.getCenter(lonLatExtent);
                                                                        if (center) {
                                                                            props.setFieldValue("longitude", center[0]);
                                                                            props.setFieldValue("latitude", center[1]);
                                                                            autoDetectGeo(center[0], center[1]);
                                                                        }
                                                                        source.clear();
                                                                        setDrawing(false);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {props.values.settlements.map(x => x.shape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "shape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 200, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(0, 200, 0, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            {props.values.settlements.map(x => x.constructedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.constructedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "constructedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(255, 60, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            {props.values.settlements.map(x => x.connectedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.connectedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "connectedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 138, 255, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={new Point(fromLonLat([parseFloat(props.values.longitude), parseFloat(props.values.latitude)]))}
                                                                properties={{ type: "location" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RCircle radius={10}>
                                                                        <RStyle.RFill color="rgba(200, 50, 50, 0.5)"></RStyle.RFill>
                                                                        <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                    </RStyle.RCircle>
                                                                </RStyle.RStyle>
                                                            </RFeature>
                                                            {drawing && <RInteraction.RDraw
                                                                type={"Point"}
                                                            />}
                                                        </RLayerVector>
                                                    </RMap>
                                                    <Row className='justify-content-center'>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(200, 50, 50, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "50%", marginRight: "8px" }} /><Form.Text muted>{t("agglomerations.agglomeration_coordinates")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 200, 0, 0.5)" style={{ border: "2px solid rgba(0, 200, 0, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.settlement_limits")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(255, 60, 0, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.constructed_sewage_network")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 138, 255, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.connected_sewage_network")}</Form.Text></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ButtonGroup className='position-fixed bottom-0 end-0 me-2 mb-2 p-2' style={{ backgroundColor: "#00000010" }} aria-label="Toolbar">
                                        <Button variant="success" type="submit" className="d-flex align-items-center justify-content-center m-0" disabled={saving}>{saving ? <><Spinner variant="light" animation="border" size="sm" className="me-2" /> {t("wtp.button.saving")}</> : <><Save className="me-2"></Save> {t("wtp.button.save")}</>}</Button>
                                        <Button variant="danger" type="reset" className="d-flex align-items-center justify-content-center m-0" disabled={saving}><ArrowCounterclockwise className="me-2"></ArrowCounterclockwise> {t("wtp.button.reset")}</Button>
                                        {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Button variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => navigate('/agglomerations/index')} disabled={saving}><SkipBackward className="me-2"></SkipBackward> {t("wtp.button.back")}</Button>}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </form>
                    </>)}
            </Formik>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                show={addModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("agglomerations.add_settlement")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            settlementId: "",
                        }}
                        validationSchema={yup.object().shape({
                            settlementId: yup.string().required(t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
                        })}
                        onSubmit={(v) => { onSettlementsChange(v.settlementId, true); setAddModalShow(false); }}
                    >
                        {props => <Form noValidate ref={addSettlementFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.waste_origin.served_sewage_settlement.settlement") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={((settlements ?? []).map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }]))}
                                        placeholder={t("wtp.waste_origin.served_sewage_settlement.settlement")}
                                        id="id"
                                        name="id"
                                        onChange={v => props.setFieldValue("settlementId", v?.value)}
                                        value={((settlements ?? []).map(x => { return { value: x.id, label: i18n.language === "en" ? `${x.id} - ${x.nameEn}, ${x.municipalEn}, ${x.administrativeRegionEn}` : `${x.id} - ${x.name}, ${x.municipal}, ${x.administrativeRegion}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }])).find(x => x.value === props.values.settlementId)}
                                        className={'react-select ' + (!props.values.settlementId ? 'is-invalid' : 'is-valid')}
                                        classNamePrefix={'react-select'}
                                        inputValue={inputValue}
                                        menuIsOpen={inputValue.length >= 2}
                                        onInputChange={(value) => setInputValue(value)}
                                        filterOption={(opt, inp) => {
                                            let option: string = `${opt.label} ${opt.value}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                            return option.indexOf(input) !== -1;
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.settlementId}
                                    </Form.Control.Feedback>
                                    {props.values.settlementId === "0" && <Form.Control.Feedback type="invalid">
                                        {t("wtp.validation.no_select")}
                                    </Form.Control.Feedback>}
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addSettlementFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </Container >
    );
}

export default AgglomerationsEdit;
