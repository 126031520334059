import { Button, ButtonGroup, Container, Form, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../state/State';
import { Definitions, Language } from '../data/Definitions';
import useFetch from 'use-http';

const Header: React.FC<{ showMenu: boolean, setShowMenu: Dispatch<SetStateAction<boolean>> }> = ({ showMenu, setShowMenu }) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const [offset, setOffset] = useState(0);
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [logo, setLogo] = useState("");
    const [menuOpen, setMenuOpen] = useState<boolean>(showMenu);
    const [currentSnapshot, setCurrentSnapshot] = useState<string>("");
    const { cache, get, response } = useFetch();

    const getNavbarClass = () => {
        if (loc.pathname === "/") {
            return (offset > 0 ? " scrolled" : "");
        }
        else {
            return "inner-page-nav";
        }
    }

    const getCurrentSnapshot = async () => {
        const val = await get("/api/snapshots/current");
        if (response.ok) {
            setCurrentSnapshot(val);
        }
    }

    useEffect(() => {
        setShowMenu(menuOpen);
    }, [menuOpen])

    useEffect(() => {
        setMenuOpen(showMenu);
    }, [showMenu])

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        getCurrentSnapshot();
        async function getLogo() {
            let response = await fetch("/api/site/logo", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                let imageInfo = await response.json();
                setLogo(imageInfo.media.publicUrl.replace("~", ""));
            }
        }

        getLogo();
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleClose = () => setMenuOpen(false)

    const signOut = async () => {
        await fetch('/api/account/signout');
        cache.delete("url:/api/wastewatertreatmentplant||method:GET||body:");
        context.dispatch({
            type: "LOGOUT",
        });
        handleClose();
    }

    return (<header>
        <Navbar className={"main-nav " + getNavbarClass()} fixed="top" expand={false}>
            <Container>
                <Navbar.Brand as={Link} to="/" className={`${loc.pathname !== "/" && "d-none"} d-md-block`}>
                    <img src={logo} width="auto" height="93" />
                </Navbar.Brand>
                {currentSnapshot.length > 0 ? <div className='current-snapshot'>{t("snapshots.current_snapshot")}: {currentSnapshot}</div> : <></>}
                <Navbar.Text as={"h1"} className='flex-grow-1' style={{ width: "min-content" }}>{t(context.state.mainAppTitle)}</Navbar.Text>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} onClick={toggleMenu} />
            </Container>
            <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-false`}
                aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                placement="end"
                show={menuOpen}
                onHide={handleClose}
            >
                <Offcanvas.Header closeButton>
                    <ButtonGroup>
                        <Button variant={i18n.resolvedLanguage === "en" ? "primary" : "secondary"} onClick={() => { i18n.changeLanguage("en"); if (loc.pathname.indexOf("/announcements/") >= 0 || loc.pathname.indexOf("/pages/") >= 0) navigate("/"); }}>EN</Button>
                        <Button variant={i18n.resolvedLanguage === "el" ? "primary" : "secondary"} onClick={() => { i18n.changeLanguage("el"); if (loc.pathname.indexOf("/announcements/") >= 0 || loc.pathname.indexOf("/pages/") >= 0) navigate("/"); }}>ΕΛ</Button>
                    </ButtonGroup>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {context.state.loggedIn && <>{context.state.userInfo && <>
                        {context.state.userInfo.username && <div className='text-muted fw-bold'>{context.state.userInfo.username}</div>}
                        {context.state.userInfo.email && <div className='text-muted fw-bold'>{context.state.userInfo.email}</div>}
                    </>}
                        <Link className='text-muted d-block' to="" onClick={signOut}><small>{t('logout')}</small></Link><hr></hr></>}
                    {!context.state.loggedIn && <><Link className='text-muted d-block' to="/login" onClick={toggleMenu}><small>{t('login')}</small></Link><hr></hr></>}
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {context.state.loggedIn && <>
                            {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? [
                                <Nav.Link key={11} className="text-primary fw-bold" as={Link} to="/wastewatertreatmentplants/index" onClick={toggleMenu}>{t("Wastewater Treatment Plants")}</Nav.Link>,
                                <Nav.Link key={8} className="text-primary fw-bold" as={Link} to="/settlements/index" onClick={toggleMenu}>{t('nav_menu.settlements')}</Nav.Link>,
                                <Nav.Link key={9} className="text-primary fw-bold" as={Link} to="/agglomerations/index" onClick={toggleMenu}>{t('nav_menu.agglomerations')}</Nav.Link>,
                                // <Nav.Link key={10} as={Link} to="agglomerations/settings" >{t('nav_menu.settings')}</Nav.Link>,
                            ] :
                                <Nav.Link key={11} className="text-primary fw-bold" as={Link} to="/wastewatertreatmentplants/index" onClick={toggleMenu}>{t("My W.T.P.")}</Nav.Link>
                                // context.state.userInfo?.wtps.map(x => <Nav.Link key={x.id} className="text-primary fw-bold" as={Link} to={`/wastewatertreatmentplants/view/${x.id}`} onClick={toggleMenu}>{`${i18n.language === "en" ? x.nameEn : x.name} (${x.code})`}</Nav.Link>)
                            }
                            {context.state.loggedIn && context.state.userInfo?.snapshotInstance && context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 &&
                                <Nav.Link key={12} className="text-primary fw-bold" as={Link} to="/snapshots" onClick={toggleMenu}>{t('nav_menu.snapshots')}</Nav.Link>}
                            {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "sysadmin") !== -1 && <a className='nav-link text-primary fw-bold' href='/manager' target={"_blank"} rel="noopener noreferrer" onClick={toggleMenu}>{t('manager')}</a>}
                            {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "sysadmin") !== -1 && <Nav.Link key={15} className="text-primary fw-bold" as={Link} to="/data" onClick={toggleMenu}>{t("admin.data")}</Nav.Link>}
                            <hr />
                        </>}
                        <Nav.Link key={1} as={Link} to="/" onClick={toggleMenu}>{t('Home')}</Nav.Link>
                        {/* <Nav.Link key={2} as={Link} to="/login">{context.state.loggedIn ? t('logout') : t('login')}</Nav.Link> */}
                        <Nav.Link key={3} as={Link} to="/wtp" onClick={toggleMenu}>{t('wtp')}</Nav.Link>
                        <Nav.Link key={4} as={Link} to="/agglomerations" onClick={toggleMenu}>{t('agglomerations')}</Nav.Link>
                        <Nav.Link key={5} as={Link} to="/queries" onClick={toggleMenu}>{t('queries')}</Nav.Link>
                        <Nav.Link key={6} as={Link} to="/announcements" onClick={toggleMenu}>{t('announcements')}</Nav.Link>
                        <Nav.Link key={7} as={Link} to={`/pages/${Definitions.contactPageSlug[i18n.resolvedLanguage as Language]}`} onClick={toggleMenu}>{t('contact')}</Nav.Link>
                    </Nav>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
        {/* <Navbar variant="dark" bg="dark" expand="lg">
            <Container fluid>
                <Navbar.Collapse>
                    <Nav>
                        <Nav.Link href="#">{t('Home')}</Nav.Link>
                        <Nav.Link href="#">{t('wtp')}</Nav.Link>
                        <Nav.Link href="#">{t('agglomerations')}</Nav.Link>
                        <Nav.Link href="#">{t('queries')}</Nav.Link>
                        <Nav.Link href="#">{t('announcements')}</Nav.Link>
                        <Nav.Link href="#">{t('contact')}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                </Navbar.Collapse>
                <NavbarToggle />
            </Container>
        </Navbar> */}
    </header>
    );
}

export default Header;