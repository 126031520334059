import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Definitions, Language } from '../data/Definitions';
import { blockBuilder } from '../components/BlockBuilder';
import { GenericPiranhaPage } from '../models/PiranhaModels';
import { useQuery } from '../models/Utilities';
import { AppContext } from '../state/State';

const AnnouncementView: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const { slug } = useParams();
    const context = useContext(AppContext);
    const query = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState<GenericPiranhaPage | null>(null);

    const loadContent = async () => {
        const newsPageId = Definitions.newsPageSlug[i18n.resolvedLanguage as Language];
        const response = await fetch(`/api/posts/${slug}?blog=${newsPageId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (response.status === 200) {
            const p = await response.json() as GenericPiranhaPage;
            context.dispatch({ type: "SetMainTitleAction", title: p.title });
            setPage(p);
        } else if (response.status === 401) {
            navigate("/login", { state: { returnUrl: location.pathname } });
        } else if (response.status === 404) {
            navigate("/NotFound");
        }
    }

    useEffect(() => {
        loadContent();
    }, [i18n.resolvedLanguage, query]);

    return (
        <Container className='menu-padding'>
            <Row>
                <Col>
                    {!page && <>
                        <span className="placeholder col-6"></span>
                        <span className="placeholder w-75"></span>
                        <span className="placeholder" style={{ width: "25%" }}></span>
                    </>}
                    {page && page.blocks && page.blocks.map(x => blockBuilder(x))}
                </Col>
            </Row>
        </Container >
    );
}

export default AnnouncementView;
