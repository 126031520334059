import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../state/State';
import { useLocation } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Forgot password" });
    }, [loc.pathname]);

    const request = async (e: React.FormEvent) => {
        if (!e) return;
        e.preventDefault();
        let formData = new FormData(e.target as HTMLFormElement);
        let data = await fetch('/api/account/forgotpassword', {
            method: 'POST',
            body: formData,
        });
        if (data.status === 200) {
            setShowAlert(true);
        }
    }

    return (
        <div className="form-signin-container">
            <form className="form-signin w-100 m-auto text-center" onSubmit={(ev) => request(ev)}>
                {showAlert && <div className="alert alert-success">
                    {t("The link has been sent, please check your email to reset your password.")}
                </div>}
                <h1 className="h3 mb-3 fw-normal">{t("Forgot password")}</h1>
                <div className="form-floating mb-3">
                    <input name="username" type="text" className="form-control" placeholder={t("SSW ID")} id="username" required onFocus={() => setShowAlert(false)} />
                    <label>{t("SSW ID")}</label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" type="submit">{t("Send request")}</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
