export const Definitions = {
    homePageSlug: {
        en: 'home-page',
        el: 'home-page-el'
    },
    contactPageSlug: {
        en: 'contact',
        el: 'contact-el'
    },
    newsPageSlug: {
        en: 'news',
        el: 'news-el'
    },
    piranhaHtmlBlockType: "Piranha.Extend.Blocks.HtmlBlock, Piranha"
}

export type Language = "en" | "el";

export const fixMediaUrl = (url: string | null | undefined): string => {
    if (!url || url === "" || url === null || url === undefined || url.length === 1) {
        return "";
    }

    return url.substring(1);
}

// export enum PointType {
//     In = 0,
//     Out = 1,
// };

export enum DocumentTypes {
    EnvironmentalTerms = 0,
    SensitiveReceiverFEK = 1,
    Images = 2,
    Additional = 3,
    RiskManagementPlans = 4,
    ReuseNoCompliance = 5,
};

export enum WaterBodyType {
    Lake = 0,
    River = 1,
    Sea = 2,
    GroundWater = 3,
    Transitional = 4,
};

export enum IndustryState {
    "wtp.industry_characteristics.state.active" = 0,
    "wtp.industry_characteristics.state.inactive" = 1,
    "wtp.industry_characteristics.state.temporary_inactive" = 2,
};

export enum OriginType {
    "wtp.waste_origin.served_industry_branches.modal.origin_type.sewage_network" = 0,
    "wtp.waste_origin.served_industry_branches.modal.origin_type.independent_pipeline" = 1,
    "wtp.waste_origin.served_industry_branches.modal.origin_type.tanker_truck" = 2,
};

export enum WastewaterTreatmentPlantType {
    Urban = 0,
    Industrial = 1,
    Reuse = 2,
};

export enum ComplianceStatus {
    Fail = 0,
    Pass = 1,
    NotRequired = 2,
    NoData = 3,
};

export enum ReuseSupplyType {
    Irrigation = 0,
    // LimitedIrrigation = 1,
    UndergroundAquifersEnrichment = 2,
    UrbanGreen = 3,
    UrbanUse = 4,
    IndustrialUse = 5,
    Other = 6,
};

export enum IrrigationReuseSupplyType {
    Unlimited = 0,
    Limited = 1,
};

export enum UndergroundAquifersEnrichmentReuseSupplyType {
    Drilling = 0,
    Filtration = 1,
};

export enum CultivationCategory {
    "wtp.reuse.cultivation_category.a" = 0,
    "wtp.reuse.cultivation_category.b" = 1,
    "wtp.reuse.cultivation_category.c" = 2,
    "wtp.reuse.cultivation_category.d" = 3,
};

export enum WaterQualityCategory {
    "wtp.reuse.water_quality_category.a" = 0,
    "wtp.reuse.water_quality_category.b" = 1,
    "wtp.reuse.water_quality_category.c" = 2,
    "wtp.reuse.water_quality_category.d" = 3,
};

export enum QueryReuseSupplyType {
    Irrigation = 0,
    FiltrationUndergroundAquifersEnrichment = 1,
    DrillingUndergroundAquifersEnrichment = 2,
    UrbanGreen = 3,
    UrbanUse = 4,
    IndustryUse = 5,
    Other = 6,
};

export interface WastewaterTreatementPlant {
    riverBasinDistrictId: number,
    administrativeRegionId: number,
    municipalId: number | null,
    authorityText: string | null,
    authorityId: number | null,
    authorityTypeId: number | null,
    iedCategoryId: number,
    eprtr: number,
    eprtrCode: string | null,
    extensions: WastewaterTreatementPlantExtension[],
    agglomerations: Agglomeration[],
    settlements: Settlement[],
    septageServedSettlements: SeptageServedSettlement[],
    industrialSectorsServed: WastewaterTreatementPlantIndustrialSectorsServed[],
    files: WastewaterTreatementPlantFile[],
    samplingPoints: WastewaterTreatementPlantSamplingPoint[],
    environmentalPermits: WastewaterTreatementPlantEnvironmentalPermit[],
    catchment: WastewaterTreatementPlantCatchment | null,
    catchmentId: number | null,
    dischargePointId: number | null,
    dischargePoint: WastewaterTreatementPlantDischargePoint | null,
    industryId: number | null,
    industry: WastewaterTreatementPlantIndustry | null,
    reuseId: number | null,
    reuse: WastewaterTreatementPlantReuse | null,
    id: number,
    name: string | null,
    nameEn: string | null,
    code: string | null,
    lastUpdatedOn: string | null,
    shape: string | null,
    latitude: number | null,
    longitude: number | null,
    fundingProgram: string | null,
    constructionEndedOn: string | null,
    operationStartedOn: string | null,
    environmentalTermsExpireOn: string | null,
    complexSamplerInflow: string | null,
    complexSamplerOutflow: string | null,
    staffChiefChemLab: string | null,
    septageTanksPerDaySummer: number | null,
    septageTanksPerDayWinter: number | null,
    capacity: number | null,
    inflowTotalMean: number | null,
    inflowTotalMax: number | null,
    inflowSewerageMean: number | null,
    inflowSewerageMax: number | null,
    inflowSeptageMean: number | null,
    inflowSeptageMax: number | null,
    incomingTotalMean: number | null,
    incomingTotalMax: number | null,
    incomingSewerageMean: number | null,
    incomingSewerageMax: number | null,
    incomingSeptageMean: number | null,
    incomingSeptageMax: number | null,
    wasteTreatmentMethod: string | null,
    disinfectionChlorination: boolean | null,
    disinfectionUltraviolet: boolean | null,
    disinfectionOzonation: boolean | null,
    otherTreatmentDescription: string | null,
    sludgeTreatmentMethod: string | null,
    othersludgeTreatmentDescription: string | null,
    operationalProblems: string | null,
    isIdle: boolean | null,
    idleReason: string | null,
    problemsSolution: string | null,
    costForecast: string | null,
    receiverCode: string | null,
    receiverName: string | null,
    receiverDescription: string | null,
    receiverCategoryId: number | null,
    receiverTypeId: number | null,
    receiverLatitude: number | null,
    receiverLongitude: number | null,
    receiverRemarks: string | null,
    reuseTotal: number | null,
    reuseIrrigation: number | null,
    reuseIndustry: number | null,
    reuseOther: number | null,
    reuseRemarks: string | null,
    sludgeDryLandfillD1: number | null,
    sludgeDryLandfillD1Ratio: number | null,
    sludgeDryLandfillD5: number | null,
    sludgeDryLandfillD5Ratio: number | null,
    sludgeDryTempStorage: number | null,
    sludgeDryTempStorageRatio: number | null,
    sludgeDryAgriculture: number | null,
    sludgeDryAgricultureRatio: number | null,
    sludgeDryEnergy: number | null,
    sludgeDryEnergyRatio: number | null,
    sludgeDryCompost: number | null,
    sludgeDryCompostRatio: number | null,
    sludgeDryOtherDescription: string | null,
    sludgeDryOther: number | null,
    sludgeDryOtherRatio: number | null,
    sludgeDryRemarks: string | null,
    measurementsRemarks: string | null,
    envPermEffluentStdBOD: number | null,
    envPermEffluentStdCOD: number | null,
    envPermEffluentStdSS: number | null,
    envPermEffluentStdTN: number | null,
    envPermEffluentStdTP: number | null,
    envPermEffluentStdNO3: number | null,
    filesToUpload: any[],
    generalRemarks: string | null,
    isVirtual: boolean | null,
}

export interface WastewaterTreatementPlantExtension {
    id: number,
    title: string | null,
    fundingProgram: string | null,
    budget: string | null,
    completionYear: number | null,
    constructionCost: number | null,
    constructionDuration: number | null,
}

export interface WastewaterTreatementPlantFile {
    id: number,
    title: string | null,
    path: string | null,
    originalFileName: string | null,
    fileType: string | null,
    size: number | null,
    public: boolean | null,
    documentType: DocumentTypes,
    added: boolean | null,
}

export interface WastewaterTreatementPlantSamplingPoint {
    id: number,
    samplingDate: string | null,
    samplingPointTypeId: number,
    isReuse: boolean,
    samples: WastewaterTreatementPlantSamplingPointParameter[],
}

export interface WastewaterTreatementPlantEnvironmentalPermit {
    id: number,
    dateIssued: string | null,
    dateExpired: string | null,
    samplingPointTypeId: number,
    comments: string | null,
    limits: WastewaterTreatementPlantEnvironmentalPermitLimit[],
}

export interface WastewaterTreatementPlantSamplingPointParameter {
    id: number,
    name: string,
    value: number,
}

export interface WastewaterTreatementPlantEnvironmentalPermitLimit {
    id: number,
    name: string,
    minSampleCount: number,
    limitValue: number,
    compliancePercentage: number,
}

export interface WastewaterTreatementPlantIndustrialSectorsServed {
    industryBranchId: number,
    loadPE: number,
    loadKG: number,
    originType: number,
}

export interface WastewaterTreatementPlantCatchment {
    id: number,
    code: string | null,
    name: string | null,
    nameEn: string | null,
}

export interface WastewaterTreatementPlantDischargePoint {
    id: number,
    code: string | null,
    name: string | null,
    nameEn: string | null,
    waterBody: WastewaterTreatementPlantWaterBody | null,
    location: any,
}

export interface WastewaterTreatementPlantIndustry {
    id: number,
    name: string,
    industryBranchId: number,
    state: IndustryState,
    codePlant: number | null,
    operationalPeriodStart: string | null,
    operationalPeriodEnd: string | null,
    stakodId: string,
    rawmaterialType: string,
    rawmaterial: number | null,
    water: number | null,
    energy: number | null,
    productionType: string,
    production: number | null,
    loadEntering: number | null,
}

export interface WastewaterTreatementPlantReuse {
    id: number,
    name: string,
    activityBranch: string,
    activityBranchText: string,
    state: IndustryState,
    landIrrigation: boolean,
    aerosolizationHazard: boolean,
    limitedCapacityIslandicArea: boolean,
    capacity: number | null,
    rReuseWaterComments: string,
    hazardComments: string,
    continuousTurbidityCheck: string,
    ReuseSupplies: WastewaterTreatmentPlantReuseSupply[],
}

export interface WastewaterTreatmentPlantReuseSupply {
    id: number,
    type: ReuseSupplyType,
    supply: number,
    area: number,
    waterBodyId: number,
    cultivationCategory: CultivationCategory,
    irrigationMethod: number,
    comments: string,
    irrigationType: number,
    aquifersEnrichmentType: number,
    waterQualityCategory: number,
    cultivationDescription: string,
    latitude?: number,
    longitude?: number,
    shape?: string,
    industrialUseWaterCooling?: number,
    industrialUseNonWaterCooling?: number,
    suitabilityCheck?: boolean,
}

export interface WastewaterTreatementPlantWaterBody {
    id: number,
    code: string | null,
    name: string | null,
    nameEn: string | null,
    type: number | null,
    isSensitive: boolean | null,
    a7: boolean | null,
    nvz: boolean | null,
}

export interface WastewaterTreatementPlantTreatmentMethod {
    code: string | null,
    name: string | null,
    nameEn: string | null,
}

export interface RiverBasinDistrict {
    id: number,
    name: string | null,
    nameEn: string | null,
    code: string | null,
}

export interface RiverBasin {
    id: number,
    name: string | null,
    nameEn: string | null,
    code: string | null,
    shape: string | null,
    riverBasinDistrictId: number,
    riverBasinDistrict: RiverBasinDistrict,
    catchments: Catchment[],
    waterBodies: WaterBody[],
}

export interface Catchment {
    id: number,
    name: string | null,
    nameEn: string | null,
    code: string | null,
    shape: string | null,
    riverBasinId: number,
}

export interface WaterBody {
    id: number,
    name: string | null,
    nameEn: string | null,
    code: string | null,
    shape: string | null,
    riverBasinId: number,
    catchmentId: number | null,
    type: WaterBodyType,
    isSensitive: boolean | null,
    a7: boolean | null,
    nvz: boolean | null,
}

export interface AdministrativeRegion {
    id: number,
    name: string | null,
    nameEn: string | null,
    notes: string | null,
}

export interface SamplingPointType {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface AuthorityType {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface Authority {
    id: number,
    name: string | null,
    nameEn: string | null,
    authorityTypeId: number,
    administrativeRegionId: number | null,
}

export interface IndustryBranch {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface SewageNetworkType {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface SeptageServedSettlement {
    id: string,
    population: number,
}

export interface Agglomeration {
    id: string,
    name: string | null,
    nameEn: string | null,
    location: string | null,
    area: number,
    population: number,
    equivalentPopulation: number,
    servedPopulation: number,
    connectedPopulation: number,
    servedPopulationPercentage?: number | null,
    connectedPopulationPercentage?: number | null,
    constructedSewageNetworkArea: number,
    constructedSewageNetworkLength: number,
    connectedSewageNetworkArea: number,
    connectedSewageNetworkLength: number,
    sewageNetworkTypeId: number | null | undefined,
    pumpingStationCount: number,
    priorityCategoryId: number | null,
    isSensitive: boolean,
    isPublic: string,
    year: number | null,
    settlements: Settlement[],
    longitude: string,
    latitude: string,
    administrativeRegion: string | null,
    municipal: string | null,
    administrativeRegionEn: string | null,
    municipalEn: string | null,
    riverBasin: string | null,
    riverBasinEn: string | null,
    riverBasinDistrict: string | null,
    riverBasinDistrictEn: string | null,
    administrativeRegionId: number | null,
    riverBasinId: number | null,
    municipalId: number | null,
    riverBasinDistrictId: number | null,
    wtpCode?: string,
    wtpName?: string,
    wtpNameEn?: string,
}

export interface SamplingParameter {
    id: number,
    name: string,
    unit: string | null,
    description: string | null,
}

export interface Stakod {
    id: string,
    description: string,
    associatedIndustryBranchId: number | null,
}

export interface IedCategory {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface PriorityCategory {
    id: number,
    name: string | null,
    nameEn: string | null,
}

export interface Settlement {
    id: string,
    name: string,
    nameEn: string,
    municipal?: string,
    administrativeRegion?: string,
    municipalEn?: string,
    administrativeRegionEn?: string,
    shape: string | null,
    area: number,
    population: number,
    equivalentPopulation: number,
    servedPopulation: number,
    connectedPopulation: number,
    servedPopulationPercentage?: number | null,
    connectedPopulationPercentage?: number | null,
    constructedSewageNetworkShape: string | null,
    constructedSewageNetworkArea: number,
    constructedSewageNetworkLength: number,
    connectedSewageNetworkShape: string | null,
    connectedSewageNetworkArea: number,
    connectedSewageNetworkLength: number,
    sewageNetworkTypeId: number | null,
    pumpingStationCount: number,
    agglomerationId?: string,
    agglomerationName?: string,
    agglomerationNameEn?: string,
    location?: string,
}

export interface ElstatSettlement {
    codeOik: string,
    nameOik: string,
    namefOik: string,
    latitude: number,
    longitude: number,
    population: number,
    nameGdiam: string,
    namefGdiam: string,
    nameOta: string,
    namefOta: string,
}

export interface WastewaterTreatementPlantUser {
    id: string,
    userName: string,
    email: string,
    fullName: string,
    lastLoginOn: string,
}

export interface WastewaterTreatementPlantReporter {
    id: number,
    name: string,
    nameEn: string,
    code: string,
    userId: string,
    isPublic: string,
    type: WastewaterTreatmentPlantType,
    samplingPointTypes: SamplingPointType[],
}

export interface WastewaterTreatmentPlantQueryView {
    code?: string,
    name?: string,
    nameEn?: string,
    nameNormalized?: string,
    nameEnNormalized?: string,
    latitude?: number,
    longitude?: number,
    priority?: string,
    priorityEn?: string,
    administrativeRegion?: string,
    administrativeRegionEn?: string,
    municipal?: string,
    municipalEn?: string,
    riverBasin?: string,
    riverBasinEn?: string,
    riverBasinDistrict?: string,
    riverBasinDistrictEn?: string,
    year?: number,
    compliance?: boolean,
    sludgeTreatmentMethod?: string,
    wasteTreatmentMethod?: string,
    capacity?: number,
    reuse?: boolean,
    reuseMethods?: WastewaterTreatmentPlantQueryViewSupplyMethod[],
    receiverCode?: string,
    receiverName?: string,
    receiverNameEn?: string,
    receiverSensitive?: boolean,
    receiverWaterType?: number,
    receiverLocation?: string,
}
export interface WastewaterTreatmentPlantQueryViewSupplyMethod {
    type: QueryReuseSupplyType,
    supply: number,
}

export interface WastewaterTreatmentPlantInfoView {
    isReuse?: boolean,
    isVirtual?: boolean,
    longitude?: number,
    latitude?: number,
    shape?: string,
    authorityType?: string,
    authorityTypeEn?: string,
    authority?: string,
    authorityEn?: string,
    fundingProgram?: string,
    constructionEndedOn?: string,
    operationStartedOn?: string,
    environmentalTermsExpireOn?: string,
    inflowTotalMean?: number,
    inflowTotalMax?: number,
    inflowSewerageMean?: number,
    inflowSewerageMax?: number,
    inflowSeptageMean?: number,
    inflowSeptageMax?: number,
    incomingTotalMean?: number,
    incomingTotalMax?: number,
    incomingSewerageMean?: number,
    incomingSewerageMax?: number,
    incomingSeptageMean?: number,
    incomingSeptageMax?: number,
    sludgeDryLandfillD1?: number,
    sludgeDryLandfillD1Ratio?: number,
    sludgeDryLandfillD5?: number,
    sludgeDryLandfillD5Ratio?: number,
    sludgeDryTempStorage?: number,
    sludgeDryTempStorageRatio?: number,
    sludgeDryAgriculture?: number,
    sludgeDryAgricultureRatio?: number,
    sludgeDryEnergy?: number,
    sludgeDryEnergyRatio?: number,
    sludgeDryCompost?: number,
    sludgeDryCompostRatio?: number,
    sludgeDryOther?: number,
    sludgeDryOtherRatio?: number,
    contactName?: string,
    contactAuthority?: string,
    contactPosition?: string,
    contactAddress?: string,
    contactPhone?: string,
    contactMobile?: string,
    contactEmail?: string,
    wasteTreatmentMethod?: string[],
    sludgeTreatmentMethod?: string[],
    wasteTreatmentMethodDescription?: string,
    sludgeTreatmentMethodDescription?: string,
    extensions: ExtensionsView[],
    reuseSupplies: ReuseSupplyView[],
    servedIndustrialSectors: ServedIndustrialSectorsView[],
    septageServedSettlements: SeptageServedSettlementsView[],
    sewageServedAgglomerations: SewageServedSettlementsView[],
    sewageServedSettlements: SewageServedSettlementsView[],
    photos?: string[],
    files?: FileView[],
}

export interface FileView {
    title?: string,
    path?: string,
    originalFileName?: string,
    fileType?: string,
    size?: number,
}

export interface ExtensionsView {
    year?: number,
    title?: string,
    fundingProgram?: string,
}

export interface ReuseSupplyView {
    typeId?: number,
    supply?: number,
    area?: number,
}

export interface SeptageServedSettlementsView {
    code?: string,
    name?: string,
    nameEn?: string,
    administrativeRegion?: string,
    administrativeRegionEn?: string,
    municipal?: string,
    municipalEn?: string,
    population?: number,
}

export interface SewageServedSettlementsView extends SeptageServedSettlementsView {
    networkType?: string,
    networkTypeEn?: string,
    constructedNetworkPercentage?: number,
}

export interface ServedIndustrialSectorsView {
    name?: string,
    nameEn?: string,
    loadPE?: number,
    loadKG?: number,
}

export interface WastewaterTreatmentPlantsResultView {
    samplingDate?: string,
    samplingPointTypeId?: number,
    samples?: ResultSampleView[],
}

export interface ResultSampleView {
    samplingParameterId?: number,
    value?: number,
}

export interface WastewaterTreatmentPlantsSamplingPointTypeView {
    id?: number,
    name?: string,
    nameEn?: string,
}

export interface AgglomerationQueryView {
    code?: string,
    name?: string,
    nameEn?: string,
    nameNormalized?: string,
    nameEnNormalized?: string,
    location?: string,
    priority?: string,
    priorityEn?: string,
    administrativeRegion?: string,
    administrativeRegionEn?: string,
    municipal?: string,
    municipalEn?: string,
    riverBasin?: string,
    riverBasinEn?: string,
    riverBasinDistrict?: string,
    riverBasinDistrictEn?: string,
    year?: number,
    population?: number,
    area?: number,
    compliance?: boolean,
    servedPopulationPercentage?: number | null,
    connectedPopulationPercentage?: number | null,
    servedPopulation?: number,
    connectedPopulation?: number,
    constructedSewageNetworkArea?: number,
    constructedSewageNetworkLength?: number,
    connectedSewageNetworkArea?: number,
    connectedSewageNetworkLength?: number,
    sewageNetworkType?: string,
    sewageNetworkTypeEn?: string,
    wtpCode?: string,
    wtpName?: string,
    wtpNameEn?: string,
    receiverSensitive?: boolean,
    isSensitive?: boolean,
}

export interface CheckResult {
    year?: number,
    results?: ParameterCheckResult[],
}

export interface ParameterCheckResult {
    parameterId?: number,
    complianceStatus?: ComplianceStatus,
    reason?: string,
}

export interface ReuseCheckResult {
    year?: number,
    results?: ReuseParameterCheckResult[],
}

export interface ReuseParameterCheckResult {
    parameterId?: number,
    waterQuality?: number | null,
    reason?: string,
}

export interface Deya {
    id: number,
    name: string,
    nameEn: string,
}

export interface SensitiveReceiver {
    id: number,
    name: string,
    nameEn: string,
    code: string,
    type: WaterBodyType,
    waterBodies: number[],
}

export const flatten = (ob: any) => {
    let result = {};

    for (const i in ob) {
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flatten(ob[i]);
            for (const j in temp) {

                (result as any)[i + '.' + j] = (temp as any)[j];
            }
        }
        else {
            (result as any)[i] = ob[i];
        }
    }
    return result;
};

export const reference = {
    "priorityCategoryId": {
        page: "general",
        caption: "wtp.general.general.priority_category",
    },
    "iedCategoryId": {
        page: "general",
        caption: "wtp.general.general.ied_category",
    },
    "eprtrCode": {
        page: "general",
        caption: "wtp.general.general.eprtr_code",
    },
    "riverBasinDistrictId": {
        page: "general",
        caption: "wtp.general.general.river_basin_district",
    },
    "riverBasinId": {
        page: "general",
        caption: "wtp.general.general.catchment",
    },
    "catchmentId": {
        page: "general",
        caption: "wtp.general.general.catchment",
    },
    "authorityTypeId": {
        page: "general",
        caption: "wtp.general.general.authority_type",
    },
    "authorityId": {
        page: "general",
        caption: "wtp.general.general.authority",
    },
    "administrativeRegionId": {
        page: "general",
        caption: "wtp.general.general.administrative_region",
    },
    "municipalId": {
        page: "general",
        caption: "settlements.municipal",
    },
    "latitude": {
        page: "general",
        caption: "wtp.general.longitude",
    },
    "longitude": {
        page: "general",
        caption: "wtp.general.latitude",
    },
    "authority": {
        page: "general",
        caption: "wtp.general.general.authority",
    },
    "fundingProgram": {
        page: "general",
        caption: "wtp.general.general.funding",
    },
    "constructionEndedOn": {
        page: "general",
        caption: "wtp.general.general.construction_completed_on",
    },
    "operationStartedOn": {
        page: "general",
        caption: "wtp.general.general.operation_started_on",
    },
    "environmentalTermsExpireOn": {
        page: "general",
        caption: "wtp.general.general.environmental_terms_expire_on",
    },
    "complexSamplerInflow": {
        page: "general",
        caption: "wtp.general.lab.complex_inflow_sampler",
    },
    "complexSamplerOutflow": {
        page: "general",
        caption: "wtp.general.lab.complex_outflow_sampler",
    },
    "staffChiefChemLab": {
        page: "general",
        caption: "wtp.general.lab.chief",
    },
    "reuse.activityBranchText": {
        page: "general",
        caption: "wtp.general.reuse_activity_branch",
    },
    "reuse.activityBranch": {
        page: "general",
        caption: "wtp.general.reuse_activity_branch_name",
    },
    "reuse.state": {
        page: "general",
        caption: "wtp.general.reuse_state",
    },
    "septageTanksPerDay": {
        page: "incoming-load",
        caption: "field",
    },
    "capacity": {
        page: "incoming-load",
        caption: "wtp.incoming_load.capacity.wtp_capacity",
    },
    "inflowSewerageMean": {
        page: "incoming-load",
        caption: "wtp.incoming_load.inflow.sewerage",
    },
    "inflowSewerageMax": {
        page: "incoming-load",
        caption: "wtp.incoming_load.inflow.sewerage",
    },
    "inflowSeptageMean": {
        page: "incoming-load",
        caption: "wtp.incoming_load.inflow.septage",
    },
    "inflowSeptageMax": {
        page: "incoming-load",
        caption: "wtp.incoming_load.inflow.septage",
    },
    "incomingSewerageMean": {
        page: "incoming-load",
        caption: "wtp.incoming_load.incoming.sewerage",
    },
    "incomingSewerageMax": {
        page: "incoming-load",
        caption: "wtp.incoming_load.incoming.sewerage",
    },
    "incomingSeptageMean": {
        page: "incoming-load",
        caption: "wtp.incoming_load.incoming.septage",
    },
    "incomingSeptageMax": {
        page: "incoming-load",
        caption: "wtp.incoming_load.incoming.septage",
    },
    "wasteTreatmentMethod": {
        page: "processing",
        reusePage: "reuse",
        caption: "wtp.treatment.method",
    },
    "otherTreatmentDescription": {
        page: "processing",
        reusePage: "reuse",
        caption: "wtp.treatment.other_treatment",
    },
    "sludgeTreatmentMethod": {
        page: "processing",
        caption: "wtp.treatment.method",
    },
    "otherSludgeTreatmentDescription": {
        page: "processing",
        caption: "wtp.treatment.other_treatment",
    },
    "operationalProblems": {
        page: "processing",
        caption: "wtp.treatment.remarks.operational_problems",
    },
    "isIdle": {
        page: "processing",
        caption: "wtp.treatment.remarks.idle",
    },
    "idleReason": {
        page: "processing",
        caption: "wtp.treatment.remarks.idle_reason",
    },
    "problemsSolution": {
        page: "processing",
        caption: "wtp.treatment.remarks.problems_solution",
    },
    "costForecast": {
        page: "processing",
        caption: "wtp.treatment.remarks.costForecast",
    },
    "receiverRemarks": {
        page: "disposal",
        caption: "wtp.receiver.receiver.remarks",
    },
    "reuseIrrigation": {
        page: "disposal",
        caption: "wtp.receiver.reuse.irrigation",
    },
    "reuseIndustry": {
        page: "disposal",
        caption: "wtp.receiver.reuse.industry",
    },
    "reuseOther": {
        page: "disposal",
        caption: "wtp.receiver.reuse.other",
    },
    "reuseRemarks": {
        page: "disposal",
        caption: "wtp.receiver.reuse.remarks",
    },
    "sludgeDryLandfillD1": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.landfillD1",
    },
    "sludgeDryLandfillD1Ratio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.landfillD1",
    },
    "sludgeDryLandfillD5": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.landfillD5",
    },
    "sludgeDryLandfillD5Ratio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.landfillD5",
    },
    "sludgeDryTempStorage": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.temp_storage",
    },
    "sludgeDryTempStorageRatio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.temp_storage",
    },
    "sludgeDryAgriculture": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.agriculture",
    },
    "sludgeDryAgricultureRatio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.agriculture",
    },
    "sludgeDryEnergy": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.energy",
    },
    "sludgeDryEnergyRatio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.energy",
    },
    "sludgeDryCompost": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.compost",
    },
    "sludgeDryCompostRatio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.compost",
    },
    "sludgeDryOtherDescription": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.other",
    },
    "sludgeDryOther": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.other",
    },
    "sludgeDryOtherRatio": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.other",
    },
    "sludgeDryRemarks": {
        page: "disposal",
        caption: "wtp.receiver.sludge_dry.remarks",
    },
    "measurementsRemarks": {
        page: "measurements",
        caption: "wtp.measurements.sampling_remarks.header",
    },
    "generalRemarks": {
        page: "comments",
        caption: "wtp.comments.comments",
    },
    "industry.name": {
        page: "industry",
        caption: "wtp.industry_characteristics.industry_name",
    },
    "industry.state": {
        page: "industry",
        caption: "wtp.industry_characteristics.industry_state",
    },
    "industry.codePlant": {
        page: "industry",
        caption: "wtp.industry_characteristics.industry_code",
    },
    "industry.operationalPeriodStart": {
        page: "industry",
        caption: "wtp.industry_characteristics.operational_period_start",
    },
    "industry.operationalPeriodEnd": {
        page: "industry",
        caption: "wtp.industry_characteristics.operational_period_end",
    },
    "industry.stakodId": {
        page: "industry",
        caption: "wtp.industry_characteristics.stakod",
    },
    "industry.rawmaterialType": {
        page: "industry",
        caption: "wtp.industry_characteristics.raw_material_type",
    },
    "industry.rawmaterial": {
        page: "industry",
        caption: "wtp.industry_characteristics.raw_material",
    },
    "industry.water": {
        page: "industry",
        caption: "wtp.industry_characteristics.water",
    },
    "industry.energy": {
        page: "industry",
        caption: "wtp.industry_characteristics.energy",
    },
    "industry.productionType": {
        page: "industry",
        caption: "wtp.industry_characteristics.production_type",
    },
    "industry.production": {
        page: "industry",
        caption: "wtp.industry_characteristics.production",
    },
    "industry.industryBranchId": {
        page: "industry",
        caption: "wtp.industry_characteristics.industry_branch",
    },
    "industry.loadEntering": {
        page: "industry",
        caption: "wtp.industry_characteristics.load_entering",
    },
    "energyConsumption": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.energy_consumption",
    },
    "energyConsumptionPerCubicMeter": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.energy_consumption_per_cubic_meter",
    },
    "energyFromRenewables": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.energy_from_renewable",
    },
    "energyFromBiogas": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.energy_from_biogas",
    },
    "greenhouseEmissions": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.greenhouse_emissions",
    },
    "greenhouseEmissionsType": {
        page: "energy-greenhouse-emissions",
        caption: "wtp.energy_greenhouse_emissions.greenhouse_emissions",
    },
};