import { useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Pencil, PlusCircle, Search } from 'react-bootstrap-icons';
import useFetch from 'use-http';
import { RiverBasinDistrict, SensitiveReceiver, WaterBody, WaterBodyType } from '../../data/Definitions';
import { ConfirmationPopover } from '../../components/ConfirmationPopover';
import DataTable from 'react-data-table-component';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';

const SensitiveReceiversIndex: React.FC = (props) => {
    const [sensitiveReceivers, setSensitiveReceivers] = useState<SensitiveReceiver[]>();
    const [waterBodies, setWaterBodies] = useState<WaterBody[]>([]);
    const [riverBasinDistricts, setRiverBasinDistricts] = useState<RiverBasinDistrict[]>([]);
    const [filteredSensitiveReceivers, setFilteredSensitiveReceivers] = useState<SensitiveReceiver[]>([]);
    const [selectedSensitiveReceiver, setSelectedSensitiveReceiver] = useState<SensitiveReceiver | null>(null)
    const [loadingError, setLoadingError] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [search, setSearch] = useState('');
    const [addSensitiveReceiverModalShow, setAddSensitiveReceiverModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const [editSensitiveReceiverModalShow, setEditSensitiveReceiverModalShow] = useState(false);
    const editFormRef = useRef<any>(null);

    const { get, post, put, del, response, loading, error, cache } = useFetch('/api');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSearchText = (event: any) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if (search) {
            let s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var filtered = [...(sensitiveReceivers ?? [])].filter(x => `${x.id} ${x.name} ${x.nameEn} ${x.code}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(s));
            setFilteredSensitiveReceivers(filtered);
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    };

    useEffect(() => {
        handleSearch();
    }, [sensitiveReceivers])

    useEffect(() => {
        getRiverBasinDistricts();
        getWaterBodies();
        getSensitiveReceivers();
    }, [])

    const getRiverBasinDistricts = async () => {
        let wds = await get('/wastewatertreatmentplant/riverbasindistricts');
        if (response.ok) setRiverBasinDistricts(wds);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const getWaterBodies = async () => {
        setLoadingError(false);
        let as = await get('/data/waterBodies');
        if (response.ok) setWaterBodies(as);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const getSensitiveReceivers = async () => {
        setLoadingError(false);
        let as = await get('/data/sensitiveReceivers');
        if (response.ok) setSensitiveReceivers(as);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const removeSensitiveReceiver = async (id: string) => {
        if (!id) return;
        await del(`/data/sensitiveReceivers/${id}`);
        if (response.ok) {
            cache.delete(`url:/api/data/sensitiveReceivers||method:GET||body:`);
            await getSensitiveReceivers();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const addValidationSchema = yup.object().shape({
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        code: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        type: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3, 4], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        connectedWaterBodies: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    const editValidationSchema = yup.object().shape({
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        code: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        type: yup.number().required(t("wtp.validation.required")).oneOf([0, 1, 2, 3, 4], t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        waterBodies: yup.array().min(1, t("wtp.validation.no_select")).required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
    });

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            cell: (row: SensitiveReceiver) => (
                <ButtonGroup>
                    {<ConfirmationPopover
                        id={`sensitiveReceiver-confirmation_popover-${row.id}`}
                        header={t("wtp.reuse.confirmation.header")}
                        body={t("wtp.reuse.confirmation.body")}
                        okLabel={t("wtp.reuse.confirmation.ok")}
                        onOk={() => { removeSensitiveReceiver(row.id.toString()) }}
                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                        onCancel={() => { }}
                    />}
                    <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ border: "none" }}
                        className="d-flex align-items-center justify-content-center m-0"
                        onClick={() => {
                            setSelectedSensitiveReceiver(row);
                            setEditSensitiveReceiverModalShow(true);
                        }}
                    >
                        <Pencil />
                    </Button>
                </ButtonGroup>
            ),
            width: "80px"
        },
        {
            name: t("admin.sensitiveReceivers.sensitive_receiver_name"),
            selector: (row: SensitiveReceiver) => row.name ?? "",
            sortable: true
        },
        {
            name: t("admin.sensitiveReceivers.sensitive_receiver_name_english"),
            selector: (row: SensitiveReceiver) => row.nameEn ?? "",
            sortable: true
        },
        {
            name: t("admin.sensitiveReceivers.sensitive_receiver_code"),
            selector: (row: SensitiveReceiver) => row.code ?? "",
            sortable: true
        },
        {
            name: t("admin.sensitiveReceivers.sensitive_receiver_type"),
            selector: (row: SensitiveReceiver) => t(`water_body.types.${row.type}`),
            sortable: true
        },
    ];

    const handleAddSubmit = async (val: {
        name: string,
        nameEn: string,
        code: string,
        type: number,
        connectedWaterBodies: string,
    }) => {
        (val as any).id = 0;
        (val as any).waterBodies = val.connectedWaterBodies.split(',').map(x => parseInt(x));
        await post('/data/sensitiveReceivers', val);
        if (response.ok) {
            cache.delete(`url:/api/data/sensitiveReceivers||method:GET||body:`);
            await getSensitiveReceivers();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            return;
        }
        setAddSensitiveReceiverModalShow(false);
        setSelectedSensitiveReceiver(null);
    };

    const handleEditSubmit = async (val: SensitiveReceiver) => {
        await put(`/data/sensitiveReceivers/${val.id}`, val);
        var hasCache = await cache.has(`url:/api/data/sensitiveReceivers/${val.id}||method:PUT||body:${JSON.stringify(val)}`);
        if (hasCache) {
            cache.delete(`url:/api/data/sensitiveReceivers/${val.id}||method:PUT||body:${JSON.stringify(val)}`);
        }
        if (response.ok) {
            cache.delete(`url:/api/data/sensitiveReceivers||method:GET||body:`);
            await getSensitiveReceivers();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            return;
        }
        setEditSensitiveReceiverModalShow(false);
        setSelectedSensitiveReceiver(null);
    };

    return (
        <>
            {!loading && !error && sensitiveReceivers && <>
                <Row>
                    <Col>
                        <h4>{t("admin.sensitiveReceivers")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddSensitiveReceiverModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        </ButtonGroup>
                        <InputGroup
                            className="mt-2"
                            size="sm"
                            style={{ width: "350px" }}
                        >
                            <Form.Control
                                id="search"
                                name="search"
                                placeholder={t("search_for")}
                                onChange={handleSearchText}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={search || ""}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={isFiltering ? filteredSensitiveReceivers : sensitiveReceivers}
                            pagination
                            // paginationComponent={BootyPagination}
                            customStyles={customStyles}
                            noDataComponent={<div className="text-center">
                                {t("wtp.table.no_data")}
                            </div>}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                show={addSensitiveReceiverModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddSensitiveReceiverModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${t("wtp.button.add")} ${t("admin.sensitiveReceiver")}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ name: "", nameEn: "", code: "", type: -1, connectedWaterBodies: "" }}
                        validationSchema={addValidationSchema}
                        onSubmit={(v) => handleAddSubmit(v)}
                    >
                        {props => <Form noValidate ref={addFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_name")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_name")}
                                        id="name"
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_name_english")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_name_english")}
                                        id="nameEn"
                                        name="nameEn"
                                        value={props.values.nameEn}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).nameEn && !!(props.errors as any).nameEn}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).nameEn}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_code")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_code")}
                                        id="code"
                                        name="code"
                                        value={props.values.code}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).code && !!(props.errors as any).code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).code}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_type")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="type"
                                        name="type"
                                        value={props.values.type ?? ""}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).type && !!(props.errors as any).type}
                                    >
                                        <option key={-1} value={-1}>{t("admin.sensitiveReceivers.select_sensitive_receiver_type")}</option>
                                        {Object.keys(WaterBodyType).filter((v) => isNaN(Number(v))).map((x, i) => <option key={i} value={i}>{t(`water_body.types.${i}`)}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).type}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_water_bodies")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        isMulti
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(waterBodies.filter((x, i) => i !== 0).map(x => {
                                            return {
                                                value: x.id, label: `${i18n.language === "en" ? x.nameEn : x.name} (${x.code})`
                                            }
                                        }))}
                                        placeholder={t("admin.sensitiveReceivers.select_sensitive_receiver_water_bodies")}
                                        id="connectedWaterBodies"
                                        name="connectedWaterBodies"
                                        onChange={v => props.setFieldValue("connectedWaterBodies", Array.from(v?.values()).map(x => x.value).join(','))}
                                        value={(props.values as any).connectedWaterBodies?.split(',').map((x: string) => waterBodies.find(y => y.id.toString() === x)).filter((x: any) => x).map((x: any) => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) ?? ""}
                                        className={'react-select ' + (!(props.values as any).connectedWaterBodies ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).connectedWaterBodies}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <DataTable
                                title={t("admin.sensitiveReceivers.sensitive_receiver_water_bodies")}
                                columns={[
                                    {
                                        name: t("wtp.receiver.receiver.name"),
                                        cell: (row: WaterBody) => i18n.language === "en" ? row.nameEn : row.name,
                                    },
                                    {
                                        name: t("wtp.receiver.receiver.code"),
                                        selector: (row: WaterBody) => row.code ?? "",
                                        center: true,
                                    },
                                    {
                                        name: t("wtp.receiver.receiver.category"),
                                        cell: (row: WaterBody) => t(`water_body.types.${row.type}`),
                                        center: true,
                                    },
                                    {
                                        name: t("wtp.general.general.river_basin_district"),
                                        cell: (row: WaterBody) => { var rbd = riverBasinDistricts.find(x => x.code === row.code?.slice(0, 4)); return rbd ? (i18n.language === "en" ? rbd.nameEn : rbd.name) : row.code?.slice(0, 4) },
                                        center: true,
                                    },
                                ]}
                                data={((props.values as any).connectedWaterBodies?.split(',').map((x: string) => waterBodies.find(y => y.id.toString() === x)).filter((x: any) => x) ?? []) as WaterBody[]}
                                customStyles={customStyles}
                                noDataComponent={<div className="text-center">
                                    {t("wtp.table.no_data")}
                                </div>}
                            />
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddSensitiveReceiverModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editSensitiveReceiverModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditSensitiveReceiverModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${t("wtp.button.edit_capitalized")} ${t("admin.sensitiveReceiver")}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={selectedSensitiveReceiver!}
                        validationSchema={editValidationSchema}
                        onSubmit={(v) => handleEditSubmit(v)}
                    >
                        {props => <Form noValidate ref={editFormRef} onSubmit={props.handleSubmit}>
                            <Form.Control type='hidden' name="id" id="id" value={selectedSensitiveReceiver?.id} />
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_name")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_name")}
                                        id="name"
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_name_english")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_name_english")}
                                        id="nameEn"
                                        name="nameEn"
                                        value={props.values.nameEn}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).nameEn && !!(props.errors as any).nameEn}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).nameEn}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_code")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.sensitiveReceivers.sensitive_receiver_code")}
                                        id="code"
                                        name="code"
                                        value={props.values.code ?? ""}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).code && !!(props.errors as any).code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).code}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_type")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="type"
                                        name="type"
                                        value={props.values.type ?? ""}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).type && !!(props.errors as any).type}
                                    >
                                        <option key={-1} value={-1}>{t("admin.sensitiveReceivers.select_sensitive_receiver_type")}</option>
                                        {Object.keys(WaterBodyType).filter((v) => isNaN(Number(v))).map((x, i) => <option key={i} value={i}>{t(`water_body.types.${i}`)}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).type}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.sensitiveReceivers.sensitive_receiver_water_bodies")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Select
                                        isMulti
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={(waterBodies.filter((x, i) => i !== 0).map(x => {
                                            return {
                                                value: x.id, label: `${i18n.language === "en" ? x.nameEn : x.name} (${x.code})`
                                            }
                                        }))}
                                        placeholder={t("admin.sensitiveReceivers.select_sensitive_receiver_water_bodies")}
                                        id="waterBodies"
                                        name="waterBodies"
                                        onChange={v => props.setFieldValue("waterBodies", Array.from(v?.values()).map(x => x.value))}
                                        value={(props.values as any).waterBodies?.map((x: number) => waterBodies.find(y => y.id === x)).filter((x: any) => x).map((x: any) => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) ?? ""}
                                        className={'react-select ' + ((props.errors as any).waterBodies ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).waterBodies}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <DataTable
                                title={t("admin.sensitiveReceivers.sensitive_receiver_water_bodies")}
                                columns={[
                                    {
                                        name: t("wtp.receiver.receiver.name"),
                                        cell: (row: WaterBody) => i18n.language === "en" ? row.nameEn : row.name,
                                    },
                                    {
                                        name: t("wtp.receiver.receiver.code"),
                                        selector: (row: WaterBody) => row.code ?? "",
                                        center: true,
                                    },
                                    {
                                        name: t("wtp.receiver.receiver.category"),
                                        cell: (row: WaterBody) => t(`water_body.types.${row.type}`),
                                        center: true,
                                    },
                                    {
                                        name: t("wtp.general.general.river_basin_district"),
                                        cell: (row: WaterBody) => { var rbd = riverBasinDistricts.find(x => x.code === row.code?.slice(0, 4)); return rbd ? (i18n.language === "en" ? rbd.nameEn : rbd.name) : row.code?.slice(0, 4) },
                                    },
                                ]}
                                data={((props.values as any).waterBodies?.map((x: number) => waterBodies.find(y => y.id === x)).filter((x: number) => x !== undefined) ?? []) as WaterBody[]}
                                customStyles={customStyles}
                                noDataComponent={<div className="text-center">
                                    {t("wtp.table.no_data")}
                                </div>}
                            />
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditSensitiveReceiverModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SensitiveReceiversIndex;
