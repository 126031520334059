import { useContext, useEffect, useState, createRef, useRef } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Form, Modal, Row, Spinner, Table, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

import { fromLonLat, getPointResolution, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { GeometryCollection, Point } from "ol/geom";
import WKT from 'ol/format/WKT';
import * as extent from "ol/extent";
import BingMap from "../../../components/BingMap";

import { Layers, PencilFill, PlusCircle, Save, Trash, XCircle, SquareFill } from 'react-bootstrap-icons';
import { Formik, FormikProps } from 'formik';
import useFetch from 'use-http';
import { Agglomeration, PriorityCategory, Settlement, SewageNetworkType } from '../../../data/Definitions';

let unique_id = 0;

const AgglomerationsView: React.FC = (props) => {
    const [agglomerationsData, setAgglomerationData] = useState<Agglomeration>();
    const [sewageNetworkTypes, setSewageNetworkTypes] = useState<SewageNetworkType[]>([]);
    const [priorityCategories, setPriorityCategories] = useState<PriorityCategory[]>([]);
    const [loadingError, setLoadingError] = useState(false);
    const mapRef = createRef() as React.RefObject<RMap>;

    const { get, put, response, loading, error, cache } = useFetch('/api');

    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "View Agglomeration" });
    }, [loc.pathname]);

    useEffect(() => {
        getAgglomerationData(id);
    }, [])

    const getAgglomerationData = async (code: string | undefined) => {
        if (!code) return;
        setLoadingError(false);
        let snts = await get('/wastewatertreatmentplant/sewagenetworktypes');
        if (response.ok) setSewageNetworkTypes(snts);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let pcs = await get('/wastewatertreatmentplant/prioritycategories');
        if (response.ok) setPriorityCategories(pcs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ad = await get(`/agglomerations/${id}`);
        if (response.ok) {
            let wkt = new WKT();
            let geomCollection = !ad.location ? new GeometryCollection(ad.settlements.map((x: Settlement) => wkt.readGeometry(x.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' }))) : wkt.readGeometry(ad.location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
            let ext = geomCollection.getExtent();
            let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
            let lonLatCenter = extent.getCenter(lonLatExtent);
            if (lonLatCenter) {
                ad.longitude = lonLatCenter[0];
                ad.latitude = lonLatCenter[1];
            }
            setAgglomerationData(ad);
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    return (
        <Container fluid className='menu-padding'>
            {!loading && !error && agglomerationsData && <Formik
                enableReinitialize
                initialValues={agglomerationsData}
                onSubmit={() => { }}
            >
                {props => (
                    <>
                        <form noValidate onSubmit={props.handleSubmit} onReset={props.handleReset}>
                            <Row>
                                <Col className='position-relative'>
                                    <div style={{ minHeight: "calc(100vh - 382px)" }}>
                                        <Row className="mb-2">
                                            <h4>{t("agglomerations.header")}</h4>
                                            <Col md={{ span: 7 }}>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_name")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="name"
                                                            name="name"
                                                            value={(props.values as any).name || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_name_english")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="nameEn"
                                                            name="nameEn"
                                                            value={(props.values as any).nameEn || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).nameEn}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_code")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            id="id"
                                                            name="id"
                                                            value={(props.values as any).id || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("Settlements")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Table striped size="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{t("settlements.settlement_name")}</th>
                                                                    <th>{t("settlements.settlement_code")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(props.values as any).settlements?.length === 0 ?
                                                                    <tr>
                                                                        <td colSpan={3} className="text-center">
                                                                            {t("wtp.table.no_data")}
                                                                        </td>
                                                                    </tr> :
                                                                    (props.values as any).settlements.map((x: Settlement, i: number) => <tr key={i}>
                                                                        <td className="text-center"></td>
                                                                        <td className="align-middle">{i18n.language === "en" ? x.nameEn : x.name}</td>
                                                                        <td className="align-middle">{x.id}</td>
                                                                    </tr>)
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.agglomeration_coordinates")}</Form.Label>
                                                    <Col md={{ span: 3 }} className="pe-0">
                                                        <Form.Label>{t("wtp.general.longitude")}</Form.Label>
                                                        <Form.Control
                                                            id="longitude"
                                                            name="longitude"
                                                            value={(props.values as any).longitude || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col md={{ span: 3, offset: 1 }} className="pe-0">
                                                        <Form.Label>{t("wtp.general.latitude")}</Form.Label>
                                                        <Form.Control
                                                            id="latitude"
                                                            name="latitude"
                                                            value={(props.values as any).latitude || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.municipal")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.municipal")}
                                                            value={(props.values as any).municipal || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.administrative_region")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.administrative_region")}
                                                            value={(props.values as any).administrativeRegion || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.river_basin")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.river_basin")}
                                                            value={""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.water_district")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.water_district")}
                                                            value={""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="area"
                                                            name="area"
                                                            value={(props.values as any).area || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).area}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="population"
                                                            name="population"
                                                            value={(props.values as any).population || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).population}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.equivalent_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="equivalentPopulation"
                                                            name="equivalentPopulation"
                                                            value={(props.values as any).equivalentPopulation || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.population_density")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => { let res = (parseFloat((props.values as any).equivalentPopulation) / parseFloat((props.values as any).area)); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).equivalentPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.served_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="servedPopulation"
                                                            name="servedPopulation"
                                                            value={(props.values as any).servedPopulation || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_service_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => { let res = parseFloat((props.values as any).servedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).servedPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.connected_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedPopulation"
                                                            name="connectedPopulation"
                                                            value={(props.values as any).connectedPopulation || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_connected_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => { let res = parseFloat((props.values as any).connectedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).connectedPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.constructed_sewage_network_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkArea"
                                                            name="constructedSewageNetworkArea"
                                                            value={(props.values as any).constructedSewageNetworkArea || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkArea, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).constructedSewageNetworkArea}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.constructed_sewage_network_length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkLength"
                                                            name="constructedSewageNetworkLength"
                                                            value={(props.values as any).constructedSewageNetworkLength || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.constructedSewageNetworkLength, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).constructedSewageNetworkLength}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.connected_sewage_network_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkArea"
                                                            name="connectedSewageNetworkArea"
                                                            value={(props.values as any).connectedSewageNetworkArea || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkArea, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).connectedSewageNetworkArea}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.connected_sewage_network_length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkLength"
                                                            name="connectedSewageNetworkLength"
                                                            value={(props.values as any).connectedSewageNetworkLength || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.connectedSewageNetworkLength, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).connectedSewageNetworkLength}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.pumping_station_count")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="pumpingStationCount"
                                                            name="pumpingStationCount"
                                                            value={(props.values as any).pumpingStationCount || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("agglomerations.from_settlements_sum")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(props.values as any).settlements.reduce((partialSum: number, a: Settlement) => partialSum + a.pumpingStationCount, 0)}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).pumpingStationCount}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.waste_origin.served_sewage_agglomeration.network_type")}</Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="sewageNetworkTypeId"
                                                            name="sewageNetworkTypeId"
                                                            value={(props.values as any).sewageNetworkTypeId ?? 0}
                                                            disabled
                                                        >
                                                            <option key={"none"} value={0}>{t("wtp.waste_origin.served_sewage_agglomeration.modal.select_network_type")}</option>
                                                            {sewageNetworkTypes.map(x => <option key={x.id} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                                        </Form.Select>
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {props.errors.sewageNetworkTypeId}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>
                                                        {t("wtp.general.general.priority_category")}
                                                    </Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="priorityCategoryId"
                                                            name="priority"
                                                            value={(props.values as any).priorityCategoryId}
                                                            disabled
                                                        >
                                                            <option key={0} value={0}>{t("agglomerations.select_priority")}</option>
                                                            {priorityCategories.map((x, i) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).priorityCategoryId}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("agglomerations.characterization_year")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="year"
                                                            name="year"
                                                            value={(props.values as any).year || ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).year}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("wtp.files.additional_files.public")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Select
                                                            id="isPublic"
                                                            name="isPublic"
                                                            value={(props.values as any).isPublic ?? "False"}
                                                            disabled
                                                        >
                                                            <option key={"no"} value={"False"}>{t("no")}</option>
                                                            <option key={"yes"} value={"True"}>{t("yes")}</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).isPublic}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col md={{ span: 5 }}>
                                                <div style={{ width: "100%", height: "500px" }}>
                                                    <RMap
                                                        className='w-100 h-100'
                                                        initial={((props.values as any).longitude && (props.values as any).latitude) ? { center: fromLonLat([(props.values as any).longitude, (props.values as any).latitude]), zoom: 13 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                                                        ref={mapRef}
                                                    >
                                                        <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                                            <ROSM properties={{ label: "Map" }} />
                                                            <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                                                        </RControl.RLayers>
                                                        <RLayerVector
                                                            zIndex={11}
                                                        >
                                                            {props.values.settlements.map(x => x.shape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "shape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 200, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(0, 200, 0, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            {props.values.settlements.map(x => x.constructedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.constructedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "constructedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(255, 60, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            {props.values.settlements.map(x => x.connectedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(x.connectedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "connectedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 138, 255, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>)}
                                                            <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={new Point(fromLonLat([parseFloat(props.values.longitude), parseFloat(props.values.latitude)]))}
                                                                properties={{ type: "location" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RCircle radius={10}>
                                                                        <RStyle.RFill color="rgba(200, 50, 50, 0.5)"></RStyle.RFill>
                                                                        <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                    </RStyle.RCircle>
                                                                </RStyle.RStyle>
                                                            </RFeature>
                                                        </RLayerVector>
                                                    </RMap>
                                                    <Row className='justify-content-center'>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(200, 50, 50, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "50%", marginRight: "8px" }} /><Form.Text muted>{t("agglomerations.agglomeration_coordinates")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 200, 0, 0.5)" style={{ border: "2px solid rgba(0, 200, 0, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.settlement_limits")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(255, 60, 0, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.constructed_sewage_network")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 138, 255, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.connected_sewage_network")}</Form.Text></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </>)}
            </Formik>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default AgglomerationsView;
