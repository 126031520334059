import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { wasteTreatmentOptions, sludgeTreatmentOptions } from "../../data/Data";
import Select from 'react-select';

const WtpManageProcessing: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();

    return (
        <>
            <h4>{t("wtp.treatment.waste.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.treatment.method")}
                </Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    {/* <Form.Select
                        id="wasteTreatmentMethod"
                        name="wasteTreatmentMethod"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).wasteTreatmentMethod ?? 0}
                        isInvalid={(touched as any).wasteTreatmentMethod && !!(errors as any).wasteTreatmentMethod}
                    >
                        {wasteTreatmentOptions.map((x, i) => <option key={i} value={x.code}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select> */}
                    <Select
                        isMulti
                        noOptionsMessage={() => t("wtp.select.no_options")}
                        options={(wasteTreatmentOptions.filter((x, i) => i !== 0).map(x => {
                            return {
                                value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, isDisabled: (values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).reduce((accumulator: boolean, currentValue: {
                                    code: string;
                                    name: string;
                                    nameEn: string;
                                    disinfection: boolean;
                                    unique: boolean;
                                }) => accumulator || currentValue.unique, false) && x.unique
                            }
                        }))}
                        placeholder={i18n.language === "en" ? wasteTreatmentOptions[0].nameEn : wasteTreatmentOptions[0].name}
                        id="wasteTreatmentMethod"
                        name="wasteTreatmentMethod"
                        onChange={v => setFieldValue("wasteTreatmentMethod", Array.from(v?.values()).map(x => x.value).join(','))}
                        value={(values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).map((x: {
                            code: string;
                            name: string;
                            nameEn: string;
                            disinfection: boolean;
                            unique: boolean;
                        }) => { return { value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, } }) ?? ""}
                        className={'react-select ' + (!(values as any).wasteTreatmentMethod ? 'is-invalid' : '')}
                        classNamePrefix={'react-select'}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).wasteTreatmentMethod}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {(values as any).wasteTreatmentMethod?.split(',').map((x: string) => wasteTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).reduce((accumulator: boolean, currentValue: {
                code: string;
                name: string;
                nameEn: string;
                disinfection: boolean;
                unique: boolean;
            }) => accumulator || currentValue.disinfection, false) && <>
                    <Form.Group as={Row} className="mb-2">
                        <Col md={{ offset: 3 }}>
                            <Form.Check
                                type="checkbox"
                                label={t("wtp.treatment.waste.disinfection_chlorination")}
                                id="disinfectionChlorination"
                                name="disinfectionChlorination"
                                checked={(values as any).disinfectionChlorination}
                                onChange={e => setFieldValue('disinfectionChlorination', e.target.checked)}
                                isInvalid={(touched as any).disinfectionChlorination && !!(errors as any).disinfectionChlorination}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).disinfectionChlorination}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Col md={{ offset: 3 }}>
                            <Form.Check
                                type="checkbox"
                                label={t("wtp.treatment.waste.disinfection_uv")}
                                id="disinfectionUltraviolet"
                                name="disinfectionUltraviolet"
                                checked={(values as any).disinfectionUltraviolet}
                                onChange={e => setFieldValue('disinfectionUltraviolet', e.target.checked)}
                                isInvalid={(touched as any).disinfectionUltraviolet && !!(errors as any).disinfectionUltraviolet}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).disinfectionUltraviolet}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2">
                        <Col md={{ offset: 3 }}>
                            <Form.Check
                                type="checkbox"
                                label={t("wtp.treatment.waste.disinfection_ozone")}
                                id="disinfectionOzonation"
                                name="disinfectionOzonation"
                                checked={(values as any).disinfectionOzonation}
                                onChange={e => setFieldValue('disinfectionOzonation', e.target.checked)}
                                isInvalid={(touched as any).disinfectionOzonation && !!(errors as any).disinfectionOzonation}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(errors as any).disinfectionOzonation}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </>}
            {(values as any).wasteTreatmentMethod?.includes("3other") &&
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>
                        {t("wtp.treatment.other_treatment")}
                    </Form.Label>
                    <Col md={{ span: 6 }} className="pe-0">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="otherTreatmentDescription"
                            name="otherTreatmentDescription"
                            onBlur={handleChange}
                            defaultValue={(values as any).otherTreatmentDescription || ""}
                            isInvalid={(touched as any).otherTreatmentDescription && !!(errors as any).otherTreatmentDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).otherTreatmentDescription}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>}
            <hr></hr>
            <h4>{t("wtp.treatment.dry_waste.header")}</h4>
            <Form.Group as={Row} className="mb-2">
                <Form.Label column className="text-end" md={{ span: 3 }}>
                    {t("wtp.treatment.method")}
                </Form.Label>
                <Col md={{ span: 6 }} className="pe-0">
                    {/* <Form.Select
                        id="sludgeTreatmentMethod"
                        name="sludgeTreatmentMethod"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={(values as any).sludgeTreatmentMethod ?? 0}
                        isInvalid={(touched as any).sludgeTreatmentMethod && !!(errors as any).sludgeTreatmentMethod}
                    >
                        {sludgeTreatmentOptions.map((x, i) => <option key={i} value={x.code}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                    </Form.Select> */}
                    <Select
                        isMulti
                        noOptionsMessage={() => t("wtp.select.no_options")}
                        options={(sludgeTreatmentOptions.filter((x, i) => i !== 0).map(x => { return { value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                        placeholder={i18n.language === "en" ? sludgeTreatmentOptions[0].nameEn : sludgeTreatmentOptions[0].name}
                        id="sludgeTreatmentMethod"
                        name="sludgeTreatmentMethod"
                        onChange={v => setFieldValue("sludgeTreatmentMethod", Array.from(v?.values()).map(x => x.value).join(','))}
                        value={(values as any).sludgeTreatmentMethod?.split(',').map((x: string) => sludgeTreatmentOptions.find(y => y.code === x)).filter((x: any) => x).map((x: {
                            code: string;
                            name: string;
                            nameEn: string;
                        }) => { return { value: x.code, label: i18n.language === "en" ? x.nameEn : x.name, } }) ?? ""}
                        className={'react-select ' + (!(values as any).sludgeTreatmentMethod ? 'is-invalid' : '')}
                        classNamePrefix={'react-select'}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).sludgeTreatmentMethod}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            {(values as any).sludgeTreatmentMethod?.includes("4sother") &&
                <Form.Group as={Row} className="mb-2">
                    <Form.Label column className="text-end" md={{ span: 3 }}>
                        {t("wtp.treatment.other_treatment")}
                    </Form.Label>
                    <Col md={{ span: 6 }} className="pe-0">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="otherSludgeTreatmentDescription"
                            name="otherSludgeTreatmentDescription"
                            onBlur={handleChange}
                            defaultValue={(values as any).otherSludgeTreatmentDescription || ""}
                            isInvalid={(touched as any).otherSludgeTreatmentDescription && !!(errors as any).otherSludgeTreatmentDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(errors as any).otherSludgeTreatmentDescription}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>}
        </>
    )
}

export default WtpManageProcessing;
