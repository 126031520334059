import { Alert, Button, Row, Col, Form, Modal, Table, Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { SamplingParameter, SamplingPointType, WastewaterTreatementPlantSamplingPoint } from '../data/Definitions';
import * as XLSX from "xlsx";
import moment from 'moment';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { DashCircle, Plus } from 'react-bootstrap-icons';
import Select from 'react-select';

const MeasurementsFromFile: React.FC<{ samplingParameters: SamplingParameter[], samplingPointTypes: SamplingPointType[], samplingPointType: number, show: boolean, setShow: Function, isReuse: boolean }> = ({ samplingParameters, samplingPointTypes, samplingPointType, show: addSamplingPointFileModalShow, setShow: setAddSamplingPointFileModalShow, isReuse }) => {
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const addSamplingPointFileFormRef = useRef<any>(null);
    const addSamplingPointFileFormikRef = useRef<any>(null);
    const [sameParameterError, setSameParameterError] = useState(false);
    const [invalidDateError, setInvalidDateErrorError] = useState(false);
    const [parametersInSamplingForm, setParametersInSamplingForm] = useState<{ id: number, parameterId: number, sheet: number, column: number }[]>([]);
    const [workBook, setWorkBook] = useState<XLSX.WorkBook>();
    const [sheets, setSheets] = useState<{ value: string, label: string }[]>([]);
    const [sheetsHeaders, setSheetsHeaders] = useState<{ value: string, label: string }[][]>([]);
    const [showDateCheckModalShow, setShowDateCheckModalShow] = useState(false);
    const [tempMeasurements, setTempMeasurements] = useState<any[]>([]);

    const samplingSchema = yup.object().shape(Object.assign(
        {
            samplingPointTypeId: yup.number().required(t("wtp.validation.required")).oneOf(samplingPointTypes.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            samplingDateSheet: yup.string().not(["-1"], t("wtp.validation.no_select")).required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            samplingDateColumn: yup.string().not(["-1"], t("wtp.validation.no_select")).required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        },
        Object.fromEntries(parametersInSamplingForm.map((x, i) => [`parameter_${i}`, yup.number().required(t("wtp.validation.required")).oneOf(Array.from({ length: samplingParameters.length }, (item, index) => index + 1), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInSamplingForm.map((x, i) => [`parameter_sheet_${i}`, yup.string().not(["-1"], t("wtp.validation.no_select")).required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInSamplingForm.map((x, i) => [`parameter_column_${i}`, yup.string().not(["-1"], t("wtp.validation.no_select")).required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),])),
    ));

    useEffect(() => {
        if (!workBook) return;
        parseWorkbook();
    }, [workBook])

    useEffect(() => {
        if (!addSamplingPointFileModalShow) {
            setParametersInSamplingForm([]);
            setSameParameterError(false);
            setInvalidDateErrorError(false);
            setWorkBook(undefined);
        }
    }, [addSamplingPointFileModalShow])

    useLayoutEffect(() => {
        let sheet = sheets.length === 1 ? 0 : -1;
        addSamplingPointFileFormikRef.current?.setFieldValue("samplingDateSheet", sheet.toString());
    }, [sheets])

    const parseWorkbook = () => {
        let sheets = workBook?.SheetNames.map((x, i) => { return { value: `${i}`, label: x } });
        if (!sheets) return;
        setSheets(sheets);
        // console.log(sheets);
        let headers = workBook?.SheetNames.map((x, i) => { return getHeaderRow(workBook?.Sheets[x]) });
        if (!headers) return;
        setSheetsHeaders(headers);
        // console.log(headers);
    }

    const excelDateToJSDate = (serial: number): Date => {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);
        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), 0, 0, 0);
    }

    const getHeaderRow = (sheet: XLSX.WorkSheet) => {
        let headers = [];
        let range = XLSX.utils.decode_range(sheet['!ref']!);
        let C, R = range.s.r; /* start in the first row */
        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
            let hdr = "UNKNOWN " + C;
            if (cell && cell.t) {
                hdr = XLSX.utils.format_cell(cell);
            }
            headers.push({ value: `${C}`, label: hdr });
        }
        return headers;
    }

    const addParameterInSamplingForm = () => {
        if (parametersInSamplingForm.length >= samplingParameters.length) return;
        let cur = [...parametersInSamplingForm];
        let id;
        if (cur.length > 0) {
            let curIds = cur.map(x => x.id) || [0];
            id = Math.max(...curIds) + 1;
        } else {
            id = 1;
        }
        let sheet = sheets.length === 1 ? 0 : -1;
        cur.push({
            id: id,
            parameterId: 0,
            sheet: sheet,
            column: -1,
        });
        setParametersInSamplingForm(cur);
        addSamplingPointFileFormikRef.current?.setFieldValue(`parameter_sheet_${cur.length - 1}`, sheet.toString());
        addSamplingPointFileFormikRef.current?.setErrors({});
        addSamplingPointFileFormikRef.current?.setTouched({});
    }

    const removeParameterInSamplingForm = (id: number) => {
        if (!id) return;
        let idxToRemove = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToRemove === -1) return;
        let cur = [...parametersInSamplingForm];
        cur.splice(idxToRemove, 1);
        setParametersInSamplingForm(cur);
        if (addSamplingPointFileFormikRef.current?.submitCount > 0) {
            let val = Object.assign(
                {
                    samplingDate: addSamplingPointFileFormikRef.current?.values.samplingDate,
                    samplingPointTypeId: addSamplingPointFileFormikRef.current?.values.samplingPointTypeId,
                },
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, cur[i]?.parameterId || 0])),
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_sheet_${i}`, cur[i]?.sheet || -1])),
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_column_${i}`, cur[i]?.column || 0]))
            );
            addSamplingPointFileFormikRef.current?.setValues(val);
        }
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        addSamplingPointFileFormikRef.current?.setErrors({});
        addSamplingPointFileFormikRef.current?.setTouched({});
    }

    const changeParameterInSamplingForm = (id: number, val: string) => {
        if (!id) return;
        if (!val) return;
        let idxToEdit = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInSamplingForm];
        cur[idxToEdit].parameterId = parseInt(val);
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        setParametersInSamplingForm(cur);
    }

    const changeParameterSheetInSamplingForm = (id: number, val: string) => {
        // console.log(id, val)
        if (!id) return;
        let idxToEdit = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInSamplingForm];
        cur[idxToEdit].sheet = !isNaN(parseInt(val)) ? parseInt(val) : -1;
        setParametersInSamplingForm(cur);
    }

    const changeParameterColumnInSamplingForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInSamplingForm];
        cur[idxToEdit].column = !isNaN(parseInt(val)) ? parseInt(val) : -1;
        setParametersInSamplingForm(cur);
    }

    const handleAddSamplingPointFileSubmit = (val: any) => {
        // console.log(val);
        // console.log(workBook);
        if (!workBook) return;
        if (sameParameterError) {
            return;
        }

        let samples: any[] = [];
        let sheet = workBook.Sheets[workBook.SheetNames[val.samplingDateSheet]];
        if (sheet === undefined) return;
        let dataRange = XLSX.utils.decode_range(sheet['!ref']!);
        for (let i = 1; i <= dataRange.e.r; i++) {
            let cell = sheet[XLSX.utils.encode_cell({ c: parseInt(val.samplingDateColumn), r: dataRange.s.r + i })];
            if (!cell) {
                break;
            }
            let excelDateValue = cell.v as any;
            let excelDate: Date;
            if (typeof excelDateValue === "string") {
                excelDate = moment(excelDateValue, "DD/MM/YYYY").toDate();
            }
            else {
                excelDate = excelDateToJSDate(excelDateValue);
            }

            if (Number.isNaN(excelDate.valueOf())) {
                console.error("Invalid date detected!");
                setInvalidDateErrorError(true);
                return;
            }

            if (excelDate.getFullYear() < 2000 || excelDate.getFullYear() > 2100) {
                console.error("Invalid date detected!");
                setInvalidDateErrorError(true);
                return;
            }

            setShowDateCheckModalShow(true);
            let temp = samplingParameters.map(x => {
                let param = parametersInSamplingForm.find(y => y.parameterId === x.id);
                let s = param?.sheet;
                if (s === undefined) return {};
                let c = param?.column;
                if (c === undefined) return {};
                let data = workBook.Sheets[workBook.SheetNames[s]];
                if (data === undefined) return {};
                let cell = data[XLSX.utils.encode_cell({ c: c, r: dataRange.s.r + i })];
                if (!cell) return {};
                let excelDataValue = cell.v as any;
                return {
                    id: x.id,
                    name: x.name,
                    unit: x.unit,
                    description: x.description,
                    value: excelDataValue.toString().trim(),
                }
            }).filter(x => x?.value);
            let sample = {
                samplingDate: moment(excelDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
                samplingPointTypeId: parseInt(val.samplingPointTypeId.toString()),
                isReuse: isReuse,
                samples: temp as SamplingParameter[],
            };

            samples.push(sample);
        }
        setTempMeasurements(samples);
        setAddSamplingPointFileModalShow(true);
    }

    const handleDateConfirmation = () => {
        let cur = (values as any).samplingPoints;
        tempMeasurements.forEach(sample => {
            cur = cur.filter((x: any) => moment(x.samplingDate).format('L') !== moment(sample.samplingDate).format('L') || x.samplingPointTypeId.toString() !== addSamplingPointFileFormikRef.current?.values.samplingPointTypeId.toString());
            cur.push(sample);
        });

        setFieldValue("samplingPoints", cur);
        setShowDateCheckModalShow(false);
        setAddSamplingPointFileModalShow(false);
    }

    const getSamplingParametersToShow = (data: WastewaterTreatementPlantSamplingPoint[]) => {
        if (!data) return [];
        const params = data.flatMap(x => x.samples).flatMap(x => x.id).filter((item, i, ar) => ar.indexOf(item) === i);
        return samplingParameters.filter(x => params.includes(x.id));
    };

    return (<>
        <Modal
            show={addSamplingPointFileModalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setAddSamplingPointFileModalShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t("wtp.measurements.sampling_results.modal.add_file.header")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                <Formik
                    initialValues={Object.assign(
                        {
                            samplingDateSheet: "-1",
                            samplingDateColumn: "-1",
                            samplingPointTypeId: samplingPointType.toString(),
                            file: "",
                        },
                        Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, parametersInSamplingForm[i]?.parameterId.toString() || "0"])),
                        Object.fromEntries(samplingParameters.map((x, i) => [`parameter_sheet_${i}`, parametersInSamplingForm[i]?.sheet.toString() || "-1"])),
                        Object.fromEntries(samplingParameters.map((x, i) => [`parameter_column_${i}`, parametersInSamplingForm[i]?.column.toString() || "-1"])),
                    )}
                    validationSchema={samplingSchema}
                    onSubmit={(v) => handleAddSamplingPointFileSubmit(v)}
                    innerRef={addSamplingPointFileFormikRef}
                >
                    {props => <Form noValidate ref={addSamplingPointFileFormRef} onSubmit={props.handleSubmit}>
                        <Form.Group as={Row} className="mb-2">
                            <Form.Label column className="text-end" md={{ span: 2 }}>{t("wtp.measurements.sampling_results.modal.add_file.file") + " *"}</Form.Label>
                            <Col md={{ span: 10 }}>
                                <Form.Control
                                    type="file"
                                    required
                                    name="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(ev: any) => {
                                        if (ev.target.files.length === 0) {
                                            return;
                                        }
                                        const reader = new FileReader();
                                        const rABS = !!reader.readAsBinaryString;
                                        reader.onload = e => {
                                            const bstr = e.target?.result;
                                            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                                            setWorkBook(wb);
                                            props.setFieldValue("file", (ev.target.files[0] as File).name);
                                        };
                                        reader.onerror = (e) => {
                                            console.error(e);
                                        }
                                        if (rABS) {
                                            reader.readAsBinaryString(ev.target.files[0] as File);
                                        }
                                        else {
                                            reader.readAsArrayBuffer(ev.target.files[0] as File);
                                        }
                                    }}
                                    multiple={false}
                                />
                            </Col>
                        </Form.Group>
                        {props.values.file && workBook && sheets.length > 0 && sheetsHeaders.length > 0 && <>
                            <hr />
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 4 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                    >
                                        <option value={"-1"} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id.toString()} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 3 }}>{t("wtp.measurements.sampling_results.date") + " *"}</Form.Label>
                                <Col md={{ span: 4 }}>
                                    <Form.Select
                                        id="samplingDateSheet"
                                        name="samplingDateSheet"
                                        value={props.values.samplingDateSheet}
                                        onChange={(e) => { props.setFieldValue("samplingDateSheet", e.target.value); props.setFieldValue("samplingDateColumn", "-1"); }}
                                        isInvalid={props.touched.samplingDateSheet && !!props.errors.samplingDateSheet}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sheet")}</option>
                                        {sheets.map(x => <option value={x.value} key={x.label}>{x.label}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors["samplingDateSheet"]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 4 }}>
                                    <Form.Select
                                        id="samplingDateColumn"
                                        name="samplingDateColumn"
                                        value={props.values.samplingDateColumn}
                                        disabled={props.values.samplingDateSheet === "-1"}
                                        onChange={(e) => { setInvalidDateErrorError(false); props.setFieldValue("samplingDateColumn", e.target.value); }}
                                        isInvalid={props.touched.samplingDateColumn && !!props.errors.samplingDateColumn}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_column")}</option>
                                        {!isNaN(parseInt(props.values.samplingDateSheet)) && props.values.samplingDateSheet !== "-1" && sheetsHeaders[parseInt(props.values.samplingDateSheet)].map(x => <option value={x.value} key={x.label}>{x.label}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingDateColumn}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {parametersInSamplingForm.map((y: any, i: number) => <Form.Group key={i} as={Row} className="mb-2">
                                <Col md={{ span: 3 }}>
                                    {/* <Form.Select
                                    id={`parameter_${i}`}
                                    name={`parameter_${i}`}
                                    value={y.parameterId}
                                    onChange={(e) => { props.setFieldValue(`parameter_${i}`, e.target.value); changeParameterInSamplingForm(y.id, e.target.value); }}
                                    isInvalid={props.touched[`parameter_${i}`] && !!props.errors[`parameter_${i}`]}
                                >
                                    <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_parameter")}</option>
                                    {samplingParameters.map((x: any, i: number) => <option value={x.id} key={i}>{x.name}</option>)}
                                </Form.Select> */}
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } }))}
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        onChange={v => { props.setFieldValue(`parameter_${i}`, v?.value); changeParameterInSamplingForm(y.id, v?.value.toString() || "0"); }}
                                        value={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } })).find(x => x.value.toString() === props.values[`parameter_${i}`].toString())}
                                        className={'react-select ' + (props.values[`parameter_${i}`].toString() === "0" ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                        menuPosition={"fixed"}
                                        menuPlacement={"auto"}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 4 }}>
                                    <Form.Select
                                        id={`parameter_sheet_${i}`}
                                        name={`parameter_sheet_${i}`}
                                        value={props.values[`parameter_sheet_${i}`]}
                                        onChange={(e) => { props.setFieldValue(`parameter_sheet_${i}`, e.target.value); props.setFieldValue(`parameter_column_${i}`, "-1"); changeParameterColumnInSamplingForm(y.id, "-1"); changeParameterSheetInSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_sheet_${i}`] && !!props.errors[`parameter_sheet_${i}`]}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sheet")}</option>
                                        {sheets.map(x => <option value={x.value} key={x.label}>{x.label}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_sheet_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 4 }}>
                                    <Form.Select
                                        id={`parameter_column_${i}`}
                                        name={`parameter_column_${i}`}
                                        value={props.values[`parameter_column_${i}`]}
                                        disabled={props.values[`parameter_sheet_${i}`] === "-1"}
                                        onChange={(e) => { props.setFieldValue(`parameter_column_${i}`, e.target.value); changeParameterColumnInSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_column_${i}`] && !!props.errors[`parameter_column_${i}`]}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_column")}</option>
                                        {props.values[`parameter_sheet_${i}`] && props.values[`parameter_sheet_${i}`] !== "-1" && !isNaN(parseInt(props.values[`parameter_sheet_${i}`])) && sheetsHeaders[parseInt(props.values[`parameter_sheet_${i}`])].map(x => <option value={x.value} key={x.label}>{x.label}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_column_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 1 }}>
                                    <Button active style={{ border: "none" }} variant="ghost" onClick={() => removeParameterInSamplingForm(y.id)}>
                                        <DashCircle color="red" />
                                    </Button>
                                </Col>
                            </Form.Group>)}
                            {parametersInSamplingForm.length < samplingParameters.length && <Form.Group as={Row} className="mb-2">
                                <Button onClick={addParameterInSamplingForm} style={{ width: "400px", margin: "0 auto", backgroundColor: "white", color: "gray", border: "2px dotted gray" }}>
                                    <Plus />&nbsp;{t("wtp.measurements.add_parameter")}
                                </Button>
                            </Form.Group>}
                        </>}
                    </Form>}
                </Formik>
                {sameParameterError && <Alert variant={'light'} className="m-0">
                    <div className="text-danger">{t("wtp.measurements.sampling_results.modal.same_parameter")}</div>
                </Alert>}
                {invalidDateError && <Alert variant={'light'} className="m-0">
                    <div className="text-danger">{t("wtp.measurements.sampling_results.modal.invalid_date")}</div>
                </Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={parametersInSamplingForm.length === 0} onClick={() => addSamplingPointFileFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.next")}</Button>
                <Button variant="secondary" onClick={() => setAddSamplingPointFileModalShow(false)}>{t("wtp.button.cancel")}</Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showDateCheckModalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => { setShowDateCheckModalShow(false); setTempMeasurements([]); }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t("wtp.measurements.sampling_results.modal.check_results.header")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                <Container fluid>
                    <Row className="mt-2">
                        <Col>
                            {t("wtp.measurements.sampling_results.modal.check_results.description")}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Table striped bordered hover size="sm" style={{ overflowX: "auto", display: "block" }}>
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle">{t("wtp.measurements.sampling_results.date")}</th>
                                        {getSamplingParametersToShow(tempMeasurements.slice(0, Math.min(5, tempMeasurements.length))).map(x => <td key={x.name} className="text-center align-middle"><strong>{x.name}</strong><br />({x.unit})</td>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tempMeasurements.length > 0 ?
                                        tempMeasurements.slice(0, Math.min(5, tempMeasurements.length)).map((x: WastewaterTreatementPlantSamplingPoint, i: number) => <tr key={i}>
                                            <td className="text-center">{x.samplingDate && new Date(x.samplingDate).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { year: "numeric", month: "long", day: "2-digit" })}</td>
                                            {getSamplingParametersToShow(tempMeasurements.slice(0, Math.min(5, tempMeasurements.length))).map(y => <td key={y.name} className="text-center">{(x.samples.find(z => z.id === y.id)?.value === null || x.samples.find(z => z.id === y.id)?.value === undefined) ? "" : x.samples.find(z => z.id === y.id)?.value}</td>)}
                                        </tr>)
                                        : <tr>
                                            <td className="text-center">
                                                {t("wtp.table.no_data")}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleDateConfirmation}>{t("wtp.button.add")}</Button>
                <Button variant="secondary" onClick={() => { setShowDateCheckModalShow(false); setTempMeasurements([]); }}>{t("wtp.button.previous")}</Button>
            </Modal.Footer>
        </Modal>
    </>
    );
}

export default MeasurementsFromFile;