import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Form, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthorityType, IndustryBranch, WastewaterTreatementPlant, RiverBasinDistrict, SewageNetworkType, SamplingParameter, Agglomeration, AdministrativeRegion, Stakod, IndustryState, flatten, reference, IedCategory, PriorityCategory, Authority, RiverBasin, SamplingPointType, WaterBody, Settlement, ElstatSettlement } from '../../data/Definitions';
import { AppContext } from '../../state/State';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WtpManageGeneral from './WtpManageGeneral';
import WtpManageComments from './WtpManageComments';
import WtpManageFiles from './WtpManageFiles';
import WtpManageMeasurements from './WtpManageMeasurements';
import WtpManageProcessing from './WtpManageProcessing';
import WtpManageIncomingLoad from './WtpManageIncomingLoad';
import WtpManageWasteOrigin from './WtpManageWasteOrigin';
import WtpManageDisposal from './WtpManageDisposal';
import { ArrowCounterclockwise, Save, SkipBackward, XCircle } from 'react-bootstrap-icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import useFetch from 'use-http';
import { sludgeTreatmentOptions, wasteTreatmentOptions } from '../../data/Data';
import WtpManageIndustryCharacteristics from './WtpManageIndustryCharacteristics';
import FormErrorNotification from '../../components/FormErrorNotification';
import WtpManageReuse from './WtpManageReuse';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/el';
import WtpManageReuseGeneral from './WtpManageReuseGeneral';
import WtpManageMeasurementsReuse from './WtpManageMeasurementsReuse';
import WtpManageEnergyGreenhouseEmissions from './WtpManageEnergyGreenhouseEmissions';

const WtpManage: React.FC = (props) => {
    const [data, setData] = useState<WastewaterTreatementPlant>();
    const [riverBasinDistricts, setRiverBasinDistricts] = useState<RiverBasinDistrict[]>([]);
    const [riverBasins, setRiverBasins] = useState<RiverBasin[]>([]);
    const [waterBodies, setWaterBodies] = useState<WaterBody[]>([]);
    const [administrativeRegions, setAdministrativeRegions] = useState<AdministrativeRegion[]>([]);
    const [authorityTypes, setAuthorityTypes] = useState<AuthorityType[]>([]);
    const [authorities, setAuthorities] = useState<Authority[]>([]);
    const [industryBranches, setIndustryBranches] = useState<IndustryBranch[]>([]);
    const [sewageNetworkTypes, setSewageNetworkTypes] = useState<SewageNetworkType[]>([]);
    const [samplingParameters, setSamplingParameters] = useState<SamplingParameter[]>([]);
    const [elstatSettlements, setElstatSettlements] = useState<ElstatSettlement[]>([]);
    const [stakods, setStakods] = useState<Stakod[]>([]);
    const [iedCategories, setIedCategories] = useState<IedCategory[]>([]);
    const [priorityCategories, setPriorityCategories] = useState<PriorityCategory[]>([]);
    const [samplingPointTypes, setSamplingPointTypes] = useState<SamplingPointType[]>([]);
    const { t, i18n } = useTranslation();
    const context = useContext(AppContext);
    const loc = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("general");
    const [activeMeasurementsTab, setActiveMeasurementsTab] = useState("measurementsWtp");
    const [saving, setSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [savingError, setSavingError] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const { id } = useParams();
    const formikRef = useRef<any>(null);

    const [settlements, setSettlements] = useState<Settlement[]>([]);
    const [agglomerations, setAgglomerations] = useState<Agglomeration[]>([]);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "W.T.P. Edit" });
    }, [loc.pathname]);


    useEffect(() => {
        if (!id) return;
        getData();
    }, [id])

    const getData = async () => {
        setLoadingError(false);
        let wds = await get('/wastewatertreatmentplant/riverbasindistricts');
        if (response.ok) setRiverBasinDistricts(wds);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ars = await get('/wastewatertreatmentplant/administrativeregions');
        if (response.ok) setAdministrativeRegions(ars);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ats = await get('/wastewatertreatmentplant/authoritytypes');
        if (response.ok) setAuthorityTypes(ats);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let aus = await get('/wastewatertreatmentplant/authorities');
        if (response.ok) {
            (aus as Authority[]).sort((a, b) => (a.name! > b.name!) ? 1 : ((b.name! > a.name!) ? -1 : 0));
            setAuthorities(aus);
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ibs = await get('/wastewatertreatmentplant/industrybranches');
        if (response.ok) setIndustryBranches(ibs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let snts = await get('/wastewatertreatmentplant/sewagenetworktypes');
        if (response.ok) setSewageNetworkTypes(snts);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let sps = await get('/wastewatertreatmentplant/samplingparameters');
        if (response.ok) setSamplingParameters(sps);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ess = await get('/settlements/elstat');
        if (response.ok) setElstatSettlements(ess);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ss = await get('/wastewatertreatmentplant/stakods');
        if (response.ok) setStakods(ss);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ics = await get('/wastewatertreatmentplant/iedcategories');
        if (response.ok) setIedCategories(ics);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let pcs = await get('/wastewatertreatmentplant/prioritycategories');
        if (response.ok) setPriorityCategories(pcs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let rbs = await get('/wastewatertreatmentplant/riverbasins');
        if (response.ok) setRiverBasins(rbs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let spts = await get(id ? `/wastewatertreatmentplant/samplingpointtypes/${id}` : '/wastewatertreatmentplant/samplingpointtypes');
        if (response.ok) setSamplingPointTypes(spts);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let wbs = await get('/wastewatertreatmentplant/waterbodies');
        if (response.ok) setWaterBodies(wbs);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        if (context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1) {
            let sets = await get('/settlements?noshapes');
            if (response.ok) setSettlements(sets);
            if (response.redirected) navigate("/login");
            if (error || response.status >= 300) {
                setLoadingError(true);
                return;
            }
            let as = await get('/agglomerations?noshapes');
            if (response.ok) setAgglomerations(as);
            if (response.redirected) navigate("/login");
            if (error || response.status >= 300) {
                setLoadingError(true);
                return;
            }
        }

        let wtp = await get(id ? `/wastewatertreatmentplant/${id}` : '/wastewatertreatmentplant');
        if (response.ok) {
            wtp.authorityId = wtp.authorityId || 0;
            wtp.riverBasinDistrictId = wtp.riverBasinDistrictId ?? 0;
            if (wtp.reuse) {
                wtp.reuse.activityBranchText = wtp.reuse.activityBranchText ?? wtp.activityBranchText ?? "";
            }
            wtp.reuseEnabled = !!wtp.reuse;
            setData(wtp);
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const reload = async () => {
        if (id) {
            cache.delete(`url:/api/wastewatertreatmentplant/${id}||method:GET||body:`);
        } else {
            cache.delete('url:/api/wastewatertreatmentplant||method:GET||body:');
        }
        let wtp = await get(id ? `/wastewatertreatmentplant/${id}` : '/wastewatertreatmentplant');
        if (response.ok) {
            wtp.authorityId = wtp.authorityId || 0;
            wtp.riverBasinDistrictId = wtp.riverBasinDistrictId ?? 0;
            if (wtp.reuse) {
                wtp.reuse.activityBranchText = wtp.reuse.activityBranchText ?? wtp.activityBranchText ?? "";
            }
            wtp.reuseEnabled = !!wtp.reuse;
            setData(wtp);
        }
    }

    const saveData = async (values: WastewaterTreatementPlant) => {
        setSaving(true);
        if (!values.authorityTypeId) {
            values.authorityTypeId = null;
        }

        if (!values.authorityId) {
            values.authorityId = null;
        }

        let data = new FormData();
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (key === "environmentalTerms") return;
            if (key === "receiverFile") return;
            if (key === "photo") return;
            if (key === "filesToUpload") return;
            if (value === null) return;
            data.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
        });
        if (values.filesToUpload) {
            values.filesToUpload.forEach(file => {
                data.append("filesToUpload", file);
            });
        }
        var res = await fetch('/api/wastewatertreatmentplant', {
            method: 'PUT',
            body: data,
        });
        if (res.status === 200) {
            setSavingError(false);
            setShowToast(true);
            reload();
        } else {
            if (res.redirected) {
                navigate("/login");
            } else {
                setSavingError(true);
                setShowToast(true);
            }
        }
        setSaving(false);
    }

    const validationSchema1 = yup.object().shape({
        // priorityCategoryId: yup.number().required(t("wtp.validation.required")).oneOf(priorityCategories.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        iedCategoryId: yup.number().required(t("wtp.validation.required")).oneOf(iedCategories.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        eprtrCode: yup.string().typeError(t("wtp.validation.type_error")),
        riverBasinDistrictId: yup.number().required(t("wtp.validation.required")).oneOf(riverBasinDistricts.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        riverBasinId: yup.number().required(t("wtp.validation.required")).oneOf(riverBasins.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        authorityTypeId: yup.number().nullable().when("industry", (industry, schema) => {
            return !industry ?
                yup.number().required(t("wtp.validation.required")).oneOf(authorityTypes.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")) :
                schema;
        }),
        // authorityId: yup.number().typeError(t("wtp.validation.type_error")),
        administrativeRegionId: yup.number().nullable().required(t("wtp.validation.no_select")).oneOf(administrativeRegions.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        municipalId: yup.number().nullable().required(t("wtp.validation.no_select")).oneOf(authorities.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        latitude: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        longitude: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        fundingProgram: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        constructionEndedOn: yup.date().nullable().typeError(t("wtp.validation.type_error")),
        operationStartedOn: yup.date().nullable().typeError(t("wtp.validation.type_error")),
        environmentalTermsExpireOn: yup.date().nullable().typeError(t("wtp.validation.type_error")),
        complexSamplerInflow: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        complexSamplerOutflow: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        staffChiefChemLab: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        septageTanksPerDay: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        capacity: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowTotalMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowTotalMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowSewerageMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowSewerageMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowSeptageMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        inflowSeptageMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingTotalMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingTotalMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingSewerageMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingSewerageMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingSeptageMean: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        incomingSeptageMax: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        disinfectionChlorination: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        disinfectionUltraviolet: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        disinfectionOzonation: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        wasteTreatmentMethod: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.no_select")),
        otherTreatmentDescription: yup.string().when("wasteTreatmentMethod", (wasteTreatmentMethod) => {
            return wasteTreatmentMethod?.includes('3other') ?
                yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.required")) :
                yup.string().nullable();
        }),
        sludgeTreatmentMethod: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.no_select")),
        otherSludgeTreatmentDescription: yup.string().when("sludgeTreatmentMethod", (sludgeTreatmentMethod) => {
            return sludgeTreatmentMethod?.includes('4sother') ?
                yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.required")) :
                yup.string().nullable();
        }),
        operationalProblems: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        isIdle: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        idleReason: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        problemsSolution: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        costForecast: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        energyConsumption: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyConsumptionPerCubicMeter: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyFromRenewables: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyFromBiogas: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        greenhouseEmissions: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        greenhouseEmissionsType: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        receiverRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        reuseTotal: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        reuseIrrigation: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        reuseIndustry: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        reuseOther: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        reuseRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        sludgeDryLandfillD1: yup.number().when('sludgeDryLandfillD1Ratio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryLandfillD1Ratio: yup.number().when('sludgeDryLandfillD1', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryLandfillD5: yup.number().when('sludgeDryLandfillD5Ratio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryLandfillD5Ratio: yup.number().when('sludgeDryLandfillD5', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryTempStorage: yup.number().when('sludgeDryTempStorageRatio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryTempStorageRatio: yup.number().when('sludgeDryTempStorage', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryAgriculture: yup.number().when('sludgeDryAgricultureRatio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryAgricultureRatio: yup.number().when('sludgeDryAgriculture', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryEnergy: yup.number().when('sludgeDryEnergyRatio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryEnergyRatio: yup.number().when('sludgeDryEnergy', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryCompost: yup.number().when('sludgeDryCompostRatio', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryCompostRatio: yup.number().when('sludgeDryCompost', {
            is: (x: any) => !!x,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryOther: yup.number().when(['sludgeDryOtherRatio', 'sludgeDryOtherDescription'], {
            is: (x: any, y: any) => !!x || !!y,
            then: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryOtherRatio: yup.number().when(['sludgeDryOtherDescription', 'sludgeDryOther'], {
            is: (x: any, y: any) => !!x || !!y,
            then: yup.number().required(t("wtp.validation.required")).min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
            otherwise: yup.number().nullable().min(0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toString()}`).max(100, (v) => `${t("wtp.validation.less_than")} ${v.max.toString()}`).typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryOtherDescription: yup.string().when(['sludgeDryOtherRatio', 'sludgeDryOther'], {
            is: (x: any, y: any) => !!x || !!y,
            then: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            otherwise: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        }),
        sludgeDryRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        measurementsRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        generalRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        industry: yup.object().nullable().shape({
            "name": yup.string().nullable().typeError(t("wtp.validation.type_error")),
            "state": yup.number().required(t("wtp.validation.required")).oneOf(Object.values(IndustryState).filter(x => !isNaN(parseInt(x.toString()))).map(x => parseInt(x.toString())), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            "codePlant": yup.number().nullable().typeError(t("wtp.validation.type_error")),
            "operationalPeriodStart": yup.date().nullable().typeError(t("wtp.validation.type_error")),
            "operationalPeriodEnd": yup.date().nullable().typeError(t("wtp.validation.type_error")),
            "stakodId": yup.string().required(t("wtp.validation.required")).oneOf(stakods.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            "rawmaterialType": yup.string().nullable().typeError(t("wtp.validation.type_error")),
            "water": yup.number().nullable().typeError(t("wtp.validation.type_error")),
            "energy": yup.number().nullable().typeError(t("wtp.validation.type_error")),
            "productionType": yup.string().nullable().typeError(t("wtp.validation.type_error")),
            "production": yup.number().nullable().typeError(t("wtp.validation.type_error")),
            "rawmaterial": yup.number().nullable().typeError(t("wtp.validation.type_error")),
            "industryBranchId": yup.number().required(t("wtp.validation.required")).oneOf(industryBranches.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            "loadEntering": yup.number().nullable().typeError(t("wtp.validation.type_error")),
        }),
    }, [["sludgeDryLandfillD1", "sludgeDryLandfillD1Ratio"], ["sludgeDryLandfillD5", "sludgeDryLandfillD5Ratio"], ["sludgeDryTempStorage", "sludgeDryTempStorageRatio"], ["sludgeDryAgriculture", "sludgeDryAgricultureRatio"], ["sludgeDryEnergy", "sludgeDryEnergyRatio"], ["sludgeDryCompost", "sludgeDryCompostRatio"], ["sludgeDryOther", "sludgeDryOtherRatio"], ["sludgeDryOtherDescription", "sludgeDryOtherRatio"], ["sludgeDryOther", "sludgeDryOtherDescription"]]);

    const validationSchema2 = yup.object().shape({
        riverBasinDistrictId: yup.number().required(t("wtp.validation.required")).oneOf(riverBasinDistricts.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        riverBasinId: yup.number().required(t("wtp.validation.required")).oneOf(riverBasins.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        authorityId: yup.number().required(t("wtp.validation.required")).oneOf(authorities.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        administrativeRegionId: yup.number().nullable().required(t("wtp.validation.no_select")).oneOf(administrativeRegions.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        municipalId: yup.number().nullable().required(t("wtp.validation.no_select")).oneOf(authorities.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        latitude: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        longitude: yup.number().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        wasteTreatmentMethod: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.no_select")),
        disinfectionChlorination: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        disinfectionUltraviolet: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        disinfectionOzonation: yup.bool().nullable().typeError(t("wtp.validation.type_error")),
        otherTreatmentDescription: yup.string().nullable().when("wasteTreatmentMethod", (wasteTreatmentMethod, schema) => {
            return wasteTreatmentMethod?.includes('3other') ?
                yup.string().typeError(t("wtp.validation.required")) :
                schema;
        }),
        energyConsumption: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyConsumptionPerCubicMeter: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyFromRenewables: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        energyFromBiogas: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        greenhouseEmissions: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        greenhouseEmissionsType: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        measurementsRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        generalRemarks: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        reuse: yup.object().nullable().shape({
            "state": yup.number().required(t("wtp.validation.required")).oneOf(Object.values(IndustryState).filter(x => !isNaN(parseInt(x.toString()))).map(x => parseInt(x.toString())), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            "activityBranch": yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            "activityBranchText": yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        }),
    });

    const validationSchema = data?.isVirtual ? validationSchema2 : validationSchema1;

    return (
        <Container fluid className='menu-padding'>
            <ToastContainer className="p-3" position={'top-end'} containerPosition="fixed">
                <Toast
                    className="d-inline-block m-1"
                    bg={savingError ? 'danger' : 'success'}
                    key={0}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body className={'text-white'}>
                        {savingError ? t("wtp.save.error") : t("wtp.save.success")}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {!loading && !error && data && <Formik
                enableReinitialize
                initialValues={data}
                onSubmit={(values) => saveData(values)}
                onReset={(values, formikHelpers) => reload()}
                validationSchema={validationSchema}
                innerRef={formikRef}
            >
                {props => (
                    <>
                        <FormErrorNotification setPage={setActiveTab} isReuse={!!props.values.isVirtual} />
                        <form noValidate onSubmit={props.handleSubmit} onReset={props.handleReset}>
                            <Row>
                                <Col>
                                    <h4>{t("wtp.main.header")}</h4>
                                    <div><strong>{i18n.language === "en" ? data.nameEn : data.name}</strong>   {data.code}</div>
                                    <div className='text-muted mb-2' style={{ fontSize: "0.8rem", borderTop: "1px solid #bbbbbb", width: "max-content" }}> {t("wtp.main.last_updated")}: {data.lastUpdatedOn ? moment(new Date(data.lastUpdatedOn)).locale(i18n.language === "el" ? i18n.language : "en-gb").format("dddd, DD MMMM YYYY, HH:mm:ss") : "-"}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='position-relative'>
                                    <div style={{ minHeight: "calc(100vh - 382px)" }}>
                                        <Tabs
                                            // defaultActiveKey="general"
                                            activeKey={activeTab}
                                            className="mb-3"
                                            transition={false}
                                            onSelect={v => v && setActiveTab(v)}
                                        >
                                            <Tab eventKey="general" title={t("wtp.main.general")}>
                                                {props.values.isVirtual ? <WtpManageReuseGeneral
                                                    isShowing={activeTab === "general"}
                                                    riverBasins={riverBasins}
                                                    riverBasinDistricts={riverBasinDistricts}
                                                    administrativeRegions={administrativeRegions}
                                                    authorityTypes={authorityTypes}
                                                    iedCategories={iedCategories}
                                                    priorityCategories={priorityCategories}
                                                    authorities={authorities}
                                                    industryBranches={industryBranches}
                                                /> : <WtpManageGeneral
                                                    isShowing={activeTab === "general"}
                                                    riverBasins={riverBasins}
                                                    riverBasinDistricts={riverBasinDistricts}
                                                    administrativeRegions={administrativeRegions}
                                                    authorityTypes={authorityTypes}
                                                    iedCategories={iedCategories}
                                                    priorityCategories={priorityCategories}
                                                    authorities={authorities}
                                                />}
                                            </Tab>
                                            {props.values.isVirtual ? <></> : props.values.industry ? <Tab eventKey="industry" title={t("wtp.main.industry_characteristics")}>
                                                <WtpManageIndustryCharacteristics industryBranches={industryBranches} stakods={stakods} />
                                            </Tab> :
                                                <Tab eventKey="origin" title={t("wtp.main.waste_orgin")}>
                                                    <WtpManageWasteOrigin elstatSettlements={elstatSettlements} sewageNetworkTypes={sewageNetworkTypes} industryBranches={industryBranches} settlements={settlements} agglomerations={agglomerations} />
                                                </Tab>}
                                            {!props.values.isVirtual && <Tab eventKey="incoming-load" title={t("wtp.main.incoming_load")}>
                                                <WtpManageIncomingLoad />
                                            </Tab>}
                                            {!props.values.isVirtual && <Tab eventKey="processing" title={t("wtp.main.processing")}>
                                                <WtpManageProcessing />
                                            </Tab>}
                                            {!props.values.isVirtual && <Tab eventKey="energy-greenhouse-emissions" title={t("wtp.main.energy_greenhouse_emissions")}>
                                                <WtpManageEnergyGreenhouseEmissions />
                                            </Tab>}
                                            {!props.values.isVirtual && <Tab eventKey="disposal" title={t("wtp.main.disposal")}>
                                                <WtpManageDisposal isShowing={activeTab === "disposal"} />
                                            </Tab>}
                                            <Tab eventKey="reuse" title={t("wtp.main.reuse")}>
                                                <WtpManageReuse waterBodies={waterBodies} />
                                            </Tab>
                                            <Tab eventKey="measurements" disabled={props.values.samplingPoints === null} title={<>{props.values.isVirtual ? t("wtp.main.measurements_reuse") : t("wtp.main.measurements")} {props.values.samplingPoints === null && <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />}</>}>
                                                {(props.values as any).reuseEnabled ?
                                                    props.values.isVirtual ?
                                                        <WtpManageMeasurementsReuse setSamplingPointTypes={setSamplingPointTypes} samplingParameters={samplingParameters} samplingPointTypes={samplingPointTypes} shouldGetMeasurements={true} /> :
                                                        <Tabs
                                                            // defaultActiveKey="measurementsWtp"
                                                            className="mb-3"
                                                            transition={false}
                                                            activeKey={activeMeasurementsTab}
                                                            onSelect={v => v && setActiveMeasurementsTab(v)}
                                                        >
                                                            <Tab eventKey="measurementsWtp" title={t("wtp.main.measurements_wtp")}>
                                                                <WtpManageMeasurements samplingParameters={samplingParameters} samplingPointTypes={samplingPointTypes} setSamplingPointTypes={setSamplingPointTypes} />
                                                            </Tab>
                                                            <Tab eventKey="measurementsReuse" title={t("wtp.main.measurements_reuse")}>
                                                                <WtpManageMeasurementsReuse setSamplingPointTypes={setSamplingPointTypes} samplingParameters={samplingParameters} samplingPointTypes={samplingPointTypes} />
                                                            </Tab>
                                                        </Tabs> : <WtpManageMeasurements samplingParameters={samplingParameters} samplingPointTypes={samplingPointTypes} setSamplingPointTypes={setSamplingPointTypes} />}
                                            </Tab>
                                            <Tab eventKey="files" title={t("wtp.main.files")}>
                                                <WtpManageFiles />
                                            </Tab>
                                            <Tab eventKey="comments" title={t("wtp.main.comments")}>
                                                <WtpManageComments />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    <ButtonGroup className='position-fixed bottom-0 end-0 me-2 mb-2 p-2' style={{ backgroundColor: "#00000010" }} aria-label="Toolbar">
                                        <Button variant="success" type="submit" className="d-flex align-items-center justify-content-center m-0" disabled={saving || props.values.samplingPoints === null}>{saving ? <><Spinner variant="light" animation="border" size="sm" className="me-2" /> {t("wtp.button.saving")}</> : <><Save className="me-2"></Save> {t("wtp.button.save")}</>}</Button>
                                        <Button key={1} variant="danger" type="reset" className="d-flex align-items-center justify-content-center m-0" disabled={saving}><ArrowCounterclockwise className="me-2"></ArrowCounterclockwise> {t("wtp.button.reset")}</Button>
                                        <Button key={2} variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => navigate('/wastewatertreatmentplants/index')} disabled={saving}><SkipBackward className="me-2"></SkipBackward> {t("wtp.button.back")}</Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </form>
                    </>)}
            </Formik>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default WtpManage;
