export const wasteTreatmentOptions = [
    {
        name: "Επιλέξτε επεξεργασία λυμάτων",
        nameEn: "Please select waste treatment",
    },
    {
        code: "1",
        name: "Πρωτοβάθμια επεξεργασία",
        nameEn: "Primary treatment",
        unique: true,
        disinfection: false,
    },
    {
        code: "2",
        name: "Δευτεροβάθμια επεξεργασία",
        nameEn: "Secondary treatment",
        unique: true,
        disinfection: false,
    },
    {
        code: "3m",
        name: "Δευτεροβάθμια επεξεργασία και απολύμανση",
        nameEn: "More stringent disinfection",
        unique: true,
        disinfection: true,
    },
    {
        code: "3N",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση αζώτου",
        nameEn: "More stringent nitrogen removal",
        unique: true,
        disinfection: false,
    },
    {
        code: "3Nm",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση αζώτου και απολύμανση",
        nameEn: "More stringent nitrogen removal and disinfection",
        unique: true,
        disinfection: true,
    },
    {
        code: "3NP",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση αζώτου και φωσφόρου",
        nameEn: "More stringent nitrogen and phosphorus removal",
        unique: true,
        disinfection: false,
    },
    {
        code: "3NPm",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση αζώτου και φωσφόρου και απολύμανση",
        nameEn: "More stringent nitrogen and phosphorus removal and disinfection",
        unique: true,
        disinfection: true,
    },
    {
        code: "3P",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση φωσφόρου",
        nameEn: "More stringent phosphorus removal",
        unique: true,
        disinfection: false,
    },
    {
        code: "3Pm",
        name: "Δευτεροβάθμια επεξεργασία με απομάκρυνση φωσφόρου και απολύμανση",
        nameEn: "More stringent phosphorus removal and disinfection",
        unique: true,
        disinfection: true,
    },
    {
        code: "3other",
        name: "Άλλη αυστηρότερη επεξεργασία (περιγράψτε)",
        nameEn: "Any other more stringent treatment (please provide an explantion)",
        unique: false,
        disinfection: false,
    }
];

export const sludgeTreatmentOptions = [
    {
        name: "Επιλέξτε επεξεργασία ιλύος",
        nameEn: "Please select sludge treatment",
    },
    {
        code: "11s",
        name: "Πάχυνση σε βαρυτικούς παχυντές",
        nameEn: "Gravity thickening",
    },
    {
        code: "12s",
        name: "Μηχανική πάχυνση",
        nameEn: "Mechanical thickening",
    },
    {
        code: "21s",
        name: "Αερόβια σταθεροποίηση",
        nameEn: "Aerobic stabilisation",
    },
    {
        code: "22s",
        name: "Αναερόβια σταθεροποίηση",
        nameEn: "Anaerobic stabilisation",
    },
    {
        code: "31s",
        name: "Μηχανική αφυδάτωση",
        nameEn: "Mechanical dewatering",
    },
    {
        code: "32s",
        name: "Αφυδάτωση σε κλίνες ξήρανσης",
        nameEn: "Dewatering in drying beds",
    },
    {
        code: "41s",
        name: "Θερμική ξήρανση",
        nameEn: "Thermal drying",
    },
    {
        code: "42s",
        name: "Ηλιακή ξήρανση",
        nameEn: "Solar drying",
    },
    {
        code: "4sother",
        name: "Άλλη αυστηρότερη επεξεργασία (περιγράψτε)",
        nameEn: "Any other more stringent treatment (please provide an explantion)",
    },
];

export const reuseOptions = [
    {
        value: 0,
        label: "query.reuse.supplies.irrigation"
    },
    {
        value: 1,
        label: "query.reuse.supplies.filtration_aquifers_enrichment"
    },
    {
        value: 2,
        label: "query.reuse.supplies.drilling_aquifers_enrichment"
    },
    {
        value: 3,
        label: "query.reuse.supplies.urban_green"
    },
    {
        value: 4,
        label: "query.reuse.supplies.urban_usage"
    },
    {
        value: 5,
        label: "query.reuse.supplies.industrial_usage"
    },
    {
        value: 6,
        label: "query.reuse.supplies.other_method"
    },
];

export const reuseOptionsEn = [
    {
        value: 0,
        label: "query.reuse.supplies.irrigation"
    },
    {
        value: 1,
        label: "query.reuse.supplies.filtration_aquifers_enrichment"
    },
    {
        value: 2,
        label: "query.reuse.supplies.drilling_aquifers_enrichment"
    },
    {
        value: 3,
        label: "query.reuse.supplies.urban_green"
    },
    {
        value: 4,
        label: "query.reuse.supplies.urban_usage"
    },
    {
        value: 5,
        label: "query.reuse.supplies.industrial_usage"
    },
    {
        value: 6,
        label: "query.reuse.supplies.other_method"
    },
];

export const colorArray = [
    "#FDC395",
    "#FF9F86",
    "#172350",
    "#DF799A",
    "#241FDF",
    "#80A06F",
    "#18AF09",
    "#9F2B6F",
    "#023A02",
    "#C0B241",
    "#EAA95B",
    "#CDB6DB",
    "#8C6D33",
    "#9395A5",
    "#DEC9A7",
    "#D145DA",
    "#B32B11",
    "#A3C51E",
    "#A04525",
    "#632262",
    "#106539",
    "#91BC82",
    "#35C866",
    "#E070B9",
    "#B6EEAF",
    "#8BF41A",
    "#5B795A",
    "#8C4D93",
    "#3F8447",
    "#BCA9E1",
    "#B776B9",
    "#E1F09C",
    "#1C3425",
    "#122F8F",
    "#2A977D",
    "#396F50",
    "#87E02E",
    "#304E20",
    "#511A32",
    "#B26FE1",
    "#D14CB5",
    "#001063",
    "#CDBE79",
    "#FF23C8",
    "#DE2562",
    "#0F2137",
    "#7C3CE8",
    "#B17193",
    "#2707D7",
    "#958BD3",
    "#C47529",
    "#A9EC90",
    "#753D93",
    "#C245E3",
    "#B9AFBE",
    "#EEB5D8",
    "#C647C6",
    "#2E46BE",
    "#9AB099",
    "#6EDDC3",
    "#4B0A38",
    "#BA4B92",
    "#F745AD",
    "#D901C7",
    "#3677C3",
    "#3CFA33",
    "#DC0CA4",
    "#228EDE",
    "#E34316",
    "#989450",
    "#F53466",
    "#FAB636",
    "#C6A5DA",
    "#24ABC0",
    "#987133",
    "#DC7CB6",
    "#DDE3B5",
    "#7FF48C",
    "#8471C0",
    "#6EC993",
    "#DE1A8F",
    "#85ACD0",
    "#C10F0F",
    "#39C1DF",
    "#408F0F",
    "#160EEE",
    "#413AD4",
    "#6554F9",
    "#274EDD",
    "#347906",
    "#15C28E",
    "#39C9B6",
    "#339226",
    "#428E2C",
    "#706DE8",
    "#EB0241",
    "#F73DEB",
    "#C1F25D",
    "#13D62C",
    "#4B463D"
];