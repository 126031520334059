import { useEffect, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TreeMap: React.FC<{ data: any }> = ({ data }) => {
    const { t, i18n } = useTranslation();

    if (!data) return <></>;

    const p = 450 / data.TotalAgglomerations.value;
    // const h1 = data.Compliant.value * p;
    // const h2 = data.NonCompliant.value * p;
    const h3 = Math.max(data.InsufficientData.value * p, 64);
    const h4 = Math.max(data.OperationalProblems.value * p, 64);
    const h5 = Math.max(data.PlannedProjects.value * p, 64);
    const h6 = Math.max(data.MatureProjects.value * p, 64);
    const h7 = Math.max(data.ImmatureProjects.value * p, 64);
    const ih = (data.InsufficientData.value + data.OperationalProblems.value + data.PlannedProjects.value + data.MatureProjects.value + data.ImmatureProjects.value) * p;
    const h2 = h3 + h4 + h5 + h6 + h7;
    const h1 = data.Compliant.value * p * h2 / ih;
    const h = h1 + h2;

    return (<>
        <p className='text-center' style={{ whiteSpace: "pre-line" }}>{data.Subtitle.value}</p>
        <Row style={{ height: "fit-content" }}>
            <OverlayTrigger
                overlay={
                    <Tooltip>
                        {`${t("project_data.total_agglomerations")}: ${data.TotalAgglomerations.value || 0}`}
                    </Tooltip>
                }
            >
                <Col className="project-data-col" style={{ backgroundColor: data.TotalAgglomerationsColor.value, borderRight: "2px solid #999" }}>
                    <div className="project-data-col-text">
                        <h4>{data.TotalAgglomerations.value || 0}</h4>
                        <div>{t("project_data.total_agglomerations")}</div>
                    </div>
                </Col>
            </OverlayTrigger>
            <Col>
                <Row style={{ height: h1 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.compliant")}: ${data.Compliant.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.CompliantColor.value, borderRight: "2px solid #999" }}>
                            <div className="project-data-col-text">
                                <h4>{data.Compliant.value || 0}</h4>
                                <div>{t("project_data.compliant")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h2 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.non_compliant")}: ${data.NonCompliant.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.NonCompliantColor.value, borderRight: "2px solid #999" }}>
                            <div className="project-data-col-text">
                                <h4>{data.NonCompliant.value || 0}</h4>
                                <div>{t("project_data.non_compliant")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
            </Col>
            <Col>
                <Row style={{ height: h1 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.compliant")}: ${data.Compliant.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.CompliantColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.Compliant.value || 0}</h4>
                                <div>{t("project_data.compliant")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h3 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.insufficient_data")}: ${data.InsufficientData.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.InsufficientDataColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.InsufficientData.value || 0}</h4>
                                <div>{t("project_data.insufficient_data")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h4 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.operational_problems")}: ${data.OperationalProblems.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.OperationalProblemsColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.OperationalProblems.value || 0}</h4>
                                <div>{t("project_data.operational_problems")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h5 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.planned_projects")}: ${data.PlannedProjects.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.PlannedProjectsColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.PlannedProjects.value || 0}</h4>
                                <div>{t("project_data.planned_projects")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h6 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.mature_projects")}: ${data.MatureProjects.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.MatureProjectsColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.MatureProjects.value || 0}</h4>
                                <div>{t("project_data.mature_projects")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
                <Row style={{ height: h7 }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                {`${t("project_data.immature_projects")}: ${data.ImmatureProjects.value || 0}`}
                            </Tooltip>
                        }
                    >
                        <Col className="project-data-col" style={{ backgroundColor: data.ImmatureProjectsColor.value }}>
                            <div className="project-data-col-text">
                                <h4>{data.ImmatureProjects.value || 0}</h4>
                                <div>{t("project_data.immature_projects")}</div>
                            </div>
                        </Col>
                    </OverlayTrigger>
                </Row>
            </Col>
        </Row>
    </>
    );
}

export default TreeMap;
