import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, FloatingLabel, Form, ListGroup, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { Link, Navigate, redirect, useLocation, useNavigate } from "react-router-dom";
import useFetch from 'use-http';
import { SamplingPointType, WastewaterTreatementPlantReporter, WastewaterTreatementPlantUser, WastewaterTreatmentPlantType } from '../../../data/Definitions';
import { PlusCircle, PencilFill, Trash, Pencil, Eye } from 'react-bootstrap-icons';
import { Formik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import { ConfirmationPopover } from '../../../components/ConfirmationPopover';

const WastewaterTreatmentPlantsOperatorIndex: React.FC = (props) => {
    const [wastewaterTreatmentPlants, setWastewaterTreatmentPlants] = useState<WastewaterTreatementPlantReporter[]>();
    const [users, setUsers] = useState<WastewaterTreatementPlantUser[]>([]);
    const [loadingError, setLoadingError] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const editFormRef = useRef<any>(null);
    const [selectedWastewaterTreatmentPlant, setSelectedWastewaterTreatmentPlant] = useState<WastewaterTreatementPlantReporter>();

    const { get, put, del, response, loading, error, cache } = useFetch('/api');
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Wastewater Treatment Plants" });
    }, [loc.pathname]);

    useEffect(() => {
        getWastewaterTreatmentPlants();
    }, [])

    const getWastewaterTreatmentPlants = async () => {
        setLoadingError(false);
        let wtps = await get('/wastewatertreatmentplant');
        if (response.ok) {
            if (wtps.length === 1) {
                navigate(`/wastewatertreatmentplants/view/${wtps[0].id}`);
            } else {
                setWastewaterTreatmentPlants(wtps);
            }
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    return (
        <Container fluid className='menu-padding'>
            {!loading && !error && wastewaterTreatmentPlants && <>
                <Row>
                    <Col>
                        <h4>{t("Wastewater Treatment Plants")}</h4>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Table striped size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("wastewatertreatmentplants.wastewatertreatmentplant_code")}</th>
                                    <th>{t(i18n.language === "en" ? "wastewatertreatmentplants.wastewatertreatmentplant_name_english" : "wastewatertreatmentplants.wastewatertreatmentplant_name")}</th>
                                    <th>{t("wastewatertreatmentplants.type")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wastewaterTreatmentPlants.length === 0 ?
                                    <tr>
                                        <td colSpan={4} className="text-center">
                                            {t("wtp.table.no_data")}
                                        </td>
                                    </tr> :
                                    wastewaterTreatmentPlants.map((x: WastewaterTreatementPlantReporter, i: number) => <tr key={i}>
                                        <td className="text-center">{
                                            <ButtonGroup>
                                                <Link to={`/wastewatertreatmentplants/view/${x.id}`}>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        style={{ border: "none" }}
                                                        className="d-flex align-items-center justify-content-center m-0"
                                                    >
                                                        <Eye />
                                                    </Button>
                                                </Link>
                                            </ButtonGroup>
                                        }</td>
                                        <td>{x.code}</td>
                                        <td>{i18n.language === "en" ? x.nameEn : x.name}</td>
                                        <td>{t(WastewaterTreatmentPlantType[x.type])}</td>
                                    </tr>)}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default WastewaterTreatmentPlantsOperatorIndex;
