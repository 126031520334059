import React, { FormEvent, useEffect, useRef, useState, useMemo, useContext, useCallback, useLayoutEffect } from "react";
import { Row, Col, Form, Button, ButtonGroup, Table, Pagination, Modal, Alert, Tabs, Tab, Dropdown, DropdownButton } from "react-bootstrap";
import { PlusCircle, PencilFill, Trash, CollectionPlayFill, Check2Circle, Pencil, PlusCircleDotted, Plus, DashCircle, FiletypeCsv } from "react-bootstrap-icons";
import { LineChart, XAxis, CartesianGrid, Line, YAxis, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { CheckResult, ComplianceStatus, ParameterCheckResult, SamplingParameter, SamplingPointType, WastewaterTreatementPlant, WastewaterTreatementPlantEnvironmentalPermit, WastewaterTreatementPlantEnvironmentalPermitLimit, WastewaterTreatementPlantSamplingPoint } from "../../data/Definitions";
import { useTranslation } from 'react-i18next';
import { Formik, useFormikContext } from "formik";
import * as yup from 'yup';
import Select from 'react-select';
import MeasurementsFromFile from "../../components/MeasurementsFromFile";
import SamplerPositionsModal from "../../components/SamplerPositionsModal";
import moment from "moment";
import useFetch from "use-http";
import { AppContext } from "../../state/State";
import { colorArray } from "../../data/Data";


const WtpManageMeasurements: React.FC<{ samplingParameters: SamplingParameter[], samplingPointTypes: SamplingPointType[], setSamplingPointTypes: Function }> = ({ samplingParameters, samplingPointTypes, setSamplingPointTypes }) => {
    const [selectedSamplingPointType, setSelectedSamplingPointType] = useState(samplingPointTypes[0]?.id ?? 0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("all");
    const [yearFilter, setYearFilter] = useState<number>(-1);
    const [startDateFilter, setStartDateFilter] = useState<string | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<string | null>(null);
    const [inputData, setInputData] = useState<WastewaterTreatementPlantSamplingPoint[][]>([]);
    // const [outputData, setOutputData] = useState<WastewaterTreatementPlantSamplingPoint[]>([]);
    const [inputDataReversed, setInputDataReversed] = useState<WastewaterTreatementPlantSamplingPoint[][]>([]);
    // const [outputDataReversed, setOutputDataReversed] = useState<WastewaterTreatementPlantSamplingPoint[]>([]);
    const { t, i18n } = useTranslation();
    const { handleChange, handleBlur, values, touched, errors, setFieldValue } = useFormikContext();
    const [addSamplingPointModalShow, setAddSamplingPointModalShow] = useState(false);
    const addSamplingPointFormRef = useRef<any>(null);
    const addSamplingPointFormikRef = useRef<any>(null);
    const [editSamplingPointModalShow, setEditSamplingPointModalShow] = useState(false);
    const editSamplingPointFormRef = useRef<any>(null);
    const editSamplingPointFormikRef = useRef<any>(null);
    const [selectedSamplingPoint, setSelectedSamplingPoint] = useState<WastewaterTreatementPlantSamplingPoint | null>(null);
    const [visibleParametersInChart, setVisibleParametersInChart] = useState<string[]>([]);
    const [dataToDisplay, setDataToDisplay] = useState<WastewaterTreatementPlantSamplingPoint[]>([]);
    const [parametersInSamplingForm, setParametersInSamplingForm] = useState<{ id: number, parameterId: number, value: number }[]>([]);
    const [parametersInEditSamplingForm, setParametersInEditSamplingForm] = useState<{ id: number, parameterId: number, value: number }[]>([]);
    const [parametersInLimitsForm, setParametersInLimitsForm] = useState<{ id: number, parameterId: number, limitValue?: number, compliancePercentage?: number, minSampleCount?: number }[]>([]);
    const [sameParameterError, setSameParameterError] = useState(false);
    const [addEnvironmentalPermitModalShow, setAddEnvironmentalPermitModalShow] = useState(false);
    const addEnvironmentalPermitFormRef = useRef<any>(null);
    const addEnvironmentalPermitFormikRef = useRef<any>(null);
    const [editEnvironmentalPermitModalShow, setEditEnvironmentalPermitModalShow] = useState(false);
    const editEnvironmentalPermitFormRef = useRef<any>(null);
    const [selectedEnvironmentalPermit, setSelectedEnvironmentalPermit] = useState<WastewaterTreatementPlantEnvironmentalPermit | null>(null);
    const [addSamplingPointFileModalShow, setAddSamplingPointFileModalShow] = useState(false);
    const [samplingModalShow, setSamplingModalShow] = useState(false);
    const [checkComplianceModalShow, setCheckComplianceModalShow] = useState(false);
    const [complianceLegislationResults, setComplianceLegislationResults] = useState<CheckResult[]>([]);
    const [complianceAepoResults, setComplianceAepoResults] = useState<CheckResult[]>([]);
    const [selectedSamplingParameters, setSelectedSamplingParameters] = useState<(SamplingParameter & { checked: boolean, static: boolean })[]>(samplingParameters.map((x, i) => { return Object.assign(x, { checked: i < 7 ? true : false, static: i < 7 ? true : false }) }));
    const { get, post, response, loading, error, cache } = useFetch('/api');
    const context = useContext(AppContext);

    const samplingSchema = yup.object().shape(Object.assign(
        {
            samplingDate: yup.date().required(t("wtp.validation.required")).min(new Date("2000"), t("wtp.validation.date_invalid")).max(new Date(), t("wtp.validation.date_invalid")).typeError(t("wtp.validation.type_error")),
            samplingPointTypeId: yup.number().required(t("wtp.validation.required")).oneOf(samplingPointTypes.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        },
        Object.fromEntries(parametersInSamplingForm.map((x, i) => [`parameter_${i}`, yup.number().required(t("wtp.validation.required")).oneOf(Array.from({ length: samplingParameters.length }, (item, index) => index + 1), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInSamplingForm.map((x, i) => [`parameter_value_${i}`, yup.number().nullable().min(0.0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toFixed(2)}`).typeError(t("wtp.validation.type_error")),]))
    ));

    const limitsAddSchema = yup.object().shape(Object.assign(
        {
            dateIssued: yup.date().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            dateExpired: yup.date().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            samplingPointTypeId: yup.number().required(t("wtp.validation.required")).oneOf(samplingPointTypes.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            comments: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        },
        Object.fromEntries(parametersInLimitsForm.map((x, i) => [`parameter_${i}`, yup.number().required(t("wtp.validation.required")).oneOf(Array.from({ length: samplingParameters.length }, (item, index) => index + 1), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInLimitsForm.map((x, i) => [`parameter_value_${i}`, yup.number().required(t("wtp.validation.required")).min(0.0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toFixed(2)}`).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInLimitsForm.map((x, i) => [`parameter_count_${i}`, yup.number().required(t("wtp.validation.required")).min(1, (v) => `${t("wtp.validation.greater_than")} ${v.min}`).max(365, (v) => `${t("wtp.validation.less_than")} ${v.max}`).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries(parametersInLimitsForm.map((x, i) => [`parameter_compliance_${i}`, yup.number().required(t("wtp.validation.required")).min(0.0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toFixed(2)}`).max(100.0, (v) => `${t("wtp.validation.less_than")} ${v.max.toFixed(2)}`).typeError(t("wtp.validation.type_error")),]))
    ));

    const limitsEditSchema = yup.object().shape(Object.assign(
        {
            dateIssued: yup.date().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            dateExpired: yup.date().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
            samplingPointTypeId: yup.number().required(t("wtp.validation.required")).oneOf(samplingPointTypes.map(x => x.id), t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
            comments: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        },
        Object.fromEntries((selectedEnvironmentalPermit?.limits ?? []).map((x, i) => [`parameter_value_${x?.name}`, yup.number().required(t("wtp.validation.required")).min(0.0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toFixed(2)}`).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries((selectedEnvironmentalPermit?.limits ?? []).map((x, i) => [`parameter_count_${x?.name}`, yup.number().required(t("wtp.validation.required")).min(1, (v) => `${t("wtp.validation.greater_than")} ${v.min}`).max(365, (v) => `${t("wtp.validation.less_than")} ${v.max}`).typeError(t("wtp.validation.type_error")),])),
        Object.fromEntries((selectedEnvironmentalPermit?.limits ?? []).map((x, i) => [`parameter_compliance_${x?.name}`, yup.number().required(t("wtp.validation.required")).min(0.0, (v) => `${t("wtp.validation.greater_than")} ${v.min.toFixed(2)}`).max(100.0, (v) => `${t("wtp.validation.less_than")} ${v.max.toFixed(2)}`).typeError(t("wtp.validation.type_error")),]))
    ));

    useEffect(() => {
        if (selectedSamplingPointType === 0)
            setSelectedSamplingPointType(samplingPointTypes[0]?.id ?? 0);
    }, [samplingPointTypes])

    useEffect(() => {
        handleMeasurementsData();
    }, [filter, yearFilter, startDateFilter, endDateFilter])

    useEffect(() => {
        if (!addSamplingPointFileModalShow) {
            handleMeasurementsData();
        }
    }, [addSamplingPointFileModalShow])

    useEffect(() => {
        if (!addSamplingPointModalShow) {
            setParametersInSamplingForm([]);
            setSameParameterError(false);
            handleMeasurementsData();
        }
    }, [addSamplingPointModalShow])

    useEffect(() => {
        if (!editSamplingPointModalShow) {
            setSelectedSamplingPoint(null);
            setParametersInEditSamplingForm([]);
            setSameParameterError(false);
            handleMeasurementsData();
        }
    }, [editSamplingPointModalShow])

    useEffect(() => {
        if (!addEnvironmentalPermitModalShow) {
            setParametersInLimitsForm([]);
            setSameParameterError(false);
        }
    }, [addEnvironmentalPermitModalShow])

    useEffect(() => {
        if (!editEnvironmentalPermitModalShow) {
            setSelectedEnvironmentalPermit(null);
            setParametersInLimitsForm([]);
            setSameParameterError(false);
        }
    }, [editEnvironmentalPermitModalShow])

    useEffect(() => {
        // console.log(inputData);
        if (inputData.length === 0) {
            setDataToDisplay([]);
        } else {
            let arr = inputData[selectedSamplingPointType] ? [...inputData[selectedSamplingPointType]] : [];
            arr.sort((a, b) => new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime());
            let data = arr.slice((currentPage - 1) * entriesPerPage, Math.min(currentPage * entriesPerPage, arr.length))
            setDataToDisplay(data);
        }
    }, [inputData, currentPage, entriesPerPage, selectedSamplingPointType])

    useLayoutEffect(() => {
        getMeasurements();
    }, [])

    useEffect(() => {
        if (checkComplianceModalShow) {
            getCompliance();
        } else {
            setComplianceLegislationResults([]);
            setComplianceAepoResults([]);
        }
    }, [checkComplianceModalShow])

    const getMeasurements = async () => {
        cache.delete(`url:/api/wastewatertreatmentplant/${(values as any).id}/measurements||method:GET||body:`);
        let ms = await get(`/wastewatertreatmentplant/${(values as any).id}/measurements`);
        if (response.ok) {
            setFieldValue("samplingPoints", ms);
            handleMeasurementsData(ms);
            return ms;
        }
        if (error) {
            setFieldValue("samplingPoints", []);
            return [];
        }
        return [];
    }

    const getCompliance = async () => {
        let clrs = await get(`/compliance/${(values as any).code}/legislation`);
        if (response.ok) {
            setComplianceLegislationResults(clrs);
        }

        let cars = await get(`/compliance/${(values as any).code}/aepo`);
        if (response.ok) {
            setComplianceAepoResults(cars);
        }
    }

    const recalculateAepo = async () => {
        cache.delete(`url:/api/compliance/${(values as any).code}/aepo||method:POST||body:`);
        await post(`/compliance/${(values as any).code}/aepo`);
        if (response.ok) {
            cache.delete(`url:/api/compliance/${(values as any).code}/aepo||method:GET||body:`);
            await getCompliance();
        }
    }

    const recalculateLegislation = async () => {
        cache.delete(`url:/api/compliance/${(values as any).code}/legislation||method:POST||body:`);
        await post(`/compliance/${(values as any).code}/legislation`);
        if (response.ok) {
            cache.delete(`url:/api/compliance/${(values as any).code}/legislation||method:GET||body:`);
            await getCompliance();
        }
    }

    const handleMeasurementsData = (inM?: any[]) => {
        let measurements = (values as any).samplingPoints;
        if (!measurements) {
            measurements = inM;
        }
        if (!measurements) {
            return
        }

        let data = measurements.filter((x: WastewaterTreatementPlantSamplingPoint) => !x.isReuse).sort((a: WastewaterTreatementPlantSamplingPoint, b: WastewaterTreatementPlantSamplingPoint) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });
        switch (filter) {
            case "year":
                if (yearFilter >= 0) {
                    data = data.filter((x: WastewaterTreatementPlantSamplingPoint) => new Date(x.samplingDate!).getFullYear() === yearFilter);
                }
                break;
            case "range":
                if (startDateFilter || endDateFilter) {
                    let start: string | null = startDateFilter;
                    let end: string | null = endDateFilter;

                    if (!start) start = data[data.length - 1].samplingDate;
                    if (!end) end = data[0].samplingDate;

                    data = data.filter((x: WastewaterTreatementPlantSamplingPoint) => new Date(x.samplingDate!) >= new Date(start!));
                    data = data.filter((x: WastewaterTreatementPlantSamplingPoint) => new Date(x.samplingDate!) <= new Date(end!));
                }
                break;
            default:
                break;
        }

        let inputSamples = [];
        let inputSamplesReversed = [];
        for (var samplingpointType of samplingPointTypes) {
            let tempData = data.filter((x: WastewaterTreatementPlantSamplingPoint) => x.samplingPointTypeId === samplingpointType.id);
            inputSamples[samplingpointType.id] = tempData;
            inputSamplesReversed[samplingpointType.id] = tempData.reverse();
        }

        setInputData([...inputSamples]);
        setInputDataReversed([...inputSamplesReversed]);
        setVisibleParametersInChart(samplingParameters.slice(0, 3).map(x => x.name));
    }

    const getComplianceStatus = (parameterId: number, yearResults: ParameterCheckResult[]) => {
        var r = yearResults.find(x => x.parameterId === parameterId);
        if (!r) return "❔";
        switch (r.complianceStatus) {
            case ComplianceStatus.Pass: return "✔️";
            case ComplianceStatus.Fail: return "❌";
            case ComplianceStatus.NotRequired: return "󠁼➖";
            default: return "❔";
        }
    }

    const getPagination = () => {
        let rows;
        if (inputData.length === 0) {
            rows = 0;
        } else {
            rows = inputData[selectedSamplingPointType]?.length ?? 0;
        }

        let page = currentPage,
            between = 3,
            total = rows,
            limit = entriesPerPage ?? 10,
            changePage = (page: number) => setCurrentPage(page),
            next = true,
            last = true,
            ellipsis = 3;

        const total_pages = Math.ceil(total / limit)
        between = between < 1 ? 1 : between
        page = (page < 1 ? 1 : page > total_pages ? total_pages : page)
        ellipsis = ellipsis < 1 ? 0 : ellipsis + 2 >= between ? between - 2 : ellipsis

        let positions = Array.from({ length: total_pages }, (_, i) => i)

        const qtd_pages = (between * 2) + 1
        const range = (
            total_pages <= qtd_pages
                // Show active without slice
                ? positions
                : page - 1 <= between
                    // Show active in left
                    ? positions.slice(0, qtd_pages - (ellipsis > 0 ? ellipsis + 1 : 0))
                    : page + between >= total_pages
                        // Show active in right
                        ? positions.slice(total_pages - qtd_pages + (ellipsis > 0 ? ellipsis + 1 : 0), total_pages)
                        // Show active in middle
                        : positions.slice((page - 1) - (between - (ellipsis > 0 ? ellipsis + 1 : 0)), page + (between - (ellipsis > 0 ? ellipsis + 1 : 0)))
        )

        return total !== null && total > 0
            ? <Form.Group as={Row} className="mb-2 me-1 justify-content-between">
                <Form.Label column>{t("wtp.pagination.page")} <strong>{currentPage}</strong> {t("wtp.pagination.of")} <strong>{total_pages}</strong> ({rows} {t("wtp.pagination.entries")})</Form.Label>
                <Col className="pe-0">
                    <Pagination>
                        {
                            last
                            && <Pagination.First
                                onClick={() => page > 1 ? changePage(1) : {}}
                                disabled={page <= 1} />
                        }
                        {
                            next
                            && <Pagination.Prev
                                onClick={() => page > 1 ? changePage(page - 1) : {}}
                                disabled={page <= 1} />
                        }
                        {
                            total_pages > (between * 2) + 1 && ellipsis > 0
                            && positions.slice(0, page - 1 <= between ? 0 : ellipsis).map(value => {
                                return <Pagination.Item key={value}
                                    onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                                    {value + 1}
                                </Pagination.Item>
                            })
                        }
                        {
                            // Show ellipsis when "page" is bigger than "between"
                            total_pages > (between * 2) + 1 && ellipsis > 0 && page - 1 > between
                            && <Pagination.Ellipsis disabled />
                        }
                        {range.map(value => {
                            return <Pagination.Item active={value === page - 1}
                                key={value}
                                onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                                {value + 1}
                            </Pagination.Item>
                        })}
                        {
                            // Show ellipsis when "page" is lower than "between"
                            total_pages > (between * 2) + 1 && ellipsis > 0 && page < total_pages - between
                            && <Pagination.Ellipsis disabled />
                        }
                        {
                            total_pages > (between * 2) + 1 && ellipsis > 0
                            && positions.slice(page >= total_pages - between ? total_pages : total_pages - ellipsis, total_pages).map(value => {
                                return <Pagination.Item key={value}
                                    onClick={() => value !== page - 1 ? changePage(value + 1) : {}}>
                                    {value + 1}
                                </Pagination.Item>
                            })
                        }
                        {
                            next
                            && <Pagination.Next
                                onClick={() => page < total_pages ? changePage(page + 1) : {}}
                                disabled={page >= total_pages} />
                        }
                        {
                            last
                            && <Pagination.Last
                                onClick={() => page < total_pages ? changePage(total_pages) : {}}
                                disabled={page >= total_pages} />
                        }
                    </Pagination>
                </Col>
                <Col className="pe-0">
                    <Form.Group as={Row} className="mb-2 me-2">
                        <Form.Label column className="text-end" md={{ span: 4, offset: 5 }}>{t("wtp.pagination.entries_per_page")}</Form.Label>
                        <Col md={{ span: 3 }} className="pe-0">
                            <Form.Select aria-label="Επιλογή" onChange={v => { setEntriesPerPage(parseInt(v.target.value)); setCurrentPage(1); }}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Col>
            </Form.Group>
            : <></>
    }

    const handleAddSamplingPointSubmit = (val: any) => {
        if (sameParameterError) {
            return;
        }
        let cur = (values as any).samplingPoints;
        let temp = samplingParameters.map(x => {
            return {
                id: x.id,
                name: x.name,
                unit: x.unit,
                description: x.description,
                value: (parametersInSamplingForm.find(y => y.parameterId === x.id)?.value === null || parametersInSamplingForm.find(y => y.parameterId === x.id)?.value === undefined) ? null : parametersInSamplingForm.find(y => y.parameterId === x.id)?.value,
            }
        }).filter(x => x.value !== null && x.value !== undefined);
        const samplingPointTypeId = parseInt(val.samplingPointTypeId.toString());
        cur = cur.filter((x: any) => !(moment(x.samplingDate).format('L') === moment(val.samplingDate).format('L') && x.samplingPointTypeId === samplingPointTypeId && x.isReuse === false));
        cur.push({
            samplingDate: val.samplingDate.toString(),
            samplingPointTypeId: samplingPointTypeId,
            isReuse: false,
            samples: temp as SamplingParameter[],
        });
        let data = cur.sort((a: WastewaterTreatementPlantSamplingPoint, b: WastewaterTreatementPlantSamplingPoint) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });
        let inputSamples = [];
        let inputSamplesReversed = [];
        for (var samplingpointType of samplingPointTypes) {
            let tempData = data.filter((x: WastewaterTreatementPlantSamplingPoint) => x.samplingPointTypeId === samplingpointType.id);
            inputSamples[samplingpointType.id] = tempData;
            inputSamplesReversed[samplingpointType.id] = tempData.reverse();
        }

        setInputData([...inputSamples]);
        setInputDataReversed([...inputSamplesReversed]);
        setFieldValue("samplingPoints", cur);
        setAddSamplingPointModalShow(false);
        setSelectedSamplingPoint(null);
    };

    const handleEditSamplingPointSubmit = (val: any) => {
        if (!selectedSamplingPoint) {
            return;
        }
        if (sameParameterError) {
            return;
        }
        let cur = (values as any).samplingPoints;
        let temp = samplingParameters.map(x => {
            return {
                id: x.id,
                name: x.name,
                unit: x.unit,
                description: x.description,
                value: (parametersInEditSamplingForm.find(y => y.parameterId === x.id)?.value === null || parametersInEditSamplingForm.find(y => y.parameterId === x.id)?.value === undefined) ? null : parametersInEditSamplingForm.find(y => y.parameterId === x.id)?.value,
            }
        }).filter(x => x.value !== null && x.value !== undefined);
        const samplingPointTypeId = parseInt(val.samplingPointTypeId.toString());
        cur = cur.filter((x: any) => !(moment(x.samplingDate).format('L') === moment(val.samplingDate).format('L') && x.samplingPointTypeId === samplingPointTypeId && x.isReuse === false));
        cur.push({
            samplingDate: val.samplingDate.toString(),
            samplingPointTypeId: samplingPointTypeId,
            isReuse: false,
            samples: temp as SamplingParameter[],
        });
        let data = cur.sort((a: WastewaterTreatementPlantSamplingPoint, b: WastewaterTreatementPlantSamplingPoint) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });
        let inputSamples = [];
        let inputSamplesReversed = [];
        for (var samplingpointType of samplingPointTypes) {
            let tempData = data.filter((x: WastewaterTreatementPlantSamplingPoint) => x.samplingPointTypeId === samplingpointType.id);
            inputSamples[samplingpointType.id] = tempData;
            inputSamplesReversed[samplingpointType.id] = tempData.reverse();
        }

        setInputData([...inputSamples]);
        setInputDataReversed([...inputSamplesReversed]);
        setFieldValue("samplingPoints", cur);
        setEditSamplingPointModalShow(false);
        setSelectedSamplingPoint(null);
    };

    // const handleEditSamplingPointSubmit = (val: any) => {
    //     if (!selectedSamplingPoint) return;
    //     let cur = (values as any).samplingPoints;
    //     let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantSamplingPoint) => x === selectedSamplingPoint);
    //     if (idxToEdit === -1) return;
    //     let temp = samplingParameters.map(x => {
    //         return {
    //             id: x.id,
    //             name: x.name,
    //             unit: x.unit,
    //             description: x.description,
    //             value: parseFloat(val[x.name!].toString()) ?? null,
    //         }
    //     }).filter(x => x.value);
    //     let samples: SamplingParameter[] = temp as SamplingParameter[];
    //     cur[idxToEdit] = Object.assign(selectedSamplingPoint, {
    //         samplingDate: val.samplingDate.toString(),
    //         samplingPointTypeId: parseInt(val.samplingPointTypeId.toString()),
    //         isReuse: false,
    //         samples: samples,
    //     });
    //     let data = cur.sort((a: WastewaterTreatementPlantSamplingPoint, b: WastewaterTreatementPlantSamplingPoint) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });
    //     let inputSamples = [];
    //     let inputSamplesReversed = [];
    //     for (var samplingpointType of samplingPointTypes) {
    //         let tempData = data.filter((x: WastewaterTreatementPlantSamplingPoint) => x.samplingPointTypeId === samplingpointType.id);
    //         inputSamples[samplingpointType.id] = tempData;
    //         inputSamplesReversed[samplingpointType.id] = tempData.reverse();
    //     }

    //     setInputData([...inputSamples]);
    //     setInputDataReversed([...inputSamplesReversed]);
    //     setFieldValue("samplingPoints", cur);
    //     setEditSamplingPointModalShow(false);
    //     setSelectedSamplingPoint(null);
    // };

    const removeSamplingPoint = () => {
        if (!selectedSamplingPoint) return;
        let cur = (values as any).samplingPoints;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantSamplingPoint) => x === selectedSamplingPoint);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        let data = cur.sort((a: WastewaterTreatementPlantSamplingPoint, b: WastewaterTreatementPlantSamplingPoint) => { return (new Date(b.samplingDate!).getTime() - new Date(a.samplingDate!).getTime()) });
        let inputSamples = [];
        let inputSamplesReversed = [];
        for (var samplingpointType of samplingPointTypes) {
            let tempData = data.filter((x: WastewaterTreatementPlantSamplingPoint) => x.samplingPointTypeId === samplingpointType.id);
            inputSamples[samplingpointType.id] = tempData;
            inputSamplesReversed[samplingpointType.id] = tempData.reverse();
        }

        setInputData([...inputSamples]);
        setInputDataReversed([...inputSamplesReversed]);
        setFieldValue("samplingPoints", cur);
        setSelectedSamplingPoint(null);
        handleMeasurementsData();
    }

    const handleAddEnvironmentalPermitSubmit = (val: any) => {
        if (sameParameterError) {
            return;
        }
        let cur = (values as any).environmentalPermits;
        let temp = samplingParameters.map(x => {
            return {
                id: x.id,
                name: x.name,
                minSampleCount: parametersInLimitsForm.find(y => y.parameterId === x.id)?.minSampleCount ?? null,
                limitValue: parametersInLimitsForm.find(y => y.parameterId === x.id)?.limitValue ?? null,
                compliancePercentage: parametersInLimitsForm.find(y => y.parameterId === x.id)?.compliancePercentage ?? null,
            }
        }).filter(x => x.limitValue);
        cur.push({
            dateIssued: val.dateIssued.toString(),
            dateExpired: val.dateExpired.toString(),
            samplingPointTypeId: parseInt(val.samplingPointTypeId.toString()),
            comments: val.comments,
            limits: temp as WastewaterTreatementPlantEnvironmentalPermitLimit[],
        });
        setFieldValue("environmentalPermits", cur);
        setAddEnvironmentalPermitModalShow(false);
        setSelectedEnvironmentalPermit(null);
    };

    const handleEditEnvironmentalPermitSubmit = (val: any) => {
        if (!selectedEnvironmentalPermit) return;
        let cur = (values as any).environmentalPermits;
        let idxToEdit = cur.findIndex((x: WastewaterTreatementPlantEnvironmentalPermit) => x === selectedEnvironmentalPermit);
        if (idxToEdit === -1) return;
        let temp = samplingParameters.map(x => {
            return {
                id: x.id,
                name: x.name,
                limitValue: parseFloat(val[`parameter_value_${x.name}`].toString()) ?? null,
                minSampleCount: parseInt(val[`parameter_count_${x.name}`].toString()) ?? null,
                compliancePercentage: parseFloat(val[`parameter_compliance_${x.name}`].toString()) ?? null,
            }
        }).filter(x => x.limitValue);
        cur[idxToEdit] = Object.assign(selectedEnvironmentalPermit, {
            dateIssued: val.dateIssued.toString(),
            dateExpired: val.dateExpired.toString(),
            samplingPointTypeId: parseInt(val.samplingPointTypeId.toString()),
            comments: val.comments,
            limits: temp as WastewaterTreatementPlantEnvironmentalPermitLimit[],
        });
        setFieldValue("environmentalPermits", cur);
        setEditEnvironmentalPermitModalShow(false);
        setSelectedEnvironmentalPermit(null);
    };

    const removeEnvironmentalPermit = () => {
        if (!selectedEnvironmentalPermit) return;
        let cur = (values as any).environmentalPermits;
        let idxToRemove = cur.findIndex((x: WastewaterTreatementPlantEnvironmentalPermit) => x === selectedEnvironmentalPermit);
        if (idxToRemove === -1) return;
        cur.splice(idxToRemove, 1);
        setFieldValue("environmentalPermits", cur);
        setSelectedEnvironmentalPermit(null);
    }

    const changeParameterVisibility = (val: string) => {
        if (!val) return;
        let idxToRemove = visibleParametersInChart.indexOf(val);
        let arr = [...visibleParametersInChart];
        if (idxToRemove === -1) {
            arr.push(val);
        } else {
            arr.splice(idxToRemove, 1);
        }
        setVisibleParametersInChart(arr);
    }

    const addParameterInSamplingForm = () => {
        if (parametersInSamplingForm.length >= samplingParameters.length) return;
        let cur = [...parametersInSamplingForm];
        let id;
        if (cur.length > 0) {
            let curIds = cur.map(x => x.id) || [0];
            id = Math.max(...curIds) + 1;
        } else {
            id = 1;
        }
        cur.push({
            id: id,
            parameterId: 0,
            value: 0,
        });
        setParametersInSamplingForm(cur);
        addSamplingPointFormikRef.current?.setErrors({});
        addSamplingPointFormikRef.current?.setTouched({});
    }

    const removeParameterInSamplingForm = (id: number) => {
        if (!id) return;
        let idxToRemove = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToRemove === -1) return;
        let cur = [...parametersInSamplingForm];
        cur.splice(idxToRemove, 1);
        setParametersInSamplingForm(cur);
        if (addSamplingPointFormikRef.current?.submitCount > 0) {
            let val = Object.assign(
                {
                    samplingDate: addSamplingPointFormikRef.current?.values.samplingDate,
                    samplingPointTypeId: addSamplingPointFormikRef.current?.values.samplingPointTypeId,
                },
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, cur[i]?.parameterId ?? 0])),
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, cur[i]?.value ?? 0]))
            );
            addSamplingPointFormikRef.current?.setValues(val);
        }
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        addSamplingPointFormikRef.current?.setErrors({});
        addSamplingPointFormikRef.current?.setTouched({});
    }

    const changeParameterInSamplingForm = (id: number, val: string) => {
        if (!id) return;
        if (!val) return;
        let idxToEdit = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInSamplingForm];
        cur[idxToEdit].parameterId = parseInt(val);
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        setParametersInSamplingForm(cur);
    }

    const changeParameterValueInSamplingForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInSamplingForm];
        cur[idxToEdit].value = parseFloat(val) ?? 0;
        setParametersInSamplingForm(cur);
    }

    const addParameterInEditSamplingForm = () => {
        if (parametersInEditSamplingForm.length >= samplingParameters.length) return;
        let cur = [...parametersInEditSamplingForm];
        let id;
        if (cur.length > 0) {
            let curIds = cur.map(x => x.id) || [0];
            id = Math.max(...curIds) + 1;
        } else {
            id = 1;
        }
        cur.push({
            id: id,
            parameterId: 0,
            value: 0,
        });
        setParametersInEditSamplingForm(cur);
        editSamplingPointFormikRef.current?.setErrors({});
        editSamplingPointFormikRef.current?.setTouched({});
    }

    const removeParameterInEditSamplingForm = (id: number) => {
        if (!id) return;
        let idxToRemove = parametersInEditSamplingForm.findIndex(x => x.id === id);
        if (idxToRemove === -1) return;
        let cur = [...parametersInEditSamplingForm];
        cur.splice(idxToRemove, 1);
        setParametersInEditSamplingForm(cur);
        if (editSamplingPointFormikRef.current?.submitCount > 0) {
            let val = Object.assign(
                {
                    samplingDate: editSamplingPointFormikRef.current?.values.samplingDate,
                    samplingPointTypeId: editSamplingPointFormikRef.current?.values.samplingPointTypeId,
                },
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, cur[i]?.parameterId ?? 0])),
                Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, cur[i]?.value ?? 0]))
            );
            editSamplingPointFormikRef.current?.setValues(val);
        }
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        editSamplingPointFormikRef.current?.setErrors({});
        editSamplingPointFormikRef.current?.setTouched({});
    }

    const changeParameterInEditSamplingForm = (id: number, val: string) => {
        if (!id) return;
        if (!val) return;
        let idxToEdit = parametersInEditSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInEditSamplingForm];
        cur[idxToEdit].parameterId = parseInt(val);
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        setParametersInEditSamplingForm(cur);
    }

    const changeParameterValueInEditSamplingForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInEditSamplingForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInEditSamplingForm];
        cur[idxToEdit].value = parseFloat(val) ?? 0;
        setParametersInEditSamplingForm(cur);
    }

    const addParameterInLimitsForm = () => {
        if (parametersInLimitsForm.length >= samplingParameters.length) return;
        let cur = [...parametersInLimitsForm];
        let id;
        if (cur.length > 0) {
            let curIds = cur.map(x => x.id) || [0];
            id = Math.max(...curIds) + 1;
        } else {
            id = 1;
        }
        cur.push({
            id: id,
            parameterId: 0,
            limitValue: undefined,
            minSampleCount: undefined,
            compliancePercentage: undefined,
        });
        setParametersInLimitsForm(cur);
    }

    const removeParameterInLimitsForm = (id: number) => {
        if (!id) return;
        let idxToRemove = parametersInLimitsForm.findIndex(x => x.id === id);
        if (idxToRemove === -1) return;
        let cur = [...parametersInLimitsForm];
        cur.splice(idxToRemove, 1);
        setParametersInLimitsForm(cur);
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        resetAddLimitsForm(cur);
    }

    const changeParameterInLimitsForm = (id: number, val: string) => {
        if (!id) return;
        if (!val) return;
        let idxToEdit = parametersInLimitsForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInLimitsForm];
        cur[idxToEdit].parameterId = parseInt(val);
        var valueArr = cur.filter(x => x.parameterId !== 0).map(x => x.parameterId);
        var isDuplicate = valueArr.some(function (x, idx) {
            return valueArr.indexOf(x) !== idx;
        });
        if (isDuplicate) {
            setSameParameterError(true);
        } else {
            setSameParameterError(false);
        }
        setParametersInLimitsForm(cur);
        resetAddLimitsForm(cur);
    }

    const changeParameterValueInLimitsForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInLimitsForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInLimitsForm];
        cur[idxToEdit].limitValue = parseFloat(val) || undefined;
        setParametersInLimitsForm(cur);
        resetAddLimitsForm(cur);
    }

    const changeParameterComplianceInLimitsForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInLimitsForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInLimitsForm];
        cur[idxToEdit].compliancePercentage = parseFloat(val) || undefined;
        setParametersInLimitsForm(cur);
        resetAddLimitsForm(cur);
    }

    const changeParameterCountInLimitsForm = (id: number, val: string) => {
        if (!id) return;
        let idxToEdit = parametersInLimitsForm.findIndex(x => x.id === id);
        if (idxToEdit === -1) return;
        let cur = [...parametersInLimitsForm];
        cur[idxToEdit].minSampleCount = parseInt(val) || undefined;
        setParametersInLimitsForm(cur);
        resetAddLimitsForm(cur);
    }

    const resetAddLimitsForm = (cur: any[]) => {
        let val = Object.assign(
            {
                dateIssued: addEnvironmentalPermitFormikRef.current?.values.dateIssued,
                dateExpired: addEnvironmentalPermitFormikRef.current?.values.dateExpired,
                samplingPointTypeId: addEnvironmentalPermitFormikRef.current?.values.samplingPointTypeId,
                comments: addEnvironmentalPermitFormikRef.current?.values.comments,
            },
            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, cur[i]?.parameterId ?? 0])),
            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, cur[i]?.limitValue])),
            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_count_${i}`, cur[i]?.minSampleCount])),
            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_compliance_${i}`, cur[i]?.compliancePercentage]))
        );
        addEnvironmentalPermitFormikRef.current?.setValues(val);
        addEnvironmentalPermitFormikRef.current?.setErrors({});
        addEnvironmentalPermitFormikRef.current?.setTouched({});
    }

    const getSamplingParametersToShow = useCallback((data: WastewaterTreatementPlantSamplingPoint[]) => {
        if (!data) return [];
        const params = data.flatMap(x => x.samples).flatMap(x => x.id).filter((item, i, ar) => ar.indexOf(item) === i);
        return samplingParameters.filter(x => params.includes(x.id));
    }, [samplingParameters]);

    const columns = useCallback((samplingPointType?: number) => {
        if (!samplingPointType) return [];
        return [
            {
                key: "samplingDate",
                name: t("wtp.measurements.sampling_results.date"),
                selector: (row: WastewaterTreatementPlantSamplingPoint) => row.samplingDate ? new Date(row.samplingDate).toLocaleDateString(i18n.language, { year: "numeric", month: "2-digit", day: "2-digit" }) : "N/A",
                center: true,
            },
            ...getSamplingParametersToShow(inputDataReversed[samplingPointType]).map(x => {
                return {
                    key: x.id.toString(),
                    name: `${x.name} (${x.unit})`,
                    selector: (row: WastewaterTreatementPlantSamplingPoint) => row.samples?.find(y => x.id === y.id)?.value?.toLocaleString(i18n.language) || "-",
                    center: true,
                };
            })
        ];
    }, [getSamplingParametersToShow, i18n.language, inputDataReversed, t])

    const convertArrayOfObjectsToCSV = (array: WastewaterTreatementPlantSamplingPoint[], cols: any) => {
        let result: any;

        const columnDelimiter = ';';
        const lineDelimiter = '\n';
        let header: string[] = cols.map((x: any) => x.name);
        header[0] = "Sampling date";

        // result = 'sep=' + columnDelimiter + lineDelimiter;
        result = '\ufeff';
        result += header.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            let keys = cols.map((x: any) => x.key);
            keys.forEach((key: string) => {
                if (ctr > 0) result += columnDelimiter;

                if (ctr === 0) {
                    result += item.samplingDate ? new Date(item.samplingDate).toLocaleDateString("el-GR") : "";
                } else {
                    result += item.samples?.find(x => x.id?.toString() === key)?.value.toString().replace(".", ",") ?? "";
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    const downloadCSV = (array: WastewaterTreatementPlantSamplingPoint[], cols: any) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array, cols);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const generateComplianceTable = (results: CheckResult[]) => {
        const params = results.flatMap(x => x.results).map(x => x?.parameterId).filter((item, i, ar) => ar.indexOf(item) === i);
        return <Table striped bordered hover size="sm" className="mt-2">
            <thead>
                <tr>
                    <th className="text-center">{t("Year")}</th>
                    {params.map((x, i) => <th key={i} className="text-center">{samplingParameters.find(p => p.id === x)?.name}</th>)}
                </tr>
            </thead>
            <tbody>
                {
                    results.sort((a, b) => (b.year ?? 0) - (a.year ?? 0)).map((x: CheckResult, idx: number) => <tr key={idx}>
                        <td className="text-center">{x.year}</td>
                        {params.map((y, i) => <td className="text-center">{getComplianceStatus(y ?? 0, x.results ?? [])}</td>)}
                    </tr>)
                }
            </tbody>
        </Table>;
    }

    const chart = useMemo(() => (!inputDataReversed[selectedSamplingPointType] || inputDataReversed[selectedSamplingPointType].length == 0) ? <div className="w-100 text-center mt-4">{t("wtp.table.no_data")}</div> : <ResponsiveContainer minHeight={700}>
        <LineChart data={inputDataReversed[selectedSamplingPointType]}>
            <CartesianGrid strokeDasharray="4 3" />
            <Legend verticalAlign="top" onClick={data => changeParameterVisibility(data.value)} />
            <Tooltip labelStyle={{ fontWeight: "bold" }} labelFormatter={x => new Date(x).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })} />
            <XAxis dataKey={"samplingDate"} tickFormatter={(x: string, i: number) => new Date(x).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { year: "numeric", month: "2-digit", day: "2-digit" })} />
            <YAxis />
            {getSamplingParametersToShow(inputDataReversed[selectedSamplingPointType]).map(x => <Line key={x.name} type="monotone" name={x.name} dataKey={y => y.samples.find((z: SamplingParameter) => z.id === x.id)?.value} stroke={colorArray[x.id]} unit={x.unit || undefined} connectNulls hide={visibleParametersInChart.indexOf(x.name) === -1} isAnimationActive={false} />)}
        </LineChart>
    </ResponsiveContainer>, [selectedSamplingPointType, inputDataReversed, samplingParameters, visibleParametersInChart, i18n.language]);

    const showEditSamplingPoint = () => {
        if (!selectedSamplingPoint) return;
        setParametersInEditSamplingForm(selectedSamplingPoint.samples.map((s, i, a) => {
            return {
                id: i + 1,
                parameterId: s.id,
                value: s.value,
            }
        }));
        editSamplingPointFormikRef.current?.setErrors({});
        editSamplingPointFormikRef.current?.setTouched({});
        setEditSamplingPointModalShow(true);
    }

    return (
        <>
            <Row>
                {/* <h4>{t("wtp.measurements.display_options.header")}</h4> */}
                <Col md={{ span: 2 }}>
                    <Row className="mb-2">
                        <Col className="pe-0 d-grid">
                            <Button variant="outline-info" className="d-flex align-items-center justify-content-center m-0" onClick={() => setSamplingModalShow(true)}>{t("wtp.measurements.sampling_positions")}</Button>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-4">
                        <Form.Label column className="text-center" md={{ span: 12 }}>{t("wtp.measurements.display_options.sampler_position")}</Form.Label>
                        <Col md={{ span: 12 }} className="pe-0">
                            <Form.Select aria-label="Επιλογή" onChange={v => { setSelectedSamplingPointType(parseInt(v.target.value)); setCurrentPage(1) }}>
                                {
                                    samplingPointTypes.map((x: SamplingPointType, i: number) => <option key={i} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Col>
                <Col md={{ span: 8 }}>
                    {chart}
                </Col>
                <Col md={{ span: 2 }}>
                    <Form.Group as={Row} className="mb-2 me-1">
                        <Form.Label column className="text-center" md={{ span: 12 }}>{t("wtp.measurements.display_options.display")}</Form.Label>
                        <Col md={{ span: 12 }} className="pe-0">
                            <Form.Select aria-label="Επιλογή" value={filter} onChange={v => { setFilter(v.target.value); setCurrentPage(1); }}>
                                <option key={1} value="all">{t("wtp.measurements.display_options.all")}</option>
                                <option key={2} value="range">{t("wtp.measurements.display_options.range")}</option>
                                <option key={3} value="year">{t("wtp.measurements.display_options.yearly")}</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>
                    {filter === "range" && <>
                        <Form.Group as={Row} className="mb-2 me-1">
                            <Form.Label column className="text-center" md={{ span: 12 }}>{t("wtp.measurements.display_options.from")}</Form.Label>
                            <Col md={{ span: 12 }} className="pe-0">
                                <Form.Control type="date" value={startDateFilter ?? ""} onChange={v => { setStartDateFilter(v.target.value); setCurrentPage(1); }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2 me-1">
                            <Form.Label column className="text-center" md={{ span: 12 }}>{t("wtp.measurements.display_options.to")}</Form.Label>
                            <Col md={{ span: 12 }} className="pe-0">
                                <Form.Control type="date" value={endDateFilter ?? ""} onChange={v => { setEndDateFilter(v.target.value); setCurrentPage(1); }} />
                            </Col>
                        </Form.Group>
                    </>}
                    {filter === "year" &&
                        <Form.Group as={Row} className="mb-2 me-1">
                            <Form.Label column className="text-center" md={{ span: 12 }}>{t("wtp.measurements.display_options.year")}</Form.Label>
                            <Col md={{ span: 12 }} className="pe-0">
                                <Form.Select aria-label="Επιλογή" value={yearFilter ?? ""} onChange={v => { setYearFilter(parseInt(v.target.value)); setCurrentPage(1); }}>
                                    <option key={-1} value={-1}>{t("wtp.measurements.display_options.all")}</option>
                                    {(Array.from(new Set((values as any).samplingPoints.map((x: WastewaterTreatementPlantSamplingPoint) => new Date(x.samplingDate!).getFullYear()))) as number[]).sort().reverse().map((x) => <option key={x} value={x}>{x}</option>)}
                                </Form.Select>
                            </Col>
                        </Form.Group>}
                </Col>
            </Row>
            <hr></hr>
            <Tabs
                defaultActiveKey="measurements"
                className="mb-3"
                transition={false}
            >
                <Tab eventKey="measurements" title={t("wtp.main.measurements")}>
                    <Row>
                        <Col>
                            <h4>{t("wtp.measurements.sampling_results.header")}</h4>
                        </Col>
                        <Col>
                            <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                                <Button variant="outline-dark" className="d-flex align-items-center justify-content-center m-0" onClick={() => downloadCSV(inputDataReversed[selectedSamplingPointType], columns(selectedSamplingPointType))} disabled={!inputDataReversed || inputDataReversed.length === 0 || inputDataReversed[selectedSamplingPointType].length === 0}><FiletypeCsv className="me-2" /> Export to CSV</Button>
                                <Button variant="outline-info" className="d-flex align-items-center justify-content-center m-0" onClick={() => setCheckComplianceModalShow(true)}><Check2Circle className="me-2"></Check2Circle> {t("wtp.measurements.sampling_results.check")}</Button>
                                <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddSamplingPointModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                                <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={showEditSamplingPoint} disabled={!selectedSamplingPoint || !(context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 || (selectedSamplingPoint.samplingDate && (new Date() < new Date(`${new Date(selectedSamplingPoint.samplingDate).getFullYear() + 1}-06-01 00:00`))))}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                                <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeSamplingPoint} disabled={!selectedSamplingPoint}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Table striped bordered hover size="sm" style={{ overflowX: "auto", display: "block" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-center align-middle">{t("wtp.measurements.sampling_results.date")}</th>
                                        {getSamplingParametersToShow(inputDataReversed[selectedSamplingPointType]).map(x => <td key={x.name} className="text-center align-middle"><strong>{x.name}</strong><br />({x.unit})</td>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataToDisplay.length > 0 ?
                                        dataToDisplay.map((x: WastewaterTreatementPlantSamplingPoint, i: number) => <tr key={i} onClick={() => setSelectedSamplingPoint(x)} className={selectedSamplingPoint === x ? "selected" : ""}>
                                            <td className="text-center">
                                                {(context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 || (x.samplingDate && (new Date() < new Date(`${new Date(x.samplingDate).getFullYear() + 1}-06-01 00:00`)))) && <Button variant="link" className="py-0" onClick={() => { setSelectedSamplingPoint(x); showEditSamplingPoint(); }}><Pencil /></Button>}
                                            </td>
                                            <td>{x.samplingDate && new Date(x.samplingDate).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
                                            {getSamplingParametersToShow(inputDataReversed[selectedSamplingPointType]).map(y => <td key={y.name} className="text-center">{(x.samples.find(z => z.id === y.id)?.value === null || x.samples.find(z => z.id === y.id)?.value === undefined) ? "" : x.samples.find(z => z.id === y.id)?.value}</td>)}
                                        </tr>)
                                        : <tr>
                                            <td colSpan={2 + getSamplingParametersToShow(inputDataReversed[selectedSamplingPointType]).length} className="text-center">
                                                {t("wtp.table.no_data")}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            {getPagination()}
                        </Col>
                    </Row>
                    <hr></hr>
                    <h4>{t("wtp.measurements.sampling_remarks.header")}</h4>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        id="measurementsRemarks"
                        name="measurementsRemarks"
                        onBlur={handleChange}
                        defaultValue={(values as any).measurementsRemarks ?? ""}
                        isInvalid={(touched as any).measurementsRemarks && !!(errors as any).measurementsRemarks}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors as any).measurementsRemarks}
                    </Form.Control.Feedback>
                </Tab>
                <Tab eventKey="limits" title={t("wtp.main.limits")}>
                    <Row>
                        <Col>
                            <h4>{t("wtp.measurements.limits.header")}</h4>
                        </Col>
                        <Col>
                            <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                                <CheckboxDropdown items={selectedSamplingParameters} setItems={setSelectedSamplingParameters} />
                                <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddEnvironmentalPermitModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                                <Button variant="outline-secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setEditEnvironmentalPermitModalShow(true)} disabled={!selectedEnvironmentalPermit}><PencilFill className="me-2"></PencilFill> {t("wtp.button.edit")}</Button>
                                <Button variant="outline-danger" className="d-flex align-items-center justify-content-center m-0" onClick={removeEnvironmentalPermit} disabled={!selectedEnvironmentalPermit}><Trash className="me-2"></Trash> {t("wtp.button.delete")}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col style={{ overflowX: "auto", display: "block" }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="align-middle text-center">{t("wtp.measurements.limits.date_issued")}</th>
                                        <th className="align-middle text-center">{t("wtp.measurements.limits.date_expired")}</th>
                                        {selectedSamplingParameters.filter(x => x.checked).map(x => <td key={x.name} style={{ minWidth: "100px" }} className="text-center"><strong>{x.name}</strong><br />( {x.unit} )</td>)}
                                        <th className="align-middle text-center">{t("wtp.measurements.limits.comments")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (values as any).environmentalPermits.length === 0 ?
                                            <tr>
                                                <td colSpan={4 + selectedSamplingParameters.filter(x => x.checked).length} className="text-center">
                                                    {t("wtp.table.no_data")}
                                                </td>
                                            </tr> :
                                            (values as any).environmentalPermits.map((x: WastewaterTreatementPlantEnvironmentalPermit, i: number) => <tr key={i} onClick={() => setSelectedEnvironmentalPermit(x)} className={selectedEnvironmentalPermit === x ? "selected" : ""}>
                                                <td className="text-center align-middle"><Button variant="link" className="py-0" onClick={() => { setSelectedEnvironmentalPermit(x); setEditEnvironmentalPermitModalShow(true); }}><Pencil /></Button></td>
                                                <td className="align-middle text-center">{x.dateIssued && new Date(x.dateIssued).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
                                                <td className="align-middle text-center">{x.dateExpired && new Date(x.dateExpired).toLocaleDateString(i18n.language === "en" ? "en-US" : "el-GR", { year: "numeric", month: "2-digit", day: "2-digit" })}</td>
                                                {selectedSamplingParameters.filter(x => x.checked).map(y => <td key={y.name} className="text-center align-middle">{
                                                    (() => { let param = x.limits.find(z => z.id === y.id); return param ? `${param?.limitValue.toLocaleString(i18n.language, { maximumFractionDigits: 2 })} (${param?.minSampleCount} - ${param?.compliancePercentage?.toLocaleString(i18n.language, { maximumFractionDigits: 2 })}%)` : "-"; })()
                                                }</td>)}
                                                <td className="text-justify align-middle">{x.comments}</td>
                                            </tr>)
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
            <Modal
                show={addSamplingPointModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddSamplingPointModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.sampling_results.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Formik
                        initialValues={Object.assign(
                            {
                                samplingDate: "",
                                samplingPointTypeId: selectedSamplingPointType,
                            },
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, parametersInSamplingForm[i]?.parameterId ?? 0])),
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, parametersInSamplingForm[i]?.value ?? 0])),
                        )}
                        validationSchema={samplingSchema}
                        onSubmit={(v) => handleAddSamplingPointSubmit(v)}
                        innerRef={addSamplingPointFormikRef}
                    >
                        {props => <Form noValidate ref={addSamplingPointFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.sampling_results.date") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="samplingDate"
                                        name="samplingDate"
                                        value={props.values.samplingDate}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.samplingDate && !!props.errors.samplingDate}
                                        isValid={props.touched.samplingDate && !props.errors.samplingDate}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingDate}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                        isValid={props.touched.samplingPointTypeId && !props.errors.samplingPointTypeId}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {parametersInSamplingForm.map((y: any, i: number) => <Form.Group key={i} as={Row} className="mb-2">
                                <Col md={{ span: 4 }}>
                                    {/* <Form.Select
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        value={y.parameterId}
                                        onChange={(e) => { props.setFieldValue(`parameter_${i}`, e.target.value); changeParameterInSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_${i}`] && !!props.errors[`parameter_${i}`]}
                                    >
                                        <option value={0} key="none">{t("wtp.measurements.sampling_results.select_parameter")}</option>
                                        {samplingParameters.map((x: any, i: number) => <option value={x.id} key={i}>{x.name}</option>)}
                                    </Form.Select> */}
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } }))}
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        onChange={v => { props.setFieldValue(`parameter_${i}`, v?.value); changeParameterInSamplingForm(y.id, v?.value.toString() || "0"); }}
                                        value={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } })).find(x => x.value === props.values[`parameter_${i}`])}
                                        className={'react-select ' + (props.values[`parameter_${i}`] === 0 ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                        menuPosition={"fixed"}
                                        menuPlacement={"auto"}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id={`parameter_value_${i}`}
                                        name={`parameter_value_${i}`}
                                        value={props.values[`parameter_value_${i}`]}
                                        onChange={props.handleChange}
                                        onBlur={(e) => { props.setFieldValue(`parameter_value_${i}`, e.target.value); changeParameterValueInSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_value_${i}`] && !!props.errors[`parameter_value_${i}`]}
                                        isValid={props.touched[`parameter_value_${i}`] && !props.errors[`parameter_value_${i}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_value_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 1 }}>
                                    <Button active style={{ border: "none" }} variant="ghost" onClick={() => removeParameterInSamplingForm(y.id)}>
                                        <DashCircle color="red" />
                                    </Button>
                                </Col>
                            </Form.Group>)}
                            {parametersInSamplingForm.length < samplingParameters.length && <Form.Group as={Row} className="mb-2">
                                <Button onClick={addParameterInSamplingForm} style={{ width: "400px", margin: "0 auto", backgroundColor: "white", color: "gray", border: "2px dotted gray" }}>
                                    <Plus />&nbsp;{t("wtp.measurements.add_parameter")}
                                </Button>
                            </Form.Group>}
                        </Form>}
                    </Formik>
                    {sameParameterError && <Alert variant={'light'} className="m-0">
                        <div className="text-danger">{t("wtp.measurements.sampling_results.modal.same_parameter")}</div>
                    </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" className="me-auto" onClick={() => { setAddSamplingPointModalShow(false); setAddSamplingPointFileModalShow(true); }}>{t("wtp.button.add_multiple")}</Button>
                    <Button disabled={parametersInSamplingForm.length === 0} onClick={() => addSamplingPointFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddSamplingPointModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editSamplingPointModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditSamplingPointModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.sampling_results.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Formik
                        initialValues={Object.assign(
                            {
                                samplingDate: selectedSamplingPoint?.samplingDate ? new Date(selectedSamplingPoint?.samplingDate.replace("+03:00", "+00:00").replace("+02:00", "+00:00")).toISOString().substring(0, 10) : "",
                                samplingPointTypeId: selectedSamplingPoint?.samplingPointTypeId.toString() || -1,
                            },
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, parametersInEditSamplingForm[i]?.parameterId ?? 0])),
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, parametersInEditSamplingForm[i]?.value ?? 0])),
                        )}
                        validationSchema={samplingSchema}
                        onSubmit={(v) => handleEditSamplingPointSubmit(v)}
                        innerRef={editSamplingPointFormikRef}
                    >
                        {props => <Form noValidate ref={editSamplingPointFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.sampling_results.date") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="samplingDate"
                                        name="samplingDate"
                                        value={props.values.samplingDate}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.samplingDate && !!props.errors.samplingDate}
                                        isValid={props.touched.samplingDate && !props.errors.samplingDate}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingDate}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                        isValid={props.touched.samplingPointTypeId && !props.errors.samplingPointTypeId}
                                        disabled
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {parametersInEditSamplingForm.map((y: any, i: number) => <Form.Group key={i} as={Row} className="mb-2">
                                <Col md={{ span: 4 }}>
                                    {/* <Form.Select
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        value={y.parameterId}
                                        onChange={(e) => { props.setFieldValue(`parameter_${i}`, e.target.value); changeParameterInSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_${i}`] && !!props.errors[`parameter_${i}`]}
                                    >
                                        <option value={0} key="none">{t("wtp.measurements.sampling_results.select_parameter")}</option>
                                        {samplingParameters.map((x: any, i: number) => <option value={x.id} key={i}>{x.name}</option>)}
                                    </Form.Select> */}
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } }))}
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        onChange={v => { props.setFieldValue(`parameter_${i}`, v?.value); changeParameterInEditSamplingForm(y.id, v?.value.toString() || "0"); }}
                                        value={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } })).find(x => x.value === props.values[`parameter_${i}`])}
                                        className={'react-select ' + (props.values[`parameter_${i}`] === 0 ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                        menuPosition={"fixed"}
                                        menuPlacement={"auto"}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 7 }}>
                                    <Form.Control
                                        id={`parameter_value_${i}`}
                                        name={`parameter_value_${i}`}
                                        value={props.values[`parameter_value_${i}`]}
                                        onChange={props.handleChange}
                                        onBlur={(e) => { props.setFieldValue(`parameter_value_${i}`, e.target.value); changeParameterValueInEditSamplingForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_value_${i}`] && !!props.errors[`parameter_value_${i}`]}
                                        isValid={props.touched[`parameter_value_${i}`] && !props.errors[`parameter_value_${i}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_value_${i}`]}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 1 }}>
                                    <Button active style={{ border: "none" }} variant="ghost" onClick={() => removeParameterInEditSamplingForm(y.id)}>
                                        <DashCircle color="red" />
                                    </Button>
                                </Col>
                            </Form.Group>)}
                            {parametersInEditSamplingForm.length < samplingParameters.length && <Form.Group as={Row} className="mb-2">
                                <Button onClick={addParameterInEditSamplingForm} style={{ width: "400px", margin: "0 auto", backgroundColor: "white", color: "gray", border: "2px dotted gray" }}>
                                    <Plus />&nbsp;{t("wtp.measurements.add_parameter")}
                                </Button>
                            </Form.Group>}
                        </Form>}
                    </Formik>
                    {sameParameterError && <Alert variant={'light'} className="m-0">
                        <div className="text-danger">{t("wtp.measurements.sampling_results.modal.same_parameter")}</div>
                    </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editSamplingPointFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditSamplingPointModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal
                show={editSamplingPointModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditSamplingPointModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.sampling_results.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Formik
                        initialValues={Object.assign(
                            {
                                samplingDate: selectedSamplingPoint?.samplingDate ? new Date(selectedSamplingPoint?.samplingDate.replace("+03:00", "+00:00").replace("+02:00", "+00:00")).toISOString().substring(0, 10) : "",
                                samplingPointTypeId: selectedSamplingPoint?.samplingPointTypeId.toString() || -1,
                            },
                            Object.fromEntries(samplingParameters.map(x => [x.name, selectedSamplingPoint?.samples.find(y => y.id === x.id)?.value ?? ""]))
                        )}
                        validationSchema={samplingSchema}
                        onSubmit={(v) => handleEditSamplingPointSubmit(v)}
                    >
                        {props => <Form noValidate ref={editSamplingPointFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.sampling_results.date") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="samplingDate"
                                        name="samplingDate"
                                        value={props.values.samplingDate}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.samplingDate && !!props.errors.samplingDate}
                                        isValid={props.touched.samplingDate && !props.errors.samplingDate}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingDate}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                        isValid={props.touched.samplingPointTypeId && !props.errors.samplingPointTypeId}
                                        disabled
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {selectedSamplingPoint?.samples.map(x => <Form.Group key={x.name} as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{`${x.name} (${samplingParameters.find(y => y.name === x.name)?.unit})`}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={x.name!}
                                        id={x.name!}
                                        name={x.name!}
                                        value={props.values[x.name!]}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched[x.name!] && !!props.errors[x.name!]}
                                        isValid={props.touched[x.name!] && !props.errors[x.name!]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[x.name!]}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>)}
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editSamplingPointFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditSamplingPointModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal> */}
            <MeasurementsFromFile isReuse={false} samplingParameters={samplingParameters} samplingPointTypes={samplingPointTypes} samplingPointType={selectedSamplingPointType} show={addSamplingPointFileModalShow} setShow={setAddSamplingPointFileModalShow} />
            <Modal
                show={addEnvironmentalPermitModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddEnvironmentalPermitModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.limits.modal.add.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Formik
                        initialValues={Object.assign(
                            {
                                dateIssued: "",
                                dateExpired: "",
                                samplingPointTypeId: samplingPointTypes.find(x => x.nameEn === "Outlet")?.id,
                                comments: "",
                            },
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_${i}`, parametersInLimitsForm[i]?.parameterId ?? 0])),
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_value_${i}`, parametersInLimitsForm[i]?.limitValue])),
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_compliance_${i}`, parametersInLimitsForm[i]?.compliancePercentage])),
                            Object.fromEntries(samplingParameters.map((x, i) => [`parameter_count_${i}`, parametersInLimitsForm[i]?.minSampleCount])),
                        )}
                        validationSchema={limitsAddSchema}
                        onSubmit={(v) => handleAddEnvironmentalPermitSubmit(v)}
                        innerRef={addEnvironmentalPermitFormikRef}
                    >
                        {props => <Form noValidate ref={addEnvironmentalPermitFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.date_issued") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="dateIssued"
                                        name="dateIssued"
                                        value={props.values.dateIssued}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.dateIssued && !!props.errors.dateIssued}
                                        isValid={props.touched.dateIssued && !props.errors.dateIssued}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.dateIssued as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.date_expired") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="dateExpired"
                                        name="dateExpired"
                                        value={props.values.dateExpired}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.dateExpired && !!props.errors.dateExpired}
                                        isValid={props.touched.dateExpired && !props.errors.dateExpired}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.dateExpired as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" hidden>
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                        isValid={props.touched.samplingPointTypeId && !props.errors.samplingPointTypeId}
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.comments")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        id="comments"
                                        name="comments"
                                        value={props.values.comments}
                                        onChange={v => props.setFieldValue("comments", v.target.value)}
                                        isInvalid={props.touched.comments && !!props.errors.comments}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.comments as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {parametersInLimitsForm?.length > 0 && <Row className="mb-2">
                                <Col md={{ span: 4 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter")}</Form.Text></Col>
                                <Col md={{ span: 8 }}>
                                    <Row>
                                        <Col md={{ span: 3 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_value")}</Form.Text></Col>
                                        <Col md={{ span: 3 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_count")}</Form.Text></Col>
                                        <Col md={{ span: 3 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_compliance")} (%)</Form.Text></Col>
                                    </Row>
                                </Col>
                            </Row>}
                            {parametersInLimitsForm.map((y: any, i: number) => <Form.Group key={i} as={Row} className="mb-2">
                                <Col md={{ span: 4 }}>
                                    {/* <Form.Select
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        value={y.parameterId}
                                        onChange={(e) => { props.setFieldValue(`parameter_${i}`, e.target.value); changeParameterInLimitsForm(y.id, e.target.value); }}
                                        isInvalid={props.touched[`parameter_${i}`] && !!props.errors[`parameter_${i}`]}
                                    >
                                        <option value={0} key="none">{t("wtp.measurements.sampling_results.select_parameter")}</option>
                                        {samplingParameters.map((x: any, i: number) => <option value={x.id} key={i}>{x.name}</option>)}
                                    </Form.Select> */}
                                    <Select
                                        noOptionsMessage={() => t("wtp.select.no_options")}
                                        options={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } }))}
                                        id={`parameter_${i}`}
                                        name={`parameter_${i}`}
                                        onChange={v => { props.setFieldValue(`parameter_${i}`, v?.value); changeParameterInLimitsForm(y.id, v?.value.toString() || "0"); }}
                                        value={[{ value: 0, label: t("wtp.measurements.sampling_results.select_parameter"), }].concat(samplingParameters.map((x: any, i: number) => { return { value: x.id, label: `${x.name} (${x.unit})`, } })).find(x => x.value === props.values[`parameter_${i}`])}
                                        className={'react-select ' + (props.values[`parameter_${i}`] === 0 ? 'is-invalid' : '')}
                                        classNamePrefix={'react-select'}
                                        menuPosition={"fixed"}
                                        menuPlacement={"auto"}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_${i}`] as string}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 8 }}>
                                    <Row>
                                        <Col md={{ span: 3 }}>
                                            <Form.Control
                                                id={`parameter_value_${i}`}
                                                name={`parameter_value_${i}`}
                                                placeholder={t("wtp.measurements.limits.parameter_value")}
                                                value={props.values[`parameter_value_${i}`]}
                                                onChange={props.handleChange}
                                                onBlur={(e) => { props.setFieldValue(`parameter_value_${i}`, e.target.value); changeParameterValueInLimitsForm(y.id, e.target.value); }}
                                                isInvalid={props.touched[`parameter_value_${i}`] && !!props.errors[`parameter_value_${i}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {props.errors[`parameter_value_${i}`] as string}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={{ span: 3 }}>
                                            <Form.Control
                                                id={`parameter_count_${i}`}
                                                name={`parameter_count_${i}`}
                                                placeholder={t("wtp.measurements.limits.parameter_count")}
                                                value={props.values[`parameter_count_${i}`]}
                                                onChange={props.handleChange}
                                                onBlur={(e) => { props.setFieldValue(`parameter_count_${i}`, e.target.value); changeParameterCountInLimitsForm(y.id, e.target.value); }}
                                                isInvalid={props.touched[`parameter_count_${i}`] && !!props.errors[`parameter_count_${i}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {props.errors[`parameter_count_${i}`] as string}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={{ span: 3 }}>
                                            <Form.Control
                                                id={`parameter_compliance_${i}`}
                                                name={`parameter_compliance_${i}`}
                                                placeholder={t("wtp.measurements.limits.parameter_compliance")}
                                                value={props.values[`parameter_compliance_${i}`]}
                                                onChange={props.handleChange}
                                                onBlur={(e) => { props.setFieldValue(`parameter_compliance_${i}`, e.target.value); changeParameterComplianceInLimitsForm(y.id, e.target.value); }}
                                                isInvalid={props.touched[`parameter_compliance_${i}`] && !!props.errors[`parameter_compliance_${i}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {props.errors[`parameter_compliance_${i}`] as string}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={{ span: 3 }}>
                                            <Button active style={{ border: "none" }} variant="ghost" onClick={() => removeParameterInLimitsForm(y.id)}>
                                                <DashCircle color="red" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Form.Group>)}
                            {parametersInLimitsForm.length < samplingParameters.length && <Form.Group as={Row} className="mb-2">
                                <Button onClick={addParameterInLimitsForm} style={{ width: "400px", margin: "0 auto", backgroundColor: "white", color: "gray", border: "2px dotted gray" }}>
                                    <Plus />&nbsp;{t("wtp.measurements.add_parameter")}
                                </Button>
                            </Form.Group>}
                        </Form>}
                    </Formik>
                    {sameParameterError && <Alert variant={'light'} className="m-0">
                        <div className="text-danger">{t("wtp.measurements.sampling_results.modal.same_parameter")}</div>
                    </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addEnvironmentalPermitFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddEnvironmentalPermitModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editEnvironmentalPermitModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditEnvironmentalPermitModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.limits.modal.edit.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Formik
                        initialValues={Object.assign(
                            {
                                dateIssued: selectedEnvironmentalPermit?.dateIssued ? new Date(selectedEnvironmentalPermit?.dateIssued.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : "",
                                dateExpired: selectedEnvironmentalPermit?.dateExpired ? new Date(selectedEnvironmentalPermit?.dateExpired.replace("+02:00", "+00:00")).toISOString().substring(0, 10) : "",
                                samplingPointTypeId: selectedEnvironmentalPermit?.samplingPointTypeId.toString() || -1,
                                comments: selectedEnvironmentalPermit?.comments ?? "",
                            },
                            Object.fromEntries(samplingParameters.map(x => [`parameter_${x.name}`, selectedEnvironmentalPermit?.limits.find(y => y.id === x.id)?.name ?? ""])),
                            Object.fromEntries(samplingParameters.map(x => [`parameter_value_${x.name}`, selectedEnvironmentalPermit?.limits.find(y => y.id === x.id)?.limitValue ?? ""])),
                            Object.fromEntries(samplingParameters.map(x => [`parameter_count_${x.name}`, selectedEnvironmentalPermit?.limits.find(y => y.id === x.id)?.minSampleCount ?? ""])),
                            Object.fromEntries(samplingParameters.map(x => [`parameter_compliance_${x.name}`, selectedEnvironmentalPermit?.limits.find(y => y.id === x.id)?.compliancePercentage ?? ""]))
                        )}
                        validationSchema={limitsEditSchema}
                        onSubmit={(v) => handleEditEnvironmentalPermitSubmit(v)}
                    >
                        {props => <Form noValidate ref={editEnvironmentalPermitFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.date_issued") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="dateIssued"
                                        name="dateIssued"
                                        value={props.values.dateIssued}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.dateIssued && !!props.errors.dateIssued}
                                        isValid={props.touched.dateIssued && !props.errors.dateIssued}
                                    // disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.dateIssued as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.date_expired") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        type="date"
                                        id="dateExpired"
                                        name="dateExpired"
                                        value={props.values.dateExpired}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched.dateExpired && !!props.errors.dateExpired}
                                        isValid={props.touched.dateExpired && !props.errors.dateExpired}
                                    // disabled
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.dateExpired as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2" hidden>
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.display_options.sampler_position") + " *"}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Select
                                        id="samplingPointTypeId"
                                        name="samplingPointTypeId"
                                        value={props.values.samplingPointTypeId}
                                        onChange={v => props.setFieldValue("samplingPointTypeId", v.target.value)}
                                        isInvalid={props.touched.samplingPointTypeId && !!props.errors.samplingPointTypeId}
                                        isValid={props.touched.samplingPointTypeId && !props.errors.samplingPointTypeId}
                                        disabled
                                    >
                                        <option value={-1} key="none">{t("wtp.measurements.sampling_results.select_sampler_location")}</option>
                                        {
                                            samplingPointTypes.map(x => <option value={x.id} key={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.samplingPointTypeId as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("wtp.measurements.limits.comments")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        id="comments"
                                        name="comments"
                                        value={props.values.comments}
                                        onChange={v => props.setFieldValue("comments", v.target.value)}
                                        isInvalid={props.touched.comments && !!props.errors.comments}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.comments as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {(selectedEnvironmentalPermit?.limits?.length ?? 0) > 0 && <Row className="mb-2">
                                <Col md={{ span: 4 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter")}</Form.Text></Col>
                                <Col md={{ span: 3 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_value")}</Form.Text></Col>
                                <Col md={{ span: 2 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_count")}</Form.Text></Col>
                                <Col md={{ span: 3 }} className="text-center"><Form.Text muted>{t("wtp.measurements.limits.parameter_compliance")} (%)</Form.Text></Col>
                            </Row>}
                            {selectedEnvironmentalPermit?.limits.map(x => <Form.Group key={x.name} as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{`${x.name} (${samplingParameters.find(y => y.name === x.name)?.unit})`}</Form.Label>
                                <Col md={{ span: 3 }}>
                                    <Form.Control
                                        placeholder={t("wtp.measurements.limits.parameter_value")}
                                        id={`parameter_value_${x.name}`}
                                        name={`parameter_value_${x.name}`}
                                        value={props.values[`parameter_value_${x.name}`]}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched[`parameter_value_${x.name}`] && !!props.errors[`parameter_value_${x.name}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_value_${x.name}`] as string}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 2 }}>
                                    <Form.Control
                                        id={`parameter_count_${x.name}`}
                                        name={`parameter_count_${x.name}`}
                                        placeholder={t("wtp.measurements.limits.parameter_count")}
                                        value={props.values[`parameter_count_${x.name}`]}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched[`parameter_count_${x.name}`] && !!props.errors[`parameter_count_${x.name}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_count_${x.name}`] as string}
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={{ span: 3 }}>
                                    <Form.Control
                                        id={`parameter_compliance_${x.name}`}
                                        name={`parameter_compliance_${x.name}`}
                                        placeholder={t("wtp.measurements.limits.parameter_compliance")}
                                        value={props.values[`parameter_compliance_${x.name}`]}
                                        onChange={props.handleChange}
                                        isInvalid={props.touched[`parameter_compliance_${x.name}`] && !!props.errors[`parameter_compliance_${x.name}`]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors[`parameter_compliance_${x.name}`] as string}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>)}
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editEnvironmentalPermitFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditEnvironmentalPermitModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={checkComplianceModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setCheckComplianceModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("wtp.measurements.sampling_results.check")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <ul><li><h5>{t("wtp.measurements.compliance_legislation")}</h5></li></ul>
                    {complianceLegislationResults.length > 0 ? generateComplianceTable(complianceLegislationResults) : t("wtp.table.no_data")}
                    <ul><li><h5>{t("wtp.measurements.compliance_aepo")}</h5></li></ul>
                    {(values as any).environmentalPermits?.length > 0 ? complianceAepoResults.length > 0 ? generateComplianceTable(complianceAepoResults) : t("wtp.table.no_data") : <p>{t("wtp.measurements.no_environmental_permits_found")}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => recalculateAepo()} disabled={loading || (values as any).environmentalPermits?.length <= 0}>{t("wtp.button.recalculate_aepo")}</Button>
                    <Button onClick={() => recalculateLegislation()} disabled={loading}>{t("wtp.button.recalculate_legislation")}</Button>
                    <Button variant="secondary" onClick={() => setCheckComplianceModalShow(false)}>{t("wtp.button.close")}</Button>
                </Modal.Footer>
            </Modal>
            <SamplerPositionsModal
                wastewaterTreatmentPlantId={(values as any).id}
                samplingPointTypes={samplingPointTypes}
                show={samplingModalShow}
                setShow={setSamplingModalShow}
                setSamplingPointTypes={setSamplingPointTypes}
            />
        </>
    )
}

const CheckDropdownItem = React.forwardRef(
    ({ children, id, checked, onChange }: { children: any, id: string, checked: boolean, onChange: (key: string, event: any) => void }, ref: any) => {
        return (
            <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
                <Form.Check
                    type="checkbox"
                    label={children}
                    checked={checked}
                    onChange={onChange && onChange.bind(onChange, id)}
                />
            </Form.Group>
        );
    }
);

export const CheckboxDropdown = ({ items, setItems }: { items: (SamplingParameter & { checked: boolean, static: boolean })[], setItems: Function }) => {
    const { t, i18n } = useTranslation();

    const handleChecked = (key: string, event: any) => {
        const attr = items.find(i => i.id.toString() === key);
        if (attr) {
            attr.checked = !attr.checked;
        }
        setItems(JSON.parse(JSON.stringify(items)));
    };

    const handleSelectAll = () => {
        items.filter(x => !x.static).forEach(i => (i.checked = true));
        setItems(JSON.parse(JSON.stringify(items)));
    };

    const handleSelectNone = () => {
        items.filter(x => !x.static).forEach(i => (i.checked = false));
        setItems(JSON.parse(JSON.stringify(items)));
    };

    return (
        <DropdownButton as={ButtonGroup} drop="up" title={t("wtp.measurements.display_options.header")} className="dropdown-selection">
            {items.filter(x => !x.static).map(i => (
                <Dropdown.Item
                    key={i.id}
                    as={CheckDropdownItem}
                    id={i.id.toString()}
                    checked={i.checked}
                    onChange={handleChecked as any}
                >
                    {i.name}
                </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <div className="dropdown-item disabled pt-2">
                <ButtonGroup size="sm">
                    <Button variant="link" onClick={() => handleSelectAll()} className="pe-auto">
                        {t("Select All")}
                    </Button>
                    <Button variant="link" onClick={() => handleSelectNone()} className="pe-auto">
                        {t("Select None")}
                    </Button>
                </ButtonGroup>
            </div>
        </DropdownButton>
    );
};


export default WtpManageMeasurements;
