import { useContext, useEffect, useState, createRef, useRef } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Container, Form, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';

import { fromLonLat, getPointResolution, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle } from "ol/geom";
import WKT from 'ol/format/WKT';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../../components/BingMap";

import { Layers, PencilFill, Save, SkipBackward, SquareFill, XCircle } from 'react-bootstrap-icons';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import useFetch from 'use-http';
import { ElstatSettlement, RiverBasin, Settlement } from '../../../data/Definitions';
import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';

let unique_id = 0;

const SettlementsAdd: React.FC = (props) => {
    const [elstatSettlements, setElstatSettlements] = useState<ElstatSettlement[]>([]);
    const [selectedSettlement, setSelectedSettlement] = useState<ElstatSettlement>();
    const [saving, setSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [savingError, setSavingError] = useState(0);
    const [loadingError, setLoadingError] = useState(false);
    const [duplicateId, setDuplicateId] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const formikRef = useRef<FormikProps<Settlement>>(null);

    const [drawing, setDrawing] = useState(false);
    const [geometry, setGeometry] = useState<Geometry | undefined>();
    const [showBing, setShowBing] = useState(false);
    const layersButton = <button>&#9776;</button>;
    const mapRef = createRef() as React.RefObject<RMap>;

    const { get, put, response, loading, error, cache } = useFetch('/api/settlements/elstat');
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Add Settlement" });
    }, [loc.pathname]);

    useEffect(() => {
        getSettlements();
    }, [])

    useEffect(() => {
        if (!selectedSettlement) return;
        autoDetectRiverBasin(selectedSettlement)
    }, [selectedSettlement])

    // useEffect(() => {
    //     if (!geometry) return;
    //     let feature = new Feature(geometry);
    //     let style = new Style({
    //         stroke: new Stroke({
    //             color: "rgba(0, 200, 0, 1)",
    //             width: 2,
    //         }),
    //         fill: new Fill({
    //             color: "rgba(0, 200, 0, 0.5)",
    //         }),
    //     });
    //     feature.setStyle(style);

    //     mapRef.current?.ol.getLayers().forEach(function (layer) {
    //         if (layer instanceof VectorLayer) {
    //             (layer.getSource() as VectorSource)?.clear();
    //             (layer.getSource() as VectorSource)?.addFeature(feature);
    //         }
    //     });
    //     console.log(feature);
    // }, [geometry])

    useEffect(() => {
        if (!selectedSettlement) return;
        let point = fromLonLat([selectedSettlement.longitude, selectedSettlement.latitude]);
        mapRef.current?.ol.getView().animate({ center: point }, { zoom: 15 });
    }, [selectedSettlement])

    const getSettlements = async () => {
        setLoadingError(false);
        let ess = await get();
        if (response.ok) {
            setElstatSettlements(ess);
        }
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const saveData = async (values: Settlement) => {
        setSaving(true);
        let data = new FormData();
        // console.log(values)
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (value === null) return;
            data.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
        });

        var res = await fetch('/api/settlements', {
            method: 'POST',
            body: data,
        });
        if (res.status === 200) {
            const id = await res.text();
            setSavingError(0);
            setShowToast(true);
            cache.delete(`url:/api/settlements||method:GET||body:`);
            cache.delete(`url:/api/settlements?noshapes||method:GET||body:`);
            navigate(`/settlements/edit/${id.replaceAll('"', '')}`)
        } else {
            if (res.status === 406) {
                setSavingError(1);
                formikRef.current?.setFieldError("id", t("settlements.duplicate_id"));
                setDuplicateId(true);
            } else {
                setSavingError(2);
            }
            setShowToast(true);
        }
        setSaving(false);
    }

    const autoDetectRiverBasin = async (settlement: ElstatSettlement) => {
        if (!settlement.longitude) return;
        if (!settlement.latitude) return;
        const res = await fetch(`/api/spatial/riverbasin?lng=${settlement.longitude}&lat=${settlement.latitude}`, { cache: "no-store" });
        if (res.status === 200) {
            const rb: RiverBasin = await res.json();
            formikRef.current?.setFieldValue("waterDistrict", rb.riverBasinDistrict.name);
            formikRef.current?.setFieldValue("riverBasin", rb.name);
            formikRef.current?.setFieldValue("waterDistrictEn", rb.riverBasinDistrict.nameEn);
            formikRef.current?.setFieldValue("riverBasinEn", rb.nameEn);
        } else {
            formikRef.current?.setFieldValue("waterDistrict", "");
            formikRef.current?.setFieldValue("riverBasin", "");
            formikRef.current?.setFieldValue("waterDistrictEn", "");
            formikRef.current?.setFieldValue("riverBasinEn", "");
        }
    }

    const validationSchema = yup.object().shape({
        id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        // shape: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        area: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        population: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        equivalentPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        connectedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
    });

    return (
        <Container fluid className='menu-padding'>
            <ToastContainer className="p-3" position={'top-end'} containerPosition="fixed">
                <Toast
                    className="d-inline-block m-1"
                    bg={savingError == 2 ? 'danger' : savingError == 1 ? 'warning' : 'success'}
                    key={0}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body className={'text-white'}>
                        {savingError == 2 ? t("wtp.save.error") : savingError == 1 ? t("wtp.save.warning") : t("wtp.save.success")}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {!loading && !error && elstatSettlements && <Formik
                enableReinitialize
                initialValues={{
                    id: selectedSettlement?.codeOik ?? "",
                    name: selectedSettlement?.nameOik ?? "",
                    nameEn: selectedSettlement?.namefOik ?? "",
                    riverBasin: "",
                    waterDistrict: "",
                    riverBasinEn: "",
                    waterDistrictEn: "",
                    shape: "",
                    area: 0,
                    population: selectedSettlement?.population ?? 0,
                    equivalentPopulation: 0,
                    servedPopulation: 0,
                    connectedPopulation: 0,
                    servedPopulationPercentage: null,
                    connectedPopulationPercentage: null,
                    constructedSewageNetworkShape: "",
                    constructedSewageNetworkArea: 0,
                    constructedSewageNetworkLength: 0,
                    connectedSewageNetworkShape: "",
                    connectedSewageNetworkArea: 0,
                    connectedSewageNetworkLength: 0,
                    sewageNetworkTypeId: null,
                    pumpingStationCount: 0,
                    shapeArea: 0,
                }}
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={saveData}
            >
                {props => (
                    <>
                        <form noValidate onSubmit={props.handleSubmit} onReset={props.handleReset}>
                            <Row>
                                <Col className='position-relative'>
                                    <div style={{ minHeight: "calc(100vh - 382px)" }}>
                                        <Row className="mb-2">
                                            <Col md={{ span: 7 }}>
                                                <h4>{t("wtp.general.general.header")}</h4>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>
                                                        {t("settlements.settlement_code")}
                                                    </Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Select
                                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                                            options={(elstatSettlements.map(x => { return { value: x.codeOik, label: i18n.language === "en" ? `${x.codeOik} - ${x.namefOik}, ${x.namefOta}, ${x.namefGdiam}` : `${x.codeOik} - ${x.nameOik}, ${x.nameOta}, ${x.nameGdiam}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }]))}
                                                            placeholder={t("settlements.settlement_code")}
                                                            id="id"
                                                            name="id"
                                                            onChange={v => { setDuplicateId(false); setSelectedSettlement(elstatSettlements.find(x => x.codeOik === v?.value)); }}
                                                            value={(elstatSettlements.map(x => { return { value: x.codeOik, label: i18n.language === "en" ? `${x.codeOik} - ${x.namefOik}, ${x.namefOta}, ${x.namefGdiam}` : `${x.codeOik} - ${x.nameOik}, ${x.nameOta}, ${x.nameGdiam}`, } }).concat([{ value: "", label: t("wtp.waste_origin.served_sewage_agglomeration.modal.select_settlement"), }])).find(x => x.value === props.values.id)}
                                                            className={'react-select ' + ((!props.values.id || duplicateId) ? 'is-invalid' : 'is-valid')}
                                                            classNamePrefix={'react-select'}
                                                            inputValue={inputValue}
                                                            menuIsOpen={inputValue.length >= 2}
                                                            onInputChange={(value) => setInputValue(value)}
                                                            filterOption={(opt, inp) => {
                                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                                return option.indexOf(input) !== -1;
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).id}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_name")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.settlement_name")}
                                                            value={i18n.language === "en" ? selectedSettlement?.namefOik : selectedSettlement?.nameOik ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.municipal")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.municipal")}
                                                            value={i18n.language === "en" ? selectedSettlement?.namefOta : selectedSettlement?.nameOta ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.administrative_region")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.administrative_region")}
                                                            value={i18n.language === "en" ? selectedSettlement?.namefGdiam : selectedSettlement?.nameGdiam ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.river_basin")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.river_basin")}
                                                            id="riverBasin"
                                                            name="riverBasin"
                                                            value={i18n.language === "en" ? (props.values as any).riverBasinEn : (props.values as any).riverBasin}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.water_district")}</Form.Label>
                                                    <Col md={{ span: 7 }} className="pe-0">
                                                        <Form.Control
                                                            placeholder={t("settlements.water_district")}
                                                            id="waterDistrict"
                                                            name="waterDistrict"
                                                            value={i18n.language === "en" ? (props.values as any).waterDistrictEn : (props.values as any).waterDistrict}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="area"
                                                            name="area"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).area || ""}
                                                            isInvalid={(props.touched as any).area && !!(props.errors as any).area}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.area_from_shape_ha")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => {
                                                                if (geometry) {
                                                                    let factor = getPointResolution('EPSG:3857', 1, (geometry as Polygon).getFirstCoordinate());
                                                                    let hectares = ((geometry as Polygon).getArea() * Math.pow(factor, 2)) / 10000;
                                                                    return hectares.toFixed(3);
                                                                } else {
                                                                    return "";
                                                                }
                                                            })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).area}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="population"
                                                            name="population"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).population || ""}
                                                            isInvalid={(props.touched as any).population && !!(props.errors as any).population}
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).population}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.equivalent_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="equivalentPopulation"
                                                            name="equivalentPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).equivalentPopulation || ""}
                                                            isInvalid={(props.touched as any).equivalentPopulation && !!(props.errors as any).equivalentPopulation}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.population_density")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            value={(() => { let res = (parseFloat((props.values as any).equivalentPopulation) / (parseFloat((props.values as any).area) * 10)); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).equivalentPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.served_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="servedPopulation"
                                                            name="servedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulation || ""}
                                                            isInvalid={(props.touched as any).servedPopulation && !!(props.errors as any).servedPopulation}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_service_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            id="servedPopulationPercentage"
                                                            name="servedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).servedPopulationPercentage && !!(props.errors as any).servedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).servedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).servedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).servedPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.connected_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedPopulation"
                                                            name="connectedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulation || ""}
                                                            isInvalid={(props.touched as any).connectedPopulation && !!(props.errors as any).connectedPopulation}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_connected_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }} className="pe-0">
                                                        <Form.Control
                                                            id="connectedPopulationPercentage"
                                                            name="connectedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).connectedPopulationPercentage && !!(props.errors as any).connectedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).connectedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).connectedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).connectedPopulation}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={{ span: 5 }}>
                                                <h4>{t("settlements.settlement_limits")}</h4>
                                                <div style={{ width: "100%", height: "500px" }}>
                                                    <RMap
                                                        className='w-100 h-100'
                                                        initial={{ center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                                                        ref={mapRef}
                                                    >
                                                        <RControl.RCustom className="m-2">
                                                            <Button variant="secondary" onClick={() => { !drawing && setGeometry(undefined); setDrawing(!drawing); }}>
                                                                <PencilFill />
                                                            </Button>
                                                        </RControl.RCustom>
                                                        <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                                            <ROSM properties={{ label: "Map" }} />
                                                            <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                                                        </RControl.RLayers>
                                                        <RLayerVector
                                                            zIndex={11}
                                                            onChange={(e: BaseEvent) => {
                                                                let source = e.target as VectorSource<Geometry>;
                                                                if (!source?.forEachFeatureAtCoordinateDirect) {
                                                                    return;
                                                                }

                                                                let feats = source.getFeatures();
                                                                if (feats.length > 1) {
                                                                    let geom = feats[0].getGeometry();
                                                                    setGeometry(geom);
                                                                    if (geom) {
                                                                        let wkt = new WKT();
                                                                        let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                                                        props.setFieldValue("shape", wktGeom);
                                                                        let ext = geom.getExtent();
                                                                        var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                                                                        var center = extent.getCenter(lonLatExtent);
                                                                        if (center) {
                                                                            props.setFieldValue("longitude", center[0]);
                                                                            props.setFieldValue("latitude", center[1]);
                                                                        }
                                                                    }
                                                                    source.clear();
                                                                    setDrawing(false);
                                                                }
                                                            }}
                                                        >
                                                            <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={geometry}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(71,86,127, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="#47567f"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>
                                                            {drawing && <RInteraction.RDraw
                                                                type={"Polygon"}
                                                            />}
                                                        </RLayerVector>
                                                    </RMap>
                                                    <Row className='justify-content-center'>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 200, 0, 0.5)" style={{ border: "2px solid rgba(0, 200, 0, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.settlement_limits")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(255, 60, 0, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.constructed_sewage_network")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 138, 255, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.connected_sewage_network")}</Form.Text></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ButtonGroup className='position-fixed bottom-0 end-0 me-2 mb-2 p-2' style={{ backgroundColor: "#00000010" }} aria-label="Toolbar">
                                        <Button variant="success" type="submit" className="d-flex align-items-center justify-content-center m-0" disabled={saving}>{saving ? <><Spinner variant="light" animation="border" size="sm" className="me-2" /> {t("wtp.button.saving")}</> : <><Save className="me-2"></Save> {t("wtp.button.save")}</>}</Button>
                                        {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Button variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => navigate('/settlements/index')} disabled={saving}><SkipBackward className="me-2"></SkipBackward> {t("wtp.button.back")}</Button>}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </form>
                    </>)}
            </Formik>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default SettlementsAdd;
