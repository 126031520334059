import { useEffect, useRef, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Lock, Pencil, PlusCircle, Search } from 'react-bootstrap-icons';
import useFetch from 'use-http';
import { SamplingParameter } from '../../data/Definitions';
import { ConfirmationPopover } from '../../components/ConfirmationPopover';
import DataTable from 'react-data-table-component';
import { Formik } from 'formik';
import * as yup from 'yup';

const ParametersIndex: React.FC = (props) => {
    const [parameters, setParameters] = useState<SamplingParameter[]>();
    const [filteredParameters, setFilteredParameters] = useState<SamplingParameter[]>([]);
    const [selectedParameter, setSelectedParameter] = useState<SamplingParameter | null>(null)
    const [loadingError, setLoadingError] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [search, setSearch] = useState('');
    const [addParameterModalShow, setAddParameterModalShow] = useState(false);
    const addFormRef = useRef<any>(null);
    const [editParameterModalShow, setEditParameterModalShow] = useState(false);
    const editFormRef = useRef<any>(null);

    const { get, post, put, del, response, loading, error, cache } = useFetch('/api/data/parameters');
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSearchText = (event: any) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if (search) {
            let s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var filtered = [...(parameters ?? [])].filter(x => `${x.id} ${x.name} ${x.description}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(s));
            setFilteredParameters(filtered);
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    };

    useEffect(() => {
        handleSearch();
    }, [parameters])

    useEffect(() => {
        getParameters();
    }, [])

    const getParameters = async () => {
        setLoadingError(false);
        let as = await get();
        if (response.ok) setParameters(as);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const removeParameter = async (id: string) => {
        if (!id) return;
        await del(`/${id}`);
        if (response.ok) {
            cache.delete(`url:/api/data/parameters||method:GET||body:`);
            await getParameters();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        unit: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        description: yup.string().typeError(t("wtp.validation.type_error")),
    });

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            cell: (row: SamplingParameter) => (
                <ButtonGroup>
                    {<ConfirmationPopover
                        id={`parameter-confirmation_popover-${row.id}`}
                        header={t("wtp.reuse.confirmation.header")}
                        body={t("wtp.reuse.confirmation.body")}
                        okLabel={t("wtp.reuse.confirmation.ok")}
                        onOk={() => { removeParameter(row.id.toString()) }}
                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                        onCancel={() => { }}
                        disabled={!(row.id > 8 && row.id !== 10 && row.id !== 73 && row.id !== 74 && row.id !== 75)}
                        icon={!(row.id > 8 && row.id !== 10 && row.id !== 73 && row.id !== 74 && row.id !== 75) ? <Lock /> : undefined}
                    />}
                    <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ border: "none" }}
                        className="d-flex align-items-center justify-content-center m-0"
                        onClick={() => {
                            setSelectedParameter(row);
                            setEditParameterModalShow(true);
                        }}
                    >
                        <Pencil />
                    </Button>
                </ButtonGroup>
            ),
            width: "80px"
        },
        {
            name: t("admin.parameters.parameter_name"),
            selector: (row: SamplingParameter) => row.name ?? "",
            sortable: true
        },
        {
            name: t("admin.parameters.parameter_unit"),
            selector: (row: SamplingParameter) => row.unit ?? "",
            sortable: true
        },
        {
            name: t("admin.parameters.parameter_description"),
            selector: (row: SamplingParameter) => row.description ?? "",
            sortable: true
        },
    ];

    const handleAddSubmit = async (val: {
        name: string,
        unit: string | null,
        description: string | null,
    }) => {
        (val as any).id = 0;
        await post(val);
        if (response.ok) {
            cache.delete(`url:/api/data/parameters||method:GET||body:`);
            await getParameters();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            return;
        }
        setAddParameterModalShow(false);
        setSelectedParameter(null);
    };

    const handleEditSubmit = async (val: SamplingParameter) => {
        await put(`/${val.id}`, val);
        if (response.ok) {
            cache.delete(`url:/api/data/parameters||method:GET||body:`);
            await getParameters();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            return;
        }
        setEditParameterModalShow(false);
        setSelectedParameter(null);
    };

    return (
        <>
            {!loading && !error && parameters && <>
                <Row>
                    <Col>
                        <h4>{t("admin.parameters")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => setAddParameterModalShow(true)}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        </ButtonGroup>
                        <InputGroup
                            className="mt-2"
                            size="sm"
                            style={{ width: "350px" }}
                        >
                            <Form.Control
                                id="search"
                                name="search"
                                placeholder={t("search_for")}
                                onChange={handleSearchText}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={search || ""}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={isFiltering ? filteredParameters : parameters}
                            pagination
                            // paginationComponent={BootyPagination}
                            customStyles={customStyles}
                            noDataComponent={<div className="text-center">
                                {t("wtp.table.no_data")}
                            </div>}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <Modal
                show={addParameterModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setAddParameterModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${t("wtp.button.add")} ${t("admin.parameter")}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ name: "", unit: "", description: "" }}
                        validationSchema={validationSchema}
                        onSubmit={(v) => handleAddSubmit(v)}
                    >
                        {props => <Form noValidate ref={addFormRef} onSubmit={props.handleSubmit}>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_name")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_name")}
                                        id="name"
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_unit")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_unit")}
                                        id="unit"
                                        name="unit"
                                        value={props.values.unit}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).unit && !!(props.errors as any).unit}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).unit}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_description")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_description")}
                                        id="description"
                                        name="description"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).description && !!(props.errors as any).description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).description}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => addFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.add")}</Button>
                    <Button variant="secondary" onClick={() => setAddParameterModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editParameterModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setEditParameterModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`${t("wtp.button.edit_capitalized")} ${t("admin.parameter")}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={selectedParameter!}
                        validationSchema={validationSchema}
                        onSubmit={(v) => handleEditSubmit(v)}
                    >
                        {props => <Form noValidate ref={editFormRef} onSubmit={props.handleSubmit}>
                            <Form.Control type='hidden' name="id" id="id" value={selectedParameter?.id} />
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_name")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_name")}
                                        id="name"
                                        name="name"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).name && !!(props.errors as any).name}
                                        disabled={!(props.values.id > 8 && props.values.id !== 10 && props.values.id !== 73 && props.values.id !== 74 && props.values.id !== 75)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).name}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_unit")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_unit")}
                                        id="unit"
                                        name="unit"
                                        value={props.values.unit ?? ""}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).unit && !!(props.errors as any).unit}
                                        disabled={!(props.values.id > 8 && props.values.id !== 10 && props.values.id !== 73 && props.values.id !== 74 && props.values.id !== 75)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).unit}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-2">
                                <Form.Label column className="text-end" md={{ span: 4 }}>{t("admin.parameters.parameter_description")}</Form.Label>
                                <Col md={{ span: 8 }}>
                                    <Form.Control
                                        placeholder={t("admin.parameters.parameter_description")}
                                        id="description"
                                        name="description"
                                        value={props.values.description ?? ""}
                                        onChange={props.handleChange}
                                        isInvalid={(props.touched as any).description && !!(props.errors as any).description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(props.errors as any).description}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editFormRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))}>{t("wtp.button.update")}</Button>
                    <Button variant="secondary" onClick={() => setEditParameterModalShow(false)}>{t("wtp.button.cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ParametersIndex;
