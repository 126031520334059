import { useContext, useEffect, useState } from 'react';
import { ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../state/State';
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { ConfirmationPopover } from '../../components/ConfirmationPopover';
import { ArrowUpSquare } from 'react-bootstrap-icons';

interface Snapshot {
    id: number
    snapshotFile: string
}

const Snapshots: React.FC = (props) => {
    const loc = useLocation();
    const context = useContext(AppContext);
    const [loadingError, setLoadingError] = useState(false);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [snapshots, setSnapshots] = useState<Array<Snapshot>>([]);
    const [loading, setLoading] = useState(false);

    const getSnapshots = async () => {
        setLoadingError(false);
        const response = await fetch(`/api/Snapshots`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (response.ok) {
            setSnapshots(await response.json());
        }

        if (response.redirected) {
            navigate("/login");
        }

        if (response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const restoreSnapshot = async (file: string) => {
        setLoading(true);
        await fetch(`/api/Snapshots`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ snapshotFile: file }),
        });

        setTimeout(() => {
            document.location = "/";
        }, 10000);
    }

    useEffect(() => {
        getSnapshots();
    }, [])

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Restore snapshot" });
    }, [loc.pathname]);

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλα"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            name: t("snapshots.snapshotFile"),
            selector: (row: Snapshot) => row.snapshotFile,
            sortable: true
        },
        {
            cell: (row: Snapshot) => (
                <ButtonGroup>
                    <ConfirmationPopover
                        id={`settlement-confirmation_popover-${row.id}`}
                        header={t("snapshots.restore.header")}
                        body={t("snapshots.restore.body")}
                        okLabel={t("snapshots.restore.ok")}
                        onOk={() => { restoreSnapshot(row.snapshotFile); }}
                        cancelLabel={t("snapshots.restore.cancel")}
                        onCancel={() => { }}
                        icon={<ArrowUpSquare></ArrowUpSquare>}
                    />
                </ButtonGroup >
            ),
            width: "80px"
        }
    ];

    if (loading) {
        return <Container fluid className='menu-padding text-center'>
            <div className="spinner-border" style={{ width: "10rem", height: "10rem" }} role="status">
                <span className="visually-hidden">Restoring...</span>
            </div>
        </Container>
    }

    return (
        <Container fluid className='menu-padding'>
            <Row>
                <Col>
                    <h4>{t("Snapshots")}</h4>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs={{ span: 6, offset: 3 }}>
                    <DataTable
                        columns={columns}
                        data={snapshots}
                        defaultSortFieldId={1}
                        pagination
                        customStyles={customStyles}
                        noDataComponent={<div className="text-center">
                            {t("snapshots.table.no_data")}
                        </div>}
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </Col>
            </Row>
        </Container >
    );
}

export default Snapshots;
