import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
    const { t, i18n } = useTranslation();

    return <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3">{i18n.language === "en" ? "Page not found" : "Η σελίδα δε βρέθηκε"}.</p>
            <p className="lead">
                {i18n.language === "en" ? "The page you're looking for doesn't exist." : "Η σελίδα που αναζητάτε δεν υπάρχει."}
            </p>
            <Link className="btn btn-primary" to="/">{i18n.language === "en" ? "Go Home" : "Επιστροφή στην αρχική"}</Link>
        </div>
    </div>
}

export default NotFound;