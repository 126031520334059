import * as React from "react";
import { UserInfo } from "../data/User";
import { ReducerAction } from "./StateActions";

export interface AppState {
    loggedIn: boolean,
    userInfo: UserInfo | null
    snapshotInstance: boolean
    mainAppTitle: string
};

const initialState: AppState = {
    loggedIn: false,
    userInfo: null,
    snapshotInstance: false,
    mainAppTitle: "",
};

let AppContext = React.createContext<{
    state: AppState;
    dispatch: (action: ReducerAction) => void;
}>({
    state: initialState,
    dispatch: () => { }
});

let reducer = (state: AppState, action: ReducerAction): AppState => {
    switch (action.type) {
        case "LOGIN": {
            return {
                ...state,
                loggedIn: true,
                userInfo: action.payload.userInfo
            }
        }
        case "LOGOUT": {
            return {
                ...state,
                loggedIn: false,
                userInfo: null
            }
        }
        case "SetMainTitleAction": {
            return {
                ...state,
                mainAppTitle: action.title,
            }
        }
    }

    return state;
};

const AppContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };
