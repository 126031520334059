import { fromLonLat, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerVector, RMap, ROSM, ROverlay, RPopup, RStyle } from "rlayers";
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../state/State';
import 'ol/ol.css';
import { useTranslation, Trans } from 'react-i18next';
import { Button, ButtonGroup, Col, Container, Offcanvas, Pagination, Row, Table } from "react-bootstrap";
import { ExclamationCircle, FileEarmarkExcel, FiletypeCsv, FiletypeXlsx, Filter, LayoutTextSidebar, X } from 'react-bootstrap-icons';
import { Link, useLocation } from "react-router-dom";
import Select from 'react-select';
import useFetch from "use-http";
import { AdministrativeRegion, AgglomerationQueryView, Authority, PriorityCategory, RiverBasin, RiverBasinDistrict, WastewaterTreatmentPlantQueryView } from "../data/Definitions";
import { Formik } from "formik";
import { reuseOptions, reuseOptionsEn, sludgeTreatmentOptions, wasteTreatmentOptions } from "../data/Data";
import DataTable from "react-data-table-component";
import { Geometry, Point } from "ol/geom";
import WKT from "ol/format/WKT";
import * as extent from "ol/extent";
import { shiftKeyOnly } from "ol/events/condition";
import zipcelx from 'zipcelx';

const QueryTools: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    const [loadingError, setLoadingError] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const popup = useRef<any>([]);
    const vectorLayer = useRef<RLayerVector>(null);
    const tableRef = useRef(null);

    const [riverBasinDistricts, setRiverBasinDistricts] = useState<RiverBasinDistrict[]>([]);
    const [riverBasins, setRiverBasins] = useState<RiverBasin[]>([]);
    const [administrativeRegions, setAdministrativeRegions] = useState<AdministrativeRegion[]>([]);
    const [priorityCategories, setPriorityCategories] = useState<PriorityCategory[]>([]);
    const [authorities, setAuthorities] = useState<Authority[]>([]);
    const [isWTPFiltering, setIsWTPFiltering] = useState(true);
    const [wastewaterTreatmentPlants, setWastewaterTreatmentPlants] = useState<WastewaterTreatmentPlantQueryView[]>([]);
    const [agglomerations, setAgglomerations] = useState<AgglomerationQueryView[]>([]);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Query Tools" });
    }, [loc.pathname]);


    useEffect(() => {
        getData();
    }, [i18n.language])

    useEffect(() => {
        if (isWTPFiltering) {
            setAgglomerations([]);
            getWastewaterTreatmentPlants({});
        } else {
            setWastewaterTreatmentPlants([]);
            getAgglomerations({});
        }
    }, [isWTPFiltering])

    const getData = async () => {
        setLoadingError(false);
        let wds = await get('/query/riverbasindistricts');
        if (response.ok) setRiverBasinDistricts(wds.sort((a: RiverBasinDistrict, b: RiverBasinDistrict) => (a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)));
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let ars = await get('/query/administrativeregions');
        if (response.ok) setAdministrativeRegions(ars.sort((a: AdministrativeRegion, b: AdministrativeRegion) => (a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)));
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let pcs = await get('/query/prioritycategories');
        if (response.ok) setPriorityCategories(pcs);
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let rbs = await get('/query/riverbasins');
        if (response.ok) setRiverBasins(rbs.sort((a: RiverBasin, b: RiverBasin) => (a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)));
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let aus = await get('/query/authorities');
        if (response.ok) {
            setAuthorities(aus.filter((x: Authority) => x.authorityTypeId === 1).sort((a: Authority, b: Authority) => (a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? 1 : ((b.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "") > a.name!.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) ? -1 : 0)));
        }
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const getWastewaterTreatmentPlants = async (values: any) => {
        setShowForm(false);
        setIsLoading(true);
        values.administrativeRegion = values.administrativeRegion?.map((x: any) => x.value) ?? null;
        values.riverBasinDistrict = values.riverBasinDistrict?.map((x: any) => x.value) ?? null;
        values.riverBasin = values.riverBasin?.map((x: any) => x.value) ?? null;
        values.municipal = values.municipal?.map((x: any) => x.value) ?? null;
        values.wasteTreatmentMethod = values.wasteTreatmentMethod?.map((x: any) => x.value) ?? null;
        values.sludgeTreatmentMethod = values.sludgeTreatmentMethod?.map((x: any) => x.value) ?? null;
        values.priority = values.priority?.map((x: any) => x.value) ?? null;
        values.reuseMethod = values.reuseMethod?.map((x: any) => x.value) ?? null;
        values.sensitive = values.sensitive?.value ?? null;
        values.reuse = values.reuse?.value ?? null;
        values.compliance = values.compliance?.value ?? null;

        let data = new URLSearchParams();
        for (const property in values) {
            if (!(values[property] === null || values[property] === undefined || values[property] === "")) {
                if (Array.isArray(values[property])) {
                    (values[property] as any[]).forEach(x => data.append(property, x));
                } else {
                    data.append(property, values[property]);
                }
            }
        }

        let wtps = await get('/query/wastewatertreatmentplants?' + data.toString());
        if (response.ok) {
            // vectorLayer.current?.source.clear();
            setWastewaterTreatmentPlants(wtps);
        }
        setIsLoading(false);
    }

    const getAgglomerations = async (values: any) => {
        setShowForm(false);
        setIsLoading(true);
        values.administrativeRegion = values.administrativeRegion?.map((x: any) => x.value) ?? null;
        values.riverBasinDistrict = values.riverBasinDistrict?.map((x: any) => x.value) ?? null;
        values.riverBasin = values.riverBasin?.map((x: any) => x.value) ?? null;
        values.municipal = values.municipal?.map((x: any) => x.value) ?? null;
        values.priority = values.priority?.map((x: any) => x.value) ?? null;
        // values.sewageNetworkPercentage = values.sewageNetworkPercentage?.value ?? null;
        values.sensitive = values.sensitive?.value ?? null;

        let data = new URLSearchParams();
        for (const property in values) {
            if (!(values[property] === null || values[property] === undefined) || values[property] === "") {
                if (Array.isArray(values[property])) {
                    (values[property] as any[]).forEach(x => data.append(property, x));
                } else {
                    data.append(property, values[property]);
                }
            }
        }

        let aggs = await get('/query/agglomerations?' + data.toString());
        if (response.ok) {
            // vectorLayer.current?.source.clear();
            setAgglomerations(aggs);
        }
        setIsLoading(false);
    }

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const wastewaterTreatmentPlantColumns = [
        {
            name: t("query.wtp.code"),
            cell: (row: WastewaterTreatmentPlantQueryView) => row.code ? <Link to={"/wtp?" + row.code}><strong>{row.code}</strong></Link> : "",
            selector: (row: WastewaterTreatmentPlantQueryView) => row.code ?? "",
            center: true,
            sortable: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.name"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.nameEn : row.name) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("wtp.general.coordinates.longitude"),
            selector: (row: WastewaterTreatmentPlantQueryView) => {
                if (row.longitude) {
                    return row.longitude.toLocaleString(i18n.language, { maximumFractionDigits: 6, minimumFractionDigits: 6 });
                }
                return "";
            },
            center: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("wtp.general.coordinates.latitude"),
            selector: (row: WastewaterTreatmentPlantQueryView) => {
                if (row.latitude) {
                    return row.latitude.toLocaleString(i18n.language, { maximumFractionDigits: 6, minimumFractionDigits: 6 });
                }
                return "";
            },
            center: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.year"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.year ?? "",
            center: true,
            sortable: true,
            minWidth: "160px",
        },
        // {
        //     name: t("query.wtp.priority"),
        //     selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.priorityEn : row.priority) ?? "",
        //     sortable: true,
        //     wrap: true,
        //     minWidth: "160px",
        // },
        {
            name: t("query.wtp.compliance"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.compliance ? "✔️" : "❌",
            center: true,
            sortable: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.waste_treatment"),
            // selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? wasteTreatmentOptions.find(x => x.code === row.wasteTreatmentMethod)?.nameEn : wasteTreatmentOptions.find(x => x.code === row.wasteTreatmentMethod)?.name) ?? "",
            selector: (row: WastewaterTreatmentPlantQueryView) => wasteTreatmentOptions.filter(x => row.wasteTreatmentMethod?.split(',').includes(x.code ?? "")).map(x => i18n.language === "en" ? x.nameEn : x.name).join(', '),
            sortable: true,
            wrap: true,
            minWidth: "240px",
        },
        {
            name: t("query.wtp.sludge_treatment"),
            // selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? sludgeTreatmentOptions.find(x => x.code === row.sludgeTreatmentMethod)?.nameEn : sludgeTreatmentOptions.find(x => x.code === row.sludgeTreatmentMethod)?.name) ?? "",
            selector: (row: WastewaterTreatmentPlantQueryView) => sludgeTreatmentOptions.filter(x => row.sludgeTreatmentMethod?.split(',').includes(x.code ?? "")).map(x => i18n.language === "en" ? x.nameEn : x.name).join(', '),
            sortable: true,
            wrap: true,
            minWidth: "240px",
        },
        {
            name: t("query.wtp.capacity"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.capacity?.toLocaleString(i18n.language) || "",
            center: true,
            sortable: true,
            sortFunction: (a: WastewaterTreatmentPlantQueryView, b: WastewaterTreatmentPlantQueryView) => (b.capacity ?? 0) - (a.capacity ?? 0),
            minWidth: "160px",
        },
        {
            name: t("query.wtp.reuse"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.reuse ? "✔️" : "❌",
            center: true,
            sortable: true,
            minWidth: "240px",
        },
        {
            name: t("query.reuse_methods"),
            // selector: (row: WastewaterTreatmentPlantQueryView) => row.reuseMethods?.map(x => x.type.toString()).join(", ") ?? "",
            cell: (row: WastewaterTreatmentPlantQueryView) => row.reuseMethods ? <ul>{row.reuseMethods.map(x => <li>{t((i18n.language === "en" ? reuseOptionsEn : reuseOptions).find(y => y.value === x.type)?.label ?? "")}: {x.supply} {t("wtp.receiver.reuse.unit")}</li>)}</ul> : "",
            selector: (row: WastewaterTreatmentPlantQueryView) => row.reuseMethods ? row.reuseMethods.map(x => `${t((i18n.language === "en" ? reuseOptionsEn : reuseOptions).find(y => y.value === x.type)?.label ?? "")}: ${x.supply} ${t("wtp.receiver.reuse.unit")}`).join(", ") : "",
            wrap: true,
            sortable: false,
            minWidth: "600px",
        },
        {
            name: t("query.wtp.administrative_region"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.administrativeRegionEn : row.administrativeRegion) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.municipal"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.municipalEn : row.municipal) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.water_district"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.riverBasinDistrictEn : row.riverBasinDistrict) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.river_basin"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.riverBasinEn : row.riverBasin) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.receiver_code"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.receiverCode ?? "",
            center: true,
            sortable: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.receiver_name"),
            selector: (row: WastewaterTreatmentPlantQueryView) => (i18n.language === "en" ? row.receiverNameEn : row.receiverName) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: t("query.wtp.receiver_type"),
            selector: (row: WastewaterTreatmentPlantQueryView) => row.receiverSensitive ? t("query.sensitive") : t("query.normal"),
            center: true,
            sortable: true,
            minWidth: "160px",
        },
    ];

    const agglomerationColumns = [
        {
            name: t("query.agglomeration.code"),
            cell: (row: AgglomerationQueryView) => row.code ? <Link to={"/agglomerations?" + row.code}><strong>{row.code}</strong></Link> : "",
            selector: (row: AgglomerationQueryView) => row.code ?? "",
            center: true,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: t("query.agglomeration.name"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.nameEn : row.name) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.agglomeration.coordinates.longitude"),
            selector: (row: AgglomerationQueryView) => {
                if (row.location) {
                    var longitude = (getGeometryFromLocation(row.location, true) as string)?.split(", ")?.shift();
                    if (longitude) {
                        var lng = parseFloat(longitude);
                        if (!isNaN(lng)) {
                            return lng.toLocaleString(i18n.language, { maximumFractionDigits: 6, minimumFractionDigits: 6 });
                        }
                    }
                }
                return "";
            },
            center: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.agglomeration.coordinates.latitude"),
            selector: (row: AgglomerationQueryView) => {
                if (row.location) {
                    var latitude = (getGeometryFromLocation(row.location, true) as string)?.split(", ")?.pop();
                    if (latitude) {
                        var lat = parseFloat(latitude);
                        if (!isNaN(lat)) {
                            return lat.toLocaleString(i18n.language, { maximumFractionDigits: 6, minimumFractionDigits: 6 });
                        }
                    }
                }
                return "";
            },
            center: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("agglomerations.characterization_year"),
            selector: (row: AgglomerationQueryView) => row.year || "",
            center: true,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: t("agglomerations.characterization"),
            selector: (row: AgglomerationQueryView) => row.isSensitive ? t("query.sensitive") : t("query.normal"),
            center: true,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.population"),
            selector: (row: AgglomerationQueryView) => row.population?.toLocaleString(i18n.language) || "",
            center: true,
            sortable: true,
            sortFunction: (a: AgglomerationQueryView, b: AgglomerationQueryView) => (b.population ?? 0) - (a.population ?? 0),
            minWidth: "200px",
        },
        {
            name: t("query.sewage_network_percentage"),
            selector: (row: AgglomerationQueryView) => row.servedPopulationPercentage ? `${row.servedPopulationPercentage.toLocaleString(i18n.language, { maximumFractionDigits: 2 })}%` : "",
            center: true,
            sortable: true,
            sortFunction: (a: AgglomerationQueryView, b: AgglomerationQueryView) => (b.servedPopulationPercentage ?? 0) - (a.servedPopulationPercentage ?? 0),
            minWidth: "200px",
        },
        // {
        //     name: t("query.wtp.priority"),
        //     selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.priorityEn : row.priority) ?? "",
        //     sortable: true,
        //     wrap: true
        // },
        {
            name: t("query.wtp.administrative_region"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.administrativeRegionEn : row.administrativeRegion) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.municipal"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.municipalEn : row.municipal) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.water_district"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.riverBasinDistrictEn : row.riverBasinDistrict) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.river_basin"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.riverBasinEn : row.riverBasin) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("wastewatertreatmentplants.wastewatertreatmentplant_name"),
            selector: (row: AgglomerationQueryView) => (i18n.language === "en" ? row.wtpNameEn : row.wtpName) ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("wastewatertreatmentplants.wastewatertreatmentplant_code"),
            selector: (row: AgglomerationQueryView) => row.wtpCode ?? "",
            sortable: true,
            wrap: true,
            minWidth: "200px",
        },
        {
            name: t("query.wtp.receiver_type"),
            selector: (row: AgglomerationQueryView) => row.receiverSensitive === null ? "" : row.receiverSensitive === true ? t("query.sensitive") : t("query.normal"),
            center: true,
            sortable: true,
            minWidth: "160px",
        },
    ];

    const getGeometryFromLocation = (location: string, returnLonLat: boolean = false) => {
        let wkt = new WKT();
        let geom = wkt.readGeometry(location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        let ext = geom.getExtent();
        let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
        let lonLatCenter = extent.getCenter(lonLatExtent);

        if (returnLonLat) {
            if (lonLatCenter) {
                return `${lonLatCenter[0].toFixed(6)}, ${lonLatCenter[1].toFixed(6)}`;
            } else {
                return "";
            }
        } else {
            if (lonLatCenter) {
                return new Point(fromLonLat([lonLatCenter[0], lonLatCenter[1]]))
            }
        }
    }

    const handleDownloadExcel = (data: any, isWtp: boolean) => {
        const columns = isWtp ? wastewaterTreatmentPlantColumns : agglomerationColumns;
        const keys = columns.map(x => x.name);

        const headData = keys.map((col) => ({
            value: col,
            type: "string",
        }));

        const bodyData = data.map((item: any) => {
            return columns.map(x => { return { value: x.selector ? x.selector(item) : x.cell ? x.cell(item) : "", type: x.selector ? typeof x.selector(item) : x.cell ? typeof x.cell(item) : "" } });
        });

        const config = {
            filename: isWtp ? 'wastewaterTreatmentPlants_export' : 'agglomerations_export',
            sheet: { data: [headData, ...bodyData] },
        };

        zipcelx(config);
    }

    const actionsMemoWtp = useMemo(() => <Button variant="outline-secondary" onClick={() => handleDownloadExcel(wastewaterTreatmentPlants, true)}><FiletypeXlsx /></Button>, [wastewaterTreatmentPlants, i18n.language]);
    const actionsMemoAgglo = useMemo(() => <Button variant="outline-secondary" onClick={() => handleDownloadExcel(agglomerations, false)}><FiletypeXlsx /></Button>, [agglomerations, i18n.language]);

    return (
        <>
            <RMap
                className='full-screen-map with-space'
                initial={{ center: fromLonLat([23.70066804453946, 37.983956404442104]), zoom: 6 }}
                noDefaultControls
                noDefaultInteractions
            >
                <RControl.RZoom />
                <RControl.RZoomSlider />
                <RInteraction.RPinchZoom />
                <RInteraction.RDragPan />
                <RInteraction.RMouseWheelZoom condition={shiftKeyOnly} />
                <RInteraction.RDoubleClickZoom />
                <ROSM />
                <RLayerVector
                    zIndex={11}
                    ref={vectorLayer}
                >
                    {isWTPFiltering ? wastewaterTreatmentPlants.filter(x => x.longitude && x.latitude).map((x, i) => <RFeature
                        key={`Feature_${x.code}_${Math.random()}`}
                        geometry={new Point(fromLonLat([x.longitude!, x.latitude!]))}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            popup.current.forEach((element: any) => {
                                element?.hide();
                            });
                            popup.current[i]?.show();
                        }}
                    >
                        <RStyle.RStyle>
                            <RStyle.RCircle radius={x.capacity ? x.capacity >= 500000 ? 10 : x.capacity >= 250000 ? 9 : x.capacity >= 100000 ? 8 : x.capacity >= 50000 ? 7 : 6 : 6}>
                                <RStyle.RFill color={x.compliance ? "rgba(71,86,127, 0.5)" : "rgba(200, 50, 50, 0.5)"}></RStyle.RFill>
                                <RStyle.RStroke width={2} color={x.compliance ? "rgba(71,86,127, 1)" : "rgba(200, 50, 50, 1)"}></RStyle.RStroke>
                            </RStyle.RCircle>
                        </RStyle.RStyle>
                        <RPopup ref={el => popup.current[i] = el} trigger={'click'} className='example-overlay' autoPan>
                            <div className='card' style={{ borderColor: x.compliance ? "rgba(71,86,127, 0.5)" : "rgba(200, 50, 50, 0.5)" }}>
                                <p className='card-header' style={{ backgroundColor: x.compliance ? "rgba(71,86,127, 0.25)" : "rgba(200, 50, 50, 0.25)" }}>
                                    <strong>{i18n.language === "en" ? x.nameEn : x.name} - {x.code}</strong>
                                    <button type="button" className="btn btn-outline-secondary btn-sm py-0 float-end" onClick={() => popup.current[i]?.hide()}>X</button>
                                </p>
                                <p className='card-body text-center'>
                                    {t("query.wtp.administrative_region")}: <strong>{i18n.language === "en" ? x.administrativeRegionEn : x.administrativeRegion}</strong><br />
                                    {t("query.wtp.municipal")}: <strong>{i18n.language === "en" ? x.municipalEn : x.municipal}</strong><br />
                                    {t("query.wtp.water_district")}: <strong>{i18n.language === "en" ? x.riverBasinDistrictEn : x.riverBasinDistrict}</strong><br />
                                    {t("query.wtp.river_basin")}: <strong>{i18n.language === "en" ? x.riverBasinEn : x.riverBasin}</strong><br />
                                    {t("query.wtp.capacity")}: <strong>{x.capacity?.toLocaleString(i18n.language)}</strong><br />
                                    {t("query.wtp.year")}: <strong>{x.year}</strong>
                                </p>
                            </div>
                        </RPopup>
                    </RFeature>) : agglomerations.filter(x => x.location).map((x, i) => <RFeature
                        key={`Feature_${x.code}`}
                        geometry={getGeometryFromLocation(x.location!) as Geometry | undefined}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            popup.current.forEach((element: any) => {
                                element?.hide();
                            });
                            popup.current[i]?.show();
                        }}
                    >
                        <RStyle.RStyle>
                            <RStyle.RCircle radius={6}>
                                <RStyle.RFill color={(x as any).compliance ? "rgba(25, 135, 84, 0.5)" : "rgba(253, 126, 20, 0.5)"}></RStyle.RFill>
                                <RStyle.RStroke width={2} color={(x as any).compliance ? "rgba(25, 135, 84, 1)" : "rgba(253, 126, 20, 1)"}></RStyle.RStroke>
                            </RStyle.RCircle>
                        </RStyle.RStyle>
                        <RPopup ref={el => popup.current[i] = el} trigger={'hover'} className='example-overlay' autoPan>
                            <div className='card' style={{ borderColor: (x as any).compliance ? "rgba(25, 135, 84, 0.5)" : "rgba(253, 126, 20, 0.5)" }}>
                                <p className='card-header' style={{ backgroundColor: (x as any).compliance ? "rgba(25, 135, 84, 0.25)" : "rgba(253, 126, 20, 0.25)" }}>
                                    <strong>{i18n.language === "en" ? x.nameEn : x.name} - {x.code}</strong>
                                </p>
                                <p className='card-body text-center'>
                                    {t("query.wtp.administrative_region")}: <strong>{i18n.language === "en" ? x.administrativeRegionEn : x.administrativeRegion}</strong><br />
                                    {t("query.wtp.municipal")}: <strong>{i18n.language === "en" ? x.municipalEn : x.municipal}</strong><br />
                                    {t("query.wtp.water_district")}: <strong>{i18n.language === "en" ? x.riverBasinDistrictEn : x.riverBasinDistrict}</strong><br />
                                    {t("query.wtp.river_basin")}: <strong>{i18n.language === "en" ? x.riverBasinEn : x.riverBasin}</strong><br />
                                    {/* {t("query.wtp.priority")}: <strong>{i18n.language === "en" ? x.priorityEn : x.priority}</strong><br /> */}
                                    {t("query.wtp.population")}: <strong>{x.population ? x.population.toLocaleString(i18n.language) : " - "}</strong><br />
                                    {t("query.wtp.served_population_percentage")}: <strong>{x.servedPopulationPercentage ? `${x.servedPopulationPercentage.toLocaleString(i18n.language, { maximumFractionDigits: 2 })}%` : " - "}</strong><br />
                                    {t("agglomerations.characterization_year")}: <strong>{x.year || ""}</strong><br />
                                    {/* {t("agglomerations.characterization")}: <strong>{x.isSensitive ? t("query.sensitive") : t("query.normal")}</strong> */}
                                </p>
                            </div>
                        </RPopup>
                    </RFeature>)}
                </RLayerVector>
            </RMap>
            <div style={{ marginTop: "-40px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "right", right: "3em", padding: "5px 10px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                <p className="mb-0" style={{ fontSize: "small" }}><ExclamationCircle style={{ marginRight: "5px", marginTop: "-4px" }} /><strong>Hold shift + mouse wheel scroll to zoom</strong></p>
            </div>
            {!isWTPFiltering ?
                <></>
                // <div style={{ marginTop: "-120px", position: "relative", width: "fit-content", zIndex: 12, float: "right", right: "3em", padding: "10px 20px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                //     <p className="mb-2" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(25, 135, 84, 0.5)", border: "2px solid rgba(25, 135, 84, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.compliant")}</strong></p>
                //     <p className="mb-0" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(253, 126, 20, 0.5)", border: "2px solid rgba(253, 126, 20, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.non_compliant")}</strong></p>
                // </div>
                :
                <div style={{ marginTop: "-120px", position: "relative", width: "fit-content", zIndex: 12, float: "right", right: "3em", padding: "10px 20px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                    <p className="mb-2" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(71, 86, 127, 0.5)", border: "2px solid rgba(71, 86, 127, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.compliant")}</strong></p>
                    <p className="mb-0" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(200, 50, 50, 0.5)", border: "2px solid rgba(200, 50, 50, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.non_compliant")}</strong></p>
                </div>}
            <div className="d-block d-md-none" style={{ marginTop: "-46px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "left", left: "8px" }}>
                <Button variant="secondary" onClick={() => setShowForm(true)}>
                    <Filter />
                </Button>
            </div>
            <div className={`row g-2 query-form d-md-block ${!showForm && "d-none"}`}>
                <div className="position-absolute mt-0 px-0" style={{ top: 0, right: "-33px", width: "fit-content" }}>
                    <Button variant="light" style={{ backgroundColor: "white" }} size="sm" className="border-none d-block d-md-none" onClick={() => setShowForm(false)}><X /></Button>
                </div>
                <div className="col-12">
                    <select
                        id="queryType"
                        name="queryType"
                        onChange={e => setIsWTPFiltering(e.target.value === "0")}
                        value={isWTPFiltering ? 0 : 1}
                        className="form-select"
                    >
                        <option value={0}>{t("Wastewater Treatment Plants")}</option>
                        <option value={1}>{t("agglomerations")}</option>
                    </select>
                </div>
                {isWTPFiltering ?
                    <Formik
                        enableReinitialize
                        initialValues={Object.assign({}, {
                            year: new Date().getFullYear() - 1,
                            administrativeRegion: null,
                            riverBasinDistrict: null,
                            riverBasin: null,
                            municipal: null,
                            compliance: null,
                            priority: null,
                            minCapacity: null,
                            maxCapacity: null,
                            sensitive: null,
                            wasteTreatmentMethod: null,
                            sludgeTreatmentMethod: null,
                            reuse: null,
                            reuseMethod: null,
                        })}
                        onSubmit={(values) => getWastewaterTreatmentPlants(Object.assign({}, values))}
                    >
                        {props => (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <div className="form-container row g-2 my-2">
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("Year")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[...Array(new Date().getFullYear() - 2003)].map((_, index) => 2003 + index).reverse().map(x => { return { label: x, value: x } })}
                                            id="year"
                                            name="year"
                                            onChange={v => props.setFieldValue("year", v?.value)}
                                            value={[...Array(new Date().getFullYear() - 2003)].map((_, index) => 2003 + index).map(x => { return { label: x, value: x } }).find(x => x.value === props.values.year)}
                                            className={'react-select'}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("wtp.general.general.administrative_region")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={(administrativeRegions.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            id="administrativeRegion"
                                            name="administrativeRegion"
                                            onChange={v => props.setFieldValue("administrativeRegion", v)}
                                            value={props.values.administrativeRegion}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.riverBasinDistrict && (Array.isArray(props.values.riverBasinDistrict) && (props.values.riverBasinDistrict as any[]).length > 0)) || (!!props.values.riverBasin && (Array.isArray(props.values.riverBasin) && (props.values.riverBasin as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.water_district")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={(riverBasinDistricts.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλα"}
                                            id="riverBasinDistrict"
                                            name="riverBasinDistrict"
                                            onChange={v => props.setFieldValue("riverBasinDistrict", v)}
                                            value={props.values.riverBasinDistrict}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.administrativeRegion && (Array.isArray(props.values.administrativeRegion) && (props.values.administrativeRegion as any[]).length > 0)) || (!!props.values.municipal && (Array.isArray(props.values.municipal) && (props.values.municipal as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.river_basin")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={props?.values?.riverBasinDistrict && (props.values.riverBasinDistrict as any[]).length > 0 ? riverBasins.filter(x => ((props.values.riverBasinDistrict as unknown) as any[]).map(y => y.value).includes(x.riverBasinDistrictId)).map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) : (riverBasins.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            id="riverBasin"
                                            name="riverBasin"
                                            onChange={v => props.setFieldValue("riverBasin", v)}
                                            value={props.values.riverBasin}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.administrativeRegion && (Array.isArray(props.values.administrativeRegion) && (props.values.administrativeRegion as any[]).length > 0)) || (!!props.values.municipal && (Array.isArray(props.values.municipal) && (props.values.municipal as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.municipal")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={props?.values?.administrativeRegion && (props.values.administrativeRegion as any[]).length > 0 ? authorities.filter(x => ((props.values.administrativeRegion as unknown) as any[]).map(y => y.value).includes(x.administrativeRegionId)).map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) : authorities.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } })}
                                            placeholder={i18n.language === "en" ? "All" : "Όλοι"}
                                            id="municipal"
                                            name="municipal"
                                            onChange={v => props.setFieldValue("municipal", v)}
                                            value={props.values.municipal}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.riverBasinDistrict && (Array.isArray(props.values.riverBasinDistrict) && (props.values.riverBasinDistrict as any[]).length > 0)) || (!!props.values.riverBasin && (Array.isArray(props.values.riverBasin) && (props.values.riverBasin as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.compliance")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: 0, label: "Όλες", },
                                                { value: 1, label: `${t("project_data.compliant")}`, },
                                                { value: 2, label: `${t("project_data.non_compliant")}`, },
                                            ]}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            id="compliance"
                                            name="compliance"
                                            onChange={v => props.setFieldValue("compliance", v)}
                                            value={props.values.compliance}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("Min capacity (p.e)")}</label>
                                        <input
                                            id="minCapacity"
                                            name="minCapacity"
                                            className="form-control"
                                            value={props.values.minCapacity ?? ""}
                                            onChange={e => props.setFieldValue("minCapacity", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("Max capacity (p.e)")}</label>
                                        <input
                                            id="maxCapacity"
                                            name="maxCapacity"
                                            className="form-control"
                                            value={props.values.maxCapacity ?? ""}
                                            onChange={e => props.setFieldValue("maxCapacity", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("Receiving area type")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: 0, label: "Όλοι", },
                                                { value: 1, label: `${t("query.sensitive")}`, },
                                                { value: 2, label: `${t("query.normal")}`, },
                                            ]}
                                            placeholder={i18n.language === "en" ? "All" : "Όλοι"}
                                            id="sensitive"
                                            name="sensitive"
                                            onChange={v => props.setFieldValue("sensitive", v)}
                                            value={props.values.sensitive}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.treatment_method.waste")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            options={(wasteTreatmentOptions.filter((x, i) => i !== 0).map(x => { return { value: x.code!, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            id="wasteTreatmentMethod"
                                            name="wasteTreatmentMethod"
                                            onChange={v => props.setFieldValue("wasteTreatmentMethod", v)}
                                            value={props.values.wasteTreatmentMethod}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.treatment_method.sludge")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            options={(sludgeTreatmentOptions.filter((x, i) => i !== 0).map(x => { return { value: x.code!, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            id="sludgeTreatmentMethod"
                                            name="sludgeTreatmentMethod"
                                            onChange={v => props.setFieldValue("sludgeTreatmentMethod", v)}
                                            value={props.values.sludgeTreatmentMethod}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.wtp.reuse")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: 0, label: "Όλα", },
                                                { value: 1, label: `${t("yes")}`, },
                                                { value: 2, label: `${t("no")}`, },
                                            ]}
                                            placeholder={i18n.language === "en" ? "All" : "Όλα"}
                                            id="reuse"
                                            name="reuse"
                                            onChange={v => props.setFieldValue("reuse", v)}
                                            value={props.values.reuse}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.reuse_method")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            options={(i18n.language === "en" ? reuseOptionsEn : reuseOptions).map(x => { x.label = t(x.label); return x; })}
                                            id="reuseMethod"
                                            name="reuseMethod"
                                            onChange={v => props.setFieldValue("reuseMethod", v)}
                                            value={props.values.reuseMethod}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end align-items-end">
                                    <button type="button" disabled={isLoading} className="btn btn-secondary mx-2" onClick={() => { props.resetForm(); props.submitForm(); }}>{t("Clear")}</button>
                                    <button type="submit" disabled={isLoading} className="btn btn-primary">{t("Search")}</button>
                                </div>
                            </form>)}
                    </Formik>
                    : <Formik
                        enableReinitialize
                        initialValues={{
                            year: null,
                            administrativeRegion: null,
                            riverBasinDistrict: null,
                            riverBasin: null,
                            municipal: null,
                            priority: null,
                            minSewageNetworkPercentage: null,
                            maxSewageNetworkPercentage: null,
                            minPopulation: null,
                            maxPopulation: null,
                            sensitive: null,
                        }}
                        onSubmit={(values) => getAgglomerations(Object.assign({}, values))}
                    >
                        {props => (
                            <form noValidate onSubmit={props.handleSubmit}>
                                <div className="form-container row g-2 my-2">
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("Year")}</label>
                                        <input
                                            id="year"
                                            name="year"
                                            className="form-control"
                                            value={props.values.year ?? undefined}
                                            onChange={e => props.setFieldValue("year", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("wtp.general.general.administrative_region")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={(administrativeRegions.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            id="administrativeRegion"
                                            name="administrativeRegion"
                                            onChange={v => props.setFieldValue("administrativeRegion", v)}
                                            value={props.values.administrativeRegion}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.riverBasinDistrict && (Array.isArray(props.values.riverBasinDistrict) && (props.values.riverBasinDistrict as any[]).length > 0)) || (!!props.values.riverBasin && (Array.isArray(props.values.riverBasin) && (props.values.riverBasin as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.water_district")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={(riverBasinDistricts.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλα"}
                                            id="riverBasinDistrict"
                                            name="riverBasinDistrict"
                                            onChange={v => props.setFieldValue("riverBasinDistrict", v)}
                                            value={props.values.riverBasinDistrict}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.administrativeRegion && (Array.isArray(props.values.administrativeRegion) && (props.values.administrativeRegion as any[]).length > 0)) || (!!props.values.municipal && (Array.isArray(props.values.municipal) && (props.values.municipal as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.river_basin")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={props?.values?.riverBasinDistrict && (props.values.riverBasinDistrict as any[]).length > 0 ? riverBasins.filter(x => ((props.values.riverBasinDistrict as unknown) as any[]).map(y => y.value).includes(x.riverBasinDistrictId)).map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) : (riverBasins.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }))}
                                            placeholder={i18n.language === "en" ? "All" : "Όλες"}
                                            id="riverBasin"
                                            name="riverBasin"
                                            onChange={v => props.setFieldValue("riverBasin", v)}
                                            value={props.values.riverBasin}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.administrativeRegion && (Array.isArray(props.values.administrativeRegion) && (props.values.administrativeRegion as any[]).length > 0)) || (!!props.values.municipal && (Array.isArray(props.values.municipal) && (props.values.municipal as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("settlements.municipal")}</label>
                                        <Select
                                            isMulti
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={props?.values?.administrativeRegion && (props.values.administrativeRegion as any[]).length > 0 ? authorities.filter(x => ((props.values.administrativeRegion as unknown) as any[]).map(y => y.value).includes(x.administrativeRegionId)).map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } }) : authorities.map(x => { return { value: x.id, label: i18n.language === "en" ? x.nameEn : x.name, } })}
                                            placeholder={i18n.language === "en" ? "All" : "Όλοι"}
                                            id="municipal"
                                            name="municipal"
                                            onChange={v => props.setFieldValue("municipal", v)}
                                            value={props.values.municipal}
                                            className={'react-select'}
                                            isDisabled={(!!props.values.riverBasinDistrict && (Array.isArray(props.values.riverBasinDistrict) && (props.values.riverBasinDistrict as any[]).length > 0)) || (!!props.values.riverBasin && (Array.isArray(props.values.riverBasin) && (props.values.riverBasin as any[]).length > 0))}
                                            components={{ IndicatorSeparator: () => null }}
                                            filterOption={(opt, inp) => {
                                                let option: string = opt.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                let input: string = inp.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                                                return option.indexOf(input) !== -1;
                                            }}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("agglomerations.characterization")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: 0, label: "Όλοι", },
                                                { value: 1, label: `${t("query.sensitive")}`, },
                                                { value: 2, label: `${t("query.normal")}`, },
                                            ]}
                                            placeholder={i18n.language === "en" ? "All" : "Όλοι"}
                                            id="sensitive"
                                            name="sensitive"
                                            onChange={v => props.setFieldValue("sensitive", v)}
                                            value={props.values.sensitive}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div>
                                    {/* <div className="col-xxl-6">
                                        <label className="form-label">{t("query.sewage_network_percentage")}</label>
                                        <Select
                                            noOptionsMessage={() => t("wtp.select.no_options")}
                                            options={[
                                                { value: null, label: "Όλα", },
                                                { value: 1, label: "0 - 70%", },
                                                { value: 2, label: "71 - 85%", },
                                                { value: 3, label: "86 - 95%", },
                                                { value: 4, label: "96 - 97%", },
                                                { value: 5, label: "98 - 100%", },
                                            ]}
                                            placeholder={i18n.language === "en" ? "All" : "Όλα"}
                                            id="sewageNetworkPercentage"
                                            name="sewageNetworkPercentage"
                                            onChange={v => props.setFieldValue("sewageNetworkPercentage", v)}
                                            value={props.values.sewageNetworkPercentage}
                                            className={'react-select'}
                                            isSearchable={false}
                                            components={{ IndicatorSeparator: () => null }}
                                        />
                                    </div> */}
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.sewage_network_percentage.min")}</label>
                                        <input
                                            id="minSewageNetworkPercentage"
                                            name="minSewageNetworkPercentage"
                                            className="form-control"
                                            value={props.values.minSewageNetworkPercentage ?? ""}
                                            onChange={e => props.setFieldValue("minSewageNetworkPercentage", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.sewage_network_percentage.max")}</label>
                                        <input
                                            id="maxSewageNetworkPercentage"
                                            name="maxSewageNetworkPercentage"
                                            className="form-control"
                                            value={props.values.maxSewageNetworkPercentage ?? ""}
                                            onChange={e => props.setFieldValue("maxSewageNetworkPercentage", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.equivalent_population.min")}</label>
                                        <input
                                            id="minPopulation"
                                            name="minPopulation"
                                            className="form-control"
                                            value={props.values.minPopulation ?? ""}
                                            onChange={e => props.setFieldValue("minPopulation", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                    <div className="col-xxl-6">
                                        <label className="form-label">{t("query.equivalent_population.max")}</label>
                                        <input
                                            id="maxPopulation"
                                            name="maxPopulation"
                                            className="form-control"
                                            value={props.values.maxPopulation ?? ""}
                                            onChange={e => props.setFieldValue("maxPopulation", e.currentTarget.value.replace(/\D/g, ""))}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end align-items-end">
                                    <button type="button" disabled={isLoading} className="btn btn-secondary mx-2" onClick={() => { props.resetForm(); props.submitForm(); }}>{t("Clear")}</button>
                                    <button type="submit" className="btn btn-primary">{t("Search")}</button>
                                </div>
                            </form>)}
                    </Formik>}
            </div >
            {
                isWTPFiltering ?
                    <DataTable
                        columns={wastewaterTreatmentPlantColumns}
                        data={wastewaterTreatmentPlants}
                        defaultSortFieldId={2}
                        pagination
                        striped
                        // paginationComponent={BootyPagination}
                        customStyles={customStyles}
                        noDataComponent={<div className="text-center">
                            {t("wtp.table.no_data")}
                        </div >}
                        paginationComponentOptions={paginationComponentOptions}
                        className="mt-4"
                        actions={actionsMemoWtp}
                    />
                    :
                    <DataTable
                        columns={agglomerationColumns}
                        data={agglomerations}
                        // defaultSortFieldId={2}
                        pagination
                        striped
                        // paginationComponent={BootyPagination}
                        customStyles={customStyles}
                        noDataComponent={<div className="text-center">
                            {t("wtp.table.no_data")}
                        </div>}
                        paginationComponentOptions={paginationComponentOptions}
                        className="mt-4"
                        actions={actionsMemoAgglo}
                    />
            }
        </>
    );
}

export default QueryTools;
