import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/footer-gr-commission.png'
import { blockBuilder } from './BlockBuilder';

const Footer: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const [footer, setFooter] = useState<any>();

    useEffect(() => {
        async function loadFooter() {
            let response = await fetch(`/api/pages/${i18n.resolvedLanguage === "en" ? "footeren" : "footerel"}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                }
            });

            if (response.status === 200) {
                setFooter(await response.json());
            }
        }

        loadFooter();
    }, [i18n.resolvedLanguage]);

    const footerBuilder = (data: any) => {
        if (!data || !data.blocks) return (<>
            <p>
                © 2018 ΥΠΕN - ΕΙΔΙΚΗ ΓΡΑΜΜΑΤΕΙΑ ΥΔΑΤΩΝ, Αμαλιάδος 17, Αμπελόκηποι 115 26 Αθήνα
            </p>
            <img src={logo} alt="logo" />
        </>);
        let foot = data.blocks.map((x: any, i: number) => blockBuilder(x, i));
        return (<Container className='text-center'>{foot}</Container>);
    }

    return (
        <>
            <Container fluid>
                <hr className="featurette-divider mb-0" />
            </Container>
            <footer className="container main-footer">
                {footerBuilder(footer)}
            </footer>
        </>
    );
}

export default Footer;
