import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../state/State';
import { useLocation, useSearchParams } from 'react-router-dom';

interface ResetPasswordError {
    code: string,
    description: string,
}

const ResetPassword: React.FC = () => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const [searchParams] = useSearchParams();
    const context = useContext(AppContext);
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errors, setErrors] = useState<ResetPasswordError[] | null | undefined>([]);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Reset password" });
    }, [loc.pathname]);

    const request = async (e: React.FormEvent) => {
        if (!e) return;
        e.preventDefault();
        let formData = new FormData(e.target as HTMLFormElement);
        let data = await fetch('/api/account/resetpassword', {
            method: 'POST',
            body: formData,
        });
        if (data.status === 200) {
            setShowAlert(true);
        } else if (data.status === 400) {
            var err = await data.json();
            if (err) {
                if (err.errors) {
                    let errs: any[] = [];
                    for (const [key, value] of Object.entries(err.errors)) {
                        errs.push({ code: key, description: value });
                    }
                    setErrors(errs);
                } else {
                    setErrors(err);
                }
            }
            setShowErrorAlert(true);
        }
    }

    const reset = () => {
        setShowAlert(false);
        setShowErrorAlert(false);
        setErrors([]);
    }

    return (
        <div className="form-signin-container">
            <form className="form-signin w-100 m-auto text-center" onSubmit={(ev) => request(ev)}>
                {showAlert && <div className="alert alert-success">
                    {t("Password changed successfully! Please use your new password to login.")}
                </div>}
                <h1 className="h3 mb-3 fw-normal">{t("Reset password")}</h1>
                <div className="form-floating">
                    <input name="password" type="password" className="form-control" placeholder={t("Password")} id="password" style={{ marginBottom: "-1px", borderBottomRightRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: "0.375rem", borderTopLeftRadius: "0.375rem" }} required onFocus={reset} />
                    <label>{t("Password")}</label>
                </div>
                <div className="form-floating">
                    <input name="confirmPassword" type="password" className="form-control" placeholder={t("Confirm password")} id="confirmPassword" required onFocus={reset} />
                    <label>{t("Confirm password")}</label>
                </div>
                <input type="hidden" className="form-control" name="username" id="username" value={searchParams.get("username") ?? ""} />
                <input type="hidden" className="form-control" name="token" id="token" value={searchParams.get("token") ?? ""} />
                <button className="w-100 btn btn-lg btn-primary" type="submit">{t("Change password")}</button>
                {showErrorAlert && <div className="alert alert-danger mt-2">
                    {(errors && errors.length > 0) ? <ul>
                        {errors.map((x, i) => <li key={i}>{t(x.description)}</li>)}
                    </ul> : <p>{t("Error while resetting password.")}</p>}
                </div>}
            </form>
        </div>
    );
}

export default ResetPassword;
