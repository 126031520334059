import { fromLonLat, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerVector, RMap, ROSM, RPopup, RStyle } from "rlayers";
import 'ol/ol.css';
import { Point } from "ol/geom";
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../state/State';
import { Link, useLocation } from "react-router-dom";
import useFetch from "use-http";
import { AgglomerationQueryView } from "../data/Definitions";
import { useTranslation } from "react-i18next";
import WKT from "ol/format/WKT";
import * as extent from "ol/extent";
import { shiftKeyOnly } from "ol/events/condition";
import { Dot, ExclamationCircle, Search } from "react-bootstrap-icons";
import { Button, Col, Form, ListGroup, Modal, Offcanvas, Row } from "react-bootstrap";

const AgglomerationsModal: React.FC<{ show: boolean, onHide: () => void, agglomeration: AgglomerationQueryView | undefined }> = (props) => {
    const { t, i18n } = useTranslation();
    const [fullscreen, setFullscreen] = useState(false);

    if (!props.agglomeration) return <></>;

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ fontSize: "small" }}
            {...fullscreen && { fullscreen }}
            onExited={() => {
                setFullscreen(false);
            }}
        >
            <Modal.Header closeButton className="w-100">
                <Modal.Title id="contained-modal-title-vcenter">
                    <h5>{i18n.language === "en" ? props.agglomeration.nameEn : props.agglomeration.name} - {props.agglomeration.code}</h5>
                </Modal.Title>
                <Button size="sm" className={fullscreen ? "btn-exit-fullscreen" : "btn-fullscreen"} onClick={(e) => { (e.target as any).blur(); setFullscreen(!fullscreen); }} />
            </Modal.Header>
            <Modal.Body style={fullscreen ? { maxHeight: "calc(100vh - 64px", overflowY: "auto" } : { maxHeight: "calc(100vh - 300px", overflowY: "auto" }}>
                <>
                    <h6 className="text-start mt-4">{t("wtp.general.general.header")}</h6>
                    <hr />
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.priority")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{i18n.language === "en" ? props.agglomeration.priorityEn : props.agglomeration.priority}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.year")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.year || "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("wtp.reuse.supplies.area")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.area ? `${props.agglomeration.area?.toLocaleString(i18n.language)} km²` : "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.wtp.population")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.population ? props.agglomeration.population?.toLocaleString(i18n.language) : "-"}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.agglomeration.served_population")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{`${props.agglomeration.servedPopulation ? props.agglomeration.servedPopulation.toLocaleString(i18n.language) : "-"} ${props.agglomeration.servedPopulationPercentage ? `${props.agglomeration.servedPopulationPercentage.toLocaleString(i18n.language, { maximumFractionDigits: 2 })}%` : ""}`}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.agglomeration.connected_population")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.connectedPopulation ? props.agglomeration.connectedPopulation.toLocaleString(i18n.language) : "-"} {props.agglomeration.connectedPopulationPercentage ? `(${props.agglomeration.connectedPopulationPercentage.toLocaleString(i18n.language, { maximumFractionDigits: 2 })}%)` : ""}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.agglomeration.constructed_network")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.connectedSewageNetworkArea ? `${props.agglomeration.connectedSewageNetworkArea.toLocaleString(i18n.language)} km²` : "-"}{props.agglomeration.connectedSewageNetworkLength ? ` / ${props.agglomeration.connectedSewageNetworkLength} km` : ""}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            {t("query.agglomeration.connected_network")}:
                        </Col>
                        <Col className="text-start">
                            <strong>{props.agglomeration.constructedSewageNetworkArea ? `${props.agglomeration.constructedSewageNetworkArea.toLocaleString(i18n.language)} km²` : "-"}{props.agglomeration.constructedSewageNetworkLength ? ` / ${props.agglomeration.constructedSewageNetworkLength} km` : ""}</strong>
                        </Col>
                    </Row>
                </>
            </Modal.Body>
        </Modal>
    );
}

const Agglomerations: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    const { get, put, response, loading, error, cache } = useFetch('/api');
    const popup = useRef<any>([]);
    const vectorLayer = useRef<RLayerVector>(null);
    const [agglomerations, setAgglomerations] = useState<AgglomerationQueryView[]>([]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedAgglomeration, setSelectedAgglomeration] = useState<AgglomerationQueryView>();
    const [searchModalShow, setSearchModalShow] = useState(false);
    const [filter, setFilter] = useState("");
    const mapRef = createRef() as React.RefObject<RMap>;
    const filterInputRef = createRef() as React.RefObject<HTMLInputElement>;

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "agglomerations" });
    }, [loc.pathname]);

    useEffect(() => {
        let code = loc.search.substring(1);
        if (!code) return;
        let idx = agglomerations.findIndex(x => x.code === code);
        if (idx < 0) return;
        handleAgglomerationClick(idx);
    }, [loc.search, agglomerations])

    useEffect(() => {
        getAgglomerations();
    }, [])

    const getAgglomerations = async () => {
        let aggs = await get('/query/agglomerations');
        if (response.ok) {
            aggs.forEach((agg: AgglomerationQueryView) => {
                agg.nameNormalized = agg.name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                agg.nameEnNormalized = agg.nameEn?.toLowerCase().normalize('NFD');
            });
            setAgglomerations(aggs);
        }
    }

    const getGeometryFromLocation = (location: string) => {
        let wkt = new WKT();
        let geom = wkt.readGeometry(location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        let ext = geom.getExtent();
        let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
        let lonLatCenter = extent.getCenter(lonLatExtent);
        if (lonLatCenter) {
            return new Point(fromLonLat([lonLatCenter[0], lonLatCenter[1]]))
        }
    }

    const handleAgglomerationClick = (index: number) => {
        popup.current.forEach((el: any) => {
            el?.hide();
        });
        setSearchModalShow(false);
        let agglomeration = agglomerations[index];
        if (!agglomeration) return;
        if (!agglomeration.location) return;
        let wkt = new WKT();
        let geom = wkt.readGeometry(agglomeration.location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        let ext = geom.getExtent();
        let lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
        let lonLatCenter = extent.getCenter(lonLatExtent);
        if (!lonLatCenter) return;
        mapRef.current?.ol.getView()
            .animate(
                {
                    zoom: 6,
                },
                {
                    center: fromLonLat([lonLatCenter[0], lonLatCenter[1]]),
                    zoom: 14,
                },
                () => {
                    popup.current[index].show();
                }
            );
    }

    const debounce = (func: Function, timeout: number = 300) => {
        let timer: NodeJS.Timeout;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const processChange = debounce((v: string) => setFilter(v.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")));

    return (
        <>
            <RMap
                className='full-screen-map with-space'
                initial={{ center: fromLonLat([23.70066804453946, 37.983956404442104]), zoom: 6 }}
                ref={mapRef}
                noDefaultControls
                noDefaultInteractions
            >
                <RControl.RZoom />
                <RControl.RZoomSlider />
                <RInteraction.RPinchZoom />
                <RInteraction.RDragPan />
                <RInteraction.RMouseWheelZoom condition={shiftKeyOnly} />
                <RInteraction.RDoubleClickZoom />
                <ROSM />
                <RLayerVector zIndex={11} ref={vectorLayer}>
                    {agglomerations.filter(x => x.location).map((x, i) => <RFeature
                        key={`Feature_${x.code}`}
                        geometry={getGeometryFromLocation(x.location!)}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            popup.current.forEach((element: any) => {
                                element?.hide();
                            });
                            popup.current[i]?.show();
                        }}
                    >
                        <RStyle.RStyle>
                            <RStyle.RCircle radius={6}>
                                <RStyle.RFill color={(x as any).compliance ? "rgba(25, 135, 84, 0.5)" : "rgba(253, 126, 20, 0.5)"}></RStyle.RFill>
                                <RStyle.RStroke width={2} color={(x as any).compliance ? "rgba(25, 135, 84, 1)" : "rgba(253, 126, 20, 1)"}></RStyle.RStroke>
                            </RStyle.RCircle>
                        </RStyle.RStyle>
                        <RPopup ref={el => popup.current[i] = el} trigger={'click'} className='example-overlay' autoPan>
                            <div className='card' style={{ borderColor: (x as any).compliance ? "rgba(25, 135, 84, 0.5)" : "rgba(253, 126, 20, 0.5)" }}>
                                <p className='card-header' style={{ backgroundColor: (x as any).compliance ? "rgba(25, 135, 84, 0.25)" : "rgba(253, 126, 20, 0.25)" }}>
                                    <strong>{i18n.language === "en" ? x.nameEn : x.name} - {x.code}</strong>
                                    <button type="button" className="btn btn-outline-secondary btn-sm py-0 float-end" onClick={() => popup.current[i]?.hide()}>X</button>
                                </p>
                                <p className='card-body text-center'>
                                    {t("query.wtp.administrative_region")}: <strong>{i18n.language === "en" ? x.administrativeRegionEn : x.administrativeRegion}</strong><br />
                                    {t("query.wtp.municipal")}: <strong>{i18n.language === "en" ? x.municipalEn : x.municipal}</strong><br />
                                    {t("query.wtp.water_district")}: <strong>{i18n.language === "en" ? x.riverBasinDistrictEn : x.riverBasinDistrict}</strong><br />
                                    {t("query.wtp.river_basin")}: <strong>{i18n.language === "en" ? x.riverBasinEn : x.riverBasin}</strong><br />
                                    {t("query.agglomeration.coordinates")}: <strong>{x.location?.replace("POINT (", "").slice(0, -1).split(' ').map((t: string) => parseFloat(t).toFixed(5)).join(',')}</strong><br />
                                    {t("query.wtp.population")}: <strong>{x.population?.toLocaleString(i18n.language) ?? "-"}</strong><br />
                                    {t("settlements.sewage_network_type")}: <strong>{x.wtpCode ? i18n.language === "en" ? x.sewageNetworkTypeEn : x.sewageNetworkType : "-"}</strong><br />
                                    {/* {t("agglomerations.characterization")}: <strong>{x.isSensitive ? t("query.sensitive") : t("query.normal")}</strong><br /> */}
                                    {x.wtpCode && <>{t("query.wtp.code")}: <Link to={"/wtp?" + x.wtpCode}><strong>{x.wtpCode}</strong></Link><br /></>}
                                    {x.wtpCode && <>{t("query.wtp.name")}: <strong>{i18n.language === "en" ? x.wtpNameEn : x.wtpName}</strong><br /></>}
                                    {/* <Button size="sm" variant="link" onClick={() => { setSelectedAgglomeration(x); setModalShow(true); }}>{t("More")}</Button> */}
                                </p>
                            </div>
                        </RPopup>
                    </RFeature>)}
                </RLayerVector>
            </RMap>
            <div style={{ marginTop: "-40px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "right", right: "3em", padding: "5px 10px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                <p className="mb-0" style={{ fontSize: "small" }}><ExclamationCircle style={{ marginRight: "5px", marginTop: "-4px" }} /><strong>Hold shift + mouse wheel scroll to zoom</strong></p>
            </div>
            {/* <div style={{ marginTop: "-120px", position: "relative", width: "fit-content", zIndex: 12, float: "right", right: "3em", padding: "10px 20px", backgroundColor: "rgba(255, 255, 255, 0.75)", borderRadius: "5px" }}>
                <p className="mb-2" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(25, 135, 84, 0.5)", border: "2px solid rgba(25, 135, 84, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.compliant")}</strong></p>
                <p className="mb-0" style={{ fontSize: "small" }}><div style={{ marginRight: "15px", marginTop: "-4px", borderRadius: "50%", backgroundColor: "rgba(253, 126, 20, 0.5)", border: "2px solid rgba(253, 126, 20, 1)", height: 14, width: 14, display: "inline-block" }} /><strong>{t("project_data.non_compliant")}</strong></p>
            </div> */}
            <div style={{ marginTop: "-46px", position: "relative", width: "fit-content", zIndex: 12, textAlign: "center", float: "left", left: "8px" }}>
                <Button variant="secondary" onClick={() => setSearchModalShow(true)}>
                    <Search />
                </Button>
            </div>
            <AgglomerationsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                agglomeration={selectedAgglomeration}
            />
            <Offcanvas
                show={searchModalShow}
                onHide={() => setSearchModalShow(false)}
                onExited={() => setFilter("")}
                onShow={() => filterInputRef.current?.focus()}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("query.agglomeration.search")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form.Floating>
                        <Form.Control
                            id="filter"
                            type="text"
                            onChange={e => processChange(e.target.value)}
                            placeholder=""
                            ref={filterInputRef}
                        />
                        <label htmlFor="filter">{t("query.agglomeration.name")}</label>
                    </Form.Floating>
                    <ListGroup variant="flush" className="mt-2" style={{ overflowY: "auto", maxHeight: "calc(100vh - 172px)" }}>
                        {agglomerations.map((x, i) => <ListGroup.Item key={i} hidden={!(x.nameNormalized?.includes(filter) || x.nameEnNormalized?.includes(filter))} action onClick={() => handleAgglomerationClick(i)}><Form.Text as="strong">{i18n.language === "en" ? x.nameEn : x.name}</Form.Text><br /><Form.Text>{i18n.language === "en" ? x.name : x.nameEn}</Form.Text></ListGroup.Item>)}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Agglomerations;
