import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Pagination, Row } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Definitions, fixMediaUrl, Language } from '../data/Definitions';
import { NewsPost } from '../models/PiranhaModels';
import { useQuery } from '../models/Utilities';
import { AppContext } from '../state/State';

const Announcements: React.FC = (props) => {
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const context = useContext(AppContext);
    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Announcements" });
    }, [loc.pathname]);

    let [newsList, setNewsList] = useState<{ total: number, pageSize: number, page: Array<NewsPost>, currentPage: number, totalPages: number } | null>(null);
    let query = useQuery();

    const getCurrentPage = (): number => {
        let qp = query.get("page");
        let currentPage = 1;
        if (qp !== null) {
            currentPage = parseInt(qp);
            if (isNaN(currentPage)) {
                currentPage = 1;
            }
        }

        return currentPage;
    }

    const loadContent = async () => {
        const newsPageId = Definitions.newsPageSlug[i18n.resolvedLanguage as Language];

        const response = await fetch(`/api/news/${newsPageId}?page=${getCurrentPage()}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        });

        if (response.status === 200) {
            const news = await response.json();
            setNewsList({ total: news.total, pageSize: news.pageSize, page: news.page.$values, currentPage: getCurrentPage(), totalPages: Math.ceil(news.total / news.pageSize) });
        }
    }

    useEffect(() => {
        loadContent();
    }, [i18n.resolvedLanguage, query]);

    return (
        <Container className='menu-padding'>
            <Row>
                <Col>
                    {(!newsList || newsList.page.length === 0) && <p>{t("No recent announcements")}</p>}
                    {newsList && newsList.page.length > 0 && newsList.page.map(x => {
                        return (<Card key={x.id} className="card mb-3">
                            <Row className='g-0'>
                                <Col md={{ span: 3 }}>
                                    {x.primaryImage && <img className="img-fluid rounded-start" src={fixMediaUrl(x.primaryImage?.media.publicUrl)} />}
                                </Col>
                                <Col md={{ span: 9 }}>
                                    <Card.Body>
                                        <Card.Title>
                                            {x.title}
                                            {x.published && x.published != "" && <div className='float-end'>
                                                <small className='text-muted'>
                                                    {(new Date(Date.parse(x.published))).toDateString()}
                                                </small>
                                            </div>}
                                        </Card.Title>
                                        <Card.Text>
                                            {x.excerpt}
                                        </Card.Text>
                                        <Card.Text style={{ height: "40px" }}>
                                            <Link className="btn btn-button btn-outline-secondary float-end" to={x.slug}>{t("More")}</Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>);
                    })}
                </Col>
                {newsList && newsList.total > newsList.pageSize &&
                    <Pagination className="justify-content-center">
                        <Link className='page-link' to="/announcements">
                            <span aria-hidden="true">«</span>
                            <span className="visually-hidden">{t("First")}</span>
                        </Link>
                        <Link className='page-link' to={`/announcements${newsList.currentPage > 1 ? `?page=${newsList.currentPage - 1}` : ""}`}>
                            <span aria-hidden="true">&lt;</span>
                            <span className="visually-hidden">{t("Previous")}</span>
                        </Link>

                        {Array(newsList.totalPages).fill(0).map((_, x) => {
                            return <Link className={`page-link ${(x + 1) == newsList?.currentPage ? "active" : ""}`} key={x} to={`/announcements?page=${(x + 1)}`}>{(x + 1)}</Link>;
                        })}

                        <Link className='page-link' to={`/announcements?page=${newsList.currentPage < newsList.totalPages ? newsList.currentPage + 1 : newsList.totalPages}`}>
                            <span aria-hidden="true">&gt;</span>
                            <span className="visually-hidden">{t("Next")}</span>
                        </Link>
                        <Link className='page-link' to={`/announcements?page=${newsList.totalPages > 1 ? newsList.totalPages : ""}`}>
                            <span aria-hidden="true">»</span>
                            <span className="visually-hidden">{t("Last")}</span>
                        </Link>
                    </Pagination>
                }
            </Row>
        </Container >
    );
}

export default Announcements;
