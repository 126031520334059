import { useContext, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useFetch from 'use-http';
import { Settlement } from '../../../data/Definitions';
import { PlusCircle, PencilFill, Trash, Pencil, Search } from 'react-bootstrap-icons';
import { ConfirmationPopover } from '../../../components/ConfirmationPopover';
import DataTable from 'react-data-table-component';

const SettlementsIndex: React.FC = (props) => {
    const [settlements, setSettlements] = useState<Settlement[]>();
    const [filteredSettlements, setFilteredSettlements] = useState<Settlement[]>([]);
    const [loadingError, setLoadingError] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [search, setSearch] = useState('');

    const { get, put, del, response, loading, error, cache } = useFetch('/api/settlements');
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleSearchText = (event: any) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        if (search) {
            let s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var filtered = [...(settlements ?? [])].filter(x => `${x.id} ${x.name} ${x.nameEn} ${x.municipal} ${x.administrativeRegion}`.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(s));
            setFilteredSettlements(filtered);
            setIsFiltering(true);
        } else {
            setIsFiltering(false);
        }
    };

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Settlements" });
    }, [loc.pathname]);

    useEffect(() => {
        getSettlements();
    }, [])

    const getSettlements = async () => {
        setLoadingError(false);
        let ss = await get();
        if (response.ok) setSettlements(ss);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const removeSettlement = async (id: string) => {
        if (!id) return;
        await del(`/${id}`);
        if (response.ok) {
            cache.delete(`url:/api/settlements||method:GET||body:`);
            cache.delete(`url:/api/settlements?noshapes||method:GET||body:`);
            cache.delete(`url:/api/settlements/${id}||method:GET||body:`);
            await getSettlements();
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: t("rows_per_page"),
        rangeSeparatorText: t("rows_per_page_of"),
        selectAllRowsItem: true,
        selectAllRowsItemText: i18n.language === "en" ? "All" : "Όλες"
    };

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '16px',
            },
        },
    };

    const columns = [
        {
            cell: (row: Settlement) => (
                <ButtonGroup>
                    <ConfirmationPopover
                        id={`settlement-confirmation_popover-${row.id}`}
                        header={t("wtp.reuse.confirmation.header")}
                        body={t("wtp.reuse.confirmation.body")}
                        okLabel={t("wtp.reuse.confirmation.ok")}
                        onOk={() => { removeSettlement(row.id) }}
                        cancelLabel={t("wtp.reuse.confirmation.cancel")}
                        onCancel={() => { }}
                    />
                    <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ border: "none" }}
                        className="d-flex align-items-center justify-content-center m-0"
                        onClick={() => navigate(`/settlements/edit/${row.id}`)}
                    >
                        <Pencil />
                    </Button>
                </ButtonGroup>
            ),
            width: "80px"
        },
        {
            name: t("settlements.settlement_code"),
            selector: (row: Settlement) => row.id,
            sortable: true
        },
        {
            name: t("settlements.settlement_name"),
            selector: (row: Settlement) => (i18n.language === "en" ? row.nameEn : row.name) ?? "",
            sortable: true
        },
        {
            name: t("settlements.municipal"),
            selector: (row: Settlement) => (i18n.language === "en" ? row.municipalEn : row.municipal) ?? "",
            sortable: true
        },
        {
            name: t("settlements.administrative_region"),
            selector: (row: Settlement) => (i18n.language === "en" ? row.administrativeRegionEn : row.administrativeRegion) ?? "",
            sortable: true
        },
        {
            name: t("settlements.agglomeration"),
            selector: (row: Settlement) => row.agglomerationId ? `${i18n.language === "en" ? row.agglomerationNameEn : row.agglomerationName} (${row.agglomerationId})` : "-",
            sortable: true
        }
    ];

    return (
        <Container fluid className='menu-padding'>
            {!loading && !error && settlements && <>
                <Row>
                    <Col>
                        <h4>{t("Settlements")}</h4>
                    </Col>
                    <Col>
                        <ButtonGroup className='float-end btn-group-sm mt-2' aria-label="Toolbar">
                            <Button variant="outline-primary" className="d-flex align-items-center justify-content-center m-0" onClick={() => navigate("/settlements/add")}><PlusCircle className="me-2"></PlusCircle> {t("wtp.button.add_upper")}</Button>
                        </ButtonGroup>
                        <InputGroup
                            className="mt-2"
                            size="sm"
                            style={{ width: "350px" }}
                        >
                            <Form.Control
                                id="search"
                                name="search"
                                placeholder={t("search_for")}
                                onChange={handleSearchText}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={search || ""}
                            />
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch}>
                                <Search />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={isFiltering ? filteredSettlements : settlements}
                            defaultSortFieldId={2}
                            pagination
                            // paginationComponent={BootyPagination}
                            customStyles={customStyles}
                            noDataComponent={<div className="text-center">
                                {t("wtp.table.no_data")}
                            </div>}
                            paginationComponentOptions={paginationComponentOptions}
                        />
                        {/* <Table striped size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("settlements.settlement_code")}</th>
                                    <th>{t("settlements.settlement_name")}</th>
                                    <th>{t("settlements.municipal")}</th>
                                    <th>{t("settlements.administrative_region")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {settlements.length === 0 ?
                                    <tr>
                                        <td colSpan={5} className="text-center">
                                            {t("wtp.table.no_data")}
                                        </td>
                                    </tr> :
                                    settlements.map((x: Settlement, i: number) => <tr key={i}>
                                        <td className="text-center">{<ButtonGroup>
                                            <ConfirmationPopover
                                                id={`settlement-confirmation_popover-${i}`}
                                                header={t("wtp.reuse.confirmation.header")}
                                                body={t("wtp.reuse.confirmation.body")}
                                                okLabel={t("wtp.reuse.confirmation.ok")}
                                                onOk={() => { removeSettlement(x.id) }}
                                                cancelLabel={t("wtp.reuse.confirmation.cancel")}
                                                onCancel={() => { }}
                                            />
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                style={{ border: "none" }}
                                                className="d-flex align-items-center justify-content-center m-0"
                                                onClick={() => navigate(`/settlements/edit/${x.id}`)}
                                            >
                                                <Pencil />
                                            </Button>
                                        </ButtonGroup>}</td>
                                        <td>{x.id}</td>
                                        <td>{i18n.language === "en" ? x.nameEn : x.name}</td>
                                        <td>{x.municipal}</td>
                                        <td>{x.administrativeRegion}</td>
                                    </tr>)}

                            </tbody>
                        </Table> */}
                    </Col>
                </Row>
            </>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default SettlementsIndex;
