import { useContext, useEffect, useState, createRef, useRef } from 'react';
import { Alert, Badge, Button, ButtonGroup, ButtonToolbar, Col, Container, Form, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../state/State';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

import { fromLonLat, getPointResolution, transformExtent } from "ol/proj";
import { RControl, RFeature, RInteraction, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import 'ol/ol.css';
import { Geometry, Polygon, Circle } from "ol/geom";
import WKT from 'ol/format/WKT';
import VectorSource from "ol/source/Vector";
import BaseEvent from "ol/events/Event";
import * as extent from "ol/extent";
import BingMap from "../../../components/BingMap";

import { ArrowCounterclockwise, Layers, PencilFill, Save, SkipBackward, XCircle, SquareFill, Trash, TrashFill, Trash2Fill, Trash3Fill } from 'react-bootstrap-icons';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import useFetch from 'use-http';
import { RiverBasin, Settlement, SewageNetworkType } from '../../../data/Definitions';
import Feature from 'ol/Feature';
import { Style, Stroke, Fill } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';

let unique_id = 0;

const SettlementsEdit: React.FC = (props) => {
    const [settlementData, setSettlementData] = useState<Settlement & { waterDistrict: string, riverBasin: string, waterDistrictEn: string, riverBasinEn: string }>();
    const [saving, setSaving] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [savingError, setSavingError] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [sewageNetworkTypes, setSewageNetworkTypes] = useState<SewageNetworkType[]>([]);
    const [inputValue, setInputValue] = useState('');

    const [shapeDrawing, setShapeDrawing] = useState(false);
    const [constructedDrawing, setConstructedDrawing] = useState(false);
    const [connectedDrawing, setConnectedDrawing] = useState(false);
    const mapRef = createRef() as React.RefObject<RMap>;

    const { get, put, response, loading, error, cache } = useFetch('/api');
    const loc = useLocation();
    const context = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const formikRef = useRef<FormikProps<Settlement & { waterDistrict: string, riverBasin: string }>>(null);

    useEffect(() => {
        context.dispatch({ type: "SetMainTitleAction", title: "Edit Settlement" });
    }, [loc.pathname]);

    useEffect(() => {
        getSettlementData(id);
    }, [])

    // useEffect(() => {
    //     if (!settlementData) return;

    //     let wkt = new WKT();
    //     let features: Feature[] = [];

    //     if (settlementData.shape) {
    //         let shapeGeom = wkt.readGeometry(settlementData.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
    //         let shapeFeature = new Feature(shapeGeom);
    //         let shapeStyle = new Style({
    //             stroke: new Stroke({
    //                 color: "rgba(0, 200, 0, 1)",
    //                 width: 2,
    //             }),
    //             fill: new Fill({
    //                 color: "rgba(0, 200, 0, 0.5)",
    //             }),
    //         });
    //         shapeFeature.setStyle(shapeStyle);
    //         features.push(shapeFeature);
    //     }

    //     if (settlementData.constructedSewageNetworkShape) {
    //         let constructedSewageNetworkShapeGeom = wkt.readGeometry(settlementData.constructedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
    //         let constructedSewageNetworkShapeFeature = new Feature(constructedSewageNetworkShapeGeom);
    //         let constructedSewageNetworkShapeStyle = new Style({
    //             stroke: new Stroke({
    //                 color: "rgba(200, 50, 50, 1)",
    //                 width: 2,
    //             }),
    //             fill: new Fill({
    //                 color: "rgba(255, 60, 0, 0.5)",
    //             }),
    //         });
    //         constructedSewageNetworkShapeFeature.setStyle(constructedSewageNetworkShapeStyle);
    //         features.push(constructedSewageNetworkShapeFeature);
    //     }

    //     if (settlementData.connectedSewageNetworkShape) {
    //         let connectedSewageNetworkShapeGeom = wkt.readGeometry(settlementData.connectedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
    //         let connectedSewageNetworkShapeFeature = new Feature(connectedSewageNetworkShapeGeom);
    //         let connectedSewageNetworkShapeStyle = new Style({
    //             stroke: new Stroke({
    //                 color: "rgba(200, 50, 50, 1)",
    //                 width: 2,
    //             }),
    //             fill: new Fill({
    //                 color: "rgba(0, 138, 255, 0.5)",
    //             }),
    //         });
    //         connectedSewageNetworkShapeFeature.setStyle(connectedSewageNetworkShapeStyle);
    //         features.push(connectedSewageNetworkShapeFeature);
    //     }

    //     mapRef.current?.ol.getLayers().forEach(function (layer) {
    //         if (layer instanceof VectorLayer) {
    //             (layer.getSource() as VectorSource)?.clear();
    //             (layer.getSource() as VectorSource)?.addFeatures([...features]);
    //         }
    //     });
    // }, [settlementData])

    const getSettlementData = async (code: string | undefined) => {
        if (!code) return;
        setLoadingError(false);
        let snts = await get('/wastewatertreatmentplant/sewagenetworktypes');
        if (response.ok) setSewageNetworkTypes(snts);
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }

        let sd = await get(`/settlements/${code}`);
        if (response.ok) {
            let geoData;
            if (sd.shape || sd.location) {
                let wkt = new WKT();
                let geom = wkt.readGeometry(sd.shape || sd.location, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                let ext = geom.getExtent();
                var lonLatExtent = transformExtent(ext, 'EPSG:3857', 'EPSG:4326');
                var center = extent.getCenter(lonLatExtent);
                if (center) {
                    sd.lon = center[0];
                    sd.lat = center[1];
                    geoData = await autoDetectRiverBasin(center[0], center[1]);
                }
            } else {
                geoData = await autoDetectRiverBasinByCode(sd.id);
            }
            sd.waterDistrict = geoData?.waterDistrict;
            sd.riverBasin = geoData?.riverBasin;
            sd.waterDistrictEn = geoData?.waterDistrictEn;
            sd.riverBasinEn = geoData?.riverBasinEn;
            setSettlementData(sd);
        }
        if (response.redirected) navigate("/login");
        if (error || response.status >= 300) {
            setLoadingError(true);
            return;
        }
    }

    const reload = async () => {
        cache.delete(`url:/api/settlements/${id}||method:GET||body:`);
        await getSettlementData(id);
    }

    const saveData = async (values: Settlement) => {
        setSaving(true);
        values.sewageNetworkTypeId = values.sewageNetworkTypeId?.toString() === "0" ? null : values.sewageNetworkTypeId;
        let data = new FormData();
        // console.log(values)
        Object.entries(values).forEach(entry => {
            let [key, value] = entry;
            if (value === null) return;
            data.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
        });

        var res = await fetch('/api/settlements', {
            method: 'PUT',
            body: data,
        });
        if (res.status === 200) {
            setSavingError(false);
            setShowToast(true);
            cache.delete(`url:/api/settlements||method:GET||body:`);
            cache.delete(`url:/api/settlements?noshapes||method:GET||body:`);
            cache.delete(`url:/api/settlements/${id}||method:GET||body:`);
            // reload();
        } else {
            if (res.redirected) {
                navigate("/login");
            } else {
                setSavingError(true);
                setShowToast(true);
            }
        }
        setSaving(false);
    }

    const autoDetectRiverBasin = async (lng: number, lat: number) => {
        if (!lng) return;
        if (!lat) return;
        let dt = {
            waterDistrict: "",
            riverBasin: "",
            waterDistrictEn: "",
            riverBasinEn: "",
        }

        const res = await fetch(`/api/spatial/riverbasin?lng=${lng}&lat=${lat}`, { cache: "no-store" });
        if (res.status === 200) {
            const rb: RiverBasin = await res.json();
            formikRef.current?.setFieldValue("waterDistrict", rb.riverBasinDistrict?.name ?? "");
            formikRef.current?.setFieldValue("riverBasin", rb.name ?? "");
            formikRef.current?.setFieldValue("waterDistrictEn", rb.riverBasinDistrict?.nameEn ?? "");
            formikRef.current?.setFieldValue("riverBasinEn", rb.nameEn ?? "");
            dt.riverBasin = rb.name ?? "";
            dt.waterDistrict = rb.riverBasinDistrict?.name ?? "";
            dt.riverBasinEn = rb.nameEn ?? "";
            dt.waterDistrictEn = rb.riverBasinDistrict?.nameEn ?? "";
        } else {
            formikRef.current?.setFieldValue("waterDistrict", "");
            formikRef.current?.setFieldValue("riverBasin", "");
            formikRef.current?.setFieldValue("waterDistrictEn", "");
            formikRef.current?.setFieldValue("riverBasinEn", "");
        }

        return dt;
    }

    const autoDetectRiverBasinByCode = async (code: string) => {
        if (!code) return;
        let dt = {
            waterDistrict: "",
            riverBasin: "",
            waterDistrictEn: "",
            riverBasinEn: "",
        }

        const res = await fetch(`/api/spatial/riverbasin/${code}`, { cache: "no-store" });
        if (res.status === 200) {
            const rb: RiverBasin = await res.json();
            formikRef.current?.setFieldValue("waterDistrict", rb.riverBasinDistrict?.name ?? "");
            formikRef.current?.setFieldValue("riverBasin", rb.name ?? "");
            formikRef.current?.setFieldValue("waterDistrictEn", rb.riverBasinDistrict?.nameEn ?? "");
            formikRef.current?.setFieldValue("riverBasinEn", rb.nameEn ?? "");
            dt.riverBasin = rb.name ?? "";
            dt.waterDistrict = rb.riverBasinDistrict?.name ?? "";
            dt.riverBasinEn = rb.nameEn ?? "";
            dt.waterDistrictEn = rb.riverBasinDistrict?.nameEn ?? "";
        } else {
            formikRef.current?.setFieldValue("waterDistrict", "");
            formikRef.current?.setFieldValue("riverBasin", "");
            formikRef.current?.setFieldValue("waterDistrictEn", "");
            formikRef.current?.setFieldValue("riverBasinEn", "");
        }

        return dt;
    }

    const validationSchemaReporter = {
        id: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        name: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        nameEn: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        // shape: yup.string().required(t("wtp.validation.required")).typeError(t("wtp.validation.type_error")),
        area: yup.number().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        population: yup.number().required(t("wtp.validation.required")).positive(t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        equivalentPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        connectedPopulation: yup.number().min(0, t("wtp.validation.non_positive")).integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
        servedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
        connectedPopulationPercentage: yup.number().nullable().min(0, t("wtp.validation.non_positive")).typeError(t("wtp.validation.type_error")),
    };

    const validationSchemaOperator = {
        constructedSewageNetworkShape: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        constructedSewageNetworkArea: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        constructedSewageNetworkLength: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkShape: yup.string().nullable().typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkArea: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        connectedSewageNetworkLength: yup.number().nullable().typeError(t("wtp.validation.type_error")),
        sewageNetworkTypeId: yup.number().integer(t("wtp.validation.non_integer")).required(t("wtp.validation.required")).positive(t("wtp.validation.no_select")).typeError(t("wtp.validation.type_error")),
        pumpingStationCount: yup.number().nullable().integer(t("wtp.validation.non_integer")).typeError(t("wtp.validation.type_error")),
    };

    const validationSchema = yup.object().shape(Object.assign({}, context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 ? validationSchemaReporter : {}, context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "operator") !== -1 ? validationSchemaOperator : {}));

    return (
        <Container fluid className='menu-padding'>
            <ToastContainer className="p-3" position={'top-end'} containerPosition="fixed">
                <Toast
                    className="d-inline-block m-1"
                    bg={savingError ? 'danger' : 'success'}
                    key={0}
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                >
                    <Toast.Body className={'text-white'}>
                        {savingError ? t("wtp.save.error") : t("wtp.save.success")}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {!loading && !error && settlementData && <Formik
                enableReinitialize
                initialValues={settlementData}
                validationSchema={validationSchema}
                onSubmit={(values) => saveData(values)}
                innerRef={formikRef}
                onReset={reload}
            >
                {props => (
                    <>
                        <form noValidate onSubmit={props.handleSubmit} onReset={props.handleReset}>
                            <Row>
                                <Col className='position-relative'>
                                    <div style={{ minHeight: "calc(100vh - 382px)" }}>
                                        <Row className="mb-2">
                                            <Col md={{ span: 7 }}>
                                                <h4>{t("settlements.header")}</h4>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>
                                                        {t("settlements.settlement_code")}
                                                    </Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.settlement_code")}
                                                            value={(props.values as any).id ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_name")}</Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.settlement_name")}
                                                            value={(i18n.language === "en" ? (props.values as any).nameEn : (props.values as any).name) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.municipal")}</Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.municipal")}
                                                            value={(i18n.language === "en" ? (props.values as any).municipalEn : (props.values as any).municipal) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.administrative_region")}</Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.administrative_region")}
                                                            value={(i18n.language === "en" ? (props.values as any).administrativeRegionEn : (props.values as any).administrativeRegion) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.river_basin")}</Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.river_basin")}
                                                            value={(i18n.language === "en" ? (props.values as any).riverBasinEn : (props.values as any).riverBasin) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.water_district")}</Form.Label>
                                                    <Col md={{ span: 7 }}>
                                                        <Form.Control
                                                            placeholder={t("settlements.water_district")}
                                                            value={(i18n.language === "en" ? (props.values as any).waterDistrictEn : (props.values as any).waterDistrict) ?? ""}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.settlement_area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="area"
                                                            name="area"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).area || ""}
                                                            isInvalid={(props.touched as any).area && !!(props.errors as any).area}
                                                            disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                                                        />
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.area_from_shape_ha")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control

                                                            value={(() => {
                                                                if ((props.values as any).shape) {
                                                                    let wkt = new WKT();
                                                                    let shapeGeom = wkt.readGeometry((props.values as any).shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                                                    let factor = getPointResolution('EPSG:3857', 1, (shapeGeom as Polygon).getFirstCoordinate());
                                                                    let hectares = ((shapeGeom as Polygon).getArea() * Math.pow(factor, 2)) / 10000;
                                                                    return hectares.toFixed(2);
                                                                } else {
                                                                    return "";
                                                                }
                                                            })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Form.Control.Feedback type="invalid">
                                                        {(props.errors as any).area}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="population"
                                                            name="population"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).population || ""}
                                                            isInvalid={(props.touched as any).population && !!(props.errors as any).population}
                                                            disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).population}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.equivalent_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="equivalentPopulation"
                                                            name="equivalentPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).equivalentPopulation || ""}
                                                            isInvalid={(props.touched as any).equivalentPopulation && !!(props.errors as any).equivalentPopulation}
                                                            disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).equivalentPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.population_density")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            value={(() => { let res = (parseFloat((props.values as any).equivalentPopulation) / (parseFloat((props.values as any).area) * 10)); return (res && !isNaN(res) && isFinite(res)) ? res.toFixed(2) : "" })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.served_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="servedPopulation"
                                                            name="servedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulation || ""}
                                                            isInvalid={(props.touched as any).servedPopulation && !!(props.errors as any).servedPopulation}
                                                            disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).servedPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_service_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="servedPopulationPercentage"
                                                            name="servedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).servedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).servedPopulationPercentage && !!(props.errors as any).servedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).servedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).servedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.connected_population")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedPopulation"
                                                            name="connectedPopulation"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulation || ""}
                                                            isInvalid={(props.touched as any).connectedPopulation && !!(props.errors as any).connectedPopulation}
                                                            disabled={context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") === -1}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedPopulation}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.sewage_network_connected_percentage")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedPopulationPercentage"
                                                            name="connectedPopulationPercentage"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedPopulationPercentage || ""}
                                                            isInvalid={(props.touched as any).connectedPopulationPercentage && !!(props.errors as any).connectedPopulationPercentage}
                                                        // value={(() => { let res = parseFloat((props.values as any).connectedPopulation) / parseFloat((props.values as any).equivalentPopulation) * 100; return (res && !isNaN(res) && isFinite(res)) ? `${res.toFixed(2)}%` : "" })()}
                                                        // disabled
                                                        />
                                                        <Form.Control.Feedback style={{ whiteSpace: "nowrap" }} type="invalid">
                                                            {(props.errors as any).connectedPopulationPercentage}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <h4>{t("settlements.constructed_sewage_network")}</h4>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkArea"
                                                            name="constructedSewageNetworkArea"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).constructedSewageNetworkArea || ""}
                                                            isInvalid={(props.touched as any).constructedSewageNetworkArea && !!(props.errors as any).constructedSewageNetworkArea}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).constructedSewageNetworkArea}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.area_from_shape")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            value={(() => {
                                                                if ((props.values as any).constructedSewageNetworkShape) {
                                                                    let wkt = new WKT();
                                                                    let constructedSewageNetworkShapeGeom = wkt.readGeometry((props.values as any).constructedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                                                    let factor = getPointResolution('EPSG:3857', 1, (constructedSewageNetworkShapeGeom as Polygon).getFirstCoordinate());
                                                                    let acres = ((constructedSewageNetworkShapeGeom as Polygon).getArea() * Math.pow(factor, 2)) / 1000;
                                                                    return acres.toFixed(2);
                                                                } else {
                                                                    return "";
                                                                }
                                                            })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="constructedSewageNetworkLength"
                                                            name="constructedSewageNetworkLength"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).constructedSewageNetworkLength || ""}
                                                            isInvalid={(props.touched as any).constructedSewageNetworkLength && !!(props.errors as any).constructedSewageNetworkLength}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).constructedSewageNetworkLength}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <h4>{t("settlements.connected_sewage_network")}</h4>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.area")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkArea"
                                                            name="connectedSewageNetworkArea"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedSewageNetworkArea || ""}
                                                            isInvalid={(props.touched as any).connectedSewageNetworkArea && !!(props.errors as any).connectedSewageNetworkArea}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedSewageNetworkArea}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Form.Label column className="text-end" md={{ span: 3 }}>{t("settlements.area_from_shape")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            value={(() => {
                                                                if ((props.values as any).connectedSewageNetworkShape) {
                                                                    let wkt = new WKT();
                                                                    let connectedSewageNetworkShapeGeom = wkt.readGeometry((props.values as any).connectedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
                                                                    let factor = getPointResolution('EPSG:3857', 1, (connectedSewageNetworkShapeGeom as Polygon).getFirstCoordinate());
                                                                    let acres = ((connectedSewageNetworkShapeGeom as Polygon).getArea() * Math.pow(factor, 2)) / 1000;
                                                                    return acres.toFixed(2);
                                                                } else {
                                                                    return "";
                                                                }
                                                            })()}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.length")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="connectedSewageNetworkLength"
                                                            name="connectedSewageNetworkLength"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).connectedSewageNetworkLength || ""}
                                                            isInvalid={(props.touched as any).connectedSewageNetworkLength && !!(props.errors as any).connectedSewageNetworkLength}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).connectedSewageNetworkLength}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <hr />
                                                <h4>{t("settlements.sewage_network_data")}</h4>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.sewage_network_type")}</Form.Label>
                                                    <Col md={{ span: 4 }}>
                                                        <Form.Select
                                                            id="sewageNetworkTypeId"
                                                            name="sewageNetworkTypeId"
                                                            value={(props.values as any).sewageNetworkTypeId ?? 0}
                                                            onChange={v => props.setFieldValue("sewageNetworkTypeId", v.target.value)}
                                                            isInvalid={props.touched.sewageNetworkTypeId && !!props.errors.sewageNetworkTypeId}
                                                        >
                                                            <option key={"none"} value={0}>{t("wtp.waste_origin.served_sewage_agglomeration.modal.select_network_type")}</option>
                                                            {sewageNetworkTypes.map(x => <option key={x.id} value={x.id}>{i18n.language === "en" ? x.nameEn : x.name}</option>)}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {props.errors.sewageNetworkTypeId}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-2">
                                                    <Form.Label column className="text-end" md={{ span: 5 }}>{t("settlements.pumping_station_count")}</Form.Label>
                                                    <Col md={{ span: 2 }}>
                                                        <Form.Control
                                                            id="pumpingStationCount"
                                                            name="pumpingStationCount"
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={(props.values as any).pumpingStationCount || ""}
                                                            isInvalid={(props.touched as any).pumpingStationCount && !!(props.errors as any).pumpingStationCount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {(props.errors as any).pumpingStationCount}
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col md={{ span: 5 }}>
                                                <h4>{t("settlements.settlement_limits")}</h4>
                                                <div style={{ width: "100%", height: "500px" }}>
                                                    <RMap
                                                        className='w-100 h-100'
                                                        initial={((props.values as any).lon && (props.values as any).lat) ? { center: fromLonLat([(props.values as any).lon, (props.values as any).lat]), zoom: 15 } : { center: fromLonLat([23.209779819621144, 38.566884483911025]), zoom: 5 }}
                                                        ref={mapRef}
                                                    >
                                                        <RControl.RCustom className="m-2">
                                                            {/* <Container fluid> */}
                                                            <Row className="g-1">
                                                                <Col xs={'auto'}>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2 pe-none fw-normal">
                                                                                {t("settlements.settlement_limits")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2 pe-none fw-normal">
                                                                                {t("settlements.map.constructed_sewage_network")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2 pe-none fw-normal">
                                                                                {t("settlements.map.connected_sewage_network")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={'auto'}>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => { setShapeDrawing(!shapeDrawing); }}>
                                                                                <PencilFill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => { setConstructedDrawing(!constructedDrawing); }}>
                                                                                <PencilFill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => { setConnectedDrawing(!connectedDrawing); }}>
                                                                                <PencilFill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={'auto'}>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => props.setFieldValue("shape", null)}>
                                                                                <Trash3Fill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => props.setFieldValue("constructedSewageNetworkShape", null)}>
                                                                                <Trash3Fill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Button variant="secondary" className="w-100 d-flex align-items-center justify-content-center px-2" onClick={() => props.setFieldValue("connectedSewageNetworkShape", null)}>
                                                                                <Trash3Fill />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {/* </Container> */}
                                                            {/* {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 &&
                                                                <ButtonToolbar>
                                                                    <ButtonGroup className="col">
                                                                        <Button variant="secondary" className='col-10' style={{ width: "fit-content" }} onClick={() => { setShapeDrawing(!shapeDrawing); }}>
                                                                            <PencilFill /> {t("settlements.settlement_limits")}
                                                                        </Button>
                                                                        <Button variant="secondary" className='col-2' style={{ width: "fit-content" }} onClick={() => { setShapeDrawing(!shapeDrawing); }}>
                                                                            <Trash />
                                                                        </Button>
                                                                    <Button variant="secondary" className='col-2' style={{ width: "fit-content" }} onClick={() => { setShapeDrawing(!shapeDrawing); }}>
                                                                        <Trash />
                                                                    </Button>
                                                                    </ButtonGroup>
                                                                </ButtonToolbar>} */}
                                                        </RControl.RCustom>
                                                        <RControl.RLayers className="white-bg m-2 end-0 p-2" element={<Button variant="secondary"><Layers /></Button>}>
                                                            <ROSM properties={{ label: "Map" }} />
                                                            <BingMap properties={{ label: "Satellite" }} accessToken={"AiiKpOh7qCxINCUv1giKXP4j8ycjp0iVmqApb6FmzMlX6erSMM3LzBNr7_hg7wKA"} />
                                                        </RControl.RLayers>
                                                        <RLayerVector
                                                            zIndex={11}
                                                            onAddFeature={(e: BaseEvent) => {
                                                                let source = e.target as VectorSource<Geometry>;
                                                                if (!source?.forEachFeatureAtCoordinateDirect) {
                                                                    return;
                                                                }

                                                                if (!(shapeDrawing || constructedDrawing || connectedDrawing)) return;

                                                                let feats = source.getFeatures();
                                                                if (feats.length > 0) {
                                                                    let geom = feats.filter(x => !x.getProperties().type)[0]?.getGeometry();
                                                                    if (geom) {
                                                                        let wkt = new WKT();
                                                                        let wktGeom = wkt.writeGeometry(geom, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });

                                                                        if (shapeDrawing) {
                                                                            props.setFieldValue("shape", wktGeom);
                                                                        }

                                                                        if (constructedDrawing) {
                                                                            props.setFieldValue("constructedSewageNetworkShape", wktGeom);
                                                                        }

                                                                        if (connectedDrawing) {
                                                                            props.setFieldValue("connectedSewageNetworkShape", wktGeom);
                                                                        }

                                                                        source.clear();
                                                                        setShapeDrawing(false);
                                                                        setConstructedDrawing(false);
                                                                        setConnectedDrawing(false);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {props.values.shape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(props.values.shape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "shape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 200, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(0, 200, 0, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>}
                                                            {props.values.constructedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(props.values.constructedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "constructedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(255, 60, 0, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>}
                                                            {props.values.connectedSewageNetworkShape && <RFeature
                                                                key={`Feature${++unique_id}`}
                                                                geometry={(new WKT()).readGeometry(props.values.connectedSewageNetworkShape, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })}
                                                                properties={{ type: "connectedShape" }}
                                                            >
                                                                <RStyle.RStyle>
                                                                    <RStyle.RFill color="rgba(0, 138, 255, 0.5)"></RStyle.RFill>
                                                                    <RStyle.RStroke width={2} color="rgba(200, 50, 50, 1)"></RStyle.RStroke>
                                                                </RStyle.RStyle>
                                                            </RFeature>}
                                                            {(shapeDrawing || constructedDrawing || connectedDrawing) && <RInteraction.RDraw
                                                                type={"Polygon"}
                                                            />}
                                                        </RLayerVector>
                                                    </RMap>
                                                    <Row className='justify-content-center'>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 200, 0, 0.5)" style={{ border: "2px solid rgba(0, 200, 0, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.settlement_limits")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(255, 60, 0, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.constructed_sewage_network")}</Form.Text></Col>
                                                        <Col style={{ maxWidth: "fit-content" }}><SquareFill fill="rgba(0, 138, 255, 0.5)" style={{ border: "2px solid rgba(200, 50, 50, 1)", borderRadius: "5px", marginRight: "8px" }} /><Form.Text muted>{t("settlements.map.connected_sewage_network")}</Form.Text></Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ButtonGroup className='position-fixed bottom-0 end-0 me-2 mb-2 p-2' style={{ backgroundColor: "#00000010" }} aria-label="Toolbar">
                                        <Button variant="success" type="submit" className="d-flex align-items-center justify-content-center m-0" disabled={saving}>{saving ? <><Spinner variant="light" animation="border" size="sm" className="me-2" /> {t("wtp.button.saving")}</> : <><Save className="me-2"></Save> {t("wtp.button.save")}</>}</Button>
                                        <Button variant="danger" type="reset" className="d-flex align-items-center justify-content-center m-0" disabled={saving}><ArrowCounterclockwise className="me-2"></ArrowCounterclockwise> {t("wtp.button.reset")}</Button>
                                        {context.state.userInfo?.roles.findIndex(x => x.toLowerCase() === "reporter") !== -1 && <Button variant="secondary" className="d-flex align-items-center justify-content-center m-0" onClick={() => navigate('/settlements/index')} disabled={saving}><SkipBackward className="me-2"></SkipBackward> {t("wtp.button.back")}</Button>}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </form>
                    </>)}
            </Formik>}
            {!loading && loadingError && <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <pre>{JSON.stringify(error, null, 4)}</pre>
            </Alert>}
            {loading && <div className="d-flex justify-content-center w-100" style={{ minHeight: "calc(100vh - 294px)" }}>
                <div className="spinner-border" style={{ width: "10rem", height: "10rem", marginTop: "200px" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
        </Container >
    );
}

export default SettlementsEdit;
